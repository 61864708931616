import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import useButtonLoading from "@/hooks/useButtonLoading";
import {
  InitialShuttleBasicInput,
  ShuttleBasicInputSchema,
  ShuttleBasicSearch,
} from "@/types/shuttleBasic.type";
import {
  useDeleteMutation,
  usePostMutation,
  usePutMutation,
} from "@/util/common.fn";
import { ContentTemplate } from "@/components/templates/ContentTemplate";
import ShuttleBasicGrid from "./ShuttleBasicGrid";
import ShuttleBasicInput from "./ShuttleBasicInput";
import {
  ForwarderInputSchema,
  InitialForwarderInput,
} from "@/types/forwarder.type";
import { yupResolver } from "@hookform/resolvers/yup";

const ShuttleBasicPage = () => {
  const [search, setSearch] = useState(ShuttleBasicSearch);
  const [selectedRowKey, setSelectedRowKey] = useState<string | null>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const gridMain = useRef<any>();

  const { alertErrorMessage, alertSuccessMessage, confirmMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { data: mainData, refetch: mainRefetch } = useCustomQuery<any[]>(
    `shuttlebasic`,
    search
  );
  const { data: inputData } = useCustomQuery<any[]>(
    `shuttlebasic/${selectedRowKey}`,
    {},
    !!selectedRowKey
  );

  const { mutateAsync: create } = usePostMutation("shuttlebasic");
  const { mutateAsync: update } = usePutMutation("shuttlebasic");
  const { mutateAsync: remove } = useDeleteMutation("shuttlebasic");

  const searchForm = useForm({
    defaultValues: ShuttleBasicSearch,
  });

  const inputForm = useForm({
    defaultValues: InitialShuttleBasicInput,
    resolver: yupResolver(ShuttleBasicInputSchema),
  });

  const { reset, watch, trigger } = inputForm;

  useEffect(() => {
    inputData && reset(inputData);
    // eslint-disable-next-line
  }, [inputData]);

  const initPage = () => {
    const mainGridInstance = gridMain.current.instance;
    mainGridInstance.option("focusedRowIndex", -1);
    resetGridChanges();
    setSelectedRowKey(null);
    mainRefetch();
    reset({
      importExport: 0,
      containerNo: "",
    });
  };

  const resetGridChanges = () => {
    const mainGridInstance = gridMain.current.instance;
    mainGridInstance.option("editing.changes", []);
  };

  const toggleOpen = () => setOpen(!isOpen);

  const onSearch = (data: any) => {
    setSearch(data);
    mainRefetch();
  };

  const onClickNewData = () => {
    initPage();
  };
  const onClickSaveData = async () => {
    if (!(await trigger())) return;

    const data = watch();
    const mainGridInstance = gridMain.current.instance;
    const originalKey = selectedRowKey;

    let res: any;

    try {
      setLoading();
      if (selectedRowKey) {
        res = await update({ key: selectedRowKey, data });
      } else {
        res = await create(data);
      }

      if (res.state === 1) {
        initPage();
        await alertSuccessMessage("저장완료");
      }

      mainGridInstance.option("focusedRowKey", originalKey);
    } catch (error: any) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onClickDeleteData = async () => {
    if (!selectedRowKey) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }
    try {
      setLoading();
      if (!(await confirmMessage("삭제하시겠습니까?"))) return;

      await remove({ key: selectedRowKey });

      await alertSuccessMessage("삭제완료");

      initPage();
    } catch (error: any) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onFocusedRowChanged = (e: any) => {
    if (!e.row?.key) return;
    setSelectedRowKey(e.row.key);
  };

  return (
    <ContentTemplate sideWidth={280}>
      <>
        <ShuttleBasicGrid
          gridMain={gridMain}
          mainData={mainData}
          searchForm={searchForm}
          toggleOpen={toggleOpen}
          onSearch={onSearch}
          onFocusedRowChanged={onFocusedRowChanged}
        />
        <ShuttleBasicInput
          selectedRowKey={selectedRowKey}
          inputForm={inputForm}
          toggleOpen={toggleOpen}
          onClickNewData={onClickNewData}
          onClickSaveData={onClickSaveData}
          onClickDeleteData={onClickDeleteData}
        />
      </>
    </ContentTemplate>
  );
};

export default ShuttleBasicPage;
