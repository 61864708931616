import { Controller } from "react-hook-form";
import { ColorBox } from "devextreme-react";
import { Input, InputProps } from "..";

const InputColorBox = ({
  control,
  errors,
  name,
  label,
  height,
  width,
  required,
  disabled = false,
  onValueChanged = () => {},
  maxLength,
  tabIndex,
  direction,
  className,
}: InputProps) => {
  return (
    <Input
      type="colorBox"
      direction={direction ? direction : `row`}
      label={label && label}
      required={required}
      errors={errors && errors}
    >
      <Controller
        render={({ field: { onChange, value } }) => {
          return (
            <ColorBox
              className={className}
              onValueChanged={(value) => {
                onChange(value.value);
                onValueChanged(value);
              }}
              value={`${value}`}
              disabled={disabled}
              height={height}
              width={width}
              maxLength={maxLength}
              tabIndex={tabIndex}
            />
          );
        }}
        control={control}
        name={name}
        defaultValue=""
      />
    </Input>
  );
};

export default InputColorBox;
