import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as S from "./LanguageSelect.style";

const LanguageSelect = () => {
  const { t } = useTranslation("main");
  const { register } = useForm();
  return (
    <S.LanguageSelectBox>
      <S.LanguageLabel htmlFor="language">{t("언어")}</S.LanguageLabel>
      <S.LanguageSelect
        {...register("language")}
        id="lang"
        onChange={(e: any) => {
          localStorage.setItem("language", e.target.value);

          window.location.reload();
        }}
        defaultValue={localStorage.getItem("language") || "ko"}
      >
        <option value="ko">한국어</option>
        <option value="en">English</option>
      </S.LanguageSelect>
    </S.LanguageSelectBox>
  );
};

export default LanguageSelect;
