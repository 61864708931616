import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  Column,
  CustomRule,
  Editing,
  Format,
  Lookup,
  RequiredRule,
} from "devextreme-react/data-grid";
import { 하불구분, 할증코드 } from "@/data/constant/queryKeys";
import { useComCodeData, useDriverData, useYongchaData } from "@/hooks/queries";
import Grid from "@/components/atoms/Grid";
import { Buttons } from "@/components/atoms/Buttons";
import { Modal } from "..";
import * as S from "./ExtraChargeModal.style";

interface ExtraChargeModalProps {
  onClose: () => void;
  extraChargeData: any[];
  etcChargeHabulData: any[];
  extraChargeRef: any;
  etcChargeHabulRef: any;
  onExtraChargeSubmit: () => Promise<false | undefined>;
}

const ExtraChargeModal = ({
  onClose,
  extraChargeData,
  etcChargeHabulData,
  extraChargeRef,
  etcChargeHabulRef,
  onExtraChargeSubmit,
}: ExtraChargeModalProps) => {
  const { t } = useTranslation("main");
  const { data: division } = useComCodeData(할증코드);
  const { data: habulDivision } = useComCodeData(하불구분);

  const { data: billingPlace } = useYongchaData();
  const { data: car } = useDriverData();

  const numberValidationCheck = (e: any) => {
    const { value } = e;
    if (value < 1_000_000_000) return true;
    return false;
  };

  const onExtraChargeToolbarPreparing = useCallback((e: any) => {
    e.toolbarOptions.items.forEach((item: any) => {
      if (item.name === "saveButton") {
        item.visible = false;
      }
    });
  }, []);

  const onEditingStart = useCallback((e: any) => {
    const 업체 = 4304;
    const 기사 = 4305;
    const {
      data: { habulDivision },
      column: { dataField },
    } = e;

    if (
      (Number(habulDivision) === 업체 && dataField === "fromDirverNo") ||
      (Number(habulDivision) === 기사 && dataField === "fromCompany") ||
      (!habulDivision &&
        (dataField === "fromDirverNo" || dataField === "fromCompany"))
    ) {
      e.cancel = true;
    }
  }, []);

  // 기타하불 업체, 기사 토글 함수
  const onOptionChanged = useCallback(
    (e: any) => {
      const 업체 = 4304;
      const 기사 = 4305;
      const { fullName, value } = e;
      if (fullName !== "editing.changes") return;
      if (!Array.isArray(value)) return;

      const instance = etcChargeHabulRef.current.instance;

      for (const obj of value) {
        const { data, key, type } = obj;
        if (type === "remove") continue;
        const { habulDivision, fromDirverNo, fromCompany } = data;

        const rowIndex = instance.getRowIndexByKey(key);

        if (Number(habulDivision) === 업체 && fromDirverNo !== null) {
          instance.cellValue(rowIndex, "fromDirverNo", null);
        } else if (Number(habulDivision) === 기사 && fromCompany !== null) {
          instance.cellValue(rowIndex, "fromCompany", null);
        }
      }
    },
    [etcChargeHabulRef]
  );

  return (
    <Modal
      width="800px"
      close={onClose}
      title="할증등록"
      modalButton={
        <Buttons
          permission="save"
          type="button"
          size="lg"
          width={80}
          layout="solid"
          color="save"
          label="저장"
          onClick={onExtraChargeSubmit}
        />
      }
    >
      <S.ECModalContentBox>
        <S.ECModalContent>
          <Grid
            subGrid={true}
            dataSource={extraChargeData}
            refs={extraChargeRef}
            onToolbarPreparing={onExtraChargeToolbarPreparing}
            keyExpr="id"
          >
            <Editing
              mode="batch"
              allowAdding={true}
              allowDeleting={true}
              allowUpdating={true}
            />
            <Column dataField="division" caption={t("청구구분")}>
              <Lookup
                dataSource={division}
                valueExpr="id"
                displayExpr="subName"
              />
              <RequiredRule message="구분을 선택해주세요" />
            </Column>
            <Column
              dataField="chargedAmount"
              caption={t("할증금액")}
              alignment="right"
              dataType="number"
            >
              <Format type="fixedPoint" />
              <RequiredRule message="할증 금액을 입력해주세요" />
              <CustomRule
                message="10억 이하로 입력해주세요"
                validationCallback={numberValidationCheck}
              />
            </Column>
          </Grid>
        </S.ECModalContent>

        <S.ECModalContent>
          <Grid
            subGrid={true}
            dataSource={etcChargeHabulData}
            refs={etcChargeHabulRef}
            onToolbarPreparing={onExtraChargeToolbarPreparing}
            onEditingStart={onEditingStart}
            onOptionChanged={onOptionChanged}
            keyExpr="id"
          >
            <Editing
              mode="batch"
              allowAdding={true}
              allowDeleting={true}
              allowUpdating={true}
            />
            <Column
              dataField="habulDivision"
              caption={t("하불구분")}
              width="70"
            >
              <Lookup
                dataSource={habulDivision}
                valueExpr="id"
                displayExpr="subName"
              />
              <RequiredRule message="하불구분을 선택해주세요" />
            </Column>
            <Column dataField="fromCompany" caption={t("하불처")}>
              <Lookup
                dataSource={[
                  { oid: null, company: "" },
                  ...(billingPlace || []),
                ]}
                valueExpr="oid"
                displayExpr="company"
              />
            </Column>
            <Column dataField="fromDirverNo" caption={t("기사명")}>
              <Lookup
                dataSource={[{ oid: null, carNo_Name: "" }, ...(car || [])]}
                valueExpr="oid"
                displayExpr="carNo_Name"
              />
            </Column>
            <Column
              dataField="habul"
              caption={t("하불금액")}
              alignment="right"
              dataType="number"
            >
              <Format type="fixedPoint" />
              <RequiredRule message="하불 금액을 입력해주세요" />
              <CustomRule
                message="10억 이하로 입력해주세요"
                validationCallback={numberValidationCheck}
              />
            </Column>
            <Column dataField="etcHabulRemark" caption={t("하불비고")} />
            <Column dataField="extraRemark" caption={t("청구비고")} />
          </Grid>
        </S.ECModalContent>
      </S.ECModalContentBox>
    </Modal>
  );
};

export default ExtraChargeModal;
