import * as yup from "yup";

export const ShuttleBasicSearch = {
  importExport: 0,
  containerNo: "",
};

export interface ShuttleBasicType {
  sortNo: string;
  importExport: string;
  cargoOwner: string;
  billingPlace: string;
  containerType: string;
  containerNo: string;
  loadPlace: string;
  workPlace?: string;
  unloadPlace?: string;
  freight: number;
  habul01?: number;
  habul02?: number;
}

export const ShuttleBasicInputSchema = yup.object<ShuttleBasicType>({
  sortNo: yup.string().nullable().required("정렬순서를 입력해주세요"),
  importExport: yup.string().required("수입/수출을 입력해주세요"),
  cargoOwner: yup.string().required("화주를 입력해주세요"),
  billingPlace: yup.string().required("청구처를 입력해주세요"),
  containerType: yup.string().required("컨규격를 입력해주세요"),
  containerNo: yup.string().required("컨테이너 번호를 입력해주세요"),
  loadPlace: yup.string().required("상차지를 입력해주세요"),
  workPlace: yup.string().nullable(),
  unloadPlace: yup.string().nullable(),
  freight: yup.string().required("청구금액을 입력해주세요"),
  habul01: yup.string().nullable(),
  habul02: yup.string().nullable(),
});

export const InitialShuttleBasicInput: ShuttleBasicType = {
  sortNo: "",
  importExport: "",
  cargoOwner: "",
  billingPlace: "",
  containerType: "",
  containerNo: "",
  loadPlace: "",
  workPlace: "",
  unloadPlace: "",
  freight: 0,
  habul01: 0,
  habul02: 0,
};
