import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputSelect, InputText } from "@/components/atoms/Inputs";
import InputTextArea from "@/components/atoms/Inputs/InputTextArea";
import { Buttons } from "@/components/atoms/Buttons";
import { FormContent } from "@/components/organisms/Contents/FormContent";
import { ReactComponent as IconCreate } from "@/styles/assets/svg/icon_button_create.svg";
import { ReactComponent as IconSave } from "@/styles/assets/svg/icon_button_save.svg";
import { ReactComponent as IconDelete } from "@/styles/assets/svg/icon_button_delete.svg";

interface UserInputProps {
  selectedRowKey: string | null;
  inputForm: UseFormReturn<any>;
  status: any[] | undefined;
  company: any[] | undefined;
  duty: any[] | undefined;
  department: any[] | undefined;
  auth: any[] | undefined;
  driver: any[] | undefined;

  toggleOpen: () => void;
  onClickNewData: () => void;
  onClickSaveData: () => Promise<void>;
  onClickDeleteData: () => Promise<void>;
}

const UserInput = ({
  selectedRowKey,
  inputForm,
  status,
  company,
  duty,
  department,
  auth,
  driver,
  toggleOpen,
  onClickNewData,
  onClickSaveData,
  onClickDeleteData,
}: UserInputProps) => {
  const { t } = useTranslation("main");
  const {
    control,
    formState: { errors },
  } = inputForm;

  return (
    <FormContent
      title={t("사용자 등록")}
      titleButtons={
        <Buttons
          type="button"
          size="sm"
          layout="text"
          color="primary100"
          icon={<IconCreate />}
          label="신규입력"
          onClick={onClickNewData}
        />
      }
      inputForms={
        <>
          <InputText
            label="사용자ID"
            name="userId"
            direction="column"
            control={control}
            errors={errors}
            disabled={selectedRowKey}
            required={true}
          />
          {!selectedRowKey && (
            <>
              <InputText
                label="비밀번호"
                name="password"
                direction="column"
                control={control}
                errors={errors}
                required={true}
              />
              <InputText
                label="비밀번호 확인"
                name="passwordConfirm"
                direction="column"
                control={control}
                errors={errors}
                required={true}
              />
            </>
          )}
          <InputSelect
            label="사용상태"
            name="userStatus"
            direction="column"
            displayExpr="subName"
            valueExpr="subCode"
            control={control}
            errors={errors}
            dataSource={status}
            required={true}
          />

          <InputText
            label="사용자명"
            name="userName"
            direction="column"
            control={control}
            errors={errors}
            required={true}
          />
          <InputText
            label="영문이름"
            name="englishName"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="이메일"
            name="mail"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="휴대전화"
            name="hp"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="직통번호"
            name="tel"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="팩스번호"
            name="fax"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputSelect
            label="소속법인"
            name="companyCode"
            direction="column"
            displayExpr="companyName"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={company}
            required={true}
          />
          <InputSelect
            label="직책"
            name="dutyCode"
            direction="column"
            displayExpr="subName"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={duty}
          />
          <InputSelect
            label="소속부서"
            name="deptCode"
            direction="column"
            displayExpr="deptName"
            valueExpr="oid"
            control={control}
            errors={errors}
            dataSource={department}
          />
          <InputSelect
            label="화면그룹"
            name="authGroupId"
            direction="column"
            displayExpr="authGroupName"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={auth}
            required={true}
          />
          <InputSelect
            label="기사"
            name="driverId"
            direction="column"
            displayExpr="carNo_Name"
            valueExpr="oid"
            control={control}
            errors={errors}
            dataSource={[{ oid: "", carNo_Name: "" }, ...(driver || [])]}
          />
          <InputTextArea
            label="비고"
            name="remark"
            direction="column"
            control={control}
            errors={errors}
          />
        </>
      }
      formButtons={
        <>
          <Buttons
            permission="save"
            type="button"
            size="sm"
            layout="solid"
            color="save"
            icon={<IconSave />}
            label="저장"
            onClick={onClickSaveData}
          />
          <Buttons
            permission="delete"
            type="button"
            size="sm"
            layout="solid"
            color="destructive"
            icon={<IconDelete />}
            label="삭제"
            onClick={onClickDeleteData}
          />
        </>
      }
    >
      {/* <Button
        className="input-back"
        variety="tran"
        size="xxsm"
        shape="solid"
        type="button"
        onClick={toggleOpen}
      >
        <MovePageIcon width={26} height={26} viewBox="0 0 24 24" />
        <span>{t("뒤로")}</span>
      </Button> */}
    </FormContent>
  );
};

export default UserInput;
