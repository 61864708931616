import { UseFieldArrayReturn, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { 작업지시상태_디테일 } from "@/data/constant/queryKeys";
import {
  useComCodeData,
  useDriverData,
  useTerminalData,
  useYongchaData,
} from "@/hooks/queries";
import {
  InputDate,
  InputRadioBox,
  InputSelect,
  InputText,
  InputNumber,
} from "../Inputs";
import { Buttons } from "../Buttons";
import * as S from "./DispatchItem.style";

const DISPATCH_STATUS = {
  접수: "62",
  배차등록: "78",
  배차확정: "79",
  운송완료: "80",
  장기보관: "80",
  창고작업완료: "4048",
  운송중: "4309",
};

interface DispatchItemProps {
  dispatch: Record<"id", string>;
  index: number;
  inputForm: UseFormReturn<any>;
  dispatchFieldArray: UseFieldArrayReturn<any, "dispatches", "id">;
  isAppendAble?: boolean;
}

const DispatchItem = ({
  dispatch,
  index,
  inputForm,
  dispatchFieldArray,
  isAppendAble = true,
}: DispatchItemProps) => {
  const { t } = useTranslation("main");

  const { data: terminal } = useTerminalData();
  const { data: status } = useComCodeData(작업지시상태_디테일);
  const { data: car } = useDriverData();
  const { data: yongchasa } = useYongchaData();

  const {
    watch,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = inputForm;

  const { insert, remove } = dispatchFieldArray;

  const onChangeStartPoint = (data: any, id: number) => {
    const dispatches = getValues("dispatches");
    if (Array.isArray(dispatches) && dispatches[id - 1]) {
      setValue(`dispatches[${id - 1}].endPoint`, data.value);
    }
  };

  const onChangeEndPoint = (data: any, id: number) => {
    const dispatches = getValues("dispatches");
    if (Array.isArray(dispatches) && dispatches[id + 1]) {
      setValue(`dispatches[${id + 1}].startPoint`, data.value);
    }
  };

  const onClickInsert = (index: number) => {
    const dispatches = getValues("dispatches");
    if (Array.isArray(dispatches)) {
      const targetDispatch = dispatches[index];
      const nextDispatch = dispatches[index + 1];

      nextDispatch && setValue(`dispatches[${index + 1}].startPoint`, "");
      insert(index + 1, {
        startPoint: targetDispatch ? targetDispatch.endPoint : "",
        endPoint: "",
        dispatchStatus: DISPATCH_STATUS.접수,
        carTypeCode: "carNo",
        deliverDate: null,
        habul: null,
      });
    }
  };

  const onClickRemove = (index: number) => {
    // db에서 가져온 데이터면 여기서 api로 바로 삭제
    const dispatches = getValues("dispatches");
    if (
      Array.isArray(dispatches) &&
      dispatches[index + 1] &&
      dispatches[index - 1]
    ) {
      const prevDispatchEndPoint = dispatches[index - 1].endPoint;

      setValue(`dispatches[${index + 1}].startPoint`, prevDispatchEndPoint);
    }
    remove(index);
  };

  return (
    <S.DispatchItemBox key={dispatch.id}>
      <InputSelect
        label="출발지"
        name={`dispatches[${index}].startPoint`}
        direction="column"
        displayExpr="twName"
        valueExpr="code"
        control={control}
        errors={errors}
        dataSource={terminal}
        onValueChanged={(data: any) => onChangeStartPoint(data, index)}
        index={index}
      />
      <InputSelect
        label="도착지"
        name={`dispatches[${index}].endPoint`}
        direction="column"
        displayExpr="twName"
        valueExpr="code"
        control={control}
        errors={errors}
        dataSource={terminal}
        onValueChanged={(data: any) => onChangeEndPoint(data, index)}
      />
      <InputSelect
        label="상태"
        name={`dispatches[${index}].dispatchStatus`}
        direction="column"
        displayExpr="subName"
        valueExpr="id"
        control={control}
        errors={errors}
        dataSource={status}
      />
      <InputDate
        label="운송일자"
        name={`dispatches[${index}].deliverDate`}
        direction="column"
        control={control}
        errors={errors}
      />
      <InputNumber
        label="하불금액"
        name={`dispatches[${index}].habul`}
        direction="column"
        control={control}
        errors={errors}
      />

      {/* 차량번호, 용차사. */}

      {isAppendAble && (
        <S.DispatchButtonSet>
          <Buttons
            permission="save"
            type="button"
            size="sm"
            layout="solid"
            color="primary100"
            label="추가"
            onClick={() => onClickInsert(index)}
          />
          <Buttons
            permission="delete"
            type="button"
            size="sm"
            layout="solid"
            color="destructive"
            label="삭제"
            onClick={() => onClickRemove(index)}
          />
        </S.DispatchButtonSet>
      )}
      <S.DispatchItemSelect>
        <InputRadioBox
          name={`dispatches[${index}].carTypeCode`}
          valueExpr="key"
          displayExpr="value"
          control={control}
          errors={errors}
          dataSource={[
            { key: "carNo", value: t("차량번호") },
            { key: "yongchasa", value: t("용차사") },
          ]}
        />
      </S.DispatchItemSelect>
      {watch(`dispatches[${index}].carTypeCode`) === "carNo" && (
        <InputSelect
          label="배차차량"
          name={`dispatches[${index}].carNo`}
          direction="column"
          displayExpr="carNo_Name"
          valueExpr="oid"
          control={control}
          errors={errors}
          dataSource={car}
        />
      )}
      {watch(`dispatches[${index}].carTypeCode`) === "yongchasa" && (
        <>
          <InputSelect
            label="용차사"
            name={`dispatches[${index}].yongchasa`}
            direction="column"
            displayExpr="company"
            valueExpr="oid"
            control={control}
            errors={errors}
            dataSource={yongchasa}
          />
          <InputText
            label="차량번호"
            name={`dispatches[${index}].yongchaCarNo`}
            direction="column"
            control={control}
            errors={errors}
          />
        </>
      )}
    </S.DispatchItemBox>
  );
};

export default DispatchItem;
