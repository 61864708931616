import * as S from "./FormContent.style";

interface FormContentProps {
  bottomHeight?: number;
  title?: string;
  titleButtons?: React.ReactElement;
  inputForms?: React.ReactElement;
  inputFormGrid?: number;
  formButtons?: React.ReactElement;
  children?: React.ReactElement;
}

export const FormContent = ({
  bottomHeight,
  title,
  titleButtons,
  inputForms,
  inputFormGrid,
  formButtons,
  children,
}: FormContentProps) => {
  return (
    <S.FormContentBox bottomHeight={bottomHeight}>
      <S.FormTitleBox>
        {title}
        {titleButtons}
      </S.FormTitleBox>
      {inputForms && (
        <>
          <S.InputFormBox scroll={inputFormGrid ? false : true}>
            <S.InputForm inputFormGrid={inputFormGrid}>
              {inputForms}
            </S.InputForm>
            <S.FormButtonBox>{formButtons}</S.FormButtonBox>
          </S.InputFormBox>
        </>
      )}
      {children}
    </S.FormContentBox>
  );
};
