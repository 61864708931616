import { useEffect } from "react";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  useBillingData,
  useCargoOwnerData,
  useComCodeData,
  useCompanyData,
  useDispatchData,
  useForwarderData,
} from "@/hooks/queries";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import { 컨테이너_규격 } from "@/data/constant/queryKeys";
import { ShuttleDispatchInputType } from "@/types/shuttleDispatch.type";
import { InputDate, InputSelect, InputText } from "@/components/atoms/Inputs";
import InputNumber from "@/components/atoms/Inputs/InputNumber";
import { Buttons } from "@/components/atoms/Buttons";
import { FormContent } from "@/components/organisms/Contents/FormContent";
import { ReactComponent as IconCreate } from "@/styles/assets/svg/icon_button_create.svg";
import { ReactComponent as IconSave } from "@/styles/assets/svg/icon_button_save.svg";
import { ReactComponent as IconDelete } from "@/styles/assets/svg/icon_button_delete.svg";
import DispatchItem from "@/components/atoms/DispatchItem";
import { InputMultiple } from "@/components/atoms/InputMultiple";

interface ShuttleDispatchInputProps {
  selectedRowKey: string | null;
  openinputbar: boolean;

  inputForm: UseFormReturn<ShuttleDispatchInputType, any, undefined>;
  toggleOpen: () => void;
  onClickShuttleData: () => void;
  onClickNewData: () => void;
  onClickSaveData: () => Promise<void>;
  onClickDeleteData: () => Promise<void>;
}

const ShuttleDispatchInput = ({
  selectedRowKey,
  openinputbar,
  inputForm,
  toggleOpen,
  onClickShuttleData,
  onClickNewData,
  onClickSaveData,
  onClickDeleteData,
}: ShuttleDispatchInputProps) => {
  const { t } = useTranslation("main");
  const { data } = useCustomQuery<{ masterDetail: any; dispatches: any[] }>(
    `shuttlesimple/${selectedRowKey}`,
    {},
    !!selectedRowKey
  );

  const { data: company } = useCompanyData();
  const { data: dispatch } = useDispatchData();
  const { data: billing } = useBillingData();
  const { data: cargoOwner } = useCargoOwnerData();
  const { data: forwarder } = useForwarderData();
  const { data: conType } = useComCodeData(컨테이너_규격);

  const {
    control,
    reset,
    formState: { errors },
  } = inputForm;

  const dispatchFieldArray = useFieldArray({
    control,
    name: "dispatches", // 컨트롤하고자 하는 배열의 key값
  });

  const { fields } = dispatchFieldArray;

  useEffect(() => {
    data && reset(data);
    // eslint-disable-next-line
  }, [data]);

  return (
    <FormContent
      title={t("배차등록")}
      titleButtons={
        <Buttons
          type="button"
          size="sm"
          layout="text"
          color="primary100"
          icon={<IconCreate />}
          label="신규입력"
          onClick={onClickNewData}
        />
      }
      inputForms={
        <>
          <InputMultiple span="1 / 3">
            <>
              {/* 접수번호 */}
              <InputText
                label="접수번호"
                name="code"
                direction="column"
                control={control}
                errors={errors}
                disabled={true}
              />
              <Buttons
                permission="save"
                type="button"
                size="md"
                layout="solid"
                color="find"
                label="셔틀정보 불러오기"
                onClick={onClickShuttleData}
              />
            </>
          </InputMultiple>
          {/* 접수일자 */}
          <InputDate
            label="접수일자"
            name="receiveDate"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* 접수법인 */}
          <InputSelect
            label="접수법인"
            name="receiveCo"
            direction="column"
            displayExpr="companyName"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={company}
          />
          {/* 배차팀 */}
          <InputSelect
            label="배차팀"
            name="dispatchTeam"
            direction="column"
            displayExpr="deptName"
            valueExpr="code"
            control={control}
            errors={errors}
            dataSource={dispatch}
          />
          {/* 운송일자 */}
          <InputDate
            label="운송일자"
            name="deliverDate"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* 반납일자 */}
          <InputDate
            label="반납일자"
            name="returnDate"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* 화주 */}
          <InputSelect
            label="화주"
            name="cargoOwner"
            direction="column"
            displayExpr="company"
            valueExpr="oid"
            control={control}
            errors={errors}
            dataSource={cargoOwner}
          />
          {/* 수입/수출 */}
          <InputSelect
            label="수입/수출"
            name="importExport"
            direction="column"
            displayExpr="name"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={[
              { id: 1, name: t("수입") },
              { id: 2, name: t("수출") },
            ]}
          />
          {/* 청구처 */}
          <InputSelect
            label="청구처"
            name="billingPlace"
            direction="column"
            displayExpr="company"
            valueExpr="oid"
            control={control}
            errors={errors}
            dataSource={billing}
          />
          {/* 컨규격 */}
          <InputSelect
            label="컨규격"
            name="containerType"
            direction="column"
            displayExpr="subName"
            valueExpr="subCode"
            control={control}
            errors={errors}
            dataSource={conType}
          />
          {/* 컨테이너번호 */}
          <InputText
            label="컨테이너번호"
            name="containerNo"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* Seal No */}
          <InputText
            label="Seal No"
            name="sealNo"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* 화물중량 */}
          <InputText
            label="화물중량"
            name="cargoWeight"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* B/L No */}
          <InputText
            label="B/L No"
            name="blNo"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* DO No */}
          <InputText
            label="DO No"
            name="doNo"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* B/K No */}
          <InputText
            label="B/K No"
            name="bkNo"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* 모선/항차 */}
          <InputText
            label="모선/항차"
            name="motherShip"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* 선사 */}
          <InputSelect
            label="선사"
            name="forwarder"
            direction="column"
            displayExpr="forwarder_Name"
            valueExpr="code"
            control={control}
            errors={errors}
            dataSource={forwarder}
          />
          {/* 실화주 */}
          <InputText
            label="실화주"
            name="realCargoOwner"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* 청구금액 */}
          <InputNumber
            label="청구금액"
            name="freight"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* 담당자 */}
          <InputText
            label="담당자"
            name="manager"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* 특이사항 */}
          <InputText
            label="특이사항"
            name="extraInfo"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* 비고 */}
          <InputText
            label="비고"
            name="remark"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* 배차 */}
          {fields.map((dispatch, index) => {
            return (
              <DispatchItem
                key={index}
                dispatch={dispatch}
                index={index}
                inputForm={inputForm}
                dispatchFieldArray={dispatchFieldArray}
                isAppendAble={false}
              />
            );
          })}
        </>
      }
      inputFormGrid={2}
      formButtons={
        <>
          {/* <Buttons
            type="button"
            size="xxsm"
            layout="text"
            color="white100"
            icon={<MovePageIcon width={26} height={26} viewBox="0 0 24 24" />}
            label={t("뒤로")}
            onClick={toggleOpen}
          /> */}
          <Buttons
            permission="delete"
            type="button"
            size="sm"
            layout="solid"
            color="destructive"
            icon={<IconDelete />}
            label="삭제"
            onClick={onClickDeleteData}
          />
          <Buttons
            permission="save"
            type="button"
            size="sm"
            layout="solid"
            color="save"
            icon={<IconSave />}
            label="저장"
            onClick={onClickSaveData}
          />
        </>
      }
    />
  );
};

export default ShuttleDispatchInput;
