import { Controller } from "react-hook-form";
import { TextBox } from "devextreme-react";
import { Input, InputProps } from "..";

const InputText = ({
  value,
  control,
  errors,
  name,
  label,
  mask,
  height,
  width,
  required,
  disabled = false,
  onValueChanged = () => {},
  maxLength,
  tabIndex,
  direction,
  placeHolder,
}: InputProps) => {
  return (
    <Input
      type="text"
      name={name}
      direction={direction ? direction : `row`}
      label={label && label}
      required={required}
      errors={errors && errors}
    >
      <Controller
        render={({ field: { onChange, value } }) => {
          if (value === "") value = null;

          return (
            <TextBox
              mask={mask}
              useMaskedValue={true}
              onValueChanged={(value) => {
                onChange(value.value);
                onValueChanged(value);
              }}
              name={name}
              value={`${value || ""}`}
              disabled={!!disabled}
              height={height}
              width={width}
              maxLength={maxLength}
              tabIndex={tabIndex}
              defaultValue={null}
              placeholder={placeHolder}
            />
          );
        }}
        control={control}
        name={name}
        defaultValue={null}
      />
    </Input>
  );
};

export default InputText;
