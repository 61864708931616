const ACCESS_TOKEN = "access-token";
const REFRESH_TOKEN = "refresh-token";
const LOGIN_INFO = "login-info";

export const writeAccessToken = (accessToken: string) => {
  localStorage.setItem(ACCESS_TOKEN, accessToken);
};

export const writeRefreshToken = (refreshToken: string) => {
  localStorage.setItem(REFRESH_TOKEN, refreshToken);
};

export const writeIdLocalStorage = (UserId: string, SAVEID: boolean) => {
  localStorage.setItem(
    LOGIN_INFO,
    JSON.stringify({ UserId: UserId, SAVEID: SAVEID })
  );
};

export const readAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN);
};

export const readRefreshToken = () => {
  return localStorage.getItem(REFRESH_TOKEN);
};

export const readIdLocalStorage = () => {
  const info = localStorage.getItem(LOGIN_INFO);
  if (!info) return;
  return JSON.parse(info);
};

export const deleteIdLocalStorage = () => {
  localStorage.removeItem(LOGIN_INFO);
};
export const logout = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  window.location.href = "/";
  // f7router.navigate("/login");
};

export const validateToken = async () => {};
