import * as queryKeys from "@/data/constant/queryKeys";
import { request } from "@/util/api";
import { useQuery } from "@tanstack/react-query";

const useDispatchData = (search?: any, enabled?: boolean) => {
  return useQuery<any[]>({
    queryKey: [queryKeys.DISPATCH_DATA, search],
    queryFn: () =>
      request({ method: "GET", url: `/department/isDispatch`, params: search }),
    enabled: enabled,
  });
};

export default useDispatchData;
