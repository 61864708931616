import styled from "styled-components";

export const StatusText = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.primary100};
  font-size: 1.8rem;
  font-weight: 600;
  align-items: flex-end;
  flex-direction: column;
  gap: 2px;
`;

export const StatusLabel = styled.div`
  color: ${(props) => props.theme.colors.inputLabel};
  font-size: 1.4rem;
  font-weight: 400;
`;
