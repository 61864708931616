import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  ColumnFixing,
  Format,
  HeaderFilter,
  Scrolling,
  Selection,
} from "devextreme-react/data-grid";
import useButtonLoading from "@/hooks/useButtonLoading";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import { useDeleteMutation } from "@/util/common.fn";
import Grid from "@/components/atoms/Grid";
import { Buttons } from "@/components/atoms/Buttons";
import { DetailContent } from "@/components/organisms/Contents/DetailContent";
import { DetailTabItem } from "../../../components/atoms/DetailTabItem";

interface BankAccountManageLowerGridProps {
  gridMain: React.MutableRefObject<any>;
  gridTax: React.MutableRefObject<any>;
  gridEtc: React.MutableRefObject<any>;
  selectedRowKey: string | null;
  setTaxModal: React.Dispatch<React.SetStateAction<boolean>>;
  setEtcModal: React.Dispatch<React.SetStateAction<boolean>>;
  initPage: () => void;
}

const BankAccountManageLowerGrid = ({
  gridMain,
  gridTax,
  gridEtc,
  selectedRowKey,
  setTaxModal,
  setEtcModal,
  initPage,
}: BankAccountManageLowerGridProps) => {
  const { t } = useTranslation("main");
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { alertErrorMessage, alertSuccessMessage, confirmMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { data } = useCustomQuery<{ taxBill: any[]; etcCost: any[] }>(
    `account-list/sub`,
    { accountOid: selectedRowKey },
    !!selectedRowKey
  );

  const { mutateAsync: removeTax } = useDeleteMutation(`account-list/tax`);
  const { mutateAsync: removeEtc } = useDeleteMutation(`account-list/etc-cost`);
  const tabs = [
    {
      index: 0,
      component: (
        <Grid
          detailGrid={true}
          refs={gridTax}
          dataSource={data?.taxBill}
          keyExpr="finishNo"
        >
          <HeaderFilter visible={true} />
          {/* <ColumnChooser enabled={true} /> */}
          <Scrolling mode="virtual" />
          <ColumnFixing enabled={true} />
          <Selection
            mode="multiple"
            allowSelectAll={false}
            showCheckBoxesMode="always"
          />
          <Column dataField="division" caption={t("구분")} width="47" />
          <Column
            dataField="companyName"
            caption={t("청구처/용차사/차량번호")}
            width="134"
          />
          {/* 접수법인 */}
          <Column
            dataField="receiveCoName"
            caption={t("접수법인")}
            width="134"
          />
          {/* 마감번호 */}
          <Column dataField="completeNo" caption={t("마감번호")} width="134" />
          {/* 마감일자 */}
          <Column
            dataField="completeDate"
            caption={t("마감일자")}
            width="134"
          />
          {/* 상세건수 */}
          <Column dataField="detailTotal" caption={t("상세건수")} width="100" />
          {/* 정산상태 */}
          <Column dataField="closeStatus" caption={t("정산상태")} width="100" />
          {/* 정산예정일자 */}
          <Column
            dataField="taxSchedule"
            caption={t("정산일자")}
            width="100"
            dataType="date"
            format="yyyy-MM-dd"
          />
          {/* 세금계산서번호 */}
          <Column
            dataField="finishNo"
            caption={t("세금계산서번호")}
            width="150"
          />
          {/* 계산서상태 */}
          <Column dataField="taxStatus" caption={t("계산서상태")} width="100" />
          {/* 금액 */}
          <Column
            dataField="price"
            caption={t("금액")}
            dataType="number"
            width="130"
          >
            <Format type="fixedPoint" />
          </Column>
          {/* 세액 */}
          <Column
            dataField="taxTotal"
            caption={t("세액")}
            dataType="number"
            width="130"
          >
            <Format type="fixedPoint" />
          </Column>
          {/* 합계금액 */}
          <Column
            dataField="priceTotal"
            caption={t("합계금액")}
            dataType="number"
            width="150"
          >
            <Format type="fixedPoint" />
          </Column>
        </Grid>
      ),
    },
    {
      index: 1,
      component: (
        <Grid
          detailGrid={true}
          refs={gridEtc}
          dataSource={data?.etcCost}
          keyExpr="Oid"
        >
          <HeaderFilter visible={true} />
          {/* <ColumnChooser enabled={true} /> */}
          <Scrolling mode="virtual" />
          <ColumnFixing enabled={true} />
          <Selection
            mode="multiple"
            allowSelectAll={false}
            showCheckBoxesMode="always"
          />
          <Column dataField="receivecoName" caption={t("업체구분")} />
          <Column
            dataField="regDate"
            caption={t("등록일")}
            alignment="center"
            dataType="date"
            format="yyyy-MM-dd"
          />
          <Column
            dataField="companyName"
            caption={t("업체명")}
            alignment="center"
          />
          <Column dataField="sales" caption={t("매출")} alignment="center">
            <Format type="fixedPoint" />
          </Column>
          <Column dataField="purchase" caption={t("매입")} alignment="center">
            <Format type="fixedPoint" />
          </Column>
          <Column
            dataField="remark01"
            caption={t("비고1")}
            alignment="center"
          />
          <Column
            dataField="remark02"
            caption={t("비고2")}
            alignment="center"
          />
        </Grid>
      ),
    },
  ];

  const onClickConnect = () => {
    if (!selectedRowKey) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }

    if (selectedTab === 0) {
      setTaxModal(true);
    } else {
      setEtcModal(true);
    }
  };

  const onClickDelete = async () => {
    try {
      setLoading();
      if (!selectedRowKey) {
        alertErrorMessage("데이터를 선택해주세요");
        return;
      }
      const originalKey = selectedRowKey;
      if (selectedTab === 0) {
        const selected = gridTax.current.instance.getSelectedRowsData();

        if (selected.length === 0) {
          alertErrorMessage("데이터를 선택해주세요");
          return;
        }

        if (
          !(await confirmMessage(
            "정말 삭제하시겠습니까? 삭제된 데이터는 복구되지 않습니다."
          ))
        )
          return;

        const res: any = await removeTax({
          key: selectedRowKey,
          data: selected,
        });

        if (res.state === 1) {
          initPage();

          await alertSuccessMessage("삭제완료");
        }

        gridMain.current.instance.option("focusedRowKey", originalKey);
      } else if (selectedTab === 1) {
        const selected = gridEtc.current.instance.getSelectedRowsData();

        if (selected.length === 0) {
          alertErrorMessage("데이터를 선택해주세요");
          return;
        }

        if (
          !(await confirmMessage(
            "정말 삭제하시겠습니까? 삭제된 데이터는 복구되지 않습니다."
          ))
        )
          return;

        const res: any = await removeEtc({
          key: selectedRowKey,
          data: selected,
        });

        if (res.state === 1) {
          initPage();

          await alertSuccessMessage("삭제완료");
        }

        gridMain.current.instance.option("focusedRowKey", originalKey);
      }
    } catch (error) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };
  return (
    <DetailContent
      detailSectionTab={
        <>
          <DetailTabItem
            label="세금계산서"
            active={selectedTab === 0 ? true : false}
            onClick={() => setSelectedTab(0)}
          />
          <DetailTabItem
            label="기타비용"
            active={selectedTab === 1 ? true : false}
            onClick={() => setSelectedTab(1)}
          />
        </>
      }
      detailSectionButtons={
        <>
          <Buttons
            permission="save"
            type="button"
            size="sm"
            layout="outline"
            color="grid"
            label="연결하기"
            onClick={onClickConnect}
          />
          <Buttons
            permission="save"
            type="button"
            size="sm"
            layout="outline"
            color="destructive"
            label="삭제"
            onClick={onClickDelete}
          />
        </>
      }
    >
      {tabs[selectedTab].component}
    </DetailContent>
  );
};

export default BankAccountManageLowerGrid;
