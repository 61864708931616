import styled from "styled-components";

export const LanguageSelectBox = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const LanguageLabel = styled.label`
  color: ${(props) => props.theme.colors.inputLabel};
  font-size: 1.2rem;
`;

export const LanguageSelect = styled.select`
  height: 30px;
  padding: 0 8px 0 4px;
  color: ${(props) => props.theme.colors.black60};
  border-left: none;
  border-top: none;
  border-bottom: 1px solid ${(props) => props.theme.colors.inputBorder};
  border-right: none;
  font-size: 1.4rem;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    color: ${(props) => props.theme.colors.black100};
    border-bottom: 1px solid ${(props) => props.theme.colors.primary100};
  }
`;
