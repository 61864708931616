import { Buttons } from "@/components/atoms/Buttons";
import { GridButtonSet } from "@/components/molecules/GridButtonSet";
import * as S from "./GridContent.style";
import { ReactComponent as IconSearch } from "@/styles/assets/svg/icon_search.svg";

interface GridContentProps {
  subGrid?: boolean;
  grid?: string;
  bottomHeight?: number;
  searchSubmit?: any;
  searchSection?: React.ReactElement;
  gridRef?: React.MutableRefObject<any>;
  storageKey?: string;
  totalCount?: number;
  gridSectionButtons?: React.ReactElement;
  children?: React.ReactElement;
}

export const GridContent = ({
  subGrid,
  grid,
  bottomHeight,
  searchSubmit,
  searchSection,
  gridRef,
  storageKey,
  gridSectionButtons,
  children,
  totalCount,
}: GridContentProps) => {
  const number = (e: number) => {
    return e?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <S.GridContentBox subGrid={subGrid} bottomHeight={bottomHeight}>
      {/* 그리드 상단 검색 */}
      {searchSection && (
        <S.SearchSection onSubmit={searchSubmit}>
          <S.SearchBox>{searchSection}</S.SearchBox>
          <Buttons
            type="submit"
            size="full"
            layout="solid"
            color="find"
            icon={<IconSearch />}
          />
        </S.SearchSection>
      )}

      {/* 그리드 */}
      <S.GridSection>
        {storageKey && (
          <GridButtonSet dataGridRef={gridRef} storageKey={storageKey} />
        )}
        <S.GridBox grid={grid}>
          {gridSectionButtons && (
            <S.GridSectionButtons grid={grid}>
              {gridSectionButtons}
            </S.GridSectionButtons>
          )}
          {children}
        </S.GridBox>
      </S.GridSection>
      {totalCount
        ? totalCount > 0 && (
            <S.GridTotal>{`총 ${number(totalCount)}건`}</S.GridTotal>
          )
        : ""}
    </S.GridContentBox>
  );
};
