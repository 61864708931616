import React, { useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import Grid from "@/components/atoms/Grid";
import { InputText } from "@/components/atoms/Inputs";
import { GridContent } from "@/components/organisms/Contents/GridContent";

interface UserPermissionGridProps {
  searchForm: UseFormReturn<any, any, undefined>;
  gridMain: React.MutableRefObject<any>;
  mainData: any[] | undefined;
  toggleOpen: () => void;
  onSearch: (data: any) => void;
  onFocusedRowChanged: (e: any) => void;
}

const UserPermissionGrid = ({
  gridMain,
  mainData,
  searchForm,
  toggleOpen,
  onSearch,
  onFocusedRowChanged,
}: UserPermissionGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "UserPermission";
  const [mainTotalCount, setMainTotalCount] = useState<number>(0);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = searchForm;

  return (
    <GridContent
      searchSection={
        <>
          <InputText
            label="아이디"
            name="userId"
            width={140}
            control={control}
            errors={errors}
          />
          <InputText
            label="유저명"
            name="userName"
            width={80}
            control={control}
            errors={errors}
          />
        </>
      }
      searchSubmit={handleSubmit(onSearch)}
      gridRef={gridMain}
      storageKey={storageKey}
      totalCount={mainTotalCount}
    >
      <>
        {/* <Button
            variety="new"
            shape="solid"
            size="sm"
            type="button"
            onClick={toggleOpen}
          >
            입력
          </Button> */}

        <Grid
          id={storageKey}
          className="has-custom"
          keyExpr="id"
          refs={gridMain}
          dataSource={mainData}
          focusedRowEnabled={true}
          onFocusedRowChanged={onFocusedRowChanged}
          onContentReady={(e: any) =>
            setMainTotalCount(e.component.totalCount())
          }
        >
          <HeaderFilter visible={true} />
          <ColumnChooser enabled={true} />
          <Scrolling mode="infinite" />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(
              () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
              []
            )}
          />
          <Column
            caption="No"
            cellRender={(e: any) => e.row.loadIndex + 1}
            alignment="center"
            width={60}
          />
          <Column dataField="userId" caption={t("아이디")} width={120} />
          <Column dataField="userName" caption={t("유저명")} width={120} />
          <Column dataField="dutyCodeName" caption={t("직급")} />
        </Grid>
      </>
    </GridContent>
  );
};

export default UserPermissionGrid;
