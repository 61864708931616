import * as queryKeys from "@/data/constant/queryKeys";
import { request } from "@/util/api";
import { useQuery } from "@tanstack/react-query";

const useOrderCoData = (search?: any, enabled?: boolean) => {
  return useQuery<any[]>({
    queryKey: [queryKeys.ORDER_CO_DATA, search],
    queryFn: () =>
      request({
        method: "GET",
        url: `/companyManage/lookup/isOrderCo`,
        params: search,
      }),
    enabled: enabled,
  });
};

export default useOrderCoData;
