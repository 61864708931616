import dayjs from "dayjs";

export interface ClosingSearchType {
  receiveCo: string;
  searchType: string;
  firstDay: string;
  lastDay: string;
  isDivision: string;
  isFinish: string;
  dispatchTeam: string;
  companyName: string;
  receiveCoName: string;
  carryoverInquiry: boolean;
}

export const InitialClosingSearch: ClosingSearchType = {
  receiveCo: "0",
  searchType: "0",
  firstDay: dayjs().subtract(14, "days").format("YYYY-MM-DD"),
  lastDay: dayjs().format("YYYY-MM-DD"),
  isDivision: "0",
  isFinish: "0",
  dispatchTeam: "0",
  companyName: "",
  receiveCoName: "",
  carryoverInquiry: false,
};

export interface ClosingRowType {
  oid: string;
  comOid: string;
  companyId: 1;
  completeNo: string;
  completeDate: string;
  finishNo: string;
  taxSchedule: string;
  taxStatus: "발행" | "예약발행" | undefined | null;
  closeStatus: string;
  companyName: string;
  price: string;
  taxTotal: string;
  priceTotal: string;
  detailTotal: string;
  receiveCoName: string;
  division: string;
}

export interface TemplateType {
  searchType: string;
  templete: string;
  contactName: string;
  accountNo: string;
  owner: string;
  manager: string;
  tel: string;
  fax: string;
  bank: string;
}

export interface InvoicerData {
  Biz_No: string;
  Invoicer_CorpName: string;
  Invoicer_CEOName: string;
  Invoicer_Addr: string;
  Invoicer_BizClass: string;
  Invoicer_BizType: string;
  Invoicer_Tel: string;
  Invoicer_Fax: string;
}
export interface InvoiceeData {
  Biz_No_Return: string;
  Invoicee_CorpName: string;
  Invoicee_CEOName: string;
  Invoicee_Addr: string;
  Invoicee_BizClass: string;
  Invoicee_BizType: string;
  Invoicee_Tel: string;
  Fax_No: string;
}

export interface PriceDataType {
  Amount: string;
  Tax: string;
  TotalAmount: string;
}
