import * as S from "./DetailTabItem.style";

export interface DetailTabProps {
  label?: string;
  active?: boolean;
  onClick?: any;
}

export const DetailTabItem = ({ label, active, onClick }: DetailTabProps) => {
  return (
    <S.DetailTabItem active={active} onClick={onClick}>
      {label}
    </S.DetailTabItem>
  );
};
