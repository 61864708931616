import * as yup from "yup";

export interface TerminalType {
  code: string;
  division: string;
  twName: string;
  area?: string;
  zipCode?: string;
  address?: string;
  address1?: string;
  isShuttle?: string;
  tel?: string;
  fax?: string;
  color?: string;
  remark?: string;
}

export const TerminalInputSchema = yup.object<TerminalType>({
  code: yup.string().required("터미널코드를 입력해주세요"),
  division: yup.string().required("구분을 선택해주세요"),
  twName: yup.string().required("이름을 입력해주세요"),
  area: yup.string().nullable(),
  zipCode: yup.string().nullable(),
  address: yup.string().nullable(),
  address1: yup.string().nullable(),
  isShuttle: yup.string().nullable(),
  tel: yup.string().nullable(),
  fax: yup.string().nullable(),
  color: yup.string().nullable(),
  remark: yup.string().nullable(),
});

export const InitialTerminalInput: TerminalType = {
  code: "",
  division: "",
  twName: "",
  area: undefined,
  zipCode: undefined,
  address: undefined,
  address1: undefined,
  isShuttle: undefined,
  tel: undefined,
  fax: undefined,
  color: undefined,
  remark: undefined,
};
