import styled from "styled-components";

export const TopbarPopupBg = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100vh - 60px);
  top: 60px;
  left: 0;
  z-index: 100;
`;

export const TopbarPopup = styled.ul`
  position: absolute;
  display: flex;
  right: 16px;
  top: -6px;
  padding: 8px 0;
  background: ${(props) => props.theme.colors.white100};
  border-radius: 6px;
  box-shadow: ${(props) => props.theme.shadows.popup};
  flex-direction: column;
`;
