import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import "devextreme/dist/css/dx.light.compact.css";
import { alert } from "devextreme/ui/dialog";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { ThemeProvider } from "styled-components";
import { I18nProvider } from "./modules/i18n/I18nProvider";
import { GlobalStyleProvider } from "./core/GlobalStyleProvider";
import RoutesPage from "./RoutesPage";
import { theme } from "./styles/theme";

declare global {
  interface Window {
    daum: any;
  }
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 1,
    },
  },
  queryCache: new QueryCache({
    onError: (e: any) => {
      if (typeof e === "string") alert(e, "오류");
      if (typeof e === "object") alert(e.message, "오류");
    },
  }),
});

console.log("TMS_SHINHAN");

function App() {
  return (
    <BrowserRouter>
      <RecoilRoot>
        <GlobalStyleProvider>
          <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
              <I18nProvider>
                <RoutesPage />
              </I18nProvider>
            </ThemeProvider>
          </QueryClientProvider>
        </GlobalStyleProvider>
      </RecoilRoot>
    </BrowserRouter>
  );
}

export default App;
