import styled from "styled-components";

export const TreeBox = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 6px;

  & > .dx-widget {
    overflow: hidden;
    width: 100%;
    height: 100%;

    .dx-treelist-container {
      background-color: transparent;

      // Grid 버튼 Style
      .dx-button {
        background: ${(props) => props.theme.colors.gridSub};
        border: none;
        border-radius: 4px;
        transition: background 0.2s ease;

        &:hover {
          background: ${(props) => props.theme.colors.gridSubHover};
        }

        i {
          color: ${(props) => props.theme.colors.white100};
        }
      }

      .dx-toolbar {
        background: none;
      }

      // Grid 공통 Style
      // Header 공통 Style
      .dx-treelist-headers {
        border: none;

        td {
          height: 30px;
          font-size: 1.2rem;
          font-weight: 600;
          line-height: 20px;
          border: none;

          .dx-column-indicators {
            .dx-sort,
            .dx-header-filter {
              font-size: 1.2rem;
            }
          }
        }
      }

      // Data Row 공통 Style
      .dx-treelist-rowsview {
        border: none;

        .dx-freespace-row {
          td {
            border: none;
            background: ${(props) => props.theme.colors.gridBg};
          }
        }

        .dx-row {
          td {
            height: 34px;
            font-size: 1.4rem;
            line-height: 23px;
            border-right: 1px solid ${(props) => props.theme.colors.gray30};
            border-left: 1px solid ${(props) => props.theme.colors.gray30};
            border-bottom: 1px solid ${(props) => props.theme.colors.gray100};

            .dx-link {
              height: 24px;
              padding: 0 8px;
              line-height: 24px;
              text-decoration: none;
              border-radius: 4px;
              transition: all 0.2s ease;

              &.dx-link-add {
                color: ${(props) => props.theme.colors.primary100} !important;
                border: 1px solid ${(props) => props.theme.colors.primary100} !important;

                &:hover {
                  background: ${(props) =>
                    props.theme.colors.primary100Hover}1f;
                }
              }

              &.dx-link-delete {
                color: ${(props) => props.theme.colors.destructive}!important;
                border: 1px solid
                  ${(props) => props.theme.colors.destructive}!important;

                &:hover {
                  background: ${(props) =>
                    props.theme.colors.destructiveHover}1f !important;
                }
              }
            }
          }

          &.dx-row-focused {
            td {
              color: ${(props) => props.theme.colors.darkGray100};
              background-color: ${(props) => props.theme.colors.accent08};
            }
          }
        }

        // Tree 스크롤바 Style
        .dx-scrollable-container {
          &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background-clip: padding-box;
            background-color: ${(props) => props.theme.colors.scrollBar};
            border: 2px solid transparent;
            border-radius: 10px;

            &:hover {
              background-color: ${(props) => props.theme.colors.black50};
              transition: all 0.2s ease;
            }
          }

          &::-webkit-scrollbar-track {
            background-color: ${(props) => props.theme.colors.black4};
          }
        }
      }
    }

    // Main-Grid Style
    &.main-grid {
      // Main-Header Style
      .dx-treelist-headers {
        td {
          color: ${(props) => props.theme.colors.primary100};

          .dx-column-indicators {
            .dx-sort,
            .dx-header-filter {
              color: ${(props) => props.theme.colors.primary50};
            }
          }
        }
      }

      // Main-Data Row Style
      .dx-treelist-rowsview {
      }
    }

    // Sub-Grid Style
    &.sub-grid {
      // Sub-Header Style
      .dx-treelist-headers {
        overflow: hidden;
        border-radius: 4px 4px 0 0;

        td {
          color: ${(props) => props.theme.colors.white100};
          background: ${(props) => props.theme.colors.gridSubHeader};

          .dx-column-indicators {
            .dx-sort,
            .dx-header-filter {
              color: ${(props) => props.theme.colors.white50};
            }
          }
        }
      }

      // Sub-Data Row Style
      .dx-treelist-rowsview {
        background: ${(props) => props.theme.colors.white100};
      }
    }
  }
`;

export const TreeTitle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.6rem;
  font-weight: 600;
`;
