import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import {
  InitProfitData,
  InitialSearch,
  MainDataType,
  ProfitDataType,
} from "@/types/calculateProfit.type";
import { ContentTemplate } from "@/components/templates/ContentTemplate";
import CalculateProfitInput from "./CalculateProfitInput";
import CalculateProfitUpperGrid from "./CalculateProfitUpperGrid";
import CalculateProfitLowerGrid from "./CalculateProfitLowerGrid";

const CalculateProfit = () => {
  const [search, setSearch] = useState(InitialSearch);
  const [profitData, setProfitData] = useState<ProfitDataType>(InitProfitData);
  const [isOpen, setOpen] = useState<boolean>(false);

  const gridMain = useRef<any>();
  const gridSub = useRef<any>();

  const { data: mainData, refetch: mainRefetch } = useCustomQuery<MainDataType>(
    `calculate-profit`,
    search
  );

  const searchForm = useForm({
    defaultValues: InitialSearch,
  });

  useEffect(() => {
    mainData && calculateNetProfit(mainData);
  }, [mainData]);

  const onSearch = (data: any) => {
    setSearch(data);

    mainRefetch();
  };

  const toggleOpen = () => setOpen(!isOpen);

  const calculateNetProfit = (data: MainDataType) => {
    const { calculate, etc } = data;
    let profit = 0; // 매출액
    let purchase = 0; // 매입액
    let income = 0; // 이익금
    let etcProfit = 0; // 기타매출
    let etcPurchase = 0; // 기타매입
    let netProfit = 0; // 순수익

    calculate.forEach((cur) => {
      const { totalPriceBilling, totalPriceOther } = cur;

      profit += Number(totalPriceBilling);
      purchase += Number(totalPriceOther);
    });

    etc.forEach((cur) => {
      const { sales, purchase } = cur;

      etcProfit += Number(sales);
      etcPurchase += Number(purchase);
    });

    income = profit - purchase;
    netProfit = income + etcProfit - etcPurchase;

    setProfitData({
      profit,
      purchase,
      income,
      etcProfit,
      etcPurchase,
      netProfit,
    });
  };

  return (
    <ContentTemplate sideWidth={180} bottomHeight={240}>
      <>
        <CalculateProfitUpperGrid
          bottomHeight={240}
          gridMain={gridMain}
          mainData={mainData?.calculate}
          searchForm={searchForm}
          onSearch={onSearch}
        />
        <CalculateProfitInput
          bottomHeight={240}
          profitData={profitData}
          toggleOpen={toggleOpen}
        />
        <CalculateProfitLowerGrid gridSub={gridSub} subData={mainData?.etc} />
      </>
    </ContentTemplate>
  );
};

export default CalculateProfit;
