import styled from "styled-components";

export const AuthTemplate = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  background: ${(props) => props.theme.colors.loginBg};
  align-items: center;
  justify-content: center;

  // 로그인 배경화면 모바일환경(600px 이하) 설정
  @media (max-width: 600px) {
    background: ${(props) => props.theme.colors.white};
  }
`;
