import { useState } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
// import { signOut, useSession } from "next-auth/react";
import { selectedMenuSelector } from "@/state/menu";
import { userInfo } from "@/state/atoms/userInfo";
// import usePasswordModalContext from "@hooks/useModal/usePasswordModalContext";
import LanguageSelect from "@/components/atoms/LanguageSelect";
import { Buttons } from "@/components/atoms/Buttons";
import { TopbarPopup } from "@/components/molecules/TopbarPopup";
import * as S from "./TopBar.style";
import { ReactComponent as IconUser } from "@/styles/assets/svg/icon_user.svg";
import { ReactComponent as IconMenu01 } from "@/styles/assets/svg/icon_menu_1.svg";
import { ReactComponent as IconHome } from "@/styles/assets/svg/icon_home.svg";

export default function TopBar() {
  const { t } = useTranslation("main");
  const userData = useRecoilValue(userInfo);
  const currentMenu = useRecoilValue(selectedMenuSelector);
  // const { data }: any = useSession();
  // const passwordModalContext = usePasswordModalContext();

  const [popupShow, setPopupShow] = useState(false);

  const handlePopupShow = () => {
    setPopupShow(!popupShow);
  };

  return (
    <>
      <S.TopBarBox>
        <S.TopBarTitle>
          {currentMenu.menuName ? (
            <>
              <IconMenu01 />
              <span>{t(currentMenu.menuName)}</span>
            </>
          ) : (
            <>
              <IconHome />
              <span>홈</span>
            </>
          )}
        </S.TopBarTitle>

        <S.TopBarProfileBox>
          <LanguageSelect />

          <Buttons
            type="button"
            size="md"
            layout="text"
            color="black100"
            onClick={handlePopupShow}
          >
            <S.TopBarProfile>
              <span>{userData.companyName}</span>
              <S.UserName>
                <span>
                  {userData.userName} {t("님")}
                </span>
                <IconUser />
              </S.UserName>
            </S.TopBarProfile>
          </Buttons>
        </S.TopBarProfileBox>

        {popupShow && <TopbarPopup setPopupShow={setPopupShow} />}
      </S.TopBarBox>
    </>
  );
}
