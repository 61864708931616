import { userType } from "@/types/user.type";
import { atom } from "recoil";
// import { recoilPersist } from "recoil-persist";

// const { persistAtom } = recoilPersist();

export const userInfo = atom<userType>({
  key: "userInfo", // unique ID (with respect to other atoms/selectors)
  default: {
    userId: "",
    isDev: "",
    authGroup: "",
    authToken: "",
    refreshToken: "",
    companyCode: "",
    userStatus: "",
    userName: "",
    dutyCode: "",
    bizNo: "",
    englishName: "",
    tel: "",
    hp: "",
    mail: "",
    fax: "",
    companyName: "",
    deptCode: "",
    deptName: "",
    subName: "",
  }, // default value (aka initial value)
  // effects_UNSTABLE: [persistAtom],
});
