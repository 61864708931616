import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  ColumnFixing,
  Format,
  HeaderFilter,
  Scrolling,
  Selection,
  StateStoring,
} from "devextreme-react/data-grid";
import { usePutMutation } from "@/util/common.fn";
import { TemplateType } from "@/types/closing.type";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import useButtonLoading from "@/hooks/useButtonLoading";
import { Buttons } from "@/components/atoms/Buttons";
import Grid from "@/components/atoms/Grid";
import TaxAmend from "@/components/molecules/Modal/TaxAmend";
import ClosingDateModal from "@/components/molecules/Modal/ClosingDateModal";
import SpecificationTemplateModal from "@/components/molecules/Modal/SpecificationTemplateModal";
import SpecificationModal from "@/components/molecules/Modal/SpecificationModal";
import MailingModal from "@/components/molecules/Modal/MailingModal";
import { DetailContent } from "@/components/organisms/Contents/DetailContent";

interface ClosintRightGridProps {
  gridMain: any;
  gridSub: any;
  subData: any[] | undefined;
  search: any;
  selectedRowData?: any;
  initPage?: any;
}

const ClosintRightGrid = ({
  gridMain,
  gridSub,
  subData,
  search,
  selectedRowData,
  initPage,
}: ClosintRightGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "ClosintRightGrid";
  const [totalCount, setTotalCount] = useState<number>(0);

  // 부가세 수정
  const [openTaxAmend, setOpenTaxAmend] = useState<boolean>(false);

  // 마감
  const [openFinishModal, setOpenFinishModal] = useState<boolean>(false);

  // 거래명세서
  const [templateInfo, setTemplateInfo] = useState<TemplateType>();
  const [openSpecification, setOpenSpecification] = useState(false);
  const [openSpecificationTemplete, setOpenSpecificationTemplete] =
    useState(false);

  // 메일
  const [mailPdf, setMailPdf] = useState<Blob>(new Blob());
  const [openMailingModal, setOpenMailingModal] = useState<boolean>(false);

  const { alertErrorMessage, alertSuccessMessage, confirmMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { mutateAsync: zeroTax } = usePutMutation(
    `finish-sell-manage/zero-tax`
  );
  const { mutateAsync: finishCancel } = usePutMutation(
    `finish-sell-manage/cancel`
  );

  const onClickAmendTax = () => {
    const subGridInstance = gridSub.current.instance;

    const selected = subGridInstance.getSelectedRowsData();

    if (selected.length === 0) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }

    for (const obj of selected) {
      if (obj.completeStatus === "마감") {
        alertErrorMessage("마감된 데이터가 있습니다.");
        return;
      }
      // 정산된 데이터 확인
      if (obj.closeStatus === "정산") {
        alertErrorMessage("정산된 데이터가 있습니다");
        return;
      }
    }

    setOpenTaxAmend(true);
  };

  const onClickZeroTax = async () => {
    const mainGridInstance = gridMain.current.instance;
    const subGridInstance = gridSub.current.instance;

    const originalKey = selectedRowData?.oid;

    const division = selectedRowData?.division;
    const selected = subGridInstance.getSelectedRowsData();

    if (selected.length === 0) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }

    const selectedKeys = selected.map((cur: any) => cur.id);

    // 서버로 보낼 데이터 객체 생성
    const sendData = {
      selected: selected,
      division: division,
      key: selectedKeys,
    };

    if (!(await confirmMessage("영세율로 수정하시겠습니까?"))) return;

    try {
      setLoading();
      const res: any = await zeroTax(sendData);

      if (res.state === 1) {
        initPage();
        await alertSuccessMessage("저장완료");
      }

      mainGridInstance.option("focusedRowKey", originalKey);
    } catch (error) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onClickExportTradeSheet = () => {
    const subGridInstance = gridSub.current.instance;

    const selected = subGridInstance.getSelectedRowsData();

    if (selected.length === 0) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }

    let receiveCo = selected[0].receiveCo;

    for (const obj of selected) {
      if (receiveCo !== obj.receiveCo) {
        alertErrorMessage("접수법인이 다른 데이터가 존재합니다");
        return;
      }
    }

    setOpenSpecificationTemplete(true);
  };

  const onClickFinish = () => {
    const subGridInstance = gridSub.current.instance;

    const selected = subGridInstance.getSelectedRowsData();

    if (selected.length === 0) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }

    for (const obj of selected) {
      if (obj.completeStatus === "마감") {
        alertErrorMessage("마감된 데이터가 있습니다.");
        return;
      }
      // 정산된 데이터 확인
      if (obj.closeStatus === "정산") {
        alertErrorMessage("정산된 데이터가 있습니다");
        return;
      }
    }

    setOpenFinishModal(true);
  };

  const onClickCancelFinish = async () => {
    const mainGridInstance = gridMain.current.instance;
    const subGridInstance = gridSub.current.instance;

    const originalKey = selectedRowData?.oid;

    const selected = subGridInstance.getSelectedRowsData();

    if (selected.length === 0) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }

    for (const obj of selected) {
      // 정산된 데이터 확인
      if (obj.closeStatus === "정산") {
        await alertErrorMessage("정산된 데이터가 있습니다");
        return;
      }
    }

    if (!(await confirmMessage("마감취소 하시겠습니까?"))) return;

    try {
      setLoading();
      const res: any = await finishCancel(selected);

      if (res?.message) {
        await alertErrorMessage(res.message);
      } else {
        initPage();
        await alertSuccessMessage("취소완료");
      }

      mainGridInstance.option("focusedRowKey", originalKey);
    } catch (error) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onSelect = (data: TemplateType) => {
    setTemplateInfo(data);
    setOpenSpecification(true);
    setOpenSpecificationTemplete(false);
  };

  return (
    <>
      <DetailContent
        position="right"
        detailSectionButtons={
          <>
            <Buttons
              permission="save"
              type="button"
              size="sm"
              layout="outline"
              color="grid"
              label="부가세 수정"
              onClick={onClickAmendTax}
            />
            <Buttons
              permission="save"
              type="button"
              size="sm"
              layout="outline"
              color="grid"
              label="영세율"
              onClick={onClickZeroTax}
            />
            <Buttons
              permission="export"
              type="button"
              size="sm"
              layout="outline"
              color="grid"
              label="거래명세서 출력"
              onClick={onClickExportTradeSheet}
            />
            <Buttons
              permission="save"
              type="button"
              size="sm"
              layout="outline"
              color="grid"
              label="마감"
              onClick={onClickFinish}
            />
            <Buttons
              type="button"
              permission="save"
              size="sm"
              layout="outline"
              color="destructive"
              label="마감취소"
              onClick={onClickCancelFinish}
            />
          </>
        }
        totalCount={totalCount}
        gridRef={gridSub}
        storageKey={storageKey}
      >
        <Grid
          detailGrid={true}
          refs={gridSub}
          dataSource={subData}
          keyExpr="id"
          focusedRowEnabled={true}
          onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
        >
          <ColumnFixing enabled={true} />
          <HeaderFilter visible={true} />
          <ColumnChooser enabled={true} />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(
              () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
              []
            )}
          />
          <Scrolling mode="virtual" />
          <Selection
            mode="multiple"
            allowSelectAll={true}
            showCheckBoxesMode="always"
          />
          <Column
            caption="No"
            width={28}
            cellRender={(e) => e.row.loadIndex + 1}
            alignment="center"
          />
          <Column
            dataField="extraDivision"
            caption={t("구분")}
            width={80}
            cellRender={(e) => {
              const { value } = e;
              if (value === "detail") return t("오더");
              if (value === "dis") return t("배차");
              if (value === "extra") return t("할증");
              if (value === "etc") return t("기타하불");
              return "";
            }}
            alignment="center"
          />
          <Column dataField="companyName" caption={t("접수법인")} width={110} />
          <Column dataField="code" caption={t("접수번호")} width={130} />
          <Column dataField="receiveDate" caption={t("접수일자")} width={100} />
          <Column dataField="deliverDate" caption={t("운송일자")} width={100} />
          <Column
            dataField="deliverCompleteDate"
            caption={t("운송마감일자")}
            width={100}
          />
          <Column
            dataField="workPlaceAddress"
            caption={t("청구주소")}
            width={200}
          />
          <Column dataField="completeStatus" caption={t("마감")} width={60} />
          <Column dataField="completeNo" caption={t("마감번호")} width={130} />
          <Column
            dataField="completeDate"
            caption={t("마감일자")}
            width={100}
          />
          <Column
            dataField="completeTime"
            dataType="datetime"
            format="yyyy-MM-dd hh:mm:ss"
            caption={t("마감처리일자")}
            width={160}
          />
          <Column
            dataField="completeManager"
            caption={t("마감담당자")}
            width={80}
          />
          <Column
            dataField="prtState"
            caption={t("출력구분")}
            width={90}
            cellRender={(e) => {
              if (e.value !== false) {
                return "Y";
              } else {
                return "N";
              }
            }}
          />
          <Column
            dataField="closeStatus"
            caption={t("발행상태")}
            width={90}
            alignment="center"
          />
          <Column
            dataField="importExportName"
            caption={t("수입/수출")}
            width={90}
            alignment="center"
          />
          <Column dataField="manager" caption={t("담당자")} width={100} />
          <Column
            dataField="price"
            caption={t("금액")}
            dataType="number"
            width={100}
          >
            <Format type="fixedPoint" />
          </Column>
          <Column
            dataField="tax"
            caption={t("세액")}
            dataType="number"
            width={100}
          >
            <Format type="fixedPoint" />
          </Column>
          <Column
            dataField="total"
            caption={t("합계금액")}
            dataType="number"
            width={100}
          >
            <Format type="fixedPoint" />
          </Column>
          <Column
            dataField="totalExtra"
            caption={t("할증합계")}
            dataType="number"
            width={100}
          >
            <Format type="fixedPoint" />
          </Column>
          <Column
            dataField="totalDetail"
            caption={t("청구총금액")}
            dataType="number"
            width={100}
          >
            <Format type="fixedPoint" />
          </Column>
          <Column
            dataField="habulTotal"
            caption={t("하불총금액")}
            dataType="number"
            width={100}
          >
            <Format type="fixedPoint" />
          </Column>
          <Column
            dataField="allTotalPrice"
            caption={t("수익금")}
            dataType="number"
            width={100}
          >
            <Format type="fixedPoint" />
          </Column>
          <Column dataField="bizNo" caption={t("사업자번호")} width={140} />
          <Column dataField="bizName" caption={t("상호")} width={100} />
          <Column
            dataField="containerNo"
            caption={t("컨테이너번호")}
            width={100}
          />
          <Column dataField="containerType" caption={t("컨규격")} width={80} />
          <Column dataField="sealNo" caption={t("Seal No")} width={100} />
          <Column dataField="cargoWeight" caption={t("중량")} width={80} />
          <Column dataField="amount" caption={t("수량")} width={80} />
          <Column dataField="remark" caption={t("비고")} width={100} />
          <Column dataField="innerMemo" caption={t("내부메모")} width={100} />
          <Column
            dataField="division"
            caption={t("마감구분")}
            width={90}
            alignment="center"
          />
          <Column
            dataField="extraChargeDivision"
            caption={t("청구구분")}
            width={90}
            alignment="center"
          />
          <Column dataField="extraRemark" caption={t("청구비고")} width={100} />
          <Column dataField="extraInfo" caption={t("특이사항")} width={100} />
          <Column
            dataField="etcHabulRemark"
            caption={t("기타하불비고")}
            width={100}
          />
          <Column
            dataField="dispatchTeamName"
            caption={t("배차팀")}
            width={120}
          />
          <Column
            dataField="billingPlaceName"
            caption={t("청구처")}
            width={160}
          />
          <Column dataField="orderCompany" caption={t("발주처")} width={160} />
          <Column dataField="forwarder" caption={t("선사")} width={160} />
          <Column dataField="shipName" caption={t("선명")} width={160} />
          <Column dataField="motherShip" caption={t("모선/항차")} width={160} />
          <Column dataField="cargoOwnerName" caption={t("화주")} width={160} />
          <Column
            dataField="realCargoOwner"
            caption={t("실화주")}
            width={160}
          />
          <Column dataField="dem" caption={t("선사반출기한")} width={100} />
          <Column
            dataField="enterDay"
            caption={t("입항일")}
            width={140}
            dataType="datetime"
            format="yyyy-MM-dd HH:mm:ss"
          />
          <Column
            dataField="sailDay"
            caption={t("출항일")}
            width={140}
            dataType="datetime"
            format="yyyy-MM-dd HH:mm:ss"
          />
          <Column dataField="loadPlaceName" caption={t("상차지")} width={100} />
          <Column
            dataField="loadPlaceText"
            caption={t("LCL 상차지")}
            width={100}
          />
          <Column dataField="workPlace" caption={t("작업지")} width={100} />
          <Column
            dataField="workPlaceAdd"
            caption={t("작업지주소")}
            width={100}
          />
          <Column
            dataField="unloadPlaceName"
            caption={t("하차지")}
            width={100}
          />
          <Column
            dataField="unloadPlaceText"
            caption={t("LCL 하차지")}
            width={100}
          />
          <Column dataField="blNoM" caption={t("마스터 B/L")} width={100} />
          <Column dataField="blNoH" caption={t("하우스 B/L")} width={100} />
          <Column dataField="bkNo" caption={t("B/K No")} width={100} />
          <Column dataField="doNo" caption={t("DO No")} width={100} />
          <Column
            dataField="createUsr"
            caption={t("최초입력자")}
            alignment="center"
            width={100}
          />
          <Column
            dataField="updateUsr"
            caption={t("최종수정자")}
            alignment="center"
            width={100}
          />
          <Column dataField="createDate" caption={t("작성일")} width={100} />
          <Column dataField="createTime" caption={t("작성시간")} width={100} />
        </Grid>
      </DetailContent>

      {openTaxAmend && (
        <TaxAmend
          onClose={() => setOpenTaxAmend(false)}
          initPage={initPage}
          gridMain={gridMain}
          gridSub={gridSub}
        />
      )}
      {openFinishModal && (
        <ClosingDateModal
          onClose={() => setOpenFinishModal(false)}
          initPage={initPage}
          gridMain={gridMain}
          gridSub={gridSub}
        />
      )}
      {openSpecificationTemplete && (
        <SpecificationTemplateModal
          onClose={() => setOpenSpecificationTemplete(false)}
          onSelect={onSelect}
          selectedRowData={selectedRowData}
        />
      )}
      {openSpecification && (
        <SpecificationModal
          search={search}
          templateInfo={templateInfo}
          selectedRowData={selectedRowData}
          gridSub={gridSub}
          setMailPdf={setMailPdf}
          setOpenMailingModal={setOpenMailingModal}
          onClose={() => setOpenSpecification(false)}
        />
      )}
      {openMailingModal && (
        <MailingModal
          onClose={() => setOpenMailingModal(false)}
          selectedRowData={selectedRowData}
          mailPdf={mailPdf}
          search={search}
        />
      )}
    </>
  );
};

export default ClosintRightGrid;
