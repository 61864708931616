import {
  InputDate,
  InputRadioBox,
  InputSelect,
  InputText,
} from "@/components/atoms/Inputs";
import InputNumber from "@/components/atoms/Inputs/InputNumber";
import {
  결제유형,
  결제일자,
  사업자종류,
  사용자_활동상태_구분,
  업무분류,
  은행,
  차량구분,
  차량종류2,
  차량톤수,
} from "@/data/constant/queryKeys";
import { useComCodeData, useUserListData } from "@/hooks/queries";
import useButtonLoading from "@/hooks/useButtonLoading";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import {
  ComCarInputType,
  ComCarType,
  InitialComCarInput,
} from "@/types/comCar.type";
import { usePostMutation, usePutMutation } from "@/util/common.fn";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "..";
import { ModalForm } from "../modal.styles";
import { Buttons } from "@/components/atoms/Buttons";

interface ComCarModalProps {
  onClose: () => void;
  selectedRowKey: number | null;
}

const ComCarModal = ({ onClose, selectedRowKey }: ComCarModalProps) => {
  const { alertErrorMessage, alertSuccessMessage } = useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { data: mainData, isLoading: mainDataLoading } = useCustomQuery<
    ComCarType[]
  >(`carManage/${selectedRowKey}`, {}, !!selectedRowKey);

  const { mutateAsync: create } = usePostMutation(`carManage`);
  const { mutateAsync: update } = usePutMutation(`carManage`);

  const { data: carDivision } = useComCodeData(차량구분);
  const { data: actStatus } = useComCodeData(사용자_활동상태_구분);
  const { data: workType } = useComCodeData(업무분류);
  const { data: carTon } = useComCodeData(차량톤수);
  const { data: kindCar } = useComCodeData(차량종류2);
  const { data: payType } = useComCodeData(결제유형);
  const { data: payCycle } = useComCodeData(결제일자);
  const { data: kindBiz } = useComCodeData(사업자종류);
  const { data: bank } = useComCodeData(은행);

  const { data: department } = useCustomQuery<any[]>(`department/lookup`, {});
  const { data: carAssignCom } = useCustomQuery<any[]>(
    `companyManage/lookup`,
    {}
  );
  const { data: assignWorker } = useUserListData();

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: InitialComCarInput,
    resolver: yupResolver(ComCarInputType),
  });

  useEffect(() => {
    mainData && reset(mainData[0]);
    // eslint-disable-next-line
  }, [mainData]);

  const openPost = () => {
    window.daum.postcode.load(() => {
      const postcode = new window.daum.Postcode({
        oncomplete: function (data: any) {
          let addr = "";
          let extraAddr = "";

          if (data.userSelectedType === "R") {
            addr = data.address;
          } else {
            addr = data.jibunAddress;
          }

          if (data.userSelectedType === "R") {
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            addr += extraAddr;
          }

          setValue("zipCode", data.zonecode);
          setValue("address", addr);
        },
      });
      postcode.open();
    });
  };

  const openPost2 = () => {
    window.daum.postcode.load(() => {
      const postcode = new window.daum.Postcode({
        oncomplete: function (data: any) {
          let addr = "";
          let extraAddr = "";

          if (data.userSelectedType === "R") {
            addr = data.address;
          } else {
            addr = data.jibunAddress;
          }

          if (data.userSelectedType === "R") {
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            addr += extraAddr;
          }

          setValue("bizAddress", addr);
        },
      });
      postcode.open();
    });
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading();
      let res: any;
      if (selectedRowKey) {
        res = await update({ key: selectedRowKey, data });
      } else {
        res = await create(data);
      }

      if (res?.state === 1) {
        await alertSuccessMessage("저장완료");
        // onClose();
      }
    } catch (error) {
      console.log(error);
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };
  const onError = () => {};

  return (
    <Modal width="1100px" close={onClose} title="차량정보 등록">
      <ModalForm onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="grid-4">
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="carNo"
            label="차량번호"
            required={true}
          />
          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            name="manageAssign"
            label="관리소속"
            dataSource={department}
            displayExpr="deptName"
            valueExpr="code"
            required={true}
          />
          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            name="kindCar"
            label="차량종류"
            dataSource={kindCar}
            valueExpr="subCode"
            displayExpr="subName"
            required={true}
          />
          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            name="payCycle"
            label="결제일자"
            dataSource={payCycle}
            valueExpr="subCode"
            displayExpr="subName"
          />

          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            name="carTon"
            label="차량톤수"
            dataSource={carTon}
            valueExpr="subCode"
            displayExpr="subName"
            required={true}
          />
          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            name="carDivision"
            label="차량구분"
            dataSource={carDivision}
            valueExpr="subCode"
            displayExpr="subName"
            required={true}
          />
          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            name="carAssignCom"
            label="소속운송사"
            dataSource={carAssignCom}
            valueExpr="code"
            displayExpr="companyName"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="carWanted"
            label="차량수배처"
          />

          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            name="assignWorker"
            label="담당직원"
            dataSource={assignWorker}
            valueExpr="id"
            displayExpr="userName"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="driverName"
            label="운전자이름"
            required={true}
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="tel"
            label="휴대전화"
            required={true}
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="homeTel"
            label="자택전화"
          />

          <InputDate
            type="v-array"
            control={control}
            errors={errors}
            name="brith"
            label="생년월일"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="email"
            label="이메일"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="carIdCard"
            label="차량ID카드"
          />
          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            name="workType"
            label="업무분류"
            dataSource={workType}
            valueExpr="subCode"
            displayExpr="subName"
          />

          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            name="payType"
            label="결제유형"
            dataSource={payType}
            valueExpr="subCode"
            displayExpr="subName"
          />
          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            name="actStatus"
            label="활동상태"
            dataSource={actStatus}
            valueExpr="subCode"
            displayExpr="subName"
          />
          <InputNumber
            type="v-array"
            control={control}
            errors={errors}
            name="carCommission"
            label="수수료"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="carRemark"
            label="차량비고"
          />
        </div>

        <div className="grid-4">
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="zipCode"
            label="우편번호"
            disabled={true}
          />
          <Buttons
            type="button"
            size="sm"
            layout="solid"
            color="find"
            label="주소검색"
            onClick={openPost}
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="address"
            label="주소"
            disabled={true}
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="address1"
            label="상세주소"
          />
        </div>

        <div className="grid-4">
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="bizName"
            label="사업자상호"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="bizNo"
            label="사업자번호"
          />
          <InputRadioBox
            type="v-array"
            control={control}
            errors={errors}
            name="bizType"
            label="휴폐업조회"
            dataSource={[
              { id: 0, name: "개인" },
              { id: 1, name: "법인" },
            ]}
            valueExpr="id"
            displayExpr="name"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="coporNo"
            label="법인번호"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="ceoName"
            label="대표자"
          />
          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            dataSource={kindBiz}
            valueExpr="subCode"
            displayExpr="subName"
            name="kindBiz"
            label="사업자종류"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="bizCondition"
            label="업태"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="item"
            label="종목"
          />

          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="bizPlaceNo"
            label="종사업장번호"
          />
          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            dataSource={bank}
            valueExpr="subCode"
            displayExpr="subName"
            name="bank"
            label="거래은행"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="acountNo"
            label="계좌번호"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="depositOwner"
            label="예금주"
          />
        </div>

        <div className="grid-4">
          <Buttons
            type="button"
            size="sm"
            layout="solid"
            color="primary100"
            label="주소검색"
            onClick={openPost2}
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="bizAddress"
            label="주소"
            disabled={true}
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="bizDetailAddress"
            label="상세주소"
          />
        </div>

        {/* Modal 저장 버튼 */}
        <Buttons
          permission="save"
          type="submit"
          size="full"
          layout="solid"
          color="save"
          label="저장"
          disabled={mainDataLoading}
        />
      </ModalForm>
    </Modal>
  );
};

export default ComCarModal;
