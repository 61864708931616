import React from "react";
import { Modal } from "..";
import { useForm } from "react-hook-form";
import { InputDate } from "@/components/atoms/Inputs";
import dayjs from "dayjs";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import { usePutMutation } from "@/util/common.fn";
import useButtonLoading from "@/hooks/useButtonLoading";
import { Buttons } from "@/components/atoms/Buttons";

interface ClosingDateModalProps {
  onClose: () => void;
  initPage: () => void;

  gridMain: any;
  gridSub: any;
}

const ClosingDateModal = ({
  onClose,
  initPage,
  gridMain,
  gridSub,
}: ClosingDateModalProps) => {
  const { alertErrorMessage, alertSuccessMessage } = useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { mutateAsync: finish } = usePutMutation("finish-sell-manage");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      divisionDate: dayjs().format("YYYY-MM-DD"),
    },
  });

  const onSubmit = async (date: any) => {
    const mainGridInstance = gridMain.current.instance;
    const subGridInstance = gridSub.current.instance;

    const originalKey = mainGridInstance.option("focusedRowKey");

    const selected = subGridInstance.getSelectedRowsData();

    try {
      setLoading();
      const res: any = await finish({ date, selected });

      if (res.state === 1) {
        initPage();
        await alertSuccessMessage("저장완료");

        mainGridInstance.option("focusedRowKey", originalKey);

        onClose();
      }
    } catch (error) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  return (
    <Modal
      width="320px"
      close={onClose}
      title="마감"
      modalButton={
        <Buttons
          permission="save"
          type="submit"
          size="lg"
          width={80}
          layout="solid"
          color="save"
          label="저장"
        />
      }
      formSubmit={handleSubmit(onSubmit)}
    >
      <InputDate
        type="v-array"
        control={control}
        errors={errors}
        name="divisionDate"
        label="마감일자"
      />
    </Modal>
  );
};

export default ClosingDateModal;
