import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
// import { useStmMenuListFetch } from "../../../../hooks/api/stmMenu";
import {
  menuListState,
  selecteMenuState,
  selectedMenuSelector,
} from "@/state/menu";
import { Menu } from "@/types/menu.type";
import { groupBy } from "@/util/common.fn";
import { useStmMenuListFetch } from "@/hooks/api/stmMenu";
import {
  AsideMenuList,
  AsideMenuProps,
} from "@/components/molecules/AsideMenuList";
import * as S from "./AsideMenuItem.style";
import { ReactComponent as MovePageIcon } from "@/assets/svg/Angle-right.svg";
import { ReactComponent as D02Icon } from "@/assets/svg/File.svg";
import { ReactComponent as D01Icon } from "@/assets/svg/Group-folders.svg";

export default function AsideMenuItem({
  menuDepth,
  asideOpen,
  menuId,
  depthOpen,
}: AsideMenuProps) {
  const { t } = useTranslation("main");
  // db 메뉴 데이터
  const { data } = useStmMenuListFetch();

  // 메뉴를 depth에 따라 그룹화
  const [menuGroup, setMenuGroup] = useState(groupBy(data, "depth"));
  const [menuList, setMenuList] = useRecoilState(menuListState);
  const [menuParent, setMenuParent] = useState<any>();
  const setMenu = useSetRecoilState(selectedMenuSelector);
  const selectedMenuId = useRecoilValue(selecteMenuState);

  useEffect(() => {
    if (Array.isArray(data) && data.length !== 0) {
      setMenuList(data);
    }
    // eslint-disable-next-line
  }, [data?.length]);

  useEffect(() => {
    setMenuGroup(groupBy(menuList, "depth"));
  }, [menuList]);

  const toggleMenu = (id: string, isOpen: boolean) => {
    setMenuList(
      menuList.map((menu: Menu) => {
        if (menu.id === id) {
          return { ...menu, isOpen: !isOpen };
        }
        return menu;
      })
    );
  };

  const depth02Menu = menuGroup[1]?.filter(
    (menu: Menu) => menu?.parentId === menuId
  );

  return menuDepth === 0
    ? menuGroup[menuDepth].map((item: any, idx: number) => {
        return (
          <S.AsideMenuItemBox key={idx}>
            <S.AsideMenuItem
              asideOpen={asideOpen}
              menuOpen={item.isOpen}
              menuDepth={menuDepth}
              onClick={() => toggleMenu(item.id, item.isOpen)}
            >
              <S.AsideMenuTitleBox menuDepth={0} asideOpen={asideOpen}>
                <D01Icon />
                <span>{t(item.menuName)}</span>
              </S.AsideMenuTitleBox>
              <MovePageIcon />
            </S.AsideMenuItem>

            {menuDepth === 0 && (
              <AsideMenuList
                asideOpen={asideOpen}
                menuDepth={1}
                menuId={item.id}
                depthOpen={item.isOpen ? true : false}
              />
            )}
          </S.AsideMenuItemBox>
        );
      })
    : depth02Menu.map((item: any, idx: number) => {
        return (
          <S.AsideMenuItemBox key={idx}>
            <S.AsideMenuItem
              asideOpen={asideOpen}
              menuOpen={selectedMenuId === item.id ? true : false}
              menuDepth={menuDepth}
              onClick={() => {
                setMenu({ id: item.id });
                setMenuParent(item.parentId);
              }}
            >
              <S.AsideMenuTitleBox
                menuDepth={1}
                asideOpen={asideOpen}
                menuOpen={selectedMenuId === item.id ? true : false}
              >
                <D02Icon />
                <span>{t(item.menuName)}</span>
              </S.AsideMenuTitleBox>
            </S.AsideMenuItem>
          </S.AsideMenuItemBox>
        );
      });
}
