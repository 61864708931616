import React from "react";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  Format,
  HeaderFilter,
  Scrolling,
} from "devextreme-react/data-grid";
import Grid from "@/components/atoms/Grid";
import { DetailContent } from "@/components/organisms/Contents/DetailContent";

interface CalculateProfitLowerGridProps {
  gridSub: React.MutableRefObject<any>;
  subData: any[] | undefined;
}

const CalculateProfitLowerGrid = ({
  gridSub,
  subData,
}: CalculateProfitLowerGridProps) => {
  const { t } = useTranslation("main");
  return (
    <DetailContent>
      <Grid
        detailGrid={true}
        refs={gridSub}
        dataSource={subData}
        keyExpr="Oid"
        onToolbarPreparing={(e: any) => {
          e.toolbarOptions.items.forEach((item: any) => {
            if (item.name === "saveButton") {
              item.visible = false;
            }
          });
        }}
        focusedRowEnabled={true}
      >
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} />
        <Scrolling mode="virtual" />

        {/* <Column dataField="companyName" caption={t("업체구분")} /> */}
        <Column
          dataField="regDate"
          caption={t("등록일")}
          alignment="center"
          dataType="date"
          format="yyyy-MM-dd"
        />
        <Column
          dataField="companyName"
          caption={t("업체명")}
          alignment="center"
        />
        <Column dataField="sales" caption={t("매출")}>
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="purchase" caption={t("매입")}>
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="remark01" caption={t("비고1")} alignment="center" />
        <Column dataField="remark02" caption={t("비고2")} alignment="center" />
        <Column
          dataField="AccountConection"
          caption={t("계좌연결여부")}
          alignment="center"
        />
        <Column
          dataField="ActualPaymentDate"
          caption={t("실지급일")}
          alignment="center"
        />
      </Grid>
    </DetailContent>
  );
};

export default CalculateProfitLowerGrid;
