import { useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  Format,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import Grid from "@/components/atoms/Grid";
import {
  InputCheckBox,
  InputDate,
  InputSelect,
} from "@/components/atoms/Inputs";
import { InputMultiple } from "@/components/atoms/InputMultiple";
import { GridContent } from "@/components/organisms/Contents/GridContent";

interface DispatchGridProps {
  dispatch: any[] | undefined;
  company: any[] | undefined;

  gridMain: any;
  mainData: any[] | undefined;
  searchForm: UseFormReturn<any>;

  toggleOpen: () => void;
  onSearch: (data: any) => void;
  onFocusedRowChanged: (e: any) => void;
}

const DispatchGrid = ({
  dispatch,
  company,
  gridMain,
  mainData,
  searchForm,
  toggleOpen,
  onSearch,
  onFocusedRowChanged,
}: DispatchGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "DispatchGrid";
  const [totalCount, setTotalCount] = useState<number>(0);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = searchForm;
  return (
    <GridContent
      searchSection={
        <>
          <InputMultiple direction="row" label="검색기간">
            <>
              <InputSelect
                name="searchType"
                width={120}
                displayExpr="name"
                valueExpr="id"
                control={control}
                errors={errors}
                dataSource={[
                  { id: 2, name: "운송요청일자" },
                  { id: 1, name: "접수일자" },
                  { id: 3, name: "작업지도착시간" },
                ]}
              />
              <InputDate
                name="firstDay"
                width={130}
                control={control}
                errors={errors}
              />
              ~
              <InputDate
                name="lastDay"
                width={130}
                control={control}
                errors={errors}
              />
            </>
          </InputMultiple>
          <InputSelect
            label="접수법인"
            name="receiveCo"
            width={120}
            displayExpr="companyName"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={[
              { id: "", companyName: t("전체") },
              ...(company || []),
            ]}
          />
          <InputSelect
            label="배차팀"
            name="dispatchTeam"
            width={140}
            displayExpr="deptName"
            valueExpr="code"
            control={control}
            errors={errors}
            dataSource={[
              { code: "", deptName: t("전체") },
              ...(dispatch || []),
            ]}
          />
          <InputSelect
            label="수입/수출"
            name="importExport"
            width={80}
            displayExpr="name"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={[
              { id: "", name: t("전체") },
              { id: 1, name: t("수입") },
              { id: 2, name: t("수출") },
            ]}
          />

          <InputSelect
            label="밥테일 여부"
            name="isBobtail"
            width={120}
            displayExpr="name"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={[
              { id: 0, name: t("전체") },
              { id: 1, name: "밥테일" },
              { id: 2, name: "밥테일제외" },
            ]}
          />
          <InputCheckBox
            label="하불손실조회"
            name="isLoss"
            control={control}
            errors={errors}
          />
        </>
      }
      searchSubmit={handleSubmit(onSearch)}
      gridRef={gridMain}
      storageKey={storageKey}
      totalCount={totalCount}
    >
      {/* <Button
            variety="new"
            shape="solid"
            size="sm"
            type="button"
            onClick={toggleOpen}
          >
            입력
          </Button> */}

      <Grid
        refs={gridMain}
        dataSource={mainData}
        keyExpr="id"
        onFocusedRowChanged={onFocusedRowChanged}
        focusedRowEnabled={true}
        onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
      >
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} />
        <StateStoring
          enabled={true}
          type="custom"
          customLoad={useCallback(
            () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
            []
          )}
        />
        <Scrolling mode="virtual" />
        <Column
          caption="No"
          cellRender={(e) => e.row.loadIndex + 1}
          alignment="center"
          width={35}
        />
        <Column dataField="orderCode" caption={t("접수번호")} width={130} />
        <Column
          dataField="division"
          caption={t("구분")}
          // cellRender={divisionRender}
          alignment="center"
          width={70}
        />
        <Column
          dataField="orderStatus"
          caption={t("상태")}
          // cellRender={orderStatusRender}
          alignment="center"
          width={70}
        />
        <Column
          dataField="importExport"
          caption={t("수입/수출")}
          // cellRender={importExportRender}
          alignment="center"
          width={90}
        />
        <Column
          dataField="forwarder"
          caption={t("선사")}
          alignment="center"
          width={70}
        />
        <Column dataField="cargoOwnerName" caption={t("화주")} width={120} />
        <Column
          dataField="realCargoOwner"
          caption={t("실화주")}
          alignment="center"
          width={100}
        />
        <Column
          dataField="billingPlaceName"
          caption={t("청구처")}
          width={160}
        />
        <Column
          dataField="orderCompanyName"
          caption={t("발주처")}
          width={110}
        />
        <Column
          dataField="blNo"
          caption={t("B/L No")}
          alignment="center"
          width={100}
        />
        <Column
          dataField="bkNo"
          caption={t("B/K No")}
          alignment="center"
          width={100}
        />
        <Column
          dataField="doNo"
          caption={t("DO No")}
          alignment="center"
          width={100}
        />
        <Column
          dataField="containerNo"
          caption={t("컨테이너번호")}
          width={120}
        />
        <Column dataField="sealNo" caption={t("Seal No")} width={100} />
        <Column
          dataField="containerType"
          caption={t("컨규격")}
          alignment="center"
          width={90}
        />

        <Column
          dataField="chassis"
          caption={t("샤시번호")}
          alignment="center"
          width={100}
        />
        <Column
          dataField="chassisType"
          caption={t("샤시종류")}
          alignment="center"
          width={100}
        />

        <Column
          dataField="deliverDate"
          caption={t("운송요청일자")}
          dataType="date"
          format="yyyy-MM-dd"
          alignment="center"
          width={110}
        />
        <Column
          dataField="returnDate"
          caption={t("반납마감일자")}
          dataType="date"
          format="yyyy-MM-dd"
          alignment="center"
          width={110}
        />
        <Column
          dataField="freight"
          caption={t("청구금액")}
          alignment="right"
          dataType="number"
          width={100}
        >
          <Format type="fixedPoint" />
        </Column>
        <Column
          dataField="extraCharge"
          caption={t("할증금액")}
          alignment="right"
          dataType="number"
          width={100}
        >
          <Format type="fixedPoint" />
        </Column>
        <Column
          dataField="totalPrice"
          caption={t("총합계")}
          alignment="right"
          width={100}
        >
          <Format type="fixedPoint" />
        </Column>
        <Column
          dataField="habul"
          caption={t("하불금액")}
          alignment="right"
          dataType="number"
          width={100}
        >
          <Format type="fixedPoint" />
        </Column>
        <Column
          dataField="manager"
          caption={t("청구처담당자")}
          alignment="center"
          width={110}
        />
        <Column dataField="dispatchTeam" caption={t("배차팀")} width={110} />
        <Column
          dataField="loadPlaceCode"
          caption={t("상차지")}
          alignment="center"
          width={100}
        >
          {/* <Lookup
            dataSource={terminalData}
            displayExpr="twName"
            valueExpr="code"
          /> */}
        </Column>
        <Column
          dataField="workPlaceCode"
          caption={t("작업지")}
          alignment="center"
          width={100}
        >
          {/* <Lookup
            dataSource={terminalData}
            displayExpr="twName"
            valueExpr="code"
          /> */}
        </Column>
        <Column
          dataField="unloadPlaceCode"
          caption={t("하차지")}
          alignment="center"
          width={100}
        >
          {/* <Lookup
            dataSource={terminalData}
            displayExpr="twName"
            valueExpr="code"
          /> */}
        </Column>
        <Column
          dataField="workTime"
          caption={t("작업시간")}
          dataType="date"
          format="HH:mm:ss"
          alignment="center"
          width={90}
        />
        <Column
          dataField="dem"
          caption={t("선사반출기한")}
          dataType="date"
          format="yyyy-MM-dd"
          alignment="center"
          width={110}
        />
        <Column
          dataField="bobtail"
          caption={t("밥테일")}
          dataType="boolean"
          width={80}
        />
        <Column
          dataField="fridge"
          caption={t("냉동")}
          dataType="boolean"
          width={80}
        />
        <Column
          dataField="danger"
          caption={t("위험물")}
          dataType="boolean"
          width={80}
        />
        <Column
          dataField="tank"
          caption={t("탱크")}
          dataType="boolean"
          width={80}
        />
        <Column
          dataField="direct"
          caption={t("직반입")}
          dataType="boolean"
          width={80}
        />
        <Column
          dataField="temperature"
          caption={t("온도")}
          alignment="center"
          width={80}
        />
        <Column
          dataField="humidity"
          caption={t("습도")}
          alignment="center"
          width={80}
        />
        <Column
          dataField="vent"
          caption={t("환풍구")}
          alignment="center"
          width={80}
        />
        <Column
          dataField="unNo"
          caption={t("UN번호")}
          alignment="center"
          width={100}
        />
        <Column
          dataField="class"
          caption={t("클래스")}
          alignment="center"
          width={100}
        />
        <Column
          dataField="extraInfo"
          caption={t("특이사항")}
          alignment="center"
          width={100}
        />
        <Column
          dataField="createUsr"
          caption={t("최초입력자")}
          alignment="center"
          width={100}
        />
        <Column
          dataField="updateUsr"
          caption={t("최종수정자")}
          alignment="center"
          width={100}
        />
        <Column
          dataField="createDate"
          caption={t("작성일")}
          alignment="center"
          width={100}
        />
        <Column
          dataField="createTime"
          caption={t("작성시간")}
          alignment="center"
          width={100}
        />
      </Grid>
    </GridContent>
  );
};

export default DispatchGrid;
