import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useCompanyData } from "@/hooks/queries";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import { ClosingRowType } from "@/types/closing.type";
import { FinishSearchType, InitFinishSearch } from "@/types/tax.type";
import TaxIssueModal from "@/components/molecules/Modal/TaxIssueModal";
import { ContentTemplate } from "@/components/templates/ContentTemplate";
import FinishManageLowerGrid from "./FinishManageLowerGrid";
import FinishManageUpperGrid from "./FinishManageUpperGrid";

const FinishManagePage = () => {
  const [subUrl, setSubUrl] = useState<string | null>(null);
  const [selectedRowData, setSelectedRowData] = useState<ClosingRowType | null>(
    null
  );
  const [taxIssueModalVisible, setTaxIssueModalVisible] =
    useState<boolean>(false);

  const [search, setSearch] = useState<FinishSearchType>(InitFinishSearch);

  const gridMain = useRef<any>();
  const gridSub = useRef<any>();

  const { alertErrorMessage } = useAlertMessage();

  const { data: mainData, refetch: mainRefetch } = useCustomQuery<any[]>(
    `manage-all-finish`,
    search
  );
  const { data: company } = useCompanyData();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    defaultValues: InitFinishSearch,
  });

  const initPage = () => {
    const mainGridInstance = gridMain.current.instance;
    const subGridInstance = gridSub.current.instance;
    mainGridInstance.option("focusedRowIndex", -1);
    subGridInstance.option("focusedRowIndex", -1);
    setSelectedRowData(null);
    setSubUrl(null);
    mainRefetch();
  };

  const onClickOpenTaxModal = () => {
    if (!selectedRowData?.completeNo) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }

    setTaxIssueModalVisible(true);
  };

  const onSearch = (data: any) => {
    initPage();
    setSearch(data);
  };

  const onFocusedRowChanged = (e: any) => {
    if (!e.row?.data) return;

    setSelectedRowData(e.row.data);
    const { division } = e.row.data;
    if (division === "청구처") {
      setSubUrl("billing");
    }
    if (division === "용차사") {
      setSubUrl("yongcha");
    }
    if (division === "기사") {
      setSubUrl("driver");
    }
  };

  const onRowDblClick = () => {
    if (!selectedRowData?.completeNo) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }

    setTaxIssueModalVisible(true);
  };

  const onClickTaxIssueModalClose = () => {
    const mainGridInstance = gridMain.current.instance;
    const originalKey = selectedRowData?.finishNo;

    initPage();
    mainGridInstance.option("focusedRowKey", originalKey);
    setTaxIssueModalVisible(false);
  };

  return (
    <>
      <ContentTemplate bottomHeight={320}>
        <>
          <FinishManageUpperGrid
            bottomHeight={320}
            gridMain={gridMain}
            mainData={mainData}
            control={control}
            errors={errors}
            company={company}
            searchSubmit={handleSubmit(onSearch)}
            onClickOpenTaxModal={onClickOpenTaxModal}
            onFocusedRowChanged={onFocusedRowChanged}
            onRowDblClick={onRowDblClick}
          />

          <FinishManageLowerGrid
            gridSub={gridSub}
            search={search}
            selectedRowData={selectedRowData}
            subUrl={subUrl}
          />
        </>
      </ContentTemplate>
      {taxIssueModalVisible && (
        <TaxIssueModal
          onClose={onClickTaxIssueModalClose}
          selectedRowData={selectedRowData}
        />
      )}
    </>
  );
};

export default FinishManagePage;
