import styled from "styled-components";

interface MainBoxProps {
  asideOpen: boolean;
}

export const MainTemplate = styled.div<MainBoxProps>`
  display: grid;
  width: 100vw;
  height: 100vh;
  grid-template-areas:
    "TB MN"
    "TL MN"
    "CT MN";
  grid-template-columns: ${(props) =>
    props.asideOpen ? "calc(100vw - 260px) 260px" : "calc(100vw - 54px) 54px"};
  grid-template-rows: 60px 46px calc(100vh - 106px);
  transition: all 0.3s ease;
`;

export const MainContentSection = styled.section`
  grid-area: CT;
  overflow: hidden;
  padding: 0 16px 16px;
  background: ${(props) => props.theme.colors.gray100};
`;

export const MainContentBox = styled.div`
  overflow: hidden;
  display: flex;
  height: calc(100vh - 122px);
  border-radius: 6px;
`;
