import { useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  Format,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import { useCompanyData } from "@/hooks/queries";
import Grid from "@/components/atoms/Grid";
import { InputDate, InputSelect } from "@/components/atoms/Inputs";
import { GridContent } from "@/components/organisms/Contents/GridContent";
import { InputMultiple } from "@/components/atoms/InputMultiple";

interface EtcCostGridProps {
  gridMain: any;
  mainData: any[] | undefined;
  searchForm: UseFormReturn<any>;

  toggleOpen: () => void;
  onSearch: (data: any) => void;
  onFocusedRowChanged: (e: any) => void;
}

const EtcCostGrid = ({
  gridMain,
  mainData,
  searchForm,
  toggleOpen,
  onSearch,
  onFocusedRowChanged,
}: EtcCostGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "EtcCostGrid";
  const [totalCount, setTotalCount] = useState<number>(0);

  const { data: company } = useCompanyData();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = searchForm;

  return (
    <GridContent
      searchSection={
        <>
          <InputSelect
            label="접수법인"
            name="receiveCo"
            width={140}
            control={control}
            errors={errors}
            dataSource={[
              { id: "0", companyName: t("전체") },
              ...(company || []),
            ]}
            valueExpr="id"
            displayExpr="companyName"
          />
          <InputMultiple label="등록일" direction="row">
            <>
              <InputDate
                name="firstDay"
                width={120}
                control={control}
                errors={errors}
              />
              ~
              <InputDate
                name="lastDay"
                width={120}
                control={control}
                errors={errors}
              />
            </>
          </InputMultiple>
        </>
      }
      searchSubmit={handleSubmit(onSearch)}
      totalCount={totalCount}
      gridRef={gridMain}
      storageKey={storageKey}
    >
      {/* 페이지 타이틀 라인 */}
      {/* <Button
            permission="save"
            variety="new"
            shape="solid"
            size="sm"
            type="button"
            onClick={toggleOpen}
          >
            입력
          </Button> */}

      <Grid
        className="has-custom"
        refs={gridMain}
        dataSource={mainData || []}
        keyExpr="Oid"
        onFocusedRowChanged={onFocusedRowChanged}
        focusedRowEnabled={true}
        onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
      >
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} />
        <StateStoring
          enabled={true}
          type="custom"
          customLoad={useCallback(
            () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
            []
          )}
        />
        <Scrolling mode="virtual" />
        <Column dataField="receivecoName" caption={t("업체구분")} />
        <Column
          dataField="regDate"
          caption={t("등록일")}
          alignment="center"
          dataType="date"
          format="yyyy-MM-dd"
        />
        <Column
          dataField="companyName"
          caption={t("업체명")}
          alignment="center"
        />
        <Column dataField="sales" caption={t("매출")} alignment="center">
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="purchase" caption={t("매입")} alignment="center">
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="remark01" caption={t("비고1")} alignment="center" />
        <Column dataField="remark02" caption={t("비고2")} alignment="center" />
      </Grid>
    </GridContent>
  );
};

export default EtcCostGrid;
