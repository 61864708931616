import { RadioGroup } from "devextreme-react";
import { Controller } from "react-hook-form";
import { Input } from "..";

const InputRadioBox = ({
  control,
  errors,
  name,
  label,
  dataSource,
  displayExpr = "name",
  valueExpr = "id",
  required,
  disable,
  direction,
  onValueChanged = () => {},
}: any) => {
  // dataSource
  return (
    <Input
      type="radio"
      direction={direction ? direction : `row`}
      label={label && label}
      required={required}
      errors={errors && errors}
    >
      <Controller
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            onValueChanged={(value) => {
              onValueChanged(value);
            }}
            disabled={disable}
            dataSource={dataSource}
            displayExpr={displayExpr}
            valueExpr={valueExpr}
            layout="horizontal"
            onValueChange={(value) => {
              onChange(value);
            }}
            value={value}
          />
        )}
        control={control}
        name={name}
        // defaultValue={items[0]}
      />
    </Input>
  );
};

export default InputRadioBox;
