import dayjs from "dayjs";

export interface MainDataType {
  calculate: any[];
  etc: any[];
}

export const InitialSearch = {
  receiveCo: "0",
  baseMonth: dayjs().format("YYYY-MM"),
};

export interface ProfitDataType {
  profit: number;
  purchase: number;
  income: number;
  etcProfit: number;
  etcPurchase: number;
  netProfit: number;
}

export const InitProfitData: ProfitDataType = {
  profit: 0,
  purchase: 0,
  income: 0,
  etcProfit: 0,
  etcPurchase: 0,
  netProfit: 0,
};
