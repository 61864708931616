import styled from "styled-components";

export const STModalContent = styled.div`
  display: flex;
  max-height: calc(100vh - 174px);
  flex-direction: column;
  gap: 20px;

  .sub-grid {
    max-height: calc(100vh - 224px);

    .dx-datagrid-columns-separator {
      display: none;
    }

    .dx-datagrid-header-panel {
      display: none;
    }
  }
`;

export const STModalTopSection = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
`;

export const STModalBottomSection = styled.div`
  display: grid;
  padding-top: 20px;
  border-top: 1px solid ${(props) => props.theme.colors.black12};
  grid-template-columns: repeat(2, 1fr);
  gap: 5px 16px;
`;

export const STModalGrid = styled.div`
  overflow: hidden;
  height: auto;
`;
