import { useQuery } from "@tanstack/react-query";
// import { STM_MENU, MENU_LIST } from "../../data/comCode";
// import { request } from "../../lib/api";
// import { usePostMutation } from "../../lib/common.fn";
import { Menu } from "@/types/menu.type";
import { request } from "@/util/api";

// { code }: any,  //params
function useStmMenuListFetch() {
  return useQuery<Menu[], boolean>(
    {
      queryKey: ["menuList"],
      queryFn: () =>
        request({
          method: "GET",
          url: "menu/list",
        }),
    }
    // [MENU_LIST],
    // () => request({ method: "GET", url: `/${STM_MENU}/${MENU_LIST}` }),
    // { onError: () => {} }
  );
}

// function useStmMenuFetch(search: any) {
//   return useQuery<Menu[], boolean>(
//     [STM_MENU, search],
//     () => request({ method: "GET", url: `/${STM_MENU}`, params: search }),
//     { onError: () => {} }
//   );
// }

// function useStmMenuCreate(onSuccess: any = () => {}) {
//   return usePostMutation(STM_MENU, onSuccess);
// }

export { useStmMenuListFetch };
