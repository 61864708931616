import React, { useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  Format,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import { useCompanyData, useDispatchData } from "@/hooks/queries";
import { ShuttleDispatchSearchType } from "@/types/shuttleDispatch.type";
import Grid from "@/components/atoms/Grid";
import { InputDate, InputSelect, InputText } from "@/components/atoms/Inputs";
import { InputMultiple } from "../../../components/atoms/InputMultiple";
import { GridContent } from "@/components/organisms/Contents/GridContent";

interface ShuttleDispatchGridProps {
  toggleOpen: () => void;
  gridMain: React.MutableRefObject<any>;
  mainData: any[] | undefined;
  searchForm: UseFormReturn<ShuttleDispatchSearchType, any, undefined>;
  onSearch: (data: any) => void;
  onFocusedRowChanged: (e: any) => void;
}

const ShuttleDispatchGrid = ({
  toggleOpen,
  gridMain,
  mainData,
  searchForm,
  onSearch,
  onFocusedRowChanged,
}: ShuttleDispatchGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "DispatchGrid";
  const [totalCount, setTotalCount] = useState<number>(0);

  const { data: dispatch } = useDispatchData();
  const { data: company } = useCompanyData();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = searchForm;

  return (
    <GridContent
      searchSection={
        <>
          <InputSelect
            label="수입/수출"
            name="importExport"
            width={80}
            displayExpr="name"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={[
              { id: "", name: t("전체") },
              { id: "1", name: t("수입") },
              { id: "2", name: t("수출") },
            ]}
          />
          <InputMultiple direction="row" label="검색기간">
            <>
              <InputSelect
                name="searchType"
                displayExpr="name"
                width={120}
                valueExpr="id"
                control={control}
                errors={errors}
                dataSource={[
                  { id: "1", name: "접수일자" },
                  { id: "2", name: "운송일자" },
                ]}
              />
              <InputDate
                name="firstDay"
                width={140}
                control={control}
                errors={errors}
              />
              ~
              <InputDate
                name="lastDay"
                width={140}
                control={control}
                errors={errors}
              />
            </>
          </InputMultiple>

          <InputSelect
            label="접수법인"
            name="receiveCo"
            width={140}
            displayExpr="companyName"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={[
              { id: "", companyName: t("전체") },
              ...(company || []),
            ]}
          />
          <InputSelect
            label="배차팀"
            name="dispatchTeam"
            width={140}
            displayExpr="deptName"
            valueExpr="code"
            control={control}
            errors={errors}
            dataSource={[
              { code: "", deptName: t("전체") },
              ...(dispatch || []),
            ]}
          />

          <InputText
            label="컨테이너번호"
            name="containerNo"
            width={140}
            control={control}
            errors={errors}
          />
          <InputText
            label="BL"
            name="blNo"
            width={100}
            control={control}
            errors={errors}
          />
          <InputText
            label="DO"
            name="doNo"
            width={100}
            control={control}
            errors={errors}
          />
          <InputText
            label="BK"
            name="bkNo"
            width={100}
            control={control}
            errors={errors}
          />
        </>
      }
      searchSubmit={handleSubmit(onSearch)}
      gridRef={gridMain}
      storageKey={storageKey}
      totalCount={totalCount}
    >
      <Grid
        refs={gridMain}
        dataSource={mainData}
        keyExpr="code"
        onFocusedRowChanged={onFocusedRowChanged}
        focusedRowEnabled={true}
        onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
      >
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} />
        <StateStoring
          enabled={true}
          type="custom"
          customLoad={useCallback(
            () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
            []
          )}
        />
        <Scrolling mode="virtual" />
        <Column
          dataField="code"
          caption={t("접수번호")}
          alignment="center"
          width={130}
        />
        <Column
          dataField="receiveDate"
          caption={t("접수일자")}
          alignment="center"
          width={90}
        />
        <Column dataField="receiveCoName" caption={t("접수법인")} width={120} />
        <Column
          dataField="importExport"
          caption={t("수입/수출")}
          alignment="center"
          // cellRender={importExportCellRender}
          width={90}
        />
        <Column
          dataField="billingPlaceName"
          caption={t("청구처")}
          width={120}
        />
        <Column
          dataField="containerNo"
          caption={t("컨테이너번호")}
          width={115}
        />
        <Column dataField="cargoOwnerName" caption={t("화주")} width={120} />
        <Column dataField="realCargoOwner" caption={t("실화주")} width={120} />
        <Column
          dataField="containerType"
          caption={t("컨규격")}
          alignment="center"
          width={80}
        />
        <Column dataField="loadPlaceName" caption={t("상차지")} width={75} />
        <Column dataField="carName_01" caption={t("배차차량")} width={90} />
        <Column dataField="yongchasaName_01" caption={t("용차사")} width={90} />
        <Column
          dataField="yongchaCarNo_01"
          caption={t("용차사차량")}
          width={110}
        />
        <Column dataField="workPlaceName" caption={t("작업지")} width={75} />
        <Column dataField="carName_02" caption={t("배차차량")} width={90} />
        <Column dataField="yongchasaName_02" caption={t("용차사")} width={90} />
        <Column
          dataField="yongchaCarNo_02"
          caption={t("용차사차량")}
          width={110}
        />
        <Column dataField="unloadPlaceName" caption={t("하차지")} width={75} />
        <Column
          dataField="freight"
          caption={t("청구운임")}
          dataType="number"
          width={110}
        >
          <Format type="fixedPoint" />
        </Column>
        <Column
          dataField="habul_01"
          caption={t("상차지 하불금액")}
          dataType="number"
          width={110}
        >
          <Format type="fixedPoint" />
        </Column>
        <Column
          dataField="habul_02"
          caption={t("하차지 하불금액")}
          dataType="number"
          width={110}
        >
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="blNo" caption={t("B/L No")} width={90} />
        <Column dataField="bkNo" caption={t("B/K No")} width={90} />
        <Column dataField="doNo" caption={t("DO No")} width={90} />
        <Column
          dataField="manager"
          caption={t("담당자")}
          alignment="center"
          width={80}
        />
        <Column dataField="extraInfo" caption={t("특이사항")} width={90} />
        <Column dataField="remark" caption={t("비고")} />
      </Grid>
    </GridContent>
  );
};

export default ShuttleDispatchGrid;
