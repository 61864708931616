import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Column, Scrolling, StateStoring } from "devextreme-react/data-grid";
import dayjs from "dayjs";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import Grid from "@/components/atoms/Grid";
import InputMonth from "@/components/atoms/Inputs/InputMonth";
import EmailPopup from "@/components/molecules/Modal/EmailPopup";
import { GridContent } from "@/components/organisms/Contents/GridContent";
import { ContentTemplate } from "@/components/templates/ContentTemplate";

const InvoiceEmailHistory = () => {
  const { t } = useTranslation("main");
  const storageKey = "InvoiceEmailHistory";
  const initialState = { baseMonth: dayjs().format("YYYY-MM") };
  const [search, setSearch] = useState(initialState);
  const [selectedData, setSelectedData] = useState<any>({});
  const [mailPopup, setMailPopup] = useState<boolean>(false);

  const { data: mainData, refetch: mainRefetch } = useCustomQuery<any[]>(
    `invoice-email-history`,
    search
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: initialState,
  });

  const onSearch = (data: { baseMonth: string }) => {
    setSearch(data);

    mainRefetch();
  };

  const onRowDblClick = (e: any) => {
    setSelectedData(e.data);
    setMailPopup(true);
  };

  return (
    <>
      <ContentTemplate>
        <GridContent
          searchSection={
            <InputMonth
              label="기준 월"
              name="baseMonth"
              width={100}
              control={control}
              errors={errors}
            />
          }
          searchSubmit={handleSubmit(onSearch)}
        >
          <Grid
            key="oid"
            keyExpr="oid"
            dataSource={mainData}
            focusedRowEnabled={true}
            onRowDblClick={onRowDblClick}
          >
            <Scrolling
              columnRenderingMode="virtual"
              mode="virtual"
              showScrollbar="always"
              useNative="false"
            />
            <StateStoring
              enabled={true}
              type="custom"
              customLoad={useCallback(
                () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
                []
              )}
            />
            <Column dataField="subject" caption={t("제목")} />
            <Column dataField="userName" caption={t("발송자명")} width={150} />
            <Column
              dataField="mailTo"
              caption={t("수신자 이메일")}
              width={180}
            />
            <Column dataField="contents" caption={t("내용")} />
            <Column dataField="fileName" caption={t("첨부파일명")} />
            <Column
              dataField="createDate"
              caption={t("발송일시")}
              dataType="date"
              format="yyyy-MM-dd HH:mm:ss"
              width={130}
            />
          </Grid>
        </GridContent>

        {/* <div className="grid-top-custom">
          <div className="total-count">{`총 ${totalCount}건`}</div>
          <StateStoringSaveAndDeleteButton dataGridRef={gridMain} storageKey={storageKey} />
        </div> */}
      </ContentTemplate>
      {mailPopup && (
        <EmailPopup
          onClose={() => setMailPopup(false)}
          selectedData={selectedData}
        />
      )}
    </>
  );
};

export default InvoiceEmailHistory;
