import styled from "styled-components";

export const TabListBox = styled.div`
  grid-area: TL;
  display: flex;
  padding: 0 22px;
  background: ${(props) => props.theme.colors.gray100};
  align-items: flex-end;
`;

export const TabList = styled.ul`
  position: relative;
  overflow: auto;
  display: flex;
  width: 100%;
  gap: 2px;

  // 탭메뉴 스크롤바 설정
  &::-webkit-scrollbar {
    visibility: hidden;
    width: 0px;
    height: 0px;
    background: transparent;
    transition: all 0.2s ease;
  }

  &:hover {
    &::-webkit-scrollbar {
      position: absolute;
      height: 10px;
      background: ${(props) => props.theme.colors.white};
    }

    &::-webkit-scrollbar-thumb {
      background-clip: padding-box;
      border: 2px solid transparent;
      border-radius: 10px;
      background-color: ${(props) => props.theme.colors.scrollBar};
      transition: all 0.2s ease;

      &:hover {
        background-color: ${(props) => props.theme.colors.black50};
        transition: all 0.2s ease;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: ${(props) => props.theme.colors.white70};
    }
  }
`;
