import styled from "styled-components";

interface TopBarProps {
  asideOpen?: boolean;
}

export const TopBarBox = styled.header<TopBarProps>`
  position: relative;
  grid-area: TB;
  display: flex;
  width: 100%;
  padding: 0 16px;
  background-color: ${(props) => props.theme.colors.white100};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray100};
  align-items: center;
  justify-content: space-between;
`;

export const TopBarTitle = styled.div`
  display: flex;
  padding: 0 10px;
  color: ${(props) => props.theme.colors.TopbarTitleTxt};
  font-size: 1.6rem;
  font-weight: 500;
  align-items: center;
  gap: 8px;

  svg {
    path {
      fill: ${(props) => props.theme.colors.primary100};
    }
  }

  span {
    display: inline-block;
    padding-top: 2px;
    line-height: 20px;
  }
`;

export const TopBarProfileBox = styled.div`
  display: flex;
  align-items: Center;
  gap: 14px;

  span {
    padding-top: 1px;
  }
`;

// 유저 업체명
export const TopBarProfile = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.black70};
  font-size: 1.4rem;
  font-weight: 400;
  align-items: center;
  gap: 4px;
`;

// 유저명
export const UserName = styled.span`
  display: flex;
  font-size: 1.6rem;
  font-weight: 700;
  align-items: center;
  gap: 8px;
`;
