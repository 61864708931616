import ExtraChargeModal from "@/components/molecules/Modal/ExtraChargeModal";

import { 오더마스터색상, 컨테이너_규격 } from "@/data/constant/queryKeys";
import {
  useBillingData,
  useCargoOwnerData,
  useComCodeData,
  useCompanyData,
  useDispatchData,
  useForwarderData,
  useOrderCoData,
} from "@/hooks/queries";
import useButtonLoading from "@/hooks/useButtonLoading";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import useExtraCharge from "@/hooks/utils/useExtraCharge";
import { userInfo } from "@/state/atoms/userInfo";
import {
  InitialOrderInput,
  InitialOrderSearch,
  OrderGridType,
  OrderInputSchema,
  OrderInputType,
  UnipassType,
} from "@/types/order.type";
import {
  useDeleteMutation,
  useGetMutation,
  usePostMutation,
  usePutMutation,
} from "@/util/common.fn";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import OrderDetailGrid from "./OrderDetailGrid";
import OrderGrid from "./OrderGrid";
import OrderInput from "./OrderInput";
import { ContentTemplate } from "@/components/templates/ContentTemplate";

const MakeOrderPage = () => {
  const userData = useRecoilValue(userInfo);

  // input펼치기 여부
  const [openinputbar, setopeninputbar] = useState<boolean>(true);

  const [search, setSearch] = useState(InitialOrderSearch(userData));
  const [selectedRowKey, setSelectedRowKey] = useState<string | null>(null);
  const gridMain = useRef<any>();
  const gridSub = useRef<any>();

  const { alertErrorMessage, alertSuccessMessage, confirmMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  // 할증등록 hook
  const {
    // data
    extraChargeModal,
    extraChargeData,
    etcChargeHabulData,

    // ref
    extraChargeRef,
    etcChargeHabulRef,

    // function
    onClose,
    extraChargeCellButtonRender,
    extraChargeCellRender,
    etcChargeHabulCellRender,
    onExtraChargeSubmit,
  } = useExtraCharge(gridSub);

  // 데이터 FETCH
  const { data: mainData, refetch: mainRefetch } = useCustomQuery<
    OrderGridType[]
  >(`order/master`, search);
  const { data: inputData } = useCustomQuery<OrderInputType[]>(
    `order/master/${selectedRowKey}`,
    {},
    !!selectedRowKey
  );

  const { data: dispatch } = useDispatchData();
  const { data: company } = useCompanyData();
  const { data: billing } = useBillingData();
  const { data: cargoOwner } = useCargoOwnerData();
  const { data: orderCo } = useOrderCoData();
  const { data: forwarder } = useForwarderData();
  const { data: color } = useComCodeData(오더마스터색상);
  const { data: conType } = useComCodeData(컨테이너_규격);

  const { mutateAsync: unipass } =
    usePostMutation<UnipassType>("order/unipass");
  const { mutateAsync: confirmCreate } = usePostMutation("order/confirmcreate");
  const { mutateAsync: confirmUpdate } = usePutMutation("order/confirmupdate");
  const { mutateAsync: orderCheck } = useGetMutation("order/check");
  const { mutateAsync: remove } = useDeleteMutation("order/master");

  const searchForm = useForm<any>({
    defaultValues: InitialOrderSearch(userData),
  });
  const inputForm = useForm<any>({
    defaultValues: InitialOrderInput(userData),
    resolver: yupResolver(OrderInputSchema),
  });

  const { reset, watch, setValue, trigger } = inputForm;

  useEffect(() => {
    inputData && reset(inputData);
    // eslint-disable-next-line
  }, [inputData]);

  const initPage = () => {
    const mainGridInstance = gridMain.current.instance;
    const subGridInstance = gridSub.current.instance;
    mainGridInstance.option("focusedRowIndex", -1);
    subGridInstance.option("focusedRowIndex", -1);
    resetGridChanges();
    setSelectedRowKey(null);
    mainRefetch();
    reset(InitialOrderInput(userData));
  };

  const resetGridChanges = () => {
    const mainGridInstance = gridMain.current.instance;
    const subGridInstance = gridSub.current.instance;
    mainGridInstance.option("editing.changes", []);
    subGridInstance.option("editing.changes", []);
  };

  const toggleOpen = () => setopeninputbar(!openinputbar);

  const onClickUnipass = async () => {
    const data = watch();

    if (!data.blNo) {
      alertErrorMessage("B/L No를 입력해 주세요.");
      return;
    }

    if (!data.blType) {
      alertErrorMessage("B/L 구분을 입력해 주세요.");
      return;
    }
    try {
      setLoading();
      const res = await unipass({
        blNo: data.blNo,
        blType: data.blType,
      });

      const { cntrs, vydf, shipNm, etprDt } = res;

      setValue("enterDay", dayjs(etprDt).format("YYYY-MM-DD"));
      setValue("motherShip", vydf);
      setValue("shipName", shipNm);

      if (cntrs.length === 0) {
        alertErrorMessage("조회된 정보가 없습니다.");
      }

      const grid = gridSub.current.instance;
      const rows = grid
        .getVisibleRows()
        .map((cur: any) => cur.data.containerNo);

      for (const obj of cntrs) {
        if (rows.includes(obj.cntrNo)) continue;
        let ctnr = "";

        const filteredType = (conType || []).filter(
          (cur) => cur.subCode === obj.cntrStszCd
        );

        ctnr = filteredType[0] ? filteredType[0].extra1 : "";

        grid.addRow();
        grid.cellValue(0, "containerNo", obj.cntrNo);
        grid.cellValue(0, "containerType", ctnr);
        grid.cellValue(0, "cargoWeight", obj.weight);
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onClickNewData = () => {
    initPage();
  };

  // 저장함수
  const onClickConfirmSaveData = async () => {
    if (!(await trigger())) return;

    const data = watch();
    const mainGridInstance = gridMain.current.instance;
    const subGridInstance = gridSub.current.instance;
    const originalKey = selectedRowKey;

    const detailChanges = subGridInstance.option("editing.changes");
    const isDetailValid = await subGridInstance
      .getController("validating")
      .validate(true);

    // const detailRows = subGridInstance.getVisibleRows();

    // if (Array.isArray(detailRows) && detailRows.length === 0) {
    //   alertErrorMessage("세부정보가 없습니다.");
    //   return;
    // }

    if (!isDetailValid) {
      alertErrorMessage("세부정보 오류");
      return;
    }

    data.division = "order";

    let res: any;

    try {
      setLoading();
      if (selectedRowKey) {
        res = await confirmUpdate({
          master: { ...data, confirm: true },
          detail: detailChanges,
        });
      } else {
        res = await confirmCreate({
          master: { ...data, confirm: true },
          detail: detailChanges,
        });
      }

      if (res.state === 1) {
        initPage();
        await alertSuccessMessage("저장완료");
      }

      mainGridInstance.option("focusedRowKey", originalKey);
    } catch (error: any) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onClickDeleteData = async () => {
    if (!selectedRowKey) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }
    try {
      setLoading();
      if (
        !(await confirmMessage(
          "정말 삭제하시겠습니까? 삭제된 데이터는 복구되지 않습니다."
        ))
      )
        return;

      await orderCheck({ code: selectedRowKey });

      await remove({ key: selectedRowKey });

      await alertSuccessMessage("삭제완료");

      initPage();
    } catch (error: any) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onSearch = (data: any) => {
    initPage();
    setSearch(data);
    mainRefetch();
  };

  const onFocusedRowChanged = (e: any) => {
    if (!e.row?.data) return;
    setSelectedRowKey(e.row.key);
  };
  return (
    <>
      <ContentTemplate sideWidth={280} bottomHeight={280}>
        <>
          <OrderGrid
            bottomHeight={280}
            dispatch={dispatch || []}
            company={company || []}
            gridMain={gridMain}
            mainData={mainData}
            searchForm={searchForm}
            toggleOpen={toggleOpen}
            onSearch={onSearch}
            onFocusedRowChanged={onFocusedRowChanged}
          />
          <OrderInput
            bottomHeight={280}
            openinputbar={openinputbar}
            company={company}
            billing={billing}
            cargoOwner={cargoOwner}
            orderCo={orderCo}
            forwarder={forwarder}
            isDispatch={dispatch}
            color={color}
            inputForm={inputForm}
            toggleOpen={toggleOpen}
            onClickUnipass={onClickUnipass}
            onClickNewData={onClickNewData}
            onClickConfirmSaveData={onClickConfirmSaveData}
            onClickDeleteData={onClickDeleteData}
          />
          <OrderDetailGrid
            openinputbar={openinputbar}
            selectedRowKey={selectedRowKey}
            gridSub={gridSub}
            conType={conType}
            extraChargeCellButtonRender={extraChargeCellButtonRender}
            extraChargeCellRender={extraChargeCellRender}
            etcChargeHabulCellRender={etcChargeHabulCellRender}
          />
        </>
      </ContentTemplate>
      {extraChargeModal && (
        <ExtraChargeModal
          onClose={onClose}
          extraChargeData={extraChargeData}
          etcChargeHabulData={etcChargeHabulData}
          extraChargeRef={extraChargeRef}
          etcChargeHabulRef={etcChargeHabulRef}
          onExtraChargeSubmit={onExtraChargeSubmit}
        />
      )}
    </>
  );
};

export default MakeOrderPage;
