import styled, { css } from "styled-components";
import { AsideMenuProps } from ".";

export const AsideMenuList = styled.ul<AsideMenuProps>`
  overflow-y: auto;
  display: flex;
  width: 100%;
  height: auto;
  padding-bottom: 16px;
  max-height: 0;
  font-size: 1.4rem;
  font-weight: 400;
  flex-direction: column;
  transition: max-height 0.4s ease;

  // 탭메뉴 스크롤바 설정
  &::-webkit-scrollbar {
    position: absolute;
    width: 6px;
    background: ${(props) => props.theme.colors.white100};
  }

  &::-webkit-scrollbar-thumb {
    border: 1px solid transparent;
    border-radius: 3.5px;
    background-color: ${(props) => props.theme.colors.black12};
    transition: all 0.2s ease;

    &:hover {
      background-color: ${(props) => props.theme.colors.black20};
    }
  }

  ${(props) =>
    props.depthOpen === true &&
    css`
      max-height: 100vh;
    `}

  ${(props) =>
    props.menuDepth === 1 &&
    css`
      overflow-y: hidden;
      padding: 0;
      background: ${(props) => props.theme.colors.white100};
    `}
`;
