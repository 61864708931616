import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  InputColorBox,
  InputSelect,
  InputText,
} from "@/components/atoms/Inputs";
import InputAddress from "@/components/atoms/Inputs/InputAddress";
import { Buttons } from "@/components/atoms/Buttons";
import { FormContent } from "@/components/organisms/Contents/FormContent";
import { ReactComponent as IconCreate } from "@/styles/assets/svg/icon_button_create.svg";
import { ReactComponent as IconSave } from "@/styles/assets/svg/icon_button_save.svg";
import { ReactComponent as IconDelete } from "@/styles/assets/svg/icon_button_delete.svg";

interface TerminalInputProps {
  areaList: any[] | undefined;
  divisionList: any[] | undefined;
  selectedRowKey: string | null;
  inputForm: UseFormReturn<any>;
  toggleOpen: () => void;
  onClickNewData: () => void;
  onClickSaveData: () => Promise<void>;
  onClickDeleteData: () => Promise<void>;
}

const TerminalInput = ({
  areaList,
  divisionList,
  selectedRowKey,
  inputForm,
  toggleOpen,
  onClickNewData,
  onClickSaveData,
  onClickDeleteData,
}: TerminalInputProps) => {
  const { t } = useTranslation("main");
  const {
    control,
    setValue,
    formState: { errors },
  } = inputForm;

  const openPost = () => {
    window.daum.postcode.load(() => {
      const postcode = new window.daum.Postcode({
        oncomplete: function (data: any) {
          let addr = "";
          let extraAddr = "";

          if (data.userSelectedType === "R") {
            addr = data.address;
          } else {
            addr = data.jibunAddress;
          }

          if (data.userSelectedType === "R") {
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            addr += extraAddr;
          }

          setValue("zipCode", data.zonecode);
          setValue("address", addr);
        },
      });
      postcode.open();
    });
  };

  return (
    <FormContent
      title={t("터미널/창고/작업지 관리")}
      titleButtons={
        <Buttons
          type="button"
          size="sm"
          layout="text"
          color="primary100"
          icon={<IconCreate />}
          label="신규입력"
          onClick={onClickNewData}
        />
      }
      inputForms={
        <>
          <InputText
            label="터미널코드"
            name="code"
            direction="column"
            control={control}
            errors={errors}
            required={true}
            disabled={selectedRowKey}
          />

          <InputSelect
            label="구분"
            name="division"
            direction="column"
            displayExpr="subName"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={divisionList || []}
            required={true}
          />

          <InputText
            label="이름"
            name="twName"
            direction="column"
            control={control}
            errors={errors}
            required={true}
          />

          <InputSelect
            label="지역"
            name="area"
            direction="column"
            displayExpr="subName"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={areaList || []}
          />

          {/* 주소 넣기 */}
          <InputAddress
            label="주소"
            direction="column"
            control={control}
            errors={errors}
            openPost={openPost}
            disabled={true}
          />

          <InputText
            name="tel"
            label="대표번호"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            name="fax"
            label="팩스번호"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputColorBox
            label="색상"
            name="color"
            direction="column"
            control={control}
            errors={errors}
          />

          <InputText
            label="비고"
            name="remark"
            direction="column"
            control={control}
            errors={errors}
          />
        </>
      }
      formButtons={
        <>
          <Buttons
            permission="save"
            type="button"
            size="sm"
            layout="solid"
            color="save"
            icon={<IconSave />}
            label="저장"
            onClick={onClickSaveData}
          />
          <Buttons
            permission="delete"
            type="button"
            size="sm"
            layout="solid"
            color="destructive"
            icon={<IconDelete />}
            label="삭제"
            onClick={onClickDeleteData}
          />
        </>
      }
    >
      {/* <Button
          className="input-back"
          variety="tran"
          size="xxsm"
          shape="solid"
          type="button"
          onClick={toggleOpen}
        >
          <MovePageIcon width={26} height={26} viewBox="0 0 24 24" />
          <span>뒤로</span>
        </Button> */}
    </FormContent>
  );
};

export default TerminalInput;
