import { ClosingSearchType } from "@/types/closing.type";
import { userType } from "@/types/user.type";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export const convertToPdf = async () => {
  const pdfForm = document.querySelector("#pdf-form");

  const canvas = await html2canvas(pdfForm as HTMLElement);
  const imageFile = canvas.toDataURL("image/png");
  const doc = new jsPDF("p", "mm", "a4");
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();

  const widthRatio = pageWidth / canvas.width;
  const customHeight = canvas.height * widthRatio;
  doc.addImage(imageFile, "png", 0, 0, pageWidth, customHeight);
  let heightLeft = customHeight;
  let heightAdd = -pageHeight;

  while (heightLeft >= pageHeight) {
    doc.addPage();
    doc.addImage(imageFile, "png", 0, heightAdd, pageWidth, customHeight);
    heightLeft -= pageHeight;
    heightAdd -= pageHeight;
  }
  const blob = doc.output("blob");

  return blob;
};

export const getDefaultMessage = (
  user: userType,
  searchObj: ClosingSearchType
) => {
  return `
  <p>안녕하세요</p>
  <p>${"업체명"} 입니다</p>
  <br />
  <p>${dayjs(searchObj?.firstDay).format("YYYY년 MM월 DD일")}~${dayjs(
    searchObj?.lastDay
  ).format("YYYY년 MM월 DD일")} 명세서 전달드립니다.</p>
  <br />
  <br />
  <p>감사합니다.</p>
  <br />
  <p>Best regards,</p>
  <strong>${user?.englishName || ""}❘${user?.userName || ""} ${
    user?.subName || ""
  }</strong>
  <br />
  <br />
  <br />

  <table style="width: initial;">
    <tbody>
      <tr data-table-row="row-t5rm">
        <td style="width: 380px;" class="ql-table-data-cell" data-table-row="row-t5rm">
          <p class="ql-table-cell-line" data-table-row="row-t5rm" data-table-cell="row-j0yn" data-cellwidth="380px" data-tablewidth="initial">
            <strong style="color: rgb(0, 85, 255); font-size: 16px;">
              ${"업체명"}
            </strong>
          </p>
          <p class="ql-table-cell-line" data-table-row="row-t5rm" data-table-cell="row-j0yn">
            ${"업체주소"}
          </p>
          <p class="ql-table-cell-line" data-table-row="row-t5rm" data-table-cell="row-j0yn">
            ${"업체주소"}
          </p>
          <p class="ql-table-cell-line" data-table-row="row-t5rm" data-table-cell="row-j0yn">
            TEL : ${"업체번호"}&nbsp;/&nbsp;FAX : ${"업체팩스번호"}
          </p>
          <p class="ql-table-cell-line" data-table-row="row-t5rm" data-table-cell="row-j0yn" data-cellwidth="1033px">
            Homepage :${"업체홈페이지"}&nbsp;/&nbsp;email : ${"업체메일"}
          </p>
        </td>
      </tr>
      <tr data-table-row="row-q26w">
        <td style="width: 380px;" class="ql-table-data-cell" data-table-row="row-q26w">
          <p class="ql-table-cell-line" data-table-row="row-q26w" data-table-cell="row-e591" data-cellwidth="380px" data-tablewidth="initial">
            <strong style="font-size: 14px;">${user?.userName || ""}</strong>
          </p>
          <p class="ql-table-cell-line" data-table-row="row-q26w" data-table-cell="row-e591">
            (${user?.englishName || ""})
          </p>
          <p class="ql-table-cell-line" data-table-row="row-q26w" data-table-cell="row-e591">
            HP.${user?.tel || ""}
          </p>
          <p class="ql-table-cell-line" data-table-row="row-q26w" data-table-cell="row-e591" data-cellwidth="1033px">
            ${user?.deptName || ""}팀&nbsp;/&nbsp;${user?.subName || ""}
          </p>
        </td>
      </tr>
    </tbody>
  </table>
  
  `;
};
