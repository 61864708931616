import styled from "styled-components";

interface ContentProps {
  sideWidth?: number;
  bottomHeight?: number;
}

export const ContentTemplate = styled.div<ContentProps>`
  display: grid;
  width: 100%;
  grid-template-columns: ${(props) =>
    props.sideWidth ? "auto " + props.sideWidth + "px" : "100%"};
  grid-template-rows: ${(props) =>
    props.bottomHeight ? "auto " + props.bottomHeight + "px" : "100%"};
`;
