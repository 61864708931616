import { useEffect } from "react";
import { DataGrid } from "devextreme-react";
import * as S from "./Grid.style";

export default function Grid(props: any) {
  const { dataSource, onContentReady } = props;

  useEffect(() => {
    if (onContentReady && dataSource) {
      const count = dataSource?.length;
      onContentReady({
        component: {
          totalCount: () => count ?? 0,
        },
      });
    }
  }, [dataSource, onContentReady]);

  if (props.isError) {
    return <>ERROR</>;
  }

  const onToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.forEach((item: any) => {
      if (item.name === "columnChooserButton") item.visible = true;
    });
  };

  return (
    <S.GridBox>
      {props.title && <S.GridTitle>{props.title}</S.GridTitle>}
      <DataGrid
        id={props.id}
        ref={props.refs}
        keyExpr={props.keyExpr || "code"}
        dataSource={props.dataSource || []}
        className={
          props.subGrid
            ? "sub-grid"
            : props.detailGrid
            ? "detail-grid"
            : "main-grid"
        }
        showRowLines={props.showRowLines || true}
        showBorders={props.showBorders || true}
        hoverStateEnabled={props.hoverStateEnabled || true}
        allowColumnResizing={props.allowColumnResizing || true}
        allowColumnReordering={props.allowColumnReordering || true}
        columnResizingMode="nextColumn"
        columnAutoWidth={true}
        columnMinWidth={40}
        focusedRowEnabled={props.focusedRowEnabled}
        onContentReady={props.onContentReady}
        onInitNewRow={props.onInitNewRow}
        onRowDblClick={props.onRowDblClick}
        onFocusedRowChanged={props.onFocusedRowChanged}
        onToolbarPreparing={props.onToolbarPreparing || onToolbarPreparing}
        onCellClick={props.onCellClick}
        onCellPrepared={props.onCellPrepared}
        onOptionChanged={props.onOptionChanged}
        onEditorPreparing={props.onEditorPreparing}
        onEditingStart={props.onEditingStart}
      >
        {props.children}
      </DataGrid>
    </S.GridBox>
  );
}
