import { useTranslation } from "react-i18next";
import { ProfitDataType } from "@/types/calculateProfit.type";
import { FormContent } from "@/components/organisms/Contents/FormContent";
import { StatusText } from "@/components/atoms/StatusText";

interface CalculateProfitInputProps {
  profitData: ProfitDataType;
  bottomHeight?: number;
  toggleOpen: () => void;
}

const CalculateProfitInput = ({
  bottomHeight,
  profitData,
  toggleOpen,
}: CalculateProfitInputProps) => {
  const { t } = useTranslation("main");
  return (
    <FormContent
      bottomHeight={bottomHeight}
      inputForms={
        <>
          <StatusText
            label={t("매출액")}
            statusValue={`${profitData.profit.toLocaleString(undefined, {
              minimumFractionDigits: 0,
            })} 원`}
          />
          <StatusText
            label={t("매입액")}
            statusValue={`${profitData.purchase.toLocaleString(undefined, {
              minimumFractionDigits: 0,
            })} 원`}
          />
          <StatusText
            label={t("이익금")}
            statusValue={`${profitData.income.toLocaleString(undefined, {
              minimumFractionDigits: 0,
            })} 원`}
          />
          <StatusText
            label={t("기타매출")}
            statusValue={`${profitData.etcProfit.toLocaleString(undefined, {
              minimumFractionDigits: 0,
            })} 원`}
          />
          <StatusText
            label={t("기타매입")}
            statusValue={`${profitData.etcPurchase.toLocaleString(undefined, {
              minimumFractionDigits: 0,
            })} 원`}
          />
          <StatusText
            label={t("순수익")}
            statusValue={`${profitData.netProfit.toLocaleString(undefined, {
              minimumFractionDigits: 0,
            })} 원`}
          />
        </>
      }
    >
      <>
        {/* <Button
          className="input-back"
          variety="tran"
          size="xxsm"
          shape="solid"
          type="button"
          onClick={toggleOpen}
        >
          <MovePageIcon width={26} height={26} viewBox="0 0 24 24" />
          <span>뒤로</span>
        </Button> */}
      </>
    </FormContent>
  );
};

export default CalculateProfitInput;
