import styled, { css } from "styled-components";
import { DetailTabProps } from ".";

export const DetailTabItem = styled.li<DetailTabProps>`
  display: flex;
  height: 28px;
  padding: 0 16px;
  color: ${(props) => props.theme.colors.black50};
  font-size: 1.2rem;
  border-radius: 18px;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.active &&
    css`
      color: ${(props) => props.theme.colors.white100};
      font-weight: 600;
      background: ${(props) => props.theme.colors.black70};
      cursor: default;
    `}
`;
