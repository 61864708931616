import dayjs from "dayjs";
import { userType } from "./user.type";
import * as yup from "yup";

export interface OrderSearchType {
  firstDay: string;
  lastDay: string;
  searchType: number;
  authGroupId: string;
  receiveCo: string;
  dispatchTeam: string;
  containerNo: string;
}

export const InitialOrderSearch = (userInfo: userType): OrderSearchType => {
  return {
    firstDay: dayjs(new Date()).subtract(30, "days").format("YYYY-MM-DD"),
    lastDay: dayjs().format("YYYY-MM-DD"),
    searchType: 3,
    authGroupId: userInfo.authGroup,
    receiveCo: userInfo.companyCode,
    dispatchTeam: userInfo?.deptCode || "",
    containerNo: "",
  };
};

export interface OrderInputType {
  code?: string;
  importExport?: number;
  receiveCo?: string;
  receiveDate?: string;
  dispatchTeam?: string;
  billingPlace: string;
  orderCo?: string;
  forwarder?: string;
  shipName?: string;
  motherShip?: string;
  dem?: string;
  manager?: string;
  enterDay?: string;
  sailDay?: string;
  bkNo?: string;
  doNo?: string;
  blNo?: string;
  blType?: number;
  loadPlace?: string;
  unloadPlace?: string;
  cargoOwner?: string;
  realCargoOwner?: string;
  otherDeliver?: string;
  color?: string;
  fridge?: boolean;
  danger?: boolean;
  tank?: boolean;
  direct?: boolean;
  toxic?: boolean;
  keep?: boolean;
  remark?: string;
  innerMemo?: string;
}

export const OrderInputSchema = yup.object<OrderInputType>({
  code: yup.string().nullable(),
  importExport: yup.string().nullable(),
  receiveCo: yup.string().nullable(),
  receiveDate: yup.string().nullable(),
  dispatchTeam: yup.string().nullable(),
  billingPlace: yup.string().required("청구처를 선택해주세요"),
  orderCo: yup.string().nullable(),
  forwarder: yup.string().nullable(),
  shipName: yup.string().nullable(),
  motherShip: yup.string().nullable(),
  dem: yup.string().nullable(),
  manager: yup.string().nullable(),
  enterDay: yup.string().nullable(),
  sailDay: yup.string().nullable(),
  bkNo: yup.string().nullable(),
  doNo: yup.string().nullable(),
  blNo: yup.string().nullable(),
  blType: yup.string().nullable(),
  loadPlace: yup.string().nullable(),
  unloadPlace: yup.string().nullable(),
  cargoOwner: yup.string().nullable(),
  realCargoOwner: yup.string().nullable(),
  otherDeliver: yup.string().nullable(),
  color: yup.string().nullable(),
  fridge: yup.string().nullable(),
  danger: yup.string().nullable(),
  tank: yup.string().nullable(),
  direct: yup.string().nullable(),
  toxic: yup.string().nullable(),
  keep: yup.string().nullable(),
  remark: yup.string().nullable(),
  innerMemo: yup.string().nullable(),
});

export const InitialOrderInput = (userInfo: userType): OrderInputType => {
  return {
    code: undefined,
    importExport: 1,
    receiveCo: userInfo.companyCode,
    receiveDate: dayjs().format("YYYY-MM-DD"),
    dispatchTeam: userInfo.deptCode,
    billingPlace: "",
    orderCo: undefined,
    forwarder: undefined,
    shipName: undefined,
    motherShip: undefined,
    dem: undefined,
    manager: undefined,
    enterDay: undefined,
    sailDay: undefined,
    bkNo: undefined,
    doNo: undefined,
    blNo: undefined,
    blType: 1,
    loadPlace: undefined,
    unloadPlace: undefined,
    cargoOwner: undefined,
    realCargoOwner: undefined,
    otherDeliver: undefined,
    color: undefined,
    fridge: false,
    danger: false,
    tank: false,
    direct: false,
    toxic: false,
    keep: false,
    remark: undefined,
    innerMemo: undefined,
  };
};

export interface OrderGridType {
  code: string;
  importExport: number;
  importExportKo: string;
  deliverDate: string;
  receiveDate: string;
  forwarder: string;
  shipName: string;
  motherShip: string;
  dem: string;
  fridge: boolean;
  danger: boolean;
  tank: boolean;
  direct: boolean;
  toxic: boolean;
  keep: boolean;
  blNo: string;
  doNo: string;
  bkNo: string;
  blType: number;
  confirm: boolean;
  confirmKo: string;
  remark: string;
  enterDay: string;
  sailDay: string;
  receiveCo: number;
  dispatchTeam: string;
  billingPlace: string;
  cargoOwner: string;
  realCargoOwner: string;
  orderCo: string;
  manager: string;
  color: string;
  containerCount: string;
  cargoOwnerName: string;
  billingPlaceName: string;
  orderCompany: string;
  companyName: string;
  createUsr: string;
  updateUsr: string;
  createDate: string;
  createTime: string;
  deptName: string;
}

export interface UnipassType {
  cntrs: any[];
  vydf: string;
  shipNm: string;
  etprDt: string;
}
