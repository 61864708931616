import { useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  HeaderFilter,
  StateStoring,
} from "devextreme-react/data-grid";
import { DepartmentType } from "@/types/department.type";
import Grid from "@/components/atoms/Grid";
import { InputText } from "@/components/atoms/Inputs";
import { GridContent } from "@/components/organisms/Contents/GridContent";

interface DepartmentGridProps {
  gridMain: any;
  mainData: DepartmentType[] | undefined;
  searchForm: UseFormReturn<any>;

  toggleOpen: () => void;
  onSearch: (data: any) => void;
  onFocusedRowChanged: (e: any) => void;
}

const DepartmentGrid = ({
  gridMain,
  mainData,
  searchForm,
  toggleOpen,
  onSearch,
  onFocusedRowChanged,
}: DepartmentGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "DepartmentGrid";
  const [totalCount, setTotalCount] = useState<number>(0);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = searchForm;

  return (
    <GridContent
      searchSection={
        <InputText
          label="부서명"
          name="deptName"
          control={control}
          errors={errors}
        />
      }
      searchSubmit={handleSubmit(onSearch)}
      gridRef={gridMain}
      storageKey={storageKey}
      totalCount={totalCount}
    >
      <>
        {/* 페이지 타이틀 라인 */}
        {/* <Button
            variety="new"
            shape="solid"
            size="sm"
            type="button"
            onClick={toggleOpen}
          >
            입력
          </Button> */}

        {/* 메인 그리드 라인 */}

        <Grid
          refs={gridMain}
          dataSource={mainData || []}
          keyExpr="oid"
          onFocusedRowChanged={onFocusedRowChanged}
          focusedRowEnabled={true}
          onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
        >
          <HeaderFilter visible={true} />
          <ColumnChooser enabled={true} />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(
              () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
              []
            )}
          />
          <Column
            dataField="code"
            caption={t("부서코드")}
            alignment="center"
            width={90}
          />
          <Column
            dataField="deptName"
            caption={t("부서명")}
            alignment="center"
            width={160}
          />
          <Column
            dataField="sortRef"
            caption={t("정렬순서")}
            alignment="center"
            width={90}
          />
          <Column
            dataField="isDispatch"
            caption={t("배차팀 여부")}
            width={100}
          />
          <Column dataField="remark" caption={t("비고")} alignment="center" />
        </Grid>
      </>
    </GridContent>
  );
};

export default DepartmentGrid;
