import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useComCodeData, useTerminalData } from "@/hooks/queries";
import useButtonLoading from "@/hooks/useButtonLoading";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import { 지역, 터미널_창고_작업지_구분 } from "@/data/constant/queryKeys";
import {
  InitialTerminalInput,
  TerminalInputSchema,
} from "@/types/terminal.type";
import {
  useDeleteMutation,
  usePostMutation,
  usePutMutation,
} from "@/util/common.fn";
import { ContentTemplate } from "@/components/templates/ContentTemplate";
import TerminalGrid from "./TerminalGrid";
import TerminalInput from "./TerminalInput";

const TerminalPage = () => {
  const [search, setSearch] = useState({});
  const [selectedRowKey, setSelectedRowKey] = useState<string | null>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const gridMain = useRef<any>();

  const { alertErrorMessage, alertSuccessMessage, confirmMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { data: mainData, refetch: mainRefetch } = useTerminalData(search);

  const { data: areaList } = useComCodeData(지역);
  const { data: divisionList } = useComCodeData(터미널_창고_작업지_구분);

  const { mutateAsync: create } = usePostMutation("terminal");
  const { mutateAsync: update } = usePutMutation("terminal");
  const { mutateAsync: remove } = useDeleteMutation("terminal");

  const searchForm = useForm();
  const inputForm = useForm({
    defaultValues: InitialTerminalInput,
    resolver: yupResolver(TerminalInputSchema),
  });

  const { reset, watch, trigger } = inputForm;

  const initPage = () => {
    const mainGridInstance = gridMain.current.instance;
    mainGridInstance.option("focusedRowIndex", -1);
    resetGridChanges();
    setSelectedRowKey(null);
    mainRefetch();
    reset(InitialTerminalInput);
  };

  const resetGridChanges = () => {
    const mainGridInstance = gridMain.current.instance;
    mainGridInstance.option("editing.changes", []);
  };

  const toggleOpen = () => setOpen(!isOpen);

  const onClickNewData = () => {
    initPage();
  };
  const onClickSaveData = async () => {
    if (!(await trigger())) return;

    const data = watch();
    const mainGridInstance = gridMain.current.instance;
    const originalKey = selectedRowKey;

    let res: any;

    try {
      setLoading();
      if (selectedRowKey) {
        res = await update({ key: selectedRowKey, data });
      } else {
        res = await create(data);
      }

      if (res.state === 1) {
        initPage();
        await alertSuccessMessage("저장완료");
      }

      mainGridInstance.option("focusedRowKey", originalKey);
    } catch (error: any) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onClickDeleteData = async () => {
    if (!selectedRowKey) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }
    try {
      setLoading();
      if (!(await confirmMessage("삭제하시겠습니까?"))) return;

      const res: any = await remove({ key: selectedRowKey });

      if (res?.state === 1) {
        initPage();
        await alertSuccessMessage("삭제완료");
      }

      initPage();
    } catch (error: any) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onSearch = (data: any) => {
    setSearch(data);
  };

  const onFocusedRowChanged = (e: any) => {
    if (!e.row?.data) return;
    const data = e.row.data;
    setSelectedRowKey(e.row.key);
    reset(data);
  };

  return (
    <ContentTemplate sideWidth={320}>
      <>
        <TerminalGrid
          gridMain={gridMain}
          mainData={mainData}
          searchForm={searchForm}
          toggleOpen={toggleOpen}
          onSearch={onSearch}
          onFocusedRowChanged={onFocusedRowChanged}
        />
        <TerminalInput
          areaList={areaList}
          divisionList={divisionList}
          selectedRowKey={selectedRowKey}
          inputForm={inputForm}
          toggleOpen={toggleOpen}
          onClickNewData={onClickNewData}
          onClickSaveData={onClickSaveData}
          onClickDeleteData={onClickDeleteData}
        />
      </>
    </ContentTemplate>
  );
};

export default TerminalPage;
