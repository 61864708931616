import styled from "styled-components";

export const GridButtonSet = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  gap: 4px;
  z-index: 10;

  .dx-button {
    background: ${(props) => props.theme.colors.gridSub};
    border: none;
    border-radius: 4px;
    transition: background 0.2s ease;

    &:hover {
      background: ${(props) => props.theme.colors.gridSubHover};
    }

    i {
      color: ${(props) => props.theme.colors.white100};
    }
  }
`;
