import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import * as XLSX_ST from "xlsx-js-style";
import customParseFormat from "dayjs/plugin/customParseFormat";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import {
  AccountExcelFileOptions,
  AccountExcelFileOptionsType,
  ExcelType,
  inputType,
} from "@/types/accountExcel.type";
import { usePostMutation } from "@/util/common.fn";
import ShuttleExcelUploadModal from "@/components/molecules/Modal/ShuttleExcelUploadModal";
import { ContentTemplate } from "@/components/templates/ContentTemplate";
import AccountExcelLowerGrid from "./AccountExcelLowerGrid";
import AccountExcelUpperGrid from "./AccountExcelUpperGrid";

const AccountExcel = () => {
  const { t } = useTranslation("main");
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [excelData, setExcelData] = useState<ExcelType[] | null>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [mainGrid, setMainGrid] = useState<any[]>([]);
  const [subGrid, setSubGrid] = useState<any[]>([]);

  const gridMain = useRef<any>();
  const gridSub = useRef<any>();

  const { alertErrorMessage, alertSuccessMessage } = useAlertMessage();

  const { mutateAsync: save } = usePostMutation("accountExcel");

  const processExcelData = (file: File[]) => {
    const reader = new FileReader();

    reader.readAsBinaryString(file[0]);
    reader.onload = (e) => {
      if (!e.target) return;
      const content = e.target.result;
      const wb = XLSX.read(content, { type: "binary" });

      const sheetName = wb.SheetNames[0];

      const objArr: ExcelType[] = XLSX.utils.sheet_to_json(
        wb.Sheets[sheetName],
        {
          raw: false,
          blankrows: false,
          defval: "",
        }
      );

      setExcelData(objArr);
    };
  };

  const onClickExportExcelSample = () => {
    const wb = XLSX_ST.utils.book_new();

    const title: any[] = [];
    AccountExcelFileOptions.forEach((option) => {
      title.push({ v: t(option.title), t: "s", s: {} });
    });

    const inputSheet = XLSX_ST.utils.aoa_to_sheet([title]);

    XLSX_ST.utils.book_append_sheet(wb, inputSheet, `${t("입력 폼")}`);
    XLSX_ST.writeFile(wb, `${t("업로드_샘플")}.xlsx`);
  };

  const onClickFailExport = () => {
    if (subGrid.length === 0) {
      alertErrorMessage("데이터가 없습니다.");
      return;
    }
    const wb = XLSX_ST.utils.book_new();

    const title: any[] = [];
    const body: any[] = [];

    AccountExcelFileOptions.forEach((option) => {
      title.push({ v: t(option.title), t: "s", s: {} });
    });

    subGrid.forEach((json) => {
      const row = [];
      AccountExcelFileOptions.forEach((option) => {
        row.push(json[option.column]);
      });
      row.push(json["message"]);
      body.push(row);
    });

    const inputSheet = XLSX_ST.utils.aoa_to_sheet([title, ...body]);

    XLSX_ST.utils.book_append_sheet(wb, inputSheet, `${t("실패목록")}`);
    XLSX_ST.writeFile(wb, `${t("업로드_실패목록")}.xlsx`);
  };

  const validator = (value: string, option: AccountExcelFileOptionsType) => {
    dayjs.extend(customParseFormat);
    if (option.required && !value) {
      return {
        isError: true,
        message: `(${option.title}) 해당 컬럼은 필수로 입력되어야합니다.\n`,
      };
    }

    switch (option.type) {
      case inputType.STRING:
        return { isError: false, value: value, displayValue: value };
      case inputType.NUMBER:
        const num = value.replace(/,/gi, "");
        if (/^[0-9]+$/.test(num)) {
          return { isError: false, value: num, displayValue: value };
        } else {
          return {
            isError: true,
            message: `(${option.title}) 숫자만 입력해주세요.\n`,
          };
        }
      case inputType.DATE:
        const date = dayjs(value, "YYYY-MM-DD HH:mm:ss", true).isValid();
        if (date) {
          return { isError: false, value: value, displayValue: value };
        } else {
          return {
            isError: true,
            message: `(${option.title}) 날짜 형식에 맞게 입력해주세요(YYYY-MM-DD HH:mm:ss).\n`,
          };
        }
      default:
        return { isError: true, message: `존재하지 않는 타입입니다.` };
    }
  };

  const onSave = async () => {
    if (!excelData) return;
    setIsLoading(true);

    try {
      const successExcelData = [];
      const successExcelGridData = [];
      const failExcelGridData = [];

      const arr = excelData.entries();

      for (const [index, row] of arr) {
        const result = {
          isError: false,
          message: "",
          data: {}, // db에 집어넣은 데이터
          gridData: { code: index + 1, message: "" }, // grid에 표시할 데이터
        };
        AccountExcelFileOptions.forEach((option) => {
          const excelValue = row[option.title];

          const { isError, message, value, displayValue } = validator(
            excelValue,
            option
          );

          if (isError) {
            // 에러일 경우 result의 isError true로 변경
            // 메세지 추가
            result.isError = true;
            result.message = message || "";
            result.gridData = {
              ...result.gridData,
              message: message || "",
            };
          }

          result.data = {
            ...result.data,
            [option.column]: value,
          };

          result.gridData = {
            ...result.gridData,
            [option.column]: displayValue,
          };
        });

        // result의 isError에 따라 나눠서 성공, 실패 데이터 표시
        if (!result.isError) {
          successExcelData.push(result.data);
          successExcelGridData.push(result.gridData);
        } else {
          failExcelGridData.push(result.gridData);
        }
      }

      setMainGrid(successExcelGridData);
      setSubGrid(failExcelGridData);

      // successExcelData 데이터 전송
      const res: any = await save({ data: successExcelData });

      if (res?.state === 1) {
        await alertSuccessMessage("저장완료");

        setIsModalOpen(false);
        setExcelData(null);
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ContentTemplate bottomHeight={400}>
        <>
          <AccountExcelUpperGrid
            bottomHeight={400}
            gridMain={gridMain}
            mainGrid={mainGrid}
            isLoading={isLoading}
            uploadClick={() => setIsModalOpen(true)}
            sampleClick={onClickExportExcelSample}
          />
          <AccountExcelLowerGrid
            gridSub={gridSub}
            subGrid={subGrid}
            isLoading={isLoading}
            onClickFailExport={onClickFailExport}
          />
        </>
      </ContentTemplate>
      {isModalOpen && (
        <ShuttleExcelUploadModal
          onClose={() => {
            setIsModalOpen(false);
            setExcelData(null);
          }}
          isLoading={isLoading}
          processExcelData={processExcelData}
          onSave={onSave}
        />
      )}
    </>
  );
};

export default AccountExcel;
