import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputCheckBox, InputText } from "@/components/atoms/Inputs";
import InputNumber from "@/components/atoms/Inputs/InputNumber";
import InputTextArea from "@/components/atoms/Inputs/InputTextArea";
import { Buttons } from "@/components/atoms/Buttons";
import { FormContent } from "@/components/organisms/Contents/FormContent";
import { ReactComponent as IconCreate } from "@/styles/assets/svg/icon_button_create.svg";
import { ReactComponent as IconSave } from "@/styles/assets/svg/icon_button_save.svg";
import { ReactComponent as IconDelete } from "@/styles/assets/svg/icon_button_delete.svg";

interface DepartmentInputProps {
  selectedRowKey: string | null;
  inputForm: UseFormReturn<any>;
  toggleOpen: () => void;
  onClickNewData: () => void;
  onClickSaveData: () => Promise<void>;
  onClickDeleteData: () => Promise<void>;
}

const DepartmentInput = ({
  selectedRowKey,
  inputForm,
  toggleOpen,
  onClickNewData,
  onClickSaveData,
  onClickDeleteData,
}: DepartmentInputProps) => {
  const { t } = useTranslation("main");
  const {
    control,
    formState: { errors },
  } = inputForm;

  return (
    <FormContent
      title={t("부서 등록")}
      titleButtons={
        <Buttons
          type="button"
          size="sm"
          layout="text"
          color="primary100"
          icon={<IconCreate />}
          label="신규입력"
          onClick={onClickNewData}
        />
      }
      inputForms={
        <>
          <InputText
            label="부서코드"
            name="code"
            direction="column"
            control={control}
            errors={errors}
            disabled={selectedRowKey}
            required={true}
          />
          <InputText
            label="부서명"
            name="deptName"
            direction="column"
            control={control}
            errors={errors}
            required={true}
          />
          <InputNumber
            label="정렬순서"
            name="sortRef"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputCheckBox
            label="배차팀"
            name="isDispatch"
            control={control}
            errors={errors}
          />

          <InputTextArea
            label="비고"
            name="remark"
            direction="column"
            control={control}
            errors={errors}
          />
        </>
      }
      formButtons={
        <>
          <Buttons
            permission="save"
            type="button"
            size="sm"
            layout="solid"
            color="save"
            icon={<IconSave />}
            label="저장"
            onClick={onClickSaveData}
          />
          <Buttons
            permission="delete"
            type="button"
            size="sm"
            layout="solid"
            color="destructive"
            icon={<IconDelete />}
            label="삭제"
            onClick={onClickDeleteData}
          />
        </>
      }
    >
      <>
        {/* 페이지 헤더 라인 */}
        {/* <Buttons
          size="xxsm"
          layout="solid"
          color="primary100"
          type="button"
          onClick={toggleOpen}
        >
          <MovePageIcon width={26} height={26} viewBox="0 0 24 24" />
          <span>{t("뒤로")}</span>
        </Buttons> */}
      </>
    </FormContent>
  );
};

export default DepartmentInput;
