import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Column,
  Editing,
  Lookup,
  RequiredRule,
  StateStoring,
} from "devextreme-react/data-grid";
import useButtonLoading from "@/hooks/useButtonLoading";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import { usePostMutation } from "@/util/common.fn";
import { InputText } from "@/components/atoms/Inputs";
import Tree from "@/components/atoms/Tree";
import { GridContent } from "@/components/organisms/Contents/GridContent";
import { ContentTemplate } from "@/components/templates/ContentTemplate";
import { ReactComponent as IconSave } from "@/styles/assets/svg/icon_button_save.svg";
import { Buttons } from "@/components/atoms/Buttons";

const MenuPage = () => {
  const { t } = useTranslation("main");
  const storageKey = "MenuPage";
  const [totalCount, setTotalCount] = useState<number>(0);
  const [search, setSearch] = useState({});
  const gridMain = useRef<any>();

  const { alertErrorMessage, alertSuccessMessage, confirmMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { data: mainData, refetch: mainRefetch } = useCustomQuery(
    `menu`,
    search
  );

  const { mutateAsync: create } = usePostMutation("menu");

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({});

  const initPage = () => {
    const mainGridInstance = gridMain.current.instance;
    mainGridInstance.option("focusedRowIndex", -1);
    mainGridInstance.option("editing.changes", []);
    mainRefetch();
    reset({
      menuName: "",
    });
  };

  const onSearch = (data: any) => {
    setSearch(data);
  };

  const onSave = async () => {
    if (!(await confirmMessage("저장하시겠습니까?"))) return;
    try {
      setLoading();
      const mainGridInstance = gridMain.current.instance;
      const detailIsValid = await mainGridInstance
        .getController("validating")
        .validate(true);
      if (!detailIsValid) return;

      const row = mainGridInstance.option("editing.changes");

      const res: any = await create({
        data: row,
      });

      if (res.state === 1) {
        await alertSuccessMessage("저장완료");
      }

      initPage();
    } catch (error) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onToolbarPreparing = (e: any) => {
    var toolbarItems = e.toolbarOptions.items;

    toolbarItems.forEach((cur: any) => {
      if (cur.name === "saveButton" || cur.name === "revertButton") {
        cur.visible = false;
      }
    });
  };

  return (
    <ContentTemplate>
      <GridContent
        searchSection={
          <InputText
            label="메뉴명"
            name="menuName"
            width={120}
            control={control}
            errors={errors}
          />
        }
        searchSubmit={handleSubmit(onSearch)}
        gridRef={gridMain}
        storageKey={storageKey}
        totalCount={totalCount}
        gridSectionButtons={
          <Buttons
            permission="save"
            type="button"
            size="sm"
            layout="solid"
            color="save"
            onClick={onSave}
            label="저장"
            icon={<IconSave />}
          />
        }
      >
        <>
          <Tree
            keys="id"
            keyExpr="id"
            parentIdExpr="parentId"
            dataSource={mainData}
            refs={gridMain}
            onToolbarPreparing={onToolbarPreparing}
            onInitNewRow={(e: any) => {
              e.data.menuType = 2;
              e.data.isUse = true;
              e.data.isRel = true;
            }}
            onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
          >
            <StateStoring
              enabled={true}
              type="custom"
              customLoad={useCallback(
                () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
                []
              )}
            />
            <Editing
              mode="batch"
              allowAdding={true}
              allowUpdating={true}
              allowDeleting={true}
            />
            <Column
              dataField="menuName"
              caption={t("메뉴명")}
              width={140}
              // headerCellRender={headerCellRender}
            >
              <RequiredRule message="메뉴명을 입력해주세요."></RequiredRule>
            </Column>
            <Column
              dataField="menuType"
              caption={t("구분")}
              alignment="center"
              width={60}
            >
              <Lookup
                dataSource={[
                  { value: 1, name: "폴더" },
                  { value: 2, name: "파일" },
                ]}
                valueExpr="value"
                displayExpr="name"
              />
            </Column>
            <Column
              dataField="sortSeq"
              caption={t("순서")}
              alignment="center"
              width={60}
            >
              <RequiredRule message="순서를 입력해주세요."></RequiredRule>
            </Column>

            <Column dataField="isUse" caption={t("사용")} width={60}>
              {/* <RequiredRule message="사용 여부를 체크해주세요."></RequiredRule> */}
            </Column>
            <Column dataField="isRel" caption={t("배포")} width={60}>
              {/* <RequiredRule message="배포여부를 체크해주세요."></RequiredRule> */}
            </Column>
            <Column dataField="path" caption={t("경로")}>
              <RequiredRule message="경로를 입력해주세요."></RequiredRule>
            </Column>
            <Column caption={t("종류")} alignment="center" width={100} />
            <Column
              dataField="class"
              caption={t("클래스")}
              alignment="center"
              width={100}
            />
          </Tree>
        </>
      </GridContent>
    </ContentTemplate>
  );
};

export default MenuPage;
