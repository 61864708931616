import styled, { css } from "styled-components";
import { InputMultipleProps } from ".";

export const InputMultipleBox = styled.div<InputMultipleProps>`
  display: flex;
  align-items: ${(props) =>
    props.direction === "row" ? "center" : "flex-start"};
  flex-direction: ${(props) => props.direction};
  gap: ${(props) => (props.direction === "row" ? "10px" : "3px")};

  ${(props) =>
    props.span &&
    css`
      grid-column: ${props.span};

      ${InputSetBox} {
        align-items: end;
      }
    `}
`;

export const InputMultipleLabel = styled.div<InputMultipleProps>`
  width: auto;
  color: ${(props) => props.theme.colors.inputLabel};
  font-size: 1.3rem;
  line-height: 1.3rem;
  text-align: ${(props) => (props.direction === "row" ? "right" : "left")};
  white-space: nowrap;
  flex-shrink: 0;

  span {
    color: ${(props) => props.theme.colors.destructive};
    font-size: 1.4rem;
    font-weight: 600;
  }
`;

export const InputSetBox = styled.div<InputMultipleProps>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px 4px;
`;
