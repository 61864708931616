import * as yup from "yup";

export interface ForwarderType {
  code: string;
  forwarderNameEn: string;
  forwarderNameKo: string;
  remark?: string;
}

export const ForwarderInputSchema = yup.object<ForwarderType>({
  code: yup.string().required("코드를 입력해주세요"),
  forwarderNameEn: yup.string().required("선사명을 입력해주세요"),
  forwarderNameKo: yup.string().required("선사명을 입력해주세요"),
  remark: yup.string().nullable(),
});

export const InitialForwarderInput: ForwarderType = {
  code: "",
  forwarderNameEn: "",
  forwarderNameKo: "",
  remark: undefined,
};
