import { alert, confirm } from "devextreme/ui/dialog";
import { useMemo } from "react";

const useAlertMessage = () => {
  return useMemo(() => {
    const alertSuccessMessage = async (error: string) => {
      await alert(error, "성공");
    };

    const alertErrorMessage = async (error: any) => {
      if (typeof error === "string") await alert(error, "오류");
      if (typeof error === "object") await alert(error.message, "오류");
    };

    const confirmMessage = async (message: string) => {
      return await confirm(message, "확인");
    };
    return { alertSuccessMessage, alertErrorMessage, confirmMessage };
  }, []);
};

export default useAlertMessage;
