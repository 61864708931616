import styled from "styled-components";

export const ModalMailingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  label {
    min-width: 60px;
    color: ${(props) => props.theme.colors.inputLabel};
    font-size: 1.3rem;
    text-align: left;
  }
`;

export const ModalMailingAttach = styled.div`
  display: grid;
  margin-bottom: 10px;
  grid-template-columns: 65px calc(100% - 70px);
  align-items: center;
  grid-gap: 5px;

  div {
    display: flex;
    padding: 6px 5px;
    font-size: 1.2rem;
    font-weight: bold;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: ${(props) => props.theme.colors.white100};
    align-items: center;
  }
`;
