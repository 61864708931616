import styled, { css } from "styled-components";

type AsideProps = {
  asideOpen?: boolean;
};

export const AsideBox = styled.section<AsideProps>`
  grid-area: MN;
  display: flex;
  width: 100%;
  background: ${(props) => props.theme.colors.primary100};
  border-radius: 30px 0 0 0;
  flex-direction: column;
  align-items: center;
  transition: all 0.3s ease;

  ${(props) =>
    props.asideOpen === false &&
    css`
      border-radius: 0;
    `}
`;

// 사이드메뉴 Header 레이아웃
export const Header = styled.header<AsideProps>`
  display: flex;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;

  ${(props) =>
    props.asideOpen === false &&
    css`
      padding: 0;
      justify-content: center;
    `}
`;

// 사이드메뉴 헤더 txt
export const TitleHeader = styled.h1<AsideProps>`
  display: flex;
  margin: 0 !important;
  color: ${(props) => props.theme.colors.white100};
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: right;
  text-wrap: nowrap;
  align-items: center;
  gap: 8px;

  ${(props) =>
    props.asideOpen === false &&
    css`
      display: none;
    `}
`;
