import normalize from "styled-normalize";
import { createGlobalStyle, keyframes } from "styled-components";

export const Loader = keyframes`
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(1turn);
  }
`;

export const GlobalStyle = createGlobalStyle`
${normalize}

html,
body {
  padding: 0;
  margin: 0;
  font-size: 10px;
}

html {
line-height: 1.3;
}

body {
overflow: overlay;}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Noto Sans KR", "sans-serif";
  letter-spacing: -0.01rem;
}

a {
  color: inherit;
  text-decoration: none;
}

li {
  list-style: none;
}

/* 전체 페이지 로딩 설정 */
.page-load-wrap {
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  z-index: 999;
}

.page-load-wrap .loader {
  display: flex;
  height: 30px;
  width: 30px;
}

.page-load-wrap .loader .loader-1 {
  border-radius: 50%;
  border-top: 3px solid rgba(255, 255, 255, 0.5);
  border-right: 3px solid rgba(255, 255, 255, 0.5);
  border-bottom: 3px solid rgba(255, 255, 255, 0.5);
  border-left: 3px solid rgba(255, 255, 255, 1);
  animation: ${Loader} 1s cubic-bezier(0.41, 0.25, 0.32, 0.83) infinite;
}

.page-load-wrap .loading-txt {
  margin-top: 10px;
  color: #fff;
  font-size: 1.6rem;
}

`;
