import { useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import Grid from "@/components/atoms/Grid";
import { InputText } from "@/components/atoms/Inputs";
import { GridContent } from "@/components/organisms/Contents/GridContent";

interface ForwarderGridProps {
  gridMain: any;
  mainData: any[] | undefined;
  searchForm: UseFormReturn<any>;

  toggleOpen: () => void;
  onSearch: (data: any) => void;
  onFocusedRowChanged: (e: any) => void;
}

const ForwarderGrid = ({
  gridMain,
  mainData,
  searchForm,
  toggleOpen,
  onSearch,
  onFocusedRowChanged,
}: ForwarderGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "ForwarderGrid";
  const [totalCount, setTotalCount] = useState<number>(0);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = searchForm;
  return (
    <GridContent
      searchSection={
        <InputText
          label="선사명(한국어)"
          name="forwarderNameKo"
          width={140}
          control={control}
          errors={errors}
        />
      }
      searchSubmit={handleSubmit(onSearch)}
      gridRef={gridMain}
      storageKey={storageKey}
      totalCount={totalCount}
    >
      {/* <Button
            variety="new"
            shape="solid"
            size="sm"
            type="button"
            onClick={toggleOpen}
          >
            입력
          </Button> */}

      <Grid
        refs={gridMain}
        dataSource={mainData || []}
        keyExpr="code"
        onFocusedRowChanged={onFocusedRowChanged}
        focusedRowEnabled={true}
        onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
      >
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} />
        <StateStoring
          enabled={true}
          type="custom"
          customLoad={useCallback(
            () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
            []
          )}
        />
        <Scrolling mode="virtual" />
        <Column
          dataField="code"
          caption={t("코드")}
          alignment="center"
          width={80}
        />
        <Column
          dataField="forwarderNameEn"
          caption={t("선사명(영어)")}
          width={160}
        />
        <Column
          dataField="forwarderNameKo"
          caption={t("선사명(한국어)")}
          width={160}
        />
        <Column dataField="remark" caption={t("비고")} />
      </Grid>
    </GridContent>
  );
};

export default ForwarderGrid;
