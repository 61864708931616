import { useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import Grid from "@/components/atoms/Grid";
import { InputDate, InputSelect, InputText } from "@/components/atoms/Inputs";
import { InputMultiple } from "../../../components/atoms/InputMultiple";
import { GridContent } from "@/components/organisms/Contents/GridContent";

interface OrderGridProps {
  bottomHeight?: number;
  dispatch: any[];
  company: any[];
  gridMain: any;
  mainData: any[] | undefined;
  searchForm: UseFormReturn<any>;
  toggleOpen: () => void;
  onSearch: (data: any) => void;
  onFocusedRowChanged: (e: any) => void;
}

const OrderGrid = ({
  bottomHeight,
  dispatch,
  company,
  gridMain,
  mainData,
  searchForm,
  toggleOpen,
  onSearch,
  onFocusedRowChanged,
}: OrderGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "OrderGrid";
  const [totalCount, setTotalCount] = useState<number>(0);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = searchForm;

  return (
    <GridContent
      bottomHeight={bottomHeight && bottomHeight}
      searchSection={
        <>
          <InputMultiple direction="row" label="검색기간">
            <>
              <InputSelect
                name="searchType"
                width={120}
                displayExpr="name"
                valueExpr="id"
                control={control}
                errors={errors}
                dataSource={[
                  { id: 1, name: "접수일자" },
                  { id: 2, name: "선사반출기한" },
                  { id: 3, name: "운송일자" },
                ]}
              />
              <InputDate
                name="firstDay"
                width={130}
                control={control}
                errors={errors}
              />
              ~
              <InputDate
                name="lastDay"
                width={130}
                control={control}
                errors={errors}
              />
            </>
          </InputMultiple>
          <InputSelect
            label="접수법인"
            name="receiveCo"
            width={140}
            displayExpr="companyName"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={[{ id: "", companyName: t("전체") }, ...company]}
          />
          <InputSelect
            label="배차팀"
            name="dispatchTeam"
            width={140}
            displayExpr="deptName"
            valueExpr="code"
            control={control}
            errors={errors}
            dataSource={[{ code: "", deptName: t("전체") }, ...dispatch]}
          />
          <InputText
            label="컨테이너번호"
            name="containerNo"
            width={140}
            control={control}
            errors={errors}
          />
        </>
      }
      searchSubmit={handleSubmit(onSearch)}
      totalCount={totalCount}
      gridRef={gridMain}
      storageKey={storageKey}
    >
      {/* <Buttons
          type="button"
          size="sm"
          layout="solid"
          color="primary100"
          label="입력"
          onClick={toggleOpen}
        /> */}

      <Grid
        className="has-custom"
        refs={gridMain}
        dataSource={mainData}
        keyExpr="code"
        onFocusedRowChanged={onFocusedRowChanged}
        focusedRowEnabled={true}
        onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
      >
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} />
        <StateStoring
          enabled={true}
          type="custom"
          customLoad={useCallback(
            () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
            []
          )}
        />
        <Scrolling mode="virtual" />
        <Column
          caption="No"
          cellRender={(e) => e.row.loadIndex + 1}
          alignment="center"
          width={35}
        />
        <Column dataField="code" caption={t("접수번호")} width={140} />
        <Column
          dataField="containerCount"
          caption={t("컨 갯수")}
          alignment="center"
          width={80}
        />
        <Column
          dataField="importExportKo"
          caption={t("수입/수출")}
          // cellRender={importExportRender}
          alignment="center"
          width={90}
        />
        <Column
          dataField="deliverDate"
          caption={t("운송일자")}
          dataType="date"
          format="yyyy-MM-dd"
          alignment="center"
          width={110}
        />
        <Column
          dataField="receiveDate"
          caption={t("접수일자")}
          dataType="date"
          format="yyyy-MM-dd"
          alignment="center"
          width={110}
        />
        <Column dataField="companyName" caption={t("접수법인")} width={110} />
        <Column dataField="orderCompany" caption={t("발주처")} width={160} />
        <Column
          dataField="billingPlaceName"
          caption={t("청구처")}
          width={160}
        />
        <Column
          dataField="forwarder"
          caption={t("선사")}
          alignment="center"
          width={80}
        />
        <Column dataField="shipName" caption={t("선명")} width={140} />
        <Column
          dataField="motherShip"
          caption={t("모선/항차")}
          alignment="center"
          width={90}
        />
        <Column
          dataField="dem"
          caption={t("선사반출기한")}
          dataType="date"
          format="yyyy-MM-dd"
          alignment="center"
          width={130}
        />
        <Column
          dataField="deptName"
          caption={t("배차팀")}
          alignment="center"
          width={120}
        />
        <Column
          dataField="manager"
          caption={t("청구처담당자")}
          alignment="center"
          width={110}
        />
        <Column
          dataField="fridge"
          caption={t("냉동")}
          dataType="boolean"
          width={70}
        />
        <Column
          dataField="danger"
          caption={t("위험물")}
          dataType="boolean"
          width={80}
        />
        <Column
          dataField="tank"
          caption={t("탱크")}
          dataType="boolean"
          width={70}
        />
        <Column
          dataField="direct"
          caption={t("직반입")}
          dataType="boolean"
          width={80}
        />
        <Column dataField="blNo" caption={t("B/L No")} width={140} />
        <Column dataField="doNo" caption={t("DO No")} width={100} />
        <Column dataField="bkNo" caption={t("B/K No")} width={140} />
        <Column dataField="cargoOwnerName" caption={t("화주")} width={140} />
        <Column dataField="realCargoOwner" caption={t("실화주")} width={100} />
        <Column
          dataField="createUsr"
          caption={t("최초입력자")}
          alignment="center"
          width={100}
        />
        <Column
          dataField="updateUsr"
          caption={t("최종수정자")}
          alignment="center"
          width={100}
        />
        <Column
          dataField="createDate"
          caption={t("작성일")}
          alignment="center"
          width={100}
        />
        <Column
          dataField="createTime"
          caption={t("작성시간")}
          alignment="center"
          width={90}
        />
      </Grid>
    </GridContent>
  );
};

export default OrderGrid;
