import { CheckBox } from "devextreme-react";
import { Controller } from "react-hook-form";
import { Input, InputProps } from "..";

const InputCheckBox = ({
  control,
  errors,
  name,
  label,
  text,
  required,
  disabled,
  direction,
  onValueChanged = () => {},
}: InputProps) => {
  return (
    <Input
      type="checkbox"
      direction={direction ? direction : `row`}
      label={label && label}
      required={required}
      errors={errors && errors}
    >
      <Controller
        render={({ field: { onChange, value } }) => (
          <CheckBox
            onValueChanged={(value) => {
              onChange(value.value);
              onValueChanged(value);
            }}
            value={value}
            disabled={disabled}
            text={text}
          ></CheckBox>
        )}
        control={control}
        name={name}
        defaultValue=""
      />
    </Input>
  );
};

export default InputCheckBox;
