import { useEffect, useState } from "react";
import Aside from "@/components/organisms/Aside";
import TopBar from "@/components/organisms/TopBar";
import TabList from "@/components/organisms/TabList";
import * as S from "./MainTemplate.style";

interface MainProps {
  children?: React.ReactElement;
}

export const MainTemplate = ({ children }: MainProps) => {
  const [asideOpen, setAsideOpen] = useState(true);

  const handleAsideOpen = () => {
    setAsideOpen(!asideOpen);
  };

  useEffect(() => {
    if (window.innerWidth <= 600) {
      setAsideOpen(false);
    } else {
      setAsideOpen(true);
    }
  }, []);

  return (
    <S.MainTemplate asideOpen={asideOpen}>
      <TopBar />
      <Aside handleAsideOpen={handleAsideOpen} asideOpen={asideOpen} />

      <TabList />
      <S.MainContentSection>
        <S.MainContentBox>{children}</S.MainContentBox>
      </S.MainContentSection>
    </S.MainTemplate>
  );
};
