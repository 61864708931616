import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { useCompanyData, useDispatchData } from "@/hooks/queries";
import useButtonLoading from "@/hooks/useButtonLoading";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import { userInfo } from "@/state/atoms/userInfo";
import {
  DispatchInputSchema,
  InitialDispatchInput,
  InitialDispatchSearch,
} from "@/types/dispatch.typs";
import { usePostMutation } from "@/util/common.fn";
import { ContentTemplate } from "@/components/templates/ContentTemplate";
import DispatchGrid from "./DispatchGrid";
import DispatchInput from "./DispatchInput";

const DispatchPage = () => {
  const userData = useRecoilValue(userInfo);
  // input펼치기 여부
  const [openinputbar, setopeninputbar] = useState<boolean>(true);

  const [search, setSearch] = useState(InitialDispatchSearch(userData));
  const [selectedRowKey, setSelectedRowKey] = useState<string | null>(null);
  const gridMain = useRef<any>();

  const { alertErrorMessage, alertSuccessMessage, confirmMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  // 데이터 FETCH
  const { data: mainData, refetch: mainRefetch } = useCustomQuery<any[]>(
    `dispatchNew`,
    search
  );

  const { data: dispatch } = useDispatchData();
  const { data: company } = useCompanyData();

  const { mutateAsync: save } = usePostMutation(`dispatchNew`);

  const searchForm = useForm<any>({
    defaultValues: InitialDispatchSearch(userData),
  });
  const inputForm = useForm<any>({
    // defaultValues: InitialOrderInput(userData),
    resolver: yupResolver(DispatchInputSchema),
  });

  const { watch, trigger, reset } = inputForm;

  // useEffect(() => {}, []);

  const initPage = () => {
    const mainGridInstance = gridMain.current.instance;
    mainGridInstance.option("focusedRowIndex", -1);
    setSelectedRowKey(null);
    mainRefetch();
    reset(InitialDispatchInput);
  };

  const toggleOpen = () => setopeninputbar(!openinputbar);

  const onSearch = (data: any) => {
    initPage();
    setSearch(data);
    mainRefetch();
  };

  const onClickSaveData = async () => {
    if (!(await trigger())) return;

    if (!(await confirmMessage("저장하시겠습니까?"))) return;
    const data = watch();
    const mainGridInstance = gridMain.current.instance;

    const originalKey = selectedRowKey;

    try {
      setLoading();
      const res: any = await save({
        masterDetail: { ...data },
        dispatches: data.dispatches,
      });

      if (res.state === 1) {
        initPage();
        await alertSuccessMessage("저장완료");
      }

      mainGridInstance.option("focusedRowKey", originalKey);
    } catch (error) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onFocusedRowChanged = (e: any) => {
    if (!e.row?.data) return;
    setSelectedRowKey(e.row.key);
  };

  return (
    <>
      <ContentTemplate sideWidth={280}>
        <>
          <DispatchGrid
            dispatch={dispatch}
            company={company}
            gridMain={gridMain}
            mainData={mainData}
            searchForm={searchForm}
            toggleOpen={toggleOpen}
            onSearch={onSearch}
            onFocusedRowChanged={onFocusedRowChanged}
          />
          <DispatchInput
            openinputbar={openinputbar}
            selectedRowKey={selectedRowKey}
            inputForm={inputForm}
            toggleOpen={toggleOpen}
            onClickSaveData={onClickSaveData}
          />
        </>
      </ContentTemplate>
    </>
  );
};

export default DispatchPage;
