import styled from "styled-components";

export const AuthFormBox = styled.div`
  display: flex;
  width: 400px;
  padding: 100px 60px;
  background: ${(props) => props.theme.colors.white100};
  border-radius: 10px;
  box-shadow: ${(props) => props.theme.shadows.field};
  flex-direction: column;
  justify-content: center;

  .login-init {
    padding: 120px 90px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.1);
    background: ${(props) => props.theme.colors.white};
  }

  // 로그인 입력 섹션 모바일환경(600px 이하) 설정
  @media (max-width: 600px) {
    width: 100%;
    height: 60%;
    right: unset;
    justify-content: flex-start;

    .login-init {
      padding: 0 40px;
      background: none;
      box-shadow: none;
    }
  }
`;

export const AuthTitle = styled.h2`
  color: ${(props) => props.theme.colors.inputLabel};
  font-size: 4rem;
  font-weight: 500;

  // 로그인 타이틀 모바일환경(600px 이하) 설정
  @media (max-width: 600px) {
    color: ${(props) => props.theme.colors.inputLabel};
  }
`;

export const AuthSubhead = styled.p`
  margin-top: 10px;
  color: ${(props) => props.theme.colors.loginSubhead};
  font-size: 1.5rem;
  font-weight: 400;
  letter-spacing: -0.07rem;

  // 로그인 타이틀 모바일환경(600px 이하) 설정
  @media (max-width: 600px) {
    margin-top: 5px;
    font-size: 1.6rem;
  }
`;

export const AuthForm = styled.form`
  display: flex;
  margin-top: 30px;
  flex-direction: column;
  gap: 40px;
`;

export const AuthFormInput = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  label {
    display: flex;
    flex-direction: column;

    span {
      display: inline-block;
      margin-bottom: 5px;
      color: ${(props) => props.theme.colors.inputLabel};
      font-size: 1.3rem;
    }

    input {
      height: 50px;
      padding: 0 16px;
      font-size: 1.6rem;
      border: 1px solid ${(props) => props.theme.colors.inputBorder};
      border-radius: 4px;

      &:focus {
        border: 1px solid ${(props) => props.theme.colors.inputFocus};
      }

      &::placeholder {
        font-size: 1.4rem;
        color: ${(props) => props.theme.colors.inputPlaceholder};
      }
    }
  }

  .error-msg-mobile {
    display: none;
  }

  .error-msg {
    display: flex;
    color: ${(props) => props.theme.colors.warning};
    font-size: 1.3rem;

    span {
      display: flex;
      width: 18px;
      height: 18px;
      margin-right: 5px;
      font-size: 1.1rem;
      font-weight: bold;
      border: 2px solid ${(props) => props.theme.colors.warning};
      border-radius: 20px;
      align-items: center;
      justify-content: center;
    }
  }

  // 로그인 입력창 모바일환경(600px 이하) 설정
  @media (max-width: 600px) {
    margin-top: 40px;
    gap: 12px;

    label {
      width: 100%;
      flex-direction: row;
      align-items: center;

      span {
        display: none;
      }

      input {
        width: 100%;
        height: 55px;
        padding-right: 0;
        border-radius: 0;
      }
    }

    .error-msg-mobile {
      display: flex;
      color: ${(props) => props.theme.colors.warning};
      font-size: 1.3rem;

      span {
        display: flex;
        width: 18px;
        height: 18px;
        margin-right: 5px;
        font-size: 1.1rem;
        font-weight: bold;
        border: 2px solid ${(props) => props.theme.colors.warning};
        border-radius: 20px;
        align-items: center;
        justify-content: center;
      }
    }

    .error-msg {
      display: none;
    }
  }
`;
