import { useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  Format,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import Grid from "@/components/atoms/Grid";
import { InputSelect, InputText } from "@/components/atoms/Inputs";
import { GridContent } from "@/components/organisms/Contents/GridContent";

interface ShuttleBasicGridProps {
  gridMain: any;
  mainData: any[] | undefined;
  searchForm: UseFormReturn<any>;

  toggleOpen: () => void;
  onSearch: (data: any) => void;
  onFocusedRowChanged: (e: any) => void;
}

const ShuttleBasicGrid = ({
  gridMain,
  mainData,
  searchForm,
  toggleOpen,
  onSearch,
  onFocusedRowChanged,
}: ShuttleBasicGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "ShuttleBasicGrid";
  const [totalCount, setTotalCount] = useState<number>(0);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = searchForm;

  const importExportCellRender = useCallback((e: any) => {
    if (e.value === 1) return t("수입");
    if (e.value === 2) return t("수출");
    return;
    // eslint-disable-next-line
  }, []);
  return (
    <GridContent
      searchSection={
        <>
          <InputSelect
            label="수입/수출"
            name="importExport"
            width={80}
            valueExpr="id"
            displayExpr="name"
            control={control}
            errors={errors}
            dataSource={[
              { id: 0, name: t("전체") },
              { id: 1, name: t("수입") },
              { id: 2, name: t("수출") },
            ]}
          />
          <InputText
            label="컨테이너번호"
            name="containerNo"
            width={120}
            control={control}
            errors={errors}
          />
        </>
      }
      searchSubmit={handleSubmit(onSearch)}
      gridRef={gridMain}
      storageKey={storageKey}
      totalCount={totalCount}
    >
      {/* <Button
            variety="new"
            shape="solid"
            size="sm"
            type="button"
            onClick={toggleOpen}
          >
            입력
          </Button> */}

      {/* 메인 그리드 라인 */}
      <Grid
        className="has-custom"
        refs={gridMain}
        dataSource={mainData || []}
        keyExpr="oid"
        onFocusedRowChanged={onFocusedRowChanged}
        focusedRowEnabled={true}
        onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
      >
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} />
        <StateStoring
          enabled={true}
          type="custom"
          customLoad={useCallback(
            () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
            []
          )}
        />
        <Scrolling mode="virtual" />
        <Column
          caption="No"
          cellRender={(e) => e.row.loadIndex + 1}
          alignment="center"
        />
        <Column
          dataField="importExport"
          caption={t("수입/수출")}
          cellRender={importExportCellRender}
          alignment="center"
          width={90}
        />
        <Column
          dataField="cargoOwnerName"
          caption={t("화주")}
          alignment="center"
        />
        <Column
          dataField="billingPlaceName"
          caption={t("청구처")}
          alignment="center"
        />
        <Column
          dataField="containerType"
          caption={t("컨규격")}
          alignment="center"
          width={80}
        />
        <Column
          dataField="containerNo"
          caption={t("컨테이너번호")}
          width={120}
        />
        <Column
          dataField="loadPlaceName"
          caption={t("상차지")}
          alignment="center"
          width={100}
        />
        <Column
          dataField="workPlaceName"
          caption={t("작업지")}
          alignment="center"
          width={100}
        />
        <Column
          dataField="unloadPlaceName"
          caption={t("하차지")}
          alignment="center"
          width={100}
        />
        <Column
          dataField="freight"
          caption={t("청구운임")}
          dataType="number"
          width={100}
        >
          <Format type="fixedPoint" />
        </Column>
        <Column
          dataField="habul01"
          caption={t("상차지 하불금액")}
          dataType="number"
          width={110}
        >
          <Format type="fixedPoint" />
        </Column>
        <Column
          dataField="habul02"
          caption={t("하차지 하불금액")}
          dataType="number"
          width={110}
        >
          <Format type="fixedPoint" />
        </Column>
      </Grid>
    </GridContent>
  );
};

export default ShuttleBasicGrid;
