import { request } from "@/util/api";
import { useEffect } from "react";

const useGetIP = () => {
  useEffect(() => {
    request({ url: "user/ip", method: "GET" }).then((res) => {
      localStorage.setItem("userIP", res as string);
    });
  }, []);
};

export default useGetIP;
