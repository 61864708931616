import styled, { css, keyframes } from "styled-components";
import { ButtonProps } from ".";

export const Loading = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(1turn);
  }
`;

export const Button = styled.button<ButtonProps>`
  display: flex;
  gap: 5px;
  border-radius: 4px;
  white-space: nowrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;

  // Size
  ${(props) =>
    props.size === "xxsm" &&
    css`
      height: 20px;
      padding: 0 4px;
    `}

  ${(props) =>
    props.size === "xsm" &&
    css`
      height: 24px;
      padding: 0 8px;
    `}

  ${(props) =>
    props.size === "sm" &&
    css`
      height: 30px;
      padding: 0 8px;
      font-size: 1.3rem;
    `}

  ${(props) =>
    props.size === "md" &&
    css`
      height: 34px;
      padding: 0 10px;
      font-size: 1.4rem;
    `}

  ${(props) =>
    props.size === "lg" &&
    css`
      height: 48px;
      padding: 0 10px;
      font-size: 1.8rem;
    `}

  ${(props) =>
    props.size === "xlg" &&
    css`
      height: 52px;
      padding: 0 10px;
      font-size: 1.8rem;
    `}

  ${(props) =>
    props.size === "full" &&
    css`
      width: 100%;
      height: 100%;
      min-height: 56px;
      font-size: 1.8rem;
      font-weight: 600;
    `}

    ${(props) =>
    props.width &&
    css`
      width: ${(props) => props.width}px;
    `}

  // Color & Layout
  ${(props) =>
    props.layout === "solid" &&
    css`
      color: ${props.theme.colors.white100};
      background: ${props.theme.colors[props.color]};
      border: 1px solid ${props.theme.colors[props.color]};

      &:hover {
        background: ${props.theme.colors[props.color + "Hover"]};
        border: 1px solid ${props.theme.colors[props.color + "Hover"]};
      }
    `}

  ${(props) =>
    props.layout === "text" &&
    css`
      color: ${props.theme.colors[props.color]};
      background: none;
      border: none;

      &:hover {
        background: ${props.theme.colors[props.color]}1f;
      }

      svg {
        path {
          fill: ${props.theme.colors[props.color]};
        }
      }
    `}

  ${(props) =>
    props.layout === "outline" &&
    css`
      color: ${props.theme.colors[props.color]};
      background: ${(props) => props.theme.colors.white100};
      border: 1px solid ${props.theme.colors[props.color]};

      &:hover {
        background: ${props.theme.colors[props.color]}1f;
      }

      svg {
        path {
          fill: ${props.theme.colors[props.color]};
        }
      }
    `}

  ${(props) => props.layout === "underline" && css``}
`;

export const ButtonLoading = styled.div<ButtonProps>`
  color: ${(props) => props.theme.colors.white};
  border: none;
  background: ${(props) =>
    props.layout === "solid"
      ? props.theme.colors.white70
      : props.theme.colors[props.color]}1f;
  transition: all 0.2s ease;
  cursor: default;

  // 로딩 스피너 설정
  .loader {
    display: flex;
    height: 20px;
    width: 20px;
    margin-right: 8px;

    &.loader-1 {
      border-radius: 50%;
      border-top: 3px solid rgba(255, 255, 255, 0.5);
      border-right: 3px solid rgba(255, 255, 255, 0.5);
      border-bottom: 3px solid rgba(255, 255, 255, 0.5);
      border-left: 3px solid rgba(255, 255, 255, 1);
      animation: ${Loading} 1s cubic-bezier(0.41, 0.25, 0.32, 0.83) infinite;
    }
  }

  .loading-txt {
    margin-top: 10px;
    color: ${(props) => props.theme.colors.white};
    font-size: 1.6rem;
  }
`;
