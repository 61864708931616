import { Suspense, lazy } from "react";
import useGetIP from "@/hooks/api/getIP";
import { useGetUserInfo } from "@/hooks/api/login";
import { useAxiosInterceptor } from "../hooks/useAxiosInterceptor";
import Loading from "@/components/atoms/Loading";
import { MainTemplate } from "@/components/templates/MainTemplate";

const AuthInit = () => {
  // const Layout = lazy(() => import("@/components/Layout"));
  const TabContent = lazy(
    () => import("@/components/organisms/Contents/TabContent")
  );
  // 로그인 유저 데이터
  useGetUserInfo();
  // 로그인 컴퓨터 아이피
  useGetIP();
  // axios interceptor설정
  useAxiosInterceptor();

  return (
    <MainTemplate>
      <Suspense
        fallback={<Loading type="page" text="페이지를 불러오는 중입니다..." />}
      >
        <TabContent />
      </Suspense>
    </MainTemplate>
  );
};

export default AuthInit;
