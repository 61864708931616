import { isLoading } from "@/state/atoms/isLoading";
import { useMemo } from "react";
import { useSetRecoilState } from "recoil";

const useButtonLoading = () => {
  const setIsLoading = useSetRecoilState(isLoading);

  return useMemo(() => {
    const setLoading = () => {
      setIsLoading(true);
    };

    const setUnloading = () => {
      setIsLoading(false);
    };
    return { setLoading, setUnloading };
  }, [setIsLoading]);
};

export default useButtonLoading;
