import { DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  shadows: {
    popup: "0px 2px 10px 0px rgba(0,0,0,0.1)",
    modal: "0px 8px 24px 0px rgba(0,0,0,.14)",
    field: "0px 6px 16px 0px rgba(0,0,0,.06)",
  },
  colors: {
    // Colors
    primary100: "#2E9A75",
    primary70: "#2E9A75b3",
    primary60: "#2E9A7599",
    primary50: "#2E9A7580",
    primary12: "#2E9A751f",
    primary100Hover: "#1E8260",
    primary100Active: "#147353",
    accent08: "#3EFFBE14",
    secondary100: "#EEF4F2",

    // Basic
    white100: "#ffffff",
    white70: "#ffffffb3",
    white50: "#ffffff80",
    white12: "#ffffff1f",
    white100Hover: "#ffffff1f",
    black100: "#000000",
    black70: "#000000b3",
    black60: "#00000099",
    black50: "#00000080",
    black20: "#00000033",
    black12: "#0000001f",
    black4: "#0000000a",
    black100Hover: "#0000001f",
    darkGray100: "#505050",
    gray100: "#e0e0e0",
    gray50: "#e0e0e080",
    gray30: "#e0e0e04d",

    // Status
    find: "#6e6e6e",
    findHover: "#4d4d4d",
    save: "#439dba",
    saveHover: "#1381A5",
    destructive: "#d74e4e",
    destructiveHover: "#c52828",
    grid: "#307974",
    gridHover: "#1d6762",
    gridSub: "#a5a5a5",
    gridSubHover: "#8d8d8d",
    gridBg: "#F8F8F8",

    // Component
    TopbarTitleTxt: "#181C32",
    titleTxt: "#505050",
    menuTxt: "#626262",
    legendTxt: "#8f8f8f",
    inputLabel: "#52585e",
    inputBorder: "#dddddd",
    gridSubHeader: "#686868",

    //old
    secondary: "#313949",
    third: "#222936",
    tertiary: "#f5f5f5",

    sideBG: "#f9f9fb",
    sideOpen: "#eff6ff",
    side02Highlight: "#E8F1FC",

    tab: "#636B79",
    tabTxt: "#000000",
    tabTxtHover: "#393939",

    sectionBorder: "#eaebec",
    innerSectionBorder: "#eaebec",
    searchBg: "#fbfbfb",

    LayoutRightTopBg: "#f3f6f9",
    LayoutRightBottomBg: "#fff",
    LayoutRightTit: "#3F4254",

    inputFocus: "#3693ff",
    inputDisable: "#C6C6C6",
    inputDisableBg: "#F7F7F7",
    inputTxt: "#52585e",
    inputPlaceholder: "#cccccc",

    new: "#46b47f",
    newHover: "#309e69",
    order: "#46b47f",
    orderHover: "#309e69",
    search: "#565e66",
    searchHover: "#414a53",
    delete: "#ff6961",
    deleteHover: "#ea4d44",
    modify: "#1e4575",
    modifyHover: "#6f7b8b",
    page: "#6385B1",
    pageHover: "#41699D",
    loading: "#8E97A3",

    white: "#ffffff",
    black: "#000000",
    gray: "#dfdfdf",
    whiteShade: "#f2f2f2",

    loginBg: "#f3f6f9",
    loginSubhead: "#7d7d7d",

    gridHeader: "#37445F",
    gridSubHeaderBorder: "#939393",
    gridSubBg: "#F3F3F3",
    gridFocused: "#3693ff",

    invoiceBorder: "#A1A1AB",
    invoiceInnerBorder: "#DEDEDE",
    invoiceCell: "#F3F5F4",
    invoiceCellTit: "#474747",
    invoicerHeader: "#FFC4CA",
    invoicerTit: "#FF2C4C",
    invoicerCell: "#FEF4F5",
    invoicerCellTit: "#F03347",
    invoiceeHeader: "#C4DCFF",
    invoiceeTit: "#3465B2",
    invoiceeCell: "#E9F3FF",
    invoiceeCellTit: "#526B93",

    scrollBar: "#a1a1a1",
    scrollBarHover: "#cecece",
    scrollBackground: "#ececec",

    success: "#03bb85",
    info: "#3b5998",
    warning: "#ff6961",
  },
};
