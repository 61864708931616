import styled from "styled-components";

export const SEUModalContent = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  border: 2px dashed ${(props) => props.theme.colors.black12};
  border-radius: 4px;
  background: ${(props) => props.theme.colors.black4};
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .dropZone-tit {
    display: flex;
    color: ${(props) => props.theme.colors.black60};
    font-size: 1.4rem;
    align-items: Center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;

    svg {
      width: 40px;
      height: 40px;
      opacity: 0.2;
    }
  }
`;
