import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { Column } from "devextreme-react/data-grid";
import { Editing } from "devextreme-react/tree-list";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useButtonLoading from "@/hooks/useButtonLoading";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import { usePostMutation } from "@/util/common.fn";
import { yupResolver } from "@hookform/resolvers/yup";
import { InputText } from "@/components/atoms/Inputs";
import { Buttons } from "@/components/atoms/Buttons";
import Tree from "@/components/atoms/Tree";
import { FormContent } from "@/components/organisms/Contents/FormContent";
import { GridContent } from "@/components/organisms/Contents/GridContent";
import { ContentTemplate } from "@/components/templates/ContentTemplate";
import UserPermissionGrid from "./UserPermissionGrid";
import { ReactComponent as IconSave } from "@/styles/assets/svg/icon_button_save.svg";

/**
 * 개인 권한 관리 페이지
 *
 */
const UserPermission = () => {
  const { t } = useTranslation("main");
  /* #region  */
  const initialSearch = {
    userId: "",
    userName: "",
  };
  const [search, setSearch] = useState(initialSearch);
  const [subTotalCount, setSubTotalCount] = useState<number>(0);
  const [selectedRowKey, setSelectedRowKey] = useState<string | null>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const gridMain = useRef<any>();
  const gridSub = useRef<any>();

  const { alertSuccessMessage, alertErrorMessage } = useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const schema = yup.object({
    userId: yup.string().nullable().required("아이디를 선택해주세요"),
    userName: yup.string().nullable(),
  });

  const {
    control,
    reset,
    trigger,
    formState: { errors },
  } = useForm<{ userId: string; userName?: string | null }>({
    defaultValues: {
      userId: "",
      userName: "",
    },
    resolver: yupResolver(schema),
  });

  const searchForm = useForm({
    defaultValues: initialSearch,
  });

  const { data: mainData, refetch: mainRefetch } = useCustomQuery<any[]>(
    `user-auth/master`,
    search
  );
  const { data: subData, refetch: subRefetch } = useCustomQuery(
    `user-auth`,
    {
      userOid: selectedRowKey,
    },
    !!selectedRowKey
  );

  const { mutateAsync: update } = usePostMutation("user-auth");

  /* #endregion */

  /* #region functions ---------------------------- */

  const initPage = () => {
    const mainGridInstance = gridMain.current.instance;
    const subGridInstance = gridSub.current.instance;
    mainGridInstance.option("focusedRowIndex", -1);
    subGridInstance.option("focusedRowIndex", -1);
    resetGridChanges();
    setSelectedRowKey(null);
    mainRefetch();
    subRefetch();
    reset({
      userId: "",
      userName: "",
    });
  };

  const resetGridChanges = () => {
    const mainGridInstance = gridMain.current.instance;
    const subGridInstance = gridSub.current.instance;
    mainGridInstance.option("editing.changes", []);
    subGridInstance.option("editing.changes", []);
  };

  const toggleOpen = () => setOpen(!isOpen);

  const onClickSave = async () => {
    if (!(await trigger())) return;

    const mainGridInstance = gridMain.current.instance;
    const subGridInstance = gridSub.current.instance;

    const changes = subGridInstance.option("editing.changes");

    const originalRowKey = selectedRowKey;

    try {
      setLoading();
      const res: any = await update({
        userOid: selectedRowKey,
        data: changes,
      });

      if (res.state === 1) {
        await alertSuccessMessage("저장완료");
      }

      initPage();
      mainGridInstance.option("focusedRowKey", originalRowKey);
    } catch (error: any) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onSearch = (data: any) => {
    setSearch(data);

    initPage();
  };

  const onFocusedRowChanged = (e: any) => {
    if (!e.row?.data) return;
    const data = e.row.data;
    resetGridChanges();
    setSelectedRowKey(data.id);
    reset({
      userId: data.userId,
      userName: data.userName,
    });
  };

  const onEditorPreparing = (e: any) => {
    if (e.parentType === "dataRow") {
      const standardHandler = e.editorOptions.onValueChanged;
      e.editorOptions.onValueChanged = function (x: any) {
        standardHandler(x);
        const instance = gridSub.current.instance;
        const node = instance.getNodeByKey(e.row.key);
        const children = node.children;

        instance.expandRow(e.row.key);

        if (Array.isArray(children)) {
          children.forEach((cur) => {
            const index = instance.getRowIndexByKey(cur.key);

            // 문제점, 보이는 row만 수정할 수 있다.
            instance.cellValue(index, e.dataField, x.value);
          });
        }
      };
    }
  };

  /* #endregion */

  return (
    <ContentTemplate sideWidth={440}>
      <>
        <UserPermissionGrid
          searchForm={searchForm}
          gridMain={gridMain}
          mainData={mainData}
          toggleOpen={toggleOpen}
          onSearch={onSearch}
          onFocusedRowChanged={onFocusedRowChanged}
        />

        <FormContent
          title={t("권한그룹 등록")}
          inputForms={
            <>
              <InputText
                label="아이디"
                name="userId"
                direction="column"
                control={control}
                errors={errors}
                disabled={true}
              />
              <InputText
                label="유저명"
                name="userName"
                direction="column"
                control={control}
                errors={errors}
                disabled={true}
              />
            </>
          }
          inputFormGrid={2}
          formButtons={
            <Buttons
              permission="save"
              type="button"
              size="sm"
              layout="solid"
              color="save"
              icon={<IconSave />}
              label="저장"
              onClick={onClickSave}
            />
          }
        >
          <>
            {/* <Button
                className="input-back"
                variety="tran"
                size="xxsm"
                shape="solid"
                type="button"
                onClick={toggleOpen}
              >
                <MovePageIcon width={26} height={26} viewBox="0 0 24 24" />
                <span>{t("뒤로")}</span>
              </Button> */}
            <GridContent totalCount={subTotalCount} subGrid={true}>
              {/* detail 정보 보기 및 입력 */}
              <Tree
                title={t("그룹 메뉴권한 설정")}
                subGrid={true}
                id="AuthDetail"
                refs={gridSub}
                keys="id"
                keyExpr="id"
                parentIdExpr="parentId"
                dataSource={subData}
                focusedRowEnabled={false}
                onEditorPreparing={onEditorPreparing}
                onContentReady={(e: any) =>
                  setSubTotalCount(e.component.totalCount())
                }
              >
                <Editing mode="batch" allowUpdating={true} />
                <Column
                  dataField="depth"
                  caption="Depth"
                  allowEditing={false}
                />
                <Column
                  dataField="menuName"
                  caption={t("메뉴명")}
                  allowEditing={false}
                />
                <Column
                  dataField="isAuth"
                  caption={t("조회")}
                  dataType="boolean"
                  allowEditing={true}
                />
                <Column
                  dataField="isSave"
                  caption={t("저장")}
                  dataType="boolean"
                  allowEditing={true}
                />
                <Column
                  dataField="isDelete"
                  caption={t("삭제")}
                  dataType="boolean"
                  allowEditing={true}
                />
                <Column
                  dataField="isExport"
                  caption={t("출력")}
                  dataType="boolean"
                  allowEditing={true}
                />
              </Tree>
            </GridContent>
          </>
        </FormContent>
      </>
    </ContentTemplate>
  );
};

export default UserPermission;
