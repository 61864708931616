import * as yup from "yup";

export interface ComCarType {
  oid?: string;
  carNo: string;
  manageAssign: string;
  kindCar: string;
  carTon: string;
  carDivision: string;
  carAssignCom?: string;
  carWanted?: string;
  loadOpt?: string;
  route?: string;
  viewRank?: string;
  creditClass?: string;
  assignWorker?: string;
  zipCode?: string;
  address?: string;
  address1?: string;
  driverId?: string;
  driverName: string;
  tel: string;
  homeTel?: string;
  brith?: string;
  email?: string;
  carIdCard?: string;
  appYn?: string;
  danger?: string;
  workType?: string;
  payType?: string;
  actStatus?: string;
  nCollectPrice?: string;
  nGivenPrice?: string;
  registerDate?: string;
  registerWorker?: string;
  bizName?: string;
  bizNo?: string;
  ceoName?: string;
  bizCondition?: string;
  item?: string;
  bizPlaceNo?: string;
  bizAddress?: string;
  bizDetailAddress?: string;
  bizType?: number;
  coporNo?: string;
  kindBiz?: string;
  bank?: string;
  payCycle?: string;
  acountNo?: string;
  depositOwner?: string;
  remark?: string;
  carCommission?: string;
  carRemark?: string;
}

export const ComCarInputType = yup.object<ComCarType>({
  oid: yup.string().nullable(),
  carNo: yup.string().required("차량번호를 입력해주세요"),
  manageAssign: yup.string().required("관리소속 입력해주세요"),
  kindCar: yup.string().required("차량종류을 입력해주세요"),
  carTon: yup.string().required("차량통수을 입력해주세요"),
  carDivision: yup.string().required("차량구분을 입력해주세요"),
  carAssignCom: yup.string().nullable(),
  carWanted: yup.string().nullable(),
  loadOpt: yup.string().nullable(),
  route: yup.string().nullable(),
  viewRank: yup.string().nullable(),
  creditClass: yup.string().nullable(),
  assignWorker: yup.string().nullable(),
  zipCode: yup.string().nullable(),
  address: yup.string().nullable(),
  address1: yup.string().nullable(),
  driverId: yup.string().nullable(),
  driverName: yup.string().required("운전자이름을 입력해주세요"),
  tel: yup.string().required("휴대전화를 입력해주세요"),
  homeTel: yup.string().nullable(),
  brith: yup.string().nullable(),
  email: yup.string().nullable(),
  carIdCard: yup.string().nullable(),
  appYn: yup.string().nullable(),
  danger: yup.string().nullable(),
  workType: yup.string().nullable(),
  payType: yup.string().nullable(),
  actStatus: yup.string().nullable(),
  nCollectPrice: yup.string().nullable(),
  nGivenPrice: yup.string().nullable(),
  registerDate: yup.string().nullable(),
  registerWorker: yup.string().nullable(),
  bizName: yup.string().nullable(),
  bizNo: yup.string().nullable(),
  ceoName: yup.string().nullable(),
  bizCondition: yup.string().nullable(),
  item: yup.string().nullable(),
  bizPlaceNo: yup.string().nullable(),
  bizAddress: yup.string().nullable(),
  bizDetailAddress: yup.string().nullable(),
  bizType: yup.number().nullable(),
  coporNo: yup.string().nullable(),
  kindBiz: yup.string().nullable(),
  bank: yup.string().nullable(),
  payCycle: yup.string().nullable(),
  acountNo: yup.string().nullable(),
  depositOwner: yup.string().nullable(),
  remark: yup.string().nullable(),
  carCommission: yup.string().nullable(),
  carRemark: yup.string().nullable(),
});

export const InitialComCarInput: ComCarType = {
  oid: undefined,
  carNo: "",
  manageAssign: "",
  kindCar: "",
  carTon: "",
  carDivision: "",
  carAssignCom: undefined,
  carWanted: undefined,
  loadOpt: undefined,
  route: undefined,
  viewRank: undefined,
  creditClass: undefined,
  assignWorker: undefined,
  zipCode: undefined,
  address: undefined,
  address1: undefined,
  driverId: undefined,
  driverName: "",
  tel: "",
  homeTel: undefined,
  brith: undefined,
  email: undefined,
  carIdCard: undefined,
  appYn: undefined,
  danger: undefined,
  workType: undefined,
  payType: undefined,
  actStatus: undefined,
  nCollectPrice: undefined,
  nGivenPrice: undefined,
  registerDate: undefined,
  registerWorker: undefined,
  bizName: undefined,
  bizNo: undefined,
  ceoName: undefined,
  bizCondition: undefined,
  item: undefined,
  bizPlaceNo: undefined,
  bizAddress: undefined,
  bizDetailAddress: undefined,
  bizType: undefined,
  coporNo: undefined,
  kindBiz: undefined,
  bank: undefined,
  payCycle: undefined,
  acountNo: undefined,
  depositOwner: undefined,
  remark: undefined,
  carCommission: undefined,
  carRemark: undefined,
};
