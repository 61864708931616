import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  Column,
  ColumnFixing,
  Format,
  Selection,
} from "devextreme-react/data-grid";
import useButtonLoading from "@/hooks/useButtonLoading";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import { usePostMutation } from "@/util/common.fn";
import Grid from "@/components/atoms/Grid";
import { Buttons } from "@/components/atoms/Buttons";
import { InputDate, InputSelect } from "@/components/atoms/Inputs";
import { Modal } from "..";
import { ReactComponent as IconSearch } from "@/styles/assets/svg/icon_search.svg";
import * as S from "./TaxListModal.style";

interface TaxListModalProps {
  onClose: () => void;
  selectedRowKey: string | null;
  initPage: () => void;
  gridMain: any;
}

const TaxListModal = ({
  onClose,
  selectedRowKey,
  initPage,
  gridMain,
}: TaxListModalProps) => {
  const { t } = useTranslation("main");

  const initialState = {
    // searchType: "0",
    firstDay: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
    lastDay: dayjs().format("YYYY-MM-DD"),
  };

  const [search, setSearch] = useState(initialState);

  const gridRef: any = useRef();

  const { alertErrorMessage, alertSuccessMessage } = useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { data: mainData, refetch: mainRefetch } = useCustomQuery<any[]>(
    `account-list/tax`,
    search
  );
  const { mutateAsync: connect } = usePostMutation(`account-list/tax`);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: initialState,
  });

  const onSearch = (data: any) => {
    setSearch(data);

    mainRefetch();
  };

  const onClickConnect = async () => {
    try {
      setLoading();
      const originalKey = selectedRowKey;
      const res: any = await connect({
        key: selectedRowKey,
        data: gridRef.current.instance.getSelectedRowsData(),
      });

      if (res.state === 1) {
        initPage();

        await alertSuccessMessage("저장완료");
      }

      gridMain.current.instance.option("focusedRowKey", originalKey);
      onClose();
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };
  return (
    <Modal
      width="1100px"
      close={onClose}
      title="연결하기"
      modalButton={
        <Buttons
          permission="save"
          type="button"
          size="lg"
          width={80}
          layout="solid"
          color="save"
          label="저장"
          onClick={onClickConnect}
        />
      }
    >
      <S.TLModalContent>
        <S.TLModalTopSection onSubmit={handleSubmit(onSearch)}>
          <InputSelect
            type="h-array"
            control={control}
            errors={errors}
            label="날짜구분"
            name="searchType"
            dataSource={[
              { finishNo: "0", name: t("전체") },
              { finishNo: "1", name: t("마감일자") },
            ]}
            valueExpr="finishNo"
            displayExpr="name"
            width={100}
          />
          <InputDate
            className="label-select"
            control={control}
            errors={errors}
            name="firstDay"
          />
          ~
          <InputDate
            className="label-select"
            control={control}
            errors={errors}
            name="lastDay"
          />
          <Buttons
            type="submit"
            size="sm"
            layout="solid"
            color="find"
            icon={<IconSearch />}
          />
        </S.TLModalTopSection>

        <S.TLModalGrid>
          <Grid
            subGrid={true}
            dataSource={mainData}
            refs={gridRef}
            keyExpr="finishNo"
          >
            <ColumnFixing enabled={true} />
            <Selection
              mode="multiple"
              allowSelectAll={false}
              showCheckBoxesMode="always"
            />
            <Column dataField="division" caption={t("구분")} width="47" />
            <Column
              dataField="companyName"
              caption={t("청구처/용차사/차량번호")}
              width="134"
            />
            {/* 접수법인 */}
            <Column
              dataField="receiveCoName"
              caption={t("접수법인")}
              width="134"
            />
            {/* 마감번호 */}
            <Column
              dataField="completeNo"
              caption={t("마감번호")}
              width="134"
            />
            {/* 마감일자 */}
            <Column
              dataField="completeDate"
              caption={t("마감일자")}
              width="134"
            />
            {/* 상세건수 */}
            <Column
              dataField="detailTotal"
              caption={t("상세건수")}
              width="100"
            />
            {/* 정산상태 */}
            <Column
              dataField="closeStatus"
              caption={t("정산상태")}
              width="100"
            />
            {/* 정산예정일자 */}
            <Column
              dataField="taxSchedule"
              caption={t("정산일자")}
              width="100"
              dataType="date"
              format="yyyy-MM-dd"
            />
            {/* 세금계산서번호 */}
            <Column
              dataField="finishNo"
              caption={t("세금계산서번호")}
              width="150"
            />
            {/* 계산서상태 */}
            <Column
              dataField="taxStatus"
              caption={t("계산서상태")}
              width="100"
            />
            {/* 금액 */}
            <Column
              dataField="price"
              caption={t("금액")}
              dataType="number"
              width="130"
            >
              <Format type="fixedPoint" />
            </Column>
            {/* 세액 */}
            <Column
              dataField="taxTotal"
              caption={t("세액")}
              dataType="number"
              width="130"
            >
              <Format type="fixedPoint" />
            </Column>
            {/* 합계금액 */}
            <Column
              dataField="priceTotal"
              caption={t("합계금액")}
              dataType="number"
              width="150"
            >
              <Format type="fixedPoint" />
            </Column>
          </Grid>
        </S.TLModalGrid>
      </S.TLModalContent>
    </Modal>
  );
};

export default TaxListModal;
