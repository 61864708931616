import { SelectBox } from "devextreme-react";
import { Controller } from "react-hook-form";
import { Input, InputProps } from "..";

const InputSelect = ({
  control,
  errors,
  name,
  label,
  type,
  dataSource,
  displayExpr,
  valueExpr,
  required,
  disabled = false,
  width,
  onValueChanged = () => {},
  direction,
  index,
}: InputProps) => {
  const arrayName = name?.split("[")[0];
  const fieldName = name?.split(".")[1];
  const arrayList = errors[arrayName];
  return (
    <Input
      type="select"
      direction={direction ? direction : `row`}
      label={label && label}
      name={name}
      required={required}
      errors={errors && errors}
      index={index}
      arrayList={arrayList}
      fieldName={fieldName}
    >
      <>
        <Controller
          render={({ field: { onChange, value } }) => (
            <SelectBox
              name={name}
              width={width}
              searchEnabled={true}
              dataSource={dataSource}
              valueExpr={valueExpr}
              displayExpr={displayExpr}
              onValueChanged={(value) => {
                onChange(value.value);
                onValueChanged(value);
              }}
              value={value}
              disabled={disabled}
            >
              {/* <DropDownOptions closeOnOutsideClick={true} showTitle={false} /> */}
            </SelectBox>
          )}
          control={control}
          name={name}
          defaultValue=""
        />
        {arrayList && arrayList[index] && (
          <div className="fv-plugins-message-container">
            <div className="fv-help-block">
              {arrayList[index][fieldName]?.message}
            </div>
          </div>
        )}
      </>
    </Input>
  );
};

export default InputSelect;
