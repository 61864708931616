import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  InputCheckBox,
  InputRadioBox,
  InputSelect,
  InputText,
} from "@/components/atoms/Inputs";
import InputAddress from "@/components/atoms/Inputs/InputAddress";
import InputNumber from "@/components/atoms/Inputs/InputNumber";
import {
  거래처_종류,
  결제일자,
  사업자종류,
  사용자_활동상태_구분,
  업무대행,
  은행,
} from "@/data/constant/queryKeys";
import { useComCodeData } from "@/hooks/queries";
import useButtonLoading from "@/hooks/useButtonLoading";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import {
  ComCompanyInputSchema,
  ComCompanyType,
  InitialComCompanyInput,
} from "@/types/comCompany.type";
import { usePostMutation, usePutMutation } from "@/util/common.fn";
import { yupResolver } from "@hookform/resolvers/yup";

import { Modal } from "..";
import { ModalForm } from "../modal.styles";
import { Buttons } from "@/components/atoms/Buttons";

interface CompanyManageInputProps {
  onClose: () => void;
  selectedRowKey: number | null;
}

const CompanyManageInput = ({
  onClose,
  selectedRowKey,
}: CompanyManageInputProps) => {
  const { t } = useTranslation("main");
  const { alertErrorMessage, alertSuccessMessage } = useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { data: mainData, isLoading: mainDataLoading } = useCustomQuery<
    ComCompanyType[]
  >(`companyManage/${selectedRowKey}`, {}, !!selectedRowKey);

  const { mutateAsync: create } = usePostMutation(`companyManage`);
  const { mutateAsync: update } = usePutMutation(`companyManage`);

  const { data: custoDivision } = useComCodeData(거래처_종류);
  const { data: agency } = useComCodeData(업무대행);
  const { data: payCycle } = useComCodeData(결제일자);
  const { data: actStatus } = useComCodeData(사용자_활동상태_구분);
  const { data: kindBiz } = useComCodeData(사업자종류);
  const { data: bank } = useComCodeData(은행);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: InitialComCompanyInput,
    resolver: yupResolver(ComCompanyInputSchema),
  });

  useEffect(() => {
    mainData && reset(mainData[0]);
    // eslint-disable-next-line
  }, [mainData]);

  const openPost = () => {
    window.daum.postcode.load(() => {
      const postcode = new window.daum.Postcode({
        oncomplete: function (data: any) {
          let addr = "";
          let extraAddr = "";

          if (data.userSelectedType === "R") {
            addr = data.address;
          } else {
            addr = data.jibunAddress;
          }

          if (data.userSelectedType === "R") {
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            addr += extraAddr;
          }

          setValue("zipCode", data.zonecode);
          setValue("address", addr);
        },
      });
      postcode.open();
    });
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading();
      let res: any;
      if (selectedRowKey) {
        res = await update({ key: selectedRowKey, data });
      } else {
        res = await create(data);
      }

      if (res?.state === 1) {
        await alertSuccessMessage("저장완료");
        onClose();
      }
    } catch (error) {
      console.log(error);
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onError = () => {};

  return (
    <Modal width="1100px" close={onClose} title="회사정보 등록">
      <ModalForm onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="grid-4">
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="company"
            label="회사명"
            required={true}
          />
          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            dataSource={custoDivision}
            valueExpr="subCode"
            displayExpr="subName"
            name="custoDivision"
            label="거래처분류"
            required={true}
          />
          <div />
          <div />

          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            dataSource={agency}
            valueExpr="subCode"
            displayExpr="subName"
            name="agency"
            label="업무대행"
          />
          <InputNumber
            type="v-array"
            control={control}
            errors={errors}
            name="agencyPrice"
            label="업무대행료"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="reportTel"
            label="도착보고전화"
          />
          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            dataSource={payCycle}
            valueExpr="subCode"
            displayExpr="subName"
            name="payCycle"
            label="결제일자"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="tel"
            label="대표전화"
            required={true}
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="fax"
            label="팩스번호"
          />
          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            dataSource={actStatus}
            valueExpr="subCode"
            displayExpr="subName"
            name="actStatus"
            label="활동상태"
          />
        </div>

        <div className="grid-4">
          <InputAddress
            label="주소"
            control={control}
            errors={errors}
            openPost={openPost}
            disabled={false}
          />
        </div>
        <div className="grid-4">
          <InputCheckBox
            control={control}
            errors={errors}
            name="isShuttleDepot"
            label="셔틀창고"
          />
          <InputCheckBox
            control={control}
            errors={errors}
            name="isBilling"
            label="업체"
          />
          <InputCheckBox
            control={control}
            errors={errors}
            name="isOrderCo"
            label="발주처"
          />
          <InputCheckBox
            control={control}
            errors={errors}
            name="isCargoOwner"
            label="화주"
          />
          <InputCheckBox
            control={control}
            errors={errors}
            name="isYongchasa"
            label="용차사"
          />
        </div>

        <InputText
          type="v-array"
          control={control}
          errors={errors}
          name="remark"
          label="비고"
        />

        <div className="grid-4">
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="bizName"
            label="사업자상호"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="bizNo"
            label="사업자번호"
          />
          <InputRadioBox
            type="v-array"
            control={control}
            errors={errors}
            name="bizType"
            label="휴폐업조회"
            dataSource={[
              { id: 0, name: t("개인") },
              { id: 1, name: t("법인") },
            ]}
            valueExpr="id"
            displayExpr="name"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="coporNo"
            label="법인번호"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="ceoName"
            label="대표자"
          />
          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            dataSource={kindBiz}
            valueExpr="subCode"
            displayExpr="subName"
            name="kindBiz"
            label="사업자종류"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="bizCondition"
            label="업태"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="item"
            label="종목"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="bizPlaceNo"
            label="종사업장번호"
          />
          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            dataSource={bank}
            valueExpr="subCode"
            displayExpr="subName"
            name="bank"
            label="거래은행"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="acountNo"
            label="계좌번호"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="depositOwner"
            label="예금주"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="billManage"
            label="계산서담당자"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="billEmail"
            label="계산서이메일"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="companyTel"
            label="회사전화"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="moveTel"
            label="이동전화"
          />
        </div>

        {/* Modal 저장 버튼 */}
        {/*  */}
        <Buttons
          type="submit"
          permission="save"
          size="full"
          layout="solid"
          color="save"
          label="저장"
          disabled={mainDataLoading}
        />
      </ModalForm>
    </Modal>
  );
};

export default CompanyManageInput;
