import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Column,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import useButtonLoading from "@/hooks/useButtonLoading";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import { useDeleteMutation } from "@/util/common.fn";
import Grid from "@/components/atoms/Grid";
import { Buttons } from "@/components/atoms/Buttons";
import { InputText } from "@/components/atoms/Inputs";
import CompanyManageInput from "@/components/molecules/Modal/CompanyManageInput";
import { GridContent } from "@/components/organisms/Contents/GridContent";
import { ContentTemplate } from "@/components/templates/ContentTemplate";
import { ReactComponent as IconCreate } from "@/styles/assets/svg/icon_button_create.svg";
import { ReactComponent as IconDelete } from "@/styles/assets/svg/icon_button_delete.svg";

const ComCompanyPage = () => {
  const { t } = useTranslation("main");
  const storageKey = "ComCompanyPage";
  const [search, setSearch] = useState({});
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedRowKey, setSelectedRowKey] = useState<number | null>(null);
  const [companyModal, setCompanyModal] = useState<boolean>(false);
  const gridMain = useRef<any>();

  const { alertErrorMessage, alertSuccessMessage, confirmMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { data: mainData, refetch: mainRefetch } = useCustomQuery(
    `companyManage`,
    search
  );
  const { mutateAsync: remove } = useDeleteMutation("companyManage");

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const initPage = () => {
    const instance = gridMain.current.instance;

    instance.option("focusedRowIndex", -1);
    setSelectedRowKey(null);
    mainRefetch();
  };

  const onSearch = (data: any) => {
    setSearch(data);
  };

  const onRowDblClick = () => {
    setCompanyModal(true);
  };
  const onFocusedRowChanged = (e: any) => {
    if (!e.row?.key) return;
    setSelectedRowKey(e.row.key);
  };

  const deleteData = async () => {
    if (!selectedRowKey) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }

    if (!(await confirmMessage("삭제하시겠습니까?"))) return;

    try {
      setLoading();
      await remove({ key: selectedRowKey });

      await alertSuccessMessage("삭제완료");
      initPage();
    } catch (error) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  return (
    <>
      <ContentTemplate>
        <GridContent
          searchSection={
            <InputText
              label="거래처명"
              name="company"
              control={control}
              errors={errors}
            />
          }
          searchSubmit={handleSubmit(onSearch)}
          gridRef={gridMain}
          storageKey={storageKey}
          totalCount={totalCount}
          gridSectionButtons={
            <>
              <Buttons
                type="button"
                size="sm"
                layout="outline"
                color="primary100"
                icon={<IconCreate />}
                label="신규입력"
                onClick={() => {
                  setSelectedRowKey(null);
                  setCompanyModal(true);
                }}
              />
              <Buttons
                type="button"
                size="sm"
                layout="outline"
                color="destructive"
                icon={<IconDelete />}
                label="삭제"
                onClick={deleteData}
              />
            </>
          }
        >
          <Grid
            key="oid"
            keyExpr="oid"
            dataSource={mainData}
            refs={gridMain}
            focusedRowEnabled={true}
            onRowDblClick={onRowDblClick}
            onFocusedRowChanged={onFocusedRowChanged}
            onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
          >
            <HeaderFilter visible={true} />
            {/* <ColumnChooser enabled={true} /> */}
            <StateStoring
              enabled={true}
              type="custom"
              customLoad={useCallback(
                () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
                []
              )}
            />
            <Scrolling mode="virtual" />
            <Column
              caption={t("순번")}
              cellRender={(e) => e.row.loadIndex + 1}
              alignment="center"
              width={35}
            />
            <Column dataField="company" caption={t("거래처명")} width={160} />
            <Column
              dataField="custoDivisionName"
              caption={t("거래처종류")}
              width={120}
            />
            <Column dataField="tel" caption={t("대표전화")} width={120} />
            <Column dataField="fax" caption={t("팩스번호")} width={120} />
            <Column dataField="reportTel" caption={t("도착보고전화")} />
            <Column dataField="bizNo" caption={t("사업자번호")} />
            <Column dataField="ceoName" caption={t("대표자명")} width={100} />
            <Column dataField="bizCondition" caption={t("업종")} width={100} />
            <Column dataField="item" caption={t("업태")} />
          </Grid>
        </GridContent>
      </ContentTemplate>
      {/* 신규등록 Modal */}
      {companyModal && (
        <CompanyManageInput
          onClose={() => {
            setCompanyModal(false);
            mainRefetch();
          }}
          selectedRowKey={selectedRowKey}
        />
      )}
    </>
  );
};

export default ComCompanyPage;
