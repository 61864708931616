import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useReactToPrint } from "react-to-print";
import html2canvas from "html2canvas";
import { useMutation } from "@tanstack/react-query";
import useButtonLoading from "@/hooks/useButtonLoading";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import {
  ClosingSearchType,
  InvoiceeData,
  InvoicerData,
  PriceDataType,
  TemplateType,
} from "@/types/closing.type";
import { request } from "@/util/api";
import { fileExcelDownload, sleep, usePutMutation } from "@/util/common.fn";
import { convertToPdf } from "@/util/emailFunction";
import Loading from "@/components/atoms/Loading";
import { Buttons } from "@/components/atoms/Buttons";
import TradeSheetTable from "@/components/atoms/TradeSheetTable";
import { Modal } from "..";

interface SpecificationModalProps {
  onClose: () => void;
  search: ClosingSearchType;
  templateInfo: TemplateType | undefined;
  selectedRowData: any;
  gridSub: React.MutableRefObject<any>;

  setMailPdf: React.Dispatch<React.SetStateAction<Blob>>;
  setOpenMailingModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const SpecificationModal = ({
  onClose,
  search,
  templateInfo,
  selectedRowData,
  gridSub,
  setMailPdf,
  setOpenMailingModal,
}: SpecificationModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  // 데이터
  const [gridColumn, setGridColumn] = useState<any[]>([]);
  const [invoicerData, setInvoicerData] = useState<InvoicerData>();
  const [invoiceeData, setInvoiceeData] = useState<InvoiceeData>();
  const [priceData, setPriceData] = useState<PriceDataType>({
    Amount: "0",
    Tax: "0",
    TotalAmount: "0",
  });

  const printRef = useRef<any>();

  const TradeSheetInput = useForm<any>();
  const { getValues, setValue, watch } = TradeSheetInput;

  const { mutateAsync: getTemplate } = useMutation({
    mutationFn: (oid: any) => {
      return request<any[]>({ method: "GET", url: `/trade-sheet/${oid}` });
    },
  });
  const { mutateAsync: getCompany } = useMutation({
    mutationFn: (selectedRowData: any) => {
      let apiUrl = "";
      let searchQuery: any = {};

      if (selectedRowData.division === "기사" && selectedRowData.companyName) {
        apiUrl += `/carManage`;
        searchQuery["carNo"] = selectedRowData.companyName
          ?.split("(")[1]
          ?.slice(0, -1);
      } else {
        apiUrl += `/companyManage/${selectedRowData?.comOid}`;
      }

      return request<any[]>({
        method: "GET",
        url: `${apiUrl}`,
        params: {
          params: searchQuery,
        },
      });
    },
  });
  const { mutateAsync: getReceive } = useMutation({
    mutationFn: (companyName) => {
      return request<any[]>({
        method: "GET",
        url: `/company`,
        params: { companyName },
      });
    },
  });

  const { mutateAsync: excelExport } = usePutMutation<string>(`excel-export`);
  const { mutateAsync: faxExport } = usePutMutation<{ result: string }>(
    `fax-export`
  );

  const { alertErrorMessage, alertSuccessMessage } = useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  useEffect(() => {
    const rows = gridSub.current.instance.getSelectedRowsData();

    templateInfo && getTemplateData(templateInfo);

    selectedRowData && getCompanyName(selectedRowData);

    rows && getReceiveCo(rows, selectedRowData);

    let Amount: number = 0;
    let Tax: number = 0;

    for (const obj of rows) {
      Amount += Number(obj.price);
      Tax += Number(obj.tax);
    }

    let TotalAmount: number = Amount + Tax;

    setPriceData({
      Amount: Amount.toLocaleString(undefined, { minimumFractionDigits: 0 }),
      Tax: Tax.toLocaleString(undefined, { minimumFractionDigits: 0 }),
      TotalAmount: TotalAmount.toLocaleString(undefined, {
        minimumFractionDigits: 0,
      }),
    });

    // eslint-disable-next-line
  }, []);

  const getTemplateData = async (templateInfo: TemplateType) => {
    try {
      setLoading();
      const list = await getTemplate(templateInfo.templete);
      const columns = list
        .filter((cur) => cur.useYn === "1")
        .sort((a, b) => {
          return a.sortNo - b.sortNo;
        });

      setGridColumn(columns);
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const getCompanyName = async (selectedRowData: any) => {
    try {
      setLoading();
      const data = await getCompany(selectedRowData);

      const companyData = data[0];

      if (selectedRowData.division === "기사") {
        // 기사일경우 공급자, 공급받는자 위치 변경
        const invoicer = {
          Biz_No: companyData?.bizNo || "",
          Invoicer_CorpName: companyData?.bizName || "",
          Invoicer_CEOName: companyData?.ceoName || "",
          Invoicer_Addr:
            `${companyData?.bizAddress || ""} ${
              companyData?.bizDetailAddress || ""
            }` !== " "
              ? `${companyData?.bizAddress || ""} ${
                  companyData?.bizDetailAddress || ""
                }`
              : `${companyData?.address} ${companyData?.address1}` !== " "
              ? `${companyData?.address} ${companyData?.address1}`
              : "",
          Invoicer_BizClass: companyData?.bizCondition || "",
          Invoicer_BizType: companyData?.item || "",
          Invoicer_Tel: companyData?.tel || "",
          Invoicer_Fax: companyData?.fax || "",
        };
        setInvoicerData(invoicer);
        setValue("Invoicer_Fax", companyData?.fax || "");
      } else {
        const invoicee = {
          Biz_No_Return: companyData?.bizNo || "",
          Invoicee_CorpName: companyData?.bizName || "",
          Invoicee_CEOName: companyData?.ceoName || "",
          Invoicee_Addr:
            `${companyData?.bizAddress || ""} ${
              companyData?.bizDetailAddress || ""
            }` !== " "
              ? `${companyData?.bizAddress || ""} ${
                  companyData?.bizDetailAddress || ""
                }`
              : `${companyData?.address} ${companyData?.address1}` !== " "
              ? `${companyData?.address} ${companyData?.address1}`
              : "",
          Invoicee_BizClass: companyData?.bizCondition || "",
          Invoicee_BizType: companyData?.item || "",
          Invoicee_Tel: companyData?.tel || "",
          Fax_No: companyData?.fax || "",
        };
        setInvoiceeData(invoicee);
        setValue("Fax_No", companyData?.fax || "");
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const getReceiveCo = async (rows: any[], selectedRowData: any) => {
    try {
      setLoading();
      const data = await getReceive(rows[0]?.companyName);

      const receiveCo = data[0];

      if (selectedRowData.division === "기사") {
        const invoicee = {
          Biz_No_Return: receiveCo?.bizNo || "",
          Invoicee_CorpName: receiveCo?.companyName || "",
          Invoicee_CEOName: receiveCo?.ceoName || "",
          Invoicee_Addr:
            `${receiveCo?.address} ${receiveCo?.addressDetail}` || "",
          Invoicee_BizClass: receiveCo?.bizType || "",
          Invoicee_BizType: receiveCo?.bizItem || "",
          Invoicee_Tel: receiveCo?.tel || "",
          Fax_No: receiveCo?.fax || "",
        };
        setInvoiceeData(invoicee);
        setValue("Fax_No", invoicee.Fax_No);
      } else {
        const invoicer = {
          Biz_No: receiveCo?.bizNo || "",
          Invoicer_CorpName: receiveCo?.companyName || "",
          Invoicer_CEOName: receiveCo?.ceoName || "",
          Invoicer_Addr:
            `${receiveCo?.address} ${receiveCo?.addressDetail}` || "",
          Invoicer_BizClass: receiveCo?.bizType || "",
          Invoicer_BizType: receiveCo?.bizItem || "",
          Invoicer_Tel: receiveCo?.tel || "",
          Invoicer_Fax: receiveCo?.fax || "",
        };
        setInvoicerData(invoicer);
        setValue("Invoicer_Fax", invoicer.Invoicer_Fax);
        setValue("Biz_No", invoicer.Biz_No);
        setValue("ContactID", receiveCo.contactId);
        setValue("ContactPassword", receiveCo.contactPassword);
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onClickMailModal = async () => {
    setIsLoading(true);
    try {
      await sleep(500);
      const blob = await convertToPdf();

      setMailPdf(blob);
      setOpenMailingModal(true);
    } catch (error) {
      console.log(error);
      alertErrorMessage("pdf변환 오류");
    } finally {
      setIsLoading(false);
    }
  };

  const onClickFaxExport = async () => {
    const pdfForm = document.querySelector("#pdf-form");
    const faxNo = getValues("Fax_No");
    const invoicerFax = getValues("Invoicer_Fax");

    // 팩스넘버 불러오기
    setIsLoading(true);
    try {
      if (!pdfForm) return;
      const canvas = await html2canvas(pdfForm as HTMLElement, {
        height: 1200,
        windowHeight: 1200,
      });

      const companyInfo = watch();
      const imageDataURL = canvas.toDataURL("image/png");
      //수신거래처 팩스 정보 체크
      if (!faxNo) {
        alertErrorMessage("거래처 수신 팩스번호정보가 없습니다.");
        //발신거래처 팩스 정보 체크
      } else if (!invoicerFax) {
        alertErrorMessage("회사 발신 팩스번호정보가 없습니다.");
      } else {
        const result: { result: string } = await faxExport({
          data: companyInfo,
          image: imageDataURL,
        });
        alertSuccessMessage(result?.result);
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickExcelExport = async () => {
    setLoading();
    const searchDate = search;
    const companyInfo = { ...invoicerData, ...invoiceeData };
    const dataColumn = gridColumn;
    const dataList = gridSub.current.instance.getSelectedRowsData();
    const etc = templateInfo;

    let rowIndex = 1;

    const rows = dataList.map((row: any) => {
      //거래명세서 출력된 데이터 정보
      const returnArr = [];
      for (const obj of dataColumn) {
        if (obj.printName === "No") {
          returnArr.push(rowIndex);
          rowIndex++;
        } else {
          returnArr.push(row[obj.columnName]);
        }
      }

      return returnArr;
    });

    try {
      const data = await excelExport({
        date: searchDate,
        cominfor: companyInfo,
        column: dataColumn,
        rows,
        data: dataList,
        etc,
      });

      if (data === "") {
        alertErrorMessage("저장실패");
      } else {
        fileExcelDownload(data);
        alertSuccessMessage("저장완료");
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onClickPrint = () => {
    handlePrint();
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: "거래명세서",
  });

  return (
    <Modal
      width="75%"
      close={onClose}
      title="거래명세서"
      modalButton={
        <>
          <Buttons
            permission="export"
            type="button"
            size="lg"
            width={80}
            layout="solid"
            color="primary100"
            label="메일"
            onClick={onClickMailModal}
          />
          <Buttons
            permission="export"
            type="button"
            size="lg"
            width={80}
            layout="solid"
            color="primary100"
            label="팩스"
            onClick={onClickFaxExport}
          />
          <Buttons
            permission="export"
            type="button"
            size="lg"
            width={90}
            layout="solid"
            color="grid"
            label="엑셀저장"
            onClick={onClickExcelExport}
          />
          <Buttons
            permission="export"
            type="button"
            size="lg"
            width={80}
            layout="solid"
            color="grid"
            label="프린트"
            onClick={onClickPrint}
          />
        </>
      }
    >
      <>
        {isLoading && <Loading type="page" text="파일 변환 중입니다..." />}
        <TradeSheetTable
          printRef={printRef}
          TradeSheetInput={TradeSheetInput}
          gridColumn={gridColumn}
          dataSource={gridSub.current.instance.getSelectedRowsData()}
          search={search}
          invoicerData={invoicerData}
          invoiceeData={invoiceeData}
          priceData={priceData}
          templateInfo={templateInfo}
        />
      </>
    </Modal>
  );
};

export default SpecificationModal;
