import * as yup from "yup";

export type CompanyType = {
  id?: number;
  companyName: string;
  companyNameEng?: string;
  bizNo: string;
  ceoName: string;
  zipCode?: string;
  address?: string;
  addressDetail?: string;
  bizType?: string;
  bizItem?: string;
  tel?: string;
  hp?: string;
  fax?: string;
  email?: string;
  bankCode?: string;
  bankNumber?: string;
  contactId?: string;
  contactPassword?: string;
  contactName?: string;
  remark?: string;
};

export const CompanyInputSchema = yup.object<CompanyType>({
  companyName: yup.string().required("회사명을 입력해주세요"),
  companyNameEng: yup.string().nullable(),
  bizNo: yup.string().required("사업자등록번호를 입력해주세요"),
  ceoName: yup.string().required("대표자명을 입력해주세요"),
  zipCode: yup.string().nullable(),
  address: yup.string().nullable(),
  addressDetail: yup.string().nullable(),
  bizType: yup.string().nullable(),
  bizItem: yup.string().nullable(),
  tel: yup.string().nullable(),
  hp: yup.string().nullable(),
  fax: yup.string().nullable(),
  email: yup.string().nullable(),
  bankCode: yup.string().nullable(),
  bankNumber: yup.string().nullable(),
  contactId: yup.string().nullable(),
  contactPassword: yup.string().nullable(),
  contactName: yup.string().nullable(),
  remark: yup.string().nullable(),
});

export const InitialCompanyUserListInput: CompanyType = {
  companyName: "",
  companyNameEng: undefined,
  bizNo: "",
  ceoName: "",
  zipCode: undefined,
  address: undefined,
  addressDetail: undefined,
  bizType: undefined,
  bizItem: undefined,
  tel: undefined,
  hp: undefined,
  fax: undefined,
  email: undefined,
  bankCode: undefined,
  bankNumber: undefined,
  contactId: undefined,
  contactPassword: undefined,
  contactName: undefined,
  remark: undefined,
};
