import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { selectedMenuSelector } from "@/state/menu";
import { isLoading } from "@/state/atoms/isLoading";
import * as S from "./Buttons.style";

export interface ButtonProps {
  type: "reset" | "submit" | "button" | undefined;
  permission?: "save" | "delete" | "export";
  width?: number;
  size: "xxsm" | "xsm" | "sm" | "md" | "lg" | "xlg" | "full";
  color:
    | "primary100"
    | "white100"
    | "black100"
    | "save"
    | "destructive"
    | "find"
    | "grid"
    | "gridSub";
  layout: "solid" | "text" | "outline" | "underline";
  label?: string;
  form?: string;
  onClick?: any;
  children?: React.ReactElement;
  icon?: React.ReactElement;
  disabled?: Boolean;
  isLoading?: any;
}

export const Buttons = (props: ButtonProps) => {
  const loading = useRecoilValue(isLoading);
  const { t } = useTranslation("main");
  const currentMenu = useRecoilValue(selectedMenuSelector);
  const { permission } = props;

  if (
    !permission ||
    (permission === "save" && currentMenu.isSave) ||
    (permission === "delete" && currentMenu.isDelete) ||
    (permission === "export" && currentMenu.isExport)
  ) {
    return (
      <S.Button
        type={props.type}
        size={props.size}
        width={props.width}
        color={props.color}
        layout={props.layout}
        onClick={props.onClick}
        form={props.form && props.form}
        disabled={loading || props.disabled}
      >
        <>
          {props.isLoading || loading ? (
            <div className="loader loader-1"></div>
          ) : (
            props.icon && props.icon
          )}
          {typeof props.label !== "string" && props.label}
          {typeof props.label === "string" && t(props.label)}
          {props.children && props.children}
        </>
      </S.Button>
    );
  }
};
