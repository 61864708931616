import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  Format,
  HeaderFilter,
  Scrolling,
  StateStoring,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import Grid from "@/components/atoms/Grid";
import { Buttons } from "@/components/atoms/Buttons";
import { InputDate, InputSelect, InputText } from "@/components/atoms/Inputs";
import { GridContent } from "@/components/organisms/Contents/GridContent";
import { InputMultiple } from "@/components/atoms/InputMultiple";

interface FinishManageUpperGridProps {
  bottomHeight?: number;
  gridMain: React.MutableRefObject<any>;
  mainData: any[] | undefined;
  searchSubmit: any;
  control: any;
  errors: any;
  company: any;
  onFocusedRowChanged: (e: any) => void;
  onRowDblClick: (e: any) => void;
  onClickOpenTaxModal: (e: any) => void;
}

const FinishManageUpperGrid = ({
  bottomHeight,
  gridMain,
  mainData,
  searchSubmit,
  control,
  errors,
  company,
  onFocusedRowChanged,
  onRowDblClick,
  onClickOpenTaxModal,
}: FinishManageUpperGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "ClosingLeftGrid";
  const [totalCount, setTotalCount] = useState<number>(0);

  return (
    <GridContent
      bottomHeight={bottomHeight}
      searchSection={
        <>
          <InputMultiple label="검색기간" direction="row">
            <>
              <InputSelect
                name="searchType"
                width={100}
                displayExpr="name"
                valueExpr="id"
                control={control}
                errors={errors}
                dataSource={[
                  { id: "0", name: "마감일자" },
                  { id: "1", name: "접수일자" },
                  { id: "2", name: "운송일자" },
                ]}
              />
              <InputDate
                name="firstDay"
                width={120}
                control={control}
                errors={errors}
              />
              ~
              <InputDate
                name="lastDay"
                width={120}
                control={control}
                errors={errors}
              />
            </>
          </InputMultiple>
          <InputSelect
            label="접수법인"
            name="receiveCo"
            width={140}
            displayExpr="companyName"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={[
              { id: "0", companyName: t("전체") },
              ...(company || []),
            ]}
          />

          <InputSelect
            label="월선택"
            name="month"
            width={80}
            displayExpr="name"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={[
              { id: "01", name: "1월" },
              { id: "02", name: "2월" },
              { id: "03", name: "3월" },
              { id: "04", name: "4월" },
              { id: "05", name: "5월" },
              { id: "06", name: "6월" },
              { id: "07", name: "7월" },
              { id: "08", name: "8월" },
              { id: "09", name: "9월" },
              { id: "10", name: "10월" },
              { id: "11", name: "11월" },
              { id: "12", name: "12월" },
            ]}
          />
          <InputSelect
            label="구분"
            name="isDivision"
            width={90}
            displayExpr="name"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={[
              { id: "0", name: t("전체") },
              { id: "청구처", name: "청구처" },
              { id: "용차사", name: "용차사" },
              { id: "기사", name: "기사" },
            ]}
          />
          <InputText
            label="청구처/용차사/차량번호"
            name="companyName"
            control={control}
            errors={errors}
          />
          <InputSelect
            label="발행상태"
            name="isFinish"
            width={90}
            displayExpr="name"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={[
              { id: "0", name: t("전체") },
              { id: "1", name: "발행" },
              { id: "2", name: "미발행" },
            ]}
          />
          <InputSelect
            label="계산서 상태"
            name="isTaxIssue"
            width={130}
            displayExpr="name"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={[
              { id: "", name: t("전체") },
              { id: "Publish-000", name: "미발행" },
              { id: "Publish-100", name: "발행" },
              { id: "Publish-150", name: "예약발행" },
              { id: "Publish-170", name: "수정발행" },
              { id: "Publish-180", name: "수정 예약발행" },
            ]}
          />
        </>
      }
      gridSectionButtons={
        <Buttons
          permission="save"
          type="button"
          size="sm"
          layout="outline"
          color="grid"
          label="세금계산서 발행"
          onClick={onClickOpenTaxModal}
        />
      }
      searchSubmit={searchSubmit}
      totalCount={totalCount}
      gridRef={gridMain}
      storageKey={storageKey}
    >
      <Grid
        refs={gridMain}
        dataSource={mainData}
        keyExpr="completeNo"
        onFocusedRowChanged={onFocusedRowChanged}
        onRowDblClick={onRowDblClick}
        focusedRowEnabled={true}
        onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
      >
        <HeaderFilter visible={true} />
        {/* <ColumnChooser enabled={true} /> */}
        <StateStoring
          enabled={true}
          type="custom"
          customLoad={useCallback(
            () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
            []
          )}
        />
        <Scrolling mode="virtual" />
        <Column dataField="division" caption={t("구분")} />
        <Column dataField="companyName" caption={t("청구처/용차사/차량번호")} />
        {/* 접수법인 */}
        <Column dataField="receiveCoName" caption={t("접수법인")} />
        {/* 마감번호 */}
        <Column dataField="completeNo" caption={t("마감번호")} />
        {/* 마감일자 */}
        <Column dataField="completeDate" caption={t("마감일자")} />
        {/* 상세건수 */}
        <Column dataField="detailTotal" caption={t("상세건수")} />
        {/* 정산상태 */}
        <Column dataField="closeStatus" caption={t("발행상태")} />
        {/* 정산예정일자 */}
        <Column dataField="taxSchedule" caption={t("정산일자")} />
        {/* 세금계산서번호 */}
        <Column dataField="finishNo" caption={t("세금계산서번호")} />
        {/* 계산서상태 */}
        <Column dataField="taxStatus" caption={t("계산서상태")} />
        {/* 금액 */}
        <Column dataField="price" caption={t("금액")} dataType="number">
          <Format type="fixedPoint" />
        </Column>
        {/* 세액 */}
        <Column dataField="taxTotal" caption={t("세액")} dataType="number">
          <Format type="fixedPoint" />
        </Column>
        {/* 합계금액 */}
        <Column
          dataField="priceTotal"
          caption={t("합계금액")}
          dataType="number"
        >
          <Format type="fixedPoint" />
        </Column>
        <Summary>
          <TotalItem
            column="price"
            summaryType="sum"
            valueFormat="fixedPoint"
            displayFormat={`${t("총 금액")} : {0}`}
          />
          <TotalItem
            column="taxTotal"
            summaryType="sum"
            valueFormat="fixedPoint"
            displayFormat={`${t("총 세액")} : {0}`}
          />
          <TotalItem
            column="priceTotal"
            summaryType="sum"
            valueFormat="fixedPoint"
            displayFormat={`${t("총 합계")} : {0}`}
          />
        </Summary>
      </Grid>
    </GridContent>
  );
};

export default FinishManageUpperGrid;
