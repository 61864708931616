import { useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import Grid from "@/components/atoms/Grid";
import { InputText } from "@/components/atoms/Inputs";
import { GridContent } from "@/components/organisms/Contents/GridContent";

interface UserGridProps {
  gridMain: any;
  mainData: any[] | undefined;
  searchForm: UseFormReturn<any>;
  toggleOpen: () => void;
  onSearch: (data: any) => void;
  onFocusedRowChanged: (e: any) => void;
  changePasswordBtnRender: (e: any) => JSX.Element;
}

const UserGrid = ({
  gridMain,
  mainData,
  searchForm,
  toggleOpen,
  onSearch,
  onFocusedRowChanged,
  changePasswordBtnRender,
}: UserGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "UserPage";
  const [totalCount, setTotalCount] = useState<number>(0);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = searchForm;

  return (
    <GridContent
      searchSection={
        <>
          <InputText
            label="사용자ID"
            name="userId"
            width={120}
            control={control}
            errors={errors}
          />
          <InputText
            label="사용자명"
            name="userName"
            width={80}
            control={control}
            errors={errors}
          />
        </>
      }
      searchSubmit={handleSubmit(onSearch)}
      gridRef={gridMain}
      storageKey={storageKey}
      totalCount={totalCount}
    >
      {/* 페이지 타이틀 라인 */}
      <>
        {/* <Button
            variety="new"
            shape="solid"
            size="sm"
            type="button"
            onClick={toggleOpen}
          >
            입력
          </Button> */}
        <Grid
          id="CompanyMaster"
          className="has-custom"
          refs={gridMain}
          dataSource={mainData}
          keyExpr="id"
          onFocusedRowChanged={onFocusedRowChanged}
          focusedRowEnabled={true}
          onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
        >
          <HeaderFilter visible={true} />
          <ColumnChooser enabled={true} />
          <Scrolling mode="infinite" />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(
              () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
              []
            )}
          />
          <Column
            caption="No"
            cellRender={(e) => e.row.loadIndex + 1}
            alignment="center"
            width={35}
          />
          <Column dataField="userId" caption={t("아이디")} width={120} />
          <Column dataField="userName" caption={t("사용자명")} width={100} />
          <Column dataField="englishName" caption={t("영문이름")} width={120} />
          <Column dataField="mail" caption={t("이메일")} width={160} />
          <Column
            dataField="hp"
            caption={t("휴대전화")}
            alignment="center"
            width={120}
          />
          <Column
            dataField="tel"
            caption={t("직통번호")}
            alignment="center"
            width={120}
          />
          <Column
            dataField="fax"
            caption={t("팩스번호")}
            alignment="center"
            width={120}
          />
          <Column
            dataField="userStatusName"
            caption={t("사용상태")}
            alignment="center"
            width={100}
          />
          <Column dataField="companyName" caption={t("소속법인")} width={120} />
          <Column dataField="deptName" caption={t("소속부서")} width={120} />
          <Column
            dataField="authGroupName"
            caption={t("권한 그룹")}
            alignment="center"
            width={100}
          />
          <Column
            dataField="dutyCodeName"
            caption={t("직책")}
            alignment="center"
            width={80}
          />
          <Column dataField="remark" caption={t("비고")} />
          <Column
            caption={t("비밀번호변경")}
            cellRender={changePasswordBtnRender}
          />
        </Grid>
      </>
    </GridContent>
  );
};

export default UserGrid;
