import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  Column,
  ColumnChooser,
  Editing,
  HeaderFilter,
  RequiredRule,
  Scrolling,
} from "devextreme-react/data-grid";
import useButtonLoading from "@/hooks/useButtonLoading";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import {
  useDeleteMutation,
  usePostMutation,
  usePutMutation,
} from "@/util/common.fn";
import { yupResolver } from "@hookform/resolvers/yup";
import Grid from "@/components/atoms/Grid";
import { InputText } from "@/components/atoms/Inputs";
import { Buttons } from "@/components/atoms/Buttons";
import { FormContent } from "@/components/organisms/Contents/FormContent";
import { GridContent } from "@/components/organisms/Contents/GridContent";
import { ContentTemplate } from "@/components/templates/ContentTemplate";
import ComCodeGrid from "./ComCodeGrid";
import { ReactComponent as IconCreate } from "@/styles/assets/svg/icon_button_create.svg";
import { ReactComponent as IconSave } from "@/styles/assets/svg/icon_button_save.svg";
import { ReactComponent as IconDelete } from "@/styles/assets/svg/icon_button_delete.svg";

const ComCodePage = () => {
  const { t } = useTranslation("main");
  const initialSearch = {
    groupCode: "",
    groupName: "",
  };
  const [search, setSearch] = useState(initialSearch);
  const [subTotalCount, setSubTotalCount] = useState<number>(0);
  const [selectedRowKey, setSelectedRowKey] = useState<string | null>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const gridMain = useRef<any>();
  const gridSub = useRef<any>();

  const { alertErrorMessage, alertSuccessMessage, confirmMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { data: mainData, refetch: mainRefetch } = useCustomQuery<any[]>(
    `comcode/master`,
    search
  );
  const { data: subData, refetch: subRefetch } = useCustomQuery(
    `comcode/detail`,
    {
      groupCode: selectedRowKey,
    },
    !!selectedRowKey
  );
  const { mutateAsync: create } = usePostMutation("comcode/master");
  const { mutateAsync: update } = usePutMutation("comcode/master");
  const { mutateAsync: remove } = useDeleteMutation("comcode/master");

  const schema = yup.object({
    groupCode: yup.string().nullable().required("그룹코드를 입력해주세요"),
    groupName: yup.string().nullable().required("그룹코드명을 입력해주세요"),
  });

  const {
    control,
    reset,
    trigger,
    watch,
    formState: { errors },
  } = useForm<{ groupCode: string; groupName: string }>({
    resolver: yupResolver(schema),
  });

  const searchForm = useForm({
    defaultValues: initialSearch,
  });

  const initPage = () => {
    const mainGridInstance = gridMain.current.instance;
    const subGridInstance = gridSub.current.instance;
    mainGridInstance.option("focusedRowIndex", -1);
    subGridInstance.option("focusedRowIndex", -1);
    resetGridChanges();
    setSelectedRowKey(null);
    mainRefetch();
    subRefetch();
    reset({
      groupCode: "",
      groupName: "",
    });
  };

  const resetGridChanges = () => {
    const mainGridInstance = gridMain.current.instance;
    const subGridInstance = gridSub.current.instance;
    mainGridInstance.option("editing.changes", []);
    subGridInstance.option("editing.changes", []);
  };

  const toggleOpen = () => setOpen(!isOpen);

  const onClickSave = async () => {
    if (!(await trigger())) return;
    try {
      setLoading();
      const mainGridInstance = gridMain.current.instance;
      const subGridInstance = gridSub.current.instance;

      const data = watch();
      const detail = subGridInstance.option("editing.changes");

      const originalRowKey = selectedRowKey;

      const detailIsValid = await subGridInstance
        .getController("validating")
        .validate(true);
      if (!detailIsValid) return;

      let res: any;

      if (selectedRowKey) {
        // update
        res = await update({
          master: data,
          detail: detail,
        });
      } else {
        // create
        res = await create({
          master: data,
          detail: detail,
        });
      }

      if (res.state === 1) {
        await alertSuccessMessage("저장완료");
      }

      initPage();
      mainGridInstance.option("focusedRowKey", originalRowKey);
    } catch (error: any) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onClickDelete = async () => {
    if (!selectedRowKey) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }

    try {
      setLoading();
      if (!(await confirmMessage("삭제하시겠습니까?"))) return;

      await remove({ key: selectedRowKey });

      await alertSuccessMessage("삭제완료");

      initPage();
    } catch (error: any) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onSearch = (data: any) => {
    setSearch(data);
    initPage();
  };

  const onFocusedRowChanged = (e: any) => {
    if (!e.row?.data) return;
    const data = e.row.data;
    resetGridChanges();
    setSelectedRowKey(data.groupCode);
    reset({
      groupCode: data.groupCode,
      groupName: data.groupName,
    });
  };

  const onToolbarPreparing = (e: any) => {
    var toolbarItems = e.toolbarOptions.items;

    toolbarItems.forEach((cur: any) => {
      if (cur.name === "saveButton" || cur.name === "revertButton") {
        cur.visible = false;
      }
    });
  };

  const headerCellRender = (data: any) => {
    return (
      <p style={{ color: "#3EFFBE", margin: "0" }}>{data.column.caption}</p>
    );
  };

  return (
    <ContentTemplate sideWidth={440}>
      <>
        <ComCodeGrid
          searchForm={searchForm}
          gridMain={gridMain}
          mainData={mainData}
          toggleOpen={toggleOpen}
          onSearch={onSearch}
          onFocusedRowChanged={onFocusedRowChanged}
        />

        <FormContent
          title={t("공통코드 등록")}
          titleButtons={
            <Buttons
              type="button"
              size="sm"
              layout="text"
              color="primary100"
              icon={<IconCreate />}
              label="신규입력"
              onClick={() => {
                initPage();
              }}
            />
          }
          inputForms={
            <>
              <InputText
                label="그룹코드"
                name="groupCode"
                direction="column"
                control={control}
                errors={errors}
                disabled={selectedRowKey}
              />
              <InputText
                label="그룹코드명"
                name="groupName"
                direction="column"
                control={control}
                errors={errors}
              />
            </>
          }
          inputFormGrid={2}
          formButtons={
            <>
              <Buttons
                permission="save"
                type="button"
                size="sm"
                layout="solid"
                color="save"
                icon={<IconSave />}
                label="저장"
                onClick={onClickSave}
              />
              <Buttons
                permission="delete"
                type="button"
                size="sm"
                layout="solid"
                color="destructive"
                icon={<IconDelete />}
                label="삭제"
                onClick={onClickDelete}
              />
            </>
          }
        >
          <>
            {/* <Buttons
              type="button"
              size="xxsm"
              layout="solid"
              color="primary100"
              icon={<MovePageIcon width={26} height={26} viewBox="0 0 24 24" />}
              label={t("뒤로")}
              onClick={toggleOpen}
            /> */}

            {/* detail 정보 보기 및 입력 */}
            <GridContent totalCount={subTotalCount} subGrid={true}>
              <Grid
                subGrid={true}
                keyExpr="id"
                refs={gridSub}
                dataSource={subData}
                focusedRowEnabled={false}
                onInitNewRow={(e: any) => (e.data.useYn = true)}
                onToolbarPreparing={onToolbarPreparing}
                onContentReady={(e: any) =>
                  setSubTotalCount(e.component.totalCount())
                }
              >
                <HeaderFilter visible={true} />
                <Editing
                  mode="batch"
                  allowAdding={true}
                  allowUpdating={true}
                  allowDeleting={true}
                />
                <ColumnChooser enabled={true} />
                <Scrolling mode="infinite" />

                <Column
                  width={70}
                  caption={t("No")}
                  cellRender={(e) => e.row.loadIndex + 1}
                />
                <Column
                  dataField="subCode"
                  caption={t("하위코드")}
                  allowEditing={true}
                  headerCellRender={headerCellRender}
                >
                  <RequiredRule message="하위코드를 입력해주세요" />
                </Column>
                <Column
                  dataField="subName"
                  caption={t("하위코드명")}
                  allowEditing={true}
                  headerCellRender={headerCellRender}
                >
                  <RequiredRule message="하위코드명을 입력해주세요" />
                </Column>
                <Column
                  dataField="extra1"
                  caption={t("여유필드1")}
                  allowEditing={true}
                />
                <Column
                  dataField="extra2"
                  caption={t("여유필드2")}
                  allowEditing={true}
                />
                <Column
                  dataField="extra3"
                  caption={t("여유필드3")}
                  allowEditing={true}
                />
                <Column
                  dataField="sortSeq"
                  caption={t("정렬순서")}
                  dataType="number"
                  allowEditing={true}
                  headerCellRender={headerCellRender}
                >
                  <RequiredRule message="정렬순서를 입력해주세요" />
                </Column>
                <Column
                  dataField="useYn"
                  caption={t("사용")}
                  dataType="boolean"
                  allowEditing={true}
                />
                <Column dataField="remark" caption={t("비고")} />
              </Grid>
            </GridContent>
          </>
        </FormContent>
      </>
    </ContentTemplate>
  );
};

export default ComCodePage;
