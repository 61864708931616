import { Controller } from "react-hook-form";
import { DateBox } from "devextreme-react";
import { Input, InputProps } from "..";

const InputDate = ({
  type,
  control,
  errors,
  name,
  label,
  mask,
  height,
  width,
  required,
  disabled = false,
  displayFormat = "yyyy-MM-dd",
  onValueChanged = () => {},
  maxLength,
  tabIndex,
  direction,
  className,
}: InputProps) => {
  return (
    <Input
      type="date"
      direction={direction ? direction : `row`}
      label={label && label}
      required={required}
      errors={errors && errors}
    >
      <Controller
        render={({ field: { onChange, value } }) => {
          if (value === "") value = null;

          return (
            <DateBox
              type="date"
              displayFormat={displayFormat}
              mask={mask}
              className={className}
              // useMaskBehavior={true}
              onValueChange={(value) => {
                onChange(value);
              }}
              onValueChanged={(value) => {
                onValueChanged(value);
              }}
              value={value}
              disabled={disabled}
              height={height}
              width={width}
              maxLength={maxLength}
              tabIndex={tabIndex}
            />
          );
        }}
        control={control}
        name={name}
        defaultValue={null}
      />
    </Input>
  );
};

export default InputDate;
