import { Layout01Side30 } from "@/components/Layout/Content/content.styles";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import {
  ForwarderInputSchema,
  ForwarderType,
  InitialForwarderInput,
} from "@/types/forwarder.type";
import {
  useDeleteMutation,
  usePostMutation,
  usePutMutation,
} from "@/util/common.fn";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ForwarderGrid from "./ForwarderGrid";
import ForwarderInput from "./ForwarderInput";
import useButtonLoading from "@/hooks/useButtonLoading";
import { ContentTemplate } from "@/components/templates/ContentTemplate";

const ForwarderPage = () => {
  const [search, setSearch] = useState({});
  const [selectedRowKey, setSelectedRowKey] = useState<string | null>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const gridMain = useRef<any>();

  const { alertErrorMessage, alertSuccessMessage, confirmMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { data: mainData, refetch: mainRefetch } = useCustomQuery<
    ForwarderType[]
  >(`forwarder`, search);

  const { mutateAsync: create } = usePostMutation("forwarder");
  const { mutateAsync: update } = usePutMutation("forwarder");
  const { mutateAsync: remove } = useDeleteMutation("forwarder");

  const searchForm = useForm();
  const inputForm = useForm({
    defaultValues: InitialForwarderInput,
    resolver: yupResolver(ForwarderInputSchema),
  });

  const { reset, watch, trigger } = inputForm;

  const initPage = () => {
    const mainGridInstance = gridMain.current.instance;
    mainGridInstance.option("focusedRowIndex", -1);
    resetGridChanges();
    setSelectedRowKey(null);
    mainRefetch();
    reset(InitialForwarderInput);
  };

  const resetGridChanges = () => {
    const mainGridInstance = gridMain.current.instance;
    mainGridInstance.option("editing.changes", []);
  };

  const toggleOpen = () => setOpen(!isOpen);

  const onClickNewData = () => {
    initPage();
  };
  const onClickSaveData = async () => {
    if (!(await trigger())) return;

    const data = watch();
    const mainGridInstance = gridMain.current.instance;
    const originalKey = selectedRowKey;

    let res: any;

    try {
      setLoading();
      if (selectedRowKey) {
        res = await update({ key: selectedRowKey, data });
      } else {
        res = await create(data);
      }

      if (res.state === 1) {
        initPage();
        await alertSuccessMessage("저장완료");
      }

      mainGridInstance.option("focusedRowKey", originalKey);
    } catch (error: any) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onClickDeleteData = async () => {
    if (!selectedRowKey) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }
    try {
      setLoading();
      if (!(await confirmMessage("삭제하시겠습니까?"))) return;

      await remove({ key: selectedRowKey });

      await alertSuccessMessage("삭제완료");

      initPage();
    } catch (error: any) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onSearch = (data: any) => {
    setSearch(data);
  };

  const onFocusedRowChanged = (e: any) => {
    if (!e.row?.data) return;
    const data = e.row.data;
    setSelectedRowKey(e.row.key);
    reset(data);
  };

  return (
    <ContentTemplate sideWidth={280}>
      <>
        <ForwarderGrid
          gridMain={gridMain}
          mainData={mainData}
          searchForm={searchForm}
          toggleOpen={toggleOpen}
          onSearch={onSearch}
          onFocusedRowChanged={onFocusedRowChanged}
        />
        <ForwarderInput
          selectedRowKey={selectedRowKey}
          inputForm={inputForm}
          toggleOpen={toggleOpen}
          onClickNewData={onClickNewData}
          onClickSaveData={onClickSaveData}
          onClickDeleteData={onClickDeleteData}
        />
      </>
    </ContentTemplate>
  );
};

export default ForwarderPage;
