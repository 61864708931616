import { useEffect } from "react";
import { UseFormReturn, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import { Buttons } from "@/components/atoms/Buttons";
import { InputCheckBox, InputDate, InputText } from "@/components/atoms/Inputs";
import InputNumber from "@/components/atoms/Inputs/InputNumber";
import { FormContent } from "@/components/organisms/Contents/FormContent";
import { ReactComponent as IconCreate } from "@/styles/assets/svg/icon_button_create.svg";
import { ReactComponent as IconSave } from "@/styles/assets/svg/icon_button_save.svg";
import DispatchItem from "@/components/atoms/DispatchItem";

interface DispatchInputProps {
  openinputbar: boolean;
  selectedRowKey: string | null;

  inputForm: UseFormReturn<any>;
  toggleOpen: () => void;
  onClickSaveData: () => Promise<void>;
}

const DISPATCH_STATUS = {
  접수: "62",
  배차등록: "78",
  배차확정: "79",
  운송완료: "80",
  장기보관: "80",
  창고작업완료: "4048",
  운송중: "4309",
};

const DispatchInput = ({
  openinputbar,
  selectedRowKey,

  inputForm,
  toggleOpen,
  onClickSaveData,
}: DispatchInputProps) => {
  const { t } = useTranslation("main");
  const { data } = useCustomQuery<{ masterDetail: any; dispatches: any[] }>(
    `dispatchNew/${selectedRowKey}`,
    {},
    !!selectedRowKey
  );

  const {
    getValues,
    control,
    formState: { errors },
    reset,
  } = inputForm;

  const dispatchFieldArray = useFieldArray({
    control,
    name: "dispatches", // 컨트롤하고자 하는 배열의 key값
  });

  const { fields, append } = dispatchFieldArray;

  useEffect(() => {
    data && reset({ ...data.masterDetail, dispatches: data.dispatches });
    // eslint-disable-next-line
  }, [data]);

  const onClickAppend = () => {
    const dispatches = getValues("dispatches");
    if (Array.isArray(dispatches)) {
      const lastDispatch = dispatches[dispatches.length - 1];

      append({
        startPoint: lastDispatch ? lastDispatch.endPoint : "",
        endPoint: "",
        dispatchStatus: DISPATCH_STATUS.접수,
        carTypeCode: "carNo",
        deliverDate: null,
        habul: 0,
      });
    }
  };

  return (
    <FormContent
      title={t("배차등록")}
      titleButtons={
        <Buttons
          permission="save"
          type="button"
          size="sm"
          layout="text"
          color="primary100"
          icon={<IconCreate />}
          label="신규입력"
          disabled={!selectedRowKey}
          onClick={onClickAppend}
        />
      }
      inputForms={
        <>
          <InputDate
            label="운송일자"
            name="deliverDate"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="컨테이너번호"
            name="containerNo"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="컨규격"
            name="containerType"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="Seal No"
            name="sealNo"
            direction="column"
            control={control}
            errors={errors}
          />

          <InputText
            label="작업시간"
            name="workTime"
            direction="column"
            control={control}
            errors={errors}
            mask="99:99:99"
          />
          <InputText
            label="도착지담당자"
            name="manager"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="도착지담당자연락처"
            name="pointManageContact"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputNumber
            label="청구금액"
            name="freight"
            direction="column"
            control={control}
            errors={errors}
          />

          <InputText
            label="화물중량"
            name="cargoWeight"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputDate
            label="반납마감일자"
            name="returnDate"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputDate
            label="청구일자"
            name="freightDate"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputCheckBox
            label="밥테일"
            name="bobtail"
            control={control}
            errors={errors}
          />

          <InputText
            label="특이사항"
            name="extraInfo"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="온도"
            name="temperature"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="습도"
            name="humidity"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="환풍구"
            name="vent"
            direction="column"
            control={control}
            errors={errors}
          />

          <InputText
            label="클래스"
            name="class"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="UN번호"
            name="unNo"
            direction="column"
            control={control}
            errors={errors}
          />
          {fields.map((dispatch, index) => (
            <DispatchItem
              dispatch={dispatch}
              index={index}
              inputForm={inputForm}
              dispatchFieldArray={dispatchFieldArray}
            />
          ))}
        </>
      }
      formButtons={
        <Buttons
          permission="save"
          type="button"
          size="sm"
          layout="solid"
          color="save"
          icon={<IconSave />}
          label="저장"
          onClick={onClickSaveData}
          disabled={!selectedRowKey}
        />
      }
    >
      {/* <Button
          className="input-back"
          variety="tran"
          size="xxsm"
          shape="solid"
          type="button"
          onClick={toggleOpen}
        >
          <MovePageIcon width={26} height={26} viewBox="0 0 24 24" />
          <span>{t("뒤로")}</span>
        </Button> */}
    </FormContent>
  );
};

export default DispatchInput;
