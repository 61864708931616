import { useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import Grid from "@/components/atoms/Grid";
import { InputText } from "@/components/atoms/Inputs";
import { GridContent } from "@/components/organisms/Contents/GridContent";

interface TerminalGridProps {
  gridMain: any;
  mainData: any[] | undefined;
  searchForm: UseFormReturn<any>;

  toggleOpen: () => void;
  onSearch: (data: any) => void;
  onFocusedRowChanged: (e: any) => void;
}

const TerminalGrid = ({
  gridMain,
  mainData,
  searchForm,
  toggleOpen,
  onSearch,
  onFocusedRowChanged,
}: TerminalGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "TerminalGrid";
  const [totalCount, setTotalCount] = useState<number>(0);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = searchForm;

  return (
    <GridContent
      searchSection={
        <InputText
          label="이름"
          name="twName"
          control={control}
          errors={errors}
        />
      }
      searchSubmit={handleSubmit(onSearch)}
      gridRef={gridMain}
      storageKey={storageKey}
      totalCount={totalCount}
    >
      <>
        {/* <Button
            variety="new"
            shape="solid"
            size="sm"
            type="button"
            onClick={toggleOpen}
          >
            입력
          </Button> */}

        <Grid
          refs={gridMain}
          dataSource={mainData || []}
          keyExpr="code"
          onFocusedRowChanged={onFocusedRowChanged}
          focusedRowEnabled={true}
          onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
        >
          <HeaderFilter visible={true} />
          <ColumnChooser enabled={true} />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(
              () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
              []
            )}
          />
          <Scrolling mode="virtual" />
          <Column dataField="code" caption={t("터미널코드")} width={140} />
          <Column dataField="division" caption={t("구분")} width={80} />
          <Column dataField="twName" caption={t("이름")} width={140} />
          <Column
            dataField="area"
            caption={t("지역")}
            alignment="center"
            width={70}
          />
          <Column dataField="tel" caption={t("대표번호")} width={120} />
          <Column dataField="fax" caption={t("팩스번호")} width={120} />
          <Column dataField="address" caption={t("주소")} width={260} />
          <Column dataField="address1" caption={t("상세주소")} width={260} />
          <Column dataField="remark" caption={t("비고")} alignment="center" />
        </Grid>
      </>
    </GridContent>
  );
};

export default TerminalGrid;
