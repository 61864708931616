import { useDropzone } from "react-dropzone";
import { Buttons } from "@/components/atoms/Buttons";
import { Modal } from "..";
import * as S from "./ShuttleExcelUploadModal.style";
import { ReactComponent as UploadButton } from "@/assets/svg/icon-upload-files.svg";

interface ShuttleExcelUploadModalProps {
  onClose: () => void;
  isLoading: boolean;
  processExcelData: (file: File[]) => void;
  onSave: () => Promise<void>;
}

const ShuttleExcelUploadModal = ({
  onClose,
  isLoading,
  processExcelData,
  onSave,
}: ShuttleExcelUploadModalProps) => {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    maxFiles: 1,
    accept: {
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "text/csv": [".csv"],
    },
    onDrop: (file) => {},
    onDropAccepted: (file) => {
      processExcelData(file);
    },
    onDropRejected: (e) => {
      alert(".csv, .xls, .xlsx파일 1개만 업로드 가능 합니다.");
    },
  });

  return (
    <Modal
      width="800px"
      close={onClose}
      title="엑셀업로드"
      modalButton={
        <Buttons
          permission="save"
          type="button"
          size="lg"
          width={80}
          layout="solid"
          color="save"
          label="저장"
          onClick={onSave}
          isLoading={isLoading}
        />
      }
    >
      <S.SEUModalContent>
        <div
          {...getRootProps({
            className: "dropzone",
          })}
        >
          <input {...getInputProps()} />
          {isDragAccept && <p>All files will be accepted</p>}
          {isDragReject && <p>Some files will be rejected</p>}
          {!isDragActive && (
            <p className="dropZone-tit">
              <UploadButton />
              <span>엑셀 파일을 선택하거나 드래그하여 이곳에 놓으십시오.</span>
            </p>
          )}
        </div>
        {acceptedFiles.length > 0 && acceptedFiles[0].name}
      </S.SEUModalContent>
    </Modal>
  );
};

export default ShuttleExcelUploadModal;
