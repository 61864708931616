import styled from "styled-components";

export const ECModalContent = styled.div``;

export const ECModalContentBox = styled.div`
  display: flex;
  align-items: Center;
  flex-direction: column;
  gap: 20px;

  ${ECModalContent} + ${ECModalContent} {
    padding-top: 20px;
    border-top: 1px solid ${(props) => props.theme.colors.black4};
  }
`;
