import React, { useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  HeaderFilter,
  StateStoring,
} from "devextreme-react/data-grid";
import Grid from "@/components/atoms/Grid";
import { InputText } from "@/components/atoms/Inputs";
import { GridContent } from "@/components/organisms/Contents/GridContent";

interface DeptPermissionGridProps {
  searchForm: UseFormReturn<any, any, undefined>;
  gridMain: React.MutableRefObject<any>;
  mainData: any[] | undefined;
  toggleOpen: () => void;
  onSearch: (data: any) => void;
  onFocusedRowChanged: (e: any) => void;
}

const DeptPermissionGrid = ({
  searchForm,
  gridMain,
  mainData,
  toggleOpen,
  onSearch,
  onFocusedRowChanged,
}: DeptPermissionGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "DeptPermission";
  const [mainTotalCount, setMainTotalCount] = useState<number>(0);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = searchForm;

  return (
    <GridContent
      searchSection={
        <>
          <InputText
            label="그룹명"
            name="authGroupName"
            width={120}
            control={control}
            errors={errors}
          />
        </>
      }
      searchSubmit={handleSubmit(onSearch)}
      gridRef={gridMain}
      storageKey={storageKey}
      totalCount={mainTotalCount}
    >
      <>
        {/* <ButtonGroup className="mobile-button-set">
          <Button
            variety="new"
            shape="solid"
            size="sm"
            type="button"
            onClick={toggleOpen}
          >
            입력
          </Button>
        </ButtonGroup> */}

        <Grid
          id={storageKey}
          keyExpr="id"
          refs={gridMain}
          dataSource={mainData}
          focusedRowEnabled={true}
          onFocusedRowChanged={onFocusedRowChanged}
          onContentReady={(e: any) =>
            setMainTotalCount(e.component.totalCount())
          }
        >
          <HeaderFilter visible={true} />
          <ColumnChooser enabled={true} />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(
              () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
              []
            )}
          />
          <Column
            caption="No"
            cellRender={(e: any) => e.row.loadIndex + 1}
            alignment="center"
            width={35}
          />
          <Column dataField="authGroupName" caption={t("그룹명")} width={120} />
          <Column dataField="remark" caption={t("비고")} />
        </Grid>
      </>
    </GridContent>
  );
};

export default DeptPermissionGrid;
