import { AuthForm } from "@/components/organisms/AuthForm";
import { AuthTemplate } from "@/components/templates/AuthTemplate";

const Login = () => {
  return (
    <AuthTemplate>
      <AuthForm />
    </AuthTemplate>
  );
};
export default Login;
