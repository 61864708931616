import InputText from "../InputText";
import { Buttons } from "@/components/atoms/Buttons";
import * as S from "./InputAddress.style";

const InputAddress = ({
  control,
  errors,
  openPost,
  direction,
  zipCode = "zipCode",
  address = "address",
  address1 = "address1",
  disabled = true,
}: any) => {
  return (
    <S.AddressBox>
      <S.AddressLabel direction={direction}>
        주소
        <S.AddressPostalCode>
          <InputText
            width={100}
            direction={direction}
            control={control}
            errors={errors}
            name={zipCode}
            disabled={disabled}
            placeHolder="우편번호"
          />
          <Buttons
            type="button"
            size="md"
            layout="solid"
            color="find"
            label="우편번호 검색"
            onClick={openPost}
          />
        </S.AddressPostalCode>
      </S.AddressLabel>
      <InputText
        direction={direction}
        control={control}
        errors={errors}
        name={address}
        disabled={disabled}
        placeHolder="주소"
      />
      <InputText
        direction={direction}
        control={control}
        errors={errors}
        name={address1}
        placeHolder="상세주소 입력"
      />
    </S.AddressBox>
  );
};

export default InputAddress;
