import React from "react";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  HeaderFilter,
  Scrolling,
} from "devextreme-react/data-grid";
import { AccountExcelFileOptions } from "@/types/accountExcel.type";
import Grid from "@/components/atoms/Grid";
import { Buttons } from "@/components/atoms/Buttons";
import { DetailContent } from "@/components/organisms/Contents/DetailContent";

interface AccountExcelLowerGridProps {
  gridSub: React.MutableRefObject<any>;
  subGrid: any[] | undefined;
  isLoading: boolean;
  onClickFailExport: () => void;
}

const AccountExcelLowerGrid = ({
  gridSub,
  subGrid,
  isLoading,
  onClickFailExport,
}: AccountExcelLowerGridProps) => {
  const { t } = useTranslation("main");
  return (
    <DetailContent
      detailSectionButtons={
        <Buttons
          permission="export"
          type="button"
          size="sm"
          layout="outline"
          color="grid"
          label="실패내역 다운로드"
          onClick={onClickFailExport}
          isLoading={isLoading}
        />
      }
    >
      <Grid detailGrid={true} refs={gridSub} dataSource={subGrid}>
        <HeaderFilter visible={true} />
        {/* <ColumnChooser enabled={true} /> */}
        <Scrolling mode="virtual" />
        {AccountExcelFileOptions.map((opt, index) => (
          <Column key={index} dataField={opt.column} caption={t(opt.title)} />
        ))}
        <Column dataField="message" caption={t("메세지")} width="300" />
      </Grid>
    </DetailContent>
  );
};

export default AccountExcelLowerGrid;
