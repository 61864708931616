import styled, { css } from "styled-components";

export const ModalTaxIssueWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  word-break: break-all;
  border-collapse: separate;
  border-top: 2px solid ${(props) => props.theme.colors.black50};
  border-bottom: 2px solid ${(props) => props.theme.colors.black50};
  border-spacing: 0;
  flex-direction: column;

  .readonly {
    background-color: #eaeaea !important;

    textarea {
      background-color: #eaeaea !important;
    }
  }

  @media print {
    @page {
      margin: 1.3cm 1.3cm;
    }
  }
`;

export const ModalTaxIssueHeader = styled.div<{
  tradeset?: any;
  modalHeader?: any;
}>`
  display: flex;
  width: 100%;
  padding: ${(props) => (props.tradeset ? "10px" : "5px 10px")};
  color: ${(props) => props.theme.colors.black100};
  background: ${(props) => props.theme.colors.white100};
  justify-content: space-between;

  ${(props) =>
    props.modalHeader &&
    css`
      overflow: hidden;
      height: 0;
      padding: 0;
      font-size: 24pt;
      font-weight: 600;
      color: ${(props) => props.theme.colors.black100};
      background: ${(props) => props.theme.colors.white};
      justify-content: center;

      @media print {
        height: auto;
        padding: 10px;
        border-bottom: 2px solid ${(props) => props.theme.colors.black50};
      }
    `}
`;

export const ModalTaxIssueTitle = styled.span<{ tradeset: any }>`
  display: flex;
  width: ${(props) => (props.tradeset ? "auto" : "100%")};
  height: 100%;
  font-size: ${(props) => (props.tradeset ? "1.3rem" : "2.8rem")};
  font-weight: ${(props) => (props.tradeset ? "400" : "600")};
  align-items: center;
  justify-content: ${(props) => (props.tradeset ? "flex-start" : "center")};

  span {
    font-size: 2rem;
    font-weight: 500;
  }

  @media print {
    font-size: 11.5pt !important;
  }
`;

export const ModalTaxIssueSubtit = styled.span`
  display: flex;

  @media print {
    font-size: 11.5pt !important;
  }
`;

export const ModalTaxIssueBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
`;

export const TradesetWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const Tradeset = styled.table`
  width: 50%;
  border-top: 2px solid ${(props) => props.theme.colors.black50};
  border-bottom: 2px solid ${(props) => props.theme.colors.black50};
  border-collapse: collapse;

  & + table {
    border-left: 1px solid ${(props) => props.theme.colors.black50};
  }
`;

export const TradesetBody = styled.tbody``;

export const TradesetRows = styled.tr`
  border-bottom: 1px solid ${(props) => props.theme.colors.black12};
`;

export const TradesetItems = styled.td`
  padding: 3px;
  text-align: center;
  vertical-align: middle;

  &.tradeset-tit {
    color: ${(props) => props.theme.colors.black100};
    font-weight: bold;
    line-height: 2rem;
    border-right: 1px solid;
    border-color: ${(props) => props.theme.colors.black12};
    background-color: ${(props) => props.theme.colors.white100};
  }

  &.item-tit {
    color: ${(props) => props.theme.colors.black100};
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: ${(props) => props.theme.colors.black12};
    background-color: ${(props) => props.theme.colors.white100};
  }

  & > div {
    grid-template-columns: 100%;

    .dx-texteditor-input-container {
      height: 25px;
    }
  }

  .item-input {
    display: flex;
    min-height: 25px;
    align-items: center;
    justify-content: center;
  }

  @media print {
    min-height: 50px;
    border-bottom: 1px solid #000;
    font-size: 12pt;

    &.item-tit {
      width: 70px;
      font-size: 12pt;
    }

    .item-input {
      overflow: hidden;
      width: 100%;
      min-height: 36px !important;
      font-size: 11.5pt !important;

      input {
        min-height: 36px !important;
        font-family: "Spoqa Han Sans", sans-serif;
        font-size: 11.5pt !important;
        text-align: center;
        letter-spacing: -0.07rem;
      }
    }
  }
`;

export const AdditionalWrapper = styled.div<{ tradeset: any }>`
  display: grid;
  width: 100%;
  margin: ${(props) => (props.tradeset ? "0 0 10px" : "0")};
  border-top: 2px solid ${(props) => props.theme.colors.black50};
  border-bottom: 2px solid ${(props) => props.theme.colors.black50};
  grid-template-columns: ${(props) => (props.tradeset ? "100%" : "120px 1fr")};
  grid-template-rows: 1fr;

  .dx-widget.sub-grid .dx-datagrid-headers {
    border-radius: 0px;

    td {
      background: ${(props) => props.theme.colors.black50};
    }
  }

  .dx-datagrid-rowsview {
    border-radius: 0px;
  }
`;

export const TotalWrapper = styled.div`
  -webkit-print-color-adjust: exact;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
  border-top: 2px solid ${(props) => props.theme.colors.black50};
  border-bottom: 2px solid ${(props) => props.theme.colors.black50};

  .total-tit {
    display: flex;
    padding: 0 10px;
    color: ${(props) => props.theme.colors.white100};
    font-weight: bold;
    align-items: center;
    background: ${(props) => props.theme.colors.black50};
    grid-row: 1/4;
  }

  .total-items {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);

    & + .total-items {
      border-top: 1px solid ${(props) => props.theme.colors.black12};
    }

    & > div {
      padding: 3px;

      & > div {
        width: 100%;
        grid-template-columns: 100% !important;
      }
    }

    .item-tit {
      display: flex;
      padding: 0px 10px;
      align-items: center;
      border-left: 1px solid ${(props) => props.theme.colors.black12};
      border-right: 1px solid ${(props) => props.theme.colors.black12};
      background: ${(props) => props.theme.colors.white100};
    }
  }

  @media print {
    .total-tit,
    .item-tit {
      font-size: 11.5pt !important;
    }

    .dx-state-disabled input {
      font-size: 11.5pt !important;
    }
  }
`;

export const ModalTaxIssueFooter = styled.div<{ tradeset: any }>`
  display: grid;
  width: 100%;
  padding: ${(props) => (props.tradeset ? "10px" : "5px 10px")};
  color: #000;
  background: ${(props) => props.theme.colors.white};
  grid-template-columns: ${(props) => (props.tradeset ? "5fr 1fr" : "100%")};
  grid-template-rows: 1fr;
`;
