import dayjs from "dayjs";
import * as yup from "yup";

export interface FinishSearchType {
  receiveCo: string;
  searchType: string;
  firstDay: string;
  lastDay: string;
  isDivision: string;
  isFinish: string;
  isTaxIssue: string | undefined;
  companyName: string;
}

export const InitFinishSearch: FinishSearchType = {
  receiveCo: "0",
  searchType: "0",
  firstDay: dayjs().subtract(14, "days").format("YYYY-MM-DD"),
  lastDay: dayjs().format("YYYY-MM-DD"),
  isDivision: "0",
  isFinish: "0",
  isTaxIssue: undefined,
  // dispatchTeam: "0",
  companyName: "",
};

function LF_RequiredText(text: string) {
  return text + " 입력하세요";
}

export const TaxInputSchema = yup.object().shape({
  kwon: yup.string().nullable().max(4, "4자 이하"),
  ho: yup.string().nullable().max(4, "4자 이하"),
  serialNum: yup.string().nullable().max(27, "27자 이하"),

  // 공급자
  corpNum: yup.string().nullable().required(),
  taxRegID: yup.string().nullable().max(4, "4자 이하"),
  corpName: yup
    .string()
    .nullable()
    .required(LF_RequiredText("상호명을"))
    .max(70, "70자 이하"),
  ceoName: yup
    .string()
    .nullable()
    .required(LF_RequiredText("대표자명을"))
    .max(30, "30자 이하"),
  addr: yup
    .string()
    .nullable()
    .required(LF_RequiredText("주소를"))
    .max(150, "150자 이하"),
  bizClass: yup.string().nullable().max(40, "40자 이하"),
  bizType: yup.string().nullable().max(40, "40자 이하"),
  // Invoicer_ContactID: yup.string().required(),
  // Invoicer_TEL: yup.string().nullable(),
  contactID: yup.string().nullable(),
  contactName: yup
    .string()
    .nullable()
    .required(LF_RequiredText("담당자명을"))
    .max(30, "30자 이하"),
  tel: yup.string().nullable().max(20, "20자 이하"),
  email: yup
    .string()
    .nullable()
    .required(LF_RequiredText("이메일을"))
    .max(40, "40자 이하"),

  // 공급받는자
  corpNum_R: yup.string().nullable().required(),
  taxRegID_R: yup.string().nullable().max(4, "4자 이하"),
  corpName_R: yup
    .string()
    .nullable()
    .required(LF_RequiredText("상호명을 "))
    .max(70, "70자 이하"),
  ceoName_R: yup
    .string()
    .nullable()
    .required(LF_RequiredText("대표자명을"))
    .max(30, "30자 이하"),
  addr_R: yup
    .string()
    .nullable()
    .required(LF_RequiredText("주소를"))
    .max(150, "150자 이하"),
  bizClass_R: yup.string().nullable().max(40, "40자 이하"),
  bizType_R: yup.string().nullable().max(40, "40자 이하"),
  // Invoicee_ContactID: yup.string().required(),
  // Invoicee_TEL: yup.string().nullable(),
  contactID_R: yup.string().nullable(),
  contactName_R: yup
    .string()
    .nullable()
    .required(LF_RequiredText("담당자명을"))
    .max(30, "30자 이하"),
  tel_R: yup.string().nullable().max(20, "20자 이하"),
  email_R: yup
    .string()
    .nullable()
    .required(LF_RequiredText("이메일을"))
    .max(40, "40자 이하"),

  // 작성일자, 공급가액, 세액, 합계금액
  writeDate: yup.string().required(),
  amountTotal: yup.number().required(),
  taxTotal: yup.number().required(),
  totalAmount: yup.number().required(),

  // 비고
  remark: yup.array().of(
    yup.object().shape({
      remark: yup.string().nullable().max(150, "150자 이하"),
    })
  ),

  // 세부내용

  TaxInvoiceTradeLineItem: yup.array().of(
    yup.object().shape({
      barobill_tax_main_oid: yup.string().nullable(),
      purchaseExpiryYear: yup.string().nullable(),
      purchaseExpiryMonth: yup.string().nullable(),
      purchaseExpiryDay: yup.string().nullable(),
      name: yup.string().nullable().max(100, "100자 이하"),
      information: yup.string().nullable().max(60, "60자 이하"),
      chargeableUnit: yup.number().nullable().max(12, "12자 이하"),
      unitPrice: yup.number().nullable().max(18, "18자 이하"),
      amount: yup.number().nullable().max(18, "18자 이하"),
      tax: yup.number().nullable().max(18, "18자 이하"),
      description: yup.string().nullable().max(40, "40자 이하"),
    })
  ),

  // 영수청구

  cash: yup.string().nullable(),
  chkBill: yup.string().nullable(),
  note: yup.string().nullable(),
  credit: yup.string().nullable(),
  purposeType: yup.string(),
});

export interface TaxInputType extends yup.InferType<typeof TaxInputSchema> {
  orderCode?: string;
}

export const InitialTaxData: TaxInputType = {
  kwon: "",
  ho: "",
  serialNum: "",
  corpNum: "",
  taxRegID: "",
  corpName: "",
  ceoName: "",
  addr: "",
  bizClass: "",
  bizType: "",
  contactName: "",
  tel: "",
  email: "",
  corpNum_R: "",
  taxRegID_R: "",
  corpName_R: "",
  ceoName_R: "",
  addr_R: "",
  bizClass_R: "",
  bizType_R: "",
  contactName_R: "",
  tel_R: "",
  email_R: "",
  writeDate: "",
  amountTotal: 0,
  taxTotal: 0,
  totalAmount: 0,
  remark: [],
  TaxInvoiceTradeLineItem: [],
};
