import React, { useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  Format,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import { useCompanyData } from "@/hooks/queries";
import Grid from "@/components/atoms/Grid";
import { InputSelect } from "@/components/atoms/Inputs";
import InputMonth from "@/components/atoms/Inputs/InputMonth";
import { GridContent } from "@/components/organisms/Contents/GridContent";

interface CalculateProfitUpperGridProps {
  bottomHeight?: number;
  gridMain: React.MutableRefObject<any>;
  mainData: any[] | undefined;
  searchForm: UseFormReturn<any, any, undefined>;
  onSearch: (data: any) => void;
}

const CalculateProfitUpperGrid = ({
  bottomHeight,
  gridMain,
  mainData,
  searchForm,
  onSearch,
}: CalculateProfitUpperGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "CalculateProfitUpperGrid";
  const [totalCount, setTotalCount] = useState<number>(0);

  const { data: company } = useCompanyData();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = searchForm;

  return (
    <GridContent
      bottomHeight={bottomHeight}
      searchSection={
        <>
          <InputSelect
            label="접수법인"
            name="receiveCo"
            width={140}
            displayExpr="companyName"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={[
              { id: "0", companyName: t("전체") },
              ...(company || []),
            ]}
          />

          {/* 기준 월 */}
          <InputMonth
            label="기준 월"
            name="baseMonth"
            width={100}
            control={control}
            errors={errors}
          />
        </>
      }
      searchSubmit={handleSubmit(onSearch)}
      totalCount={totalCount}
      gridRef={gridMain}
      storageKey={storageKey}
    >
      {/* 메인 그리드 라인 */}
      <>
        <Grid
          refs={gridMain}
          dataSource={mainData}
          keyExpr="id"
          focusedRowEnabled={true}
          onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
        >
          <HeaderFilter visible={true} />
          <ColumnChooser enabled={true} />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(
              () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
              []
            )}
          />
          <Scrolling mode="virtual" />
          <Column dataField="receiveCoName" caption={t("접수법인")} />
          <Column dataField="division" caption={t("구분")} />
          <Column
            dataField="companyName"
            caption={t("청구처/용차사/차량번호")}
          />
          <Column
            dataField="countTotal"
            caption={t("총건수")}
            dataType="number"
          />
          <Column
            dataField="totalPriceBilling"
            caption={t("매출액")}
            dataType="number"
          >
            <Format type="fixedPoint" />
          </Column>
          <Column
            dataField="totalPriceOther"
            caption={t("매입액")}
            dataType="number"
          >
            <Format type="fixedPoint" />
          </Column>
        </Grid>
      </>
    </GridContent>
  );
};

export default CalculateProfitUpperGrid;
