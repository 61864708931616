import { useTranslation } from "react-i18next";
import * as S from "./TabItems.style";
import { useSetRecoilState } from "recoil";
import { selectedMenuSelector } from "@/state/menu";
import { Buttons } from "../Buttons";
import { ReactComponent as IconClose } from "@/styles/assets/svg/icon_tab_close.svg";

interface TabItemsProps {
  item?: any;
}

export const TabItems = ({ item }: TabItemsProps) => {
  const { t } = useTranslation("main");
  const setMenu = useSetRecoilState(selectedMenuSelector);

  const onRemoveTab = (id: string) => {
    setMenu({ id: id, isClose: true });
  };

  return (
    <S.TabItems isSelected={item.isSelected}>
      <S.TabItemTitle onClick={() => setMenu({ id: item.id })}>
        {item.isSelected && <S.TabItemStatus />}
        <span>{t(item.menuName)}</span>
      </S.TabItemTitle>

      <Buttons
        type="button"
        size="xxsm"
        color="black100"
        layout="text"
        onClick={() => onRemoveTab(item.id)}
      >
        <IconClose />
      </Buttons>
    </S.TabItems>
  );
};
