export const DISPATCH_DATA = "DISPATCH_DATA";
export const COMPANY_DATA = "COMPANY_DATA";
export const BILLING_DATA = "BILLING_DATA";
export const CARGO_OWNER_DATA = "CARGO_OWNER_DATA";
export const ORDER_CO_DATA = "ORDER_CO_DATA";
export const YONGCHASA_DATA = "YONGCHASA_DATA";
export const COMCODE_DATA = "COMCODE_DATA";
export const DRIVER_DATA = "DRIVER_DATA";
export const FORWARDER_DATA = "FORWARDER_DATA";
export const TERMINAL_DATA = "TERMINAL_DATA";
export const USERLIST_DATA = "USERLIST_DATA";
export const COPINO_SEND_DATA = "COPINO_DATA";

// 공통코드
export const 부서 = 10100;
export const 할증코드 = 10300;
export const 컨테이너_규격 = 10400;
export const 컨테이너_수량_단위 = 10450;
export const 사용자코드구분 = 10500;
export const 사용자구분 = 10550;
export const 거래처구분 = 10600;
export const 결제일자 = 10650;
export const 하불구분 = 10700;
export const 컨테이너비용구분 = 10800;
export const 오더마스터색상 = 10900;
export const 직책 = 11600;
export const 신용등급 = 20100;
export const 거래처_종류 = 20200;
export const 업무대행 = 20500;
export const 사용자_활동상태_구분 = 20600;
export const 사업자종류 = 20700;
export const 터미널_창고_작업지_구분 = 30100;
export const 주차장 = 30200;
export const 샤시종류 = 30300;
export const 샤시구분 = 30400;
export const 차량종류 = 30500;
export const 차량종류2 = 30510;
export const 차량톤수 = 30520;
export const 샤시소속구분 = 30600;
export const 샤시_활동상태_구분 = 30700;
export const 차량구분 = 30800;
export const 결제유형 = 30900;
export const 업무분류 = 30950;
export const 작업지시상태_배차 = 40100;
export const 작업지시상태_디테일 = 40200;
export const 배차차량타입 = 40300;
export const 지역 = 50100;
export const 시군구 = 50200;
export const 은행 = 60100;
export const 결제_주기 = 60150;
export const 정산상태 = 60200;
export const 계산서_상태 = 60300;
export const 마감상태 = 60400;
export const 품목 = 70100;
export const 사용여부 = 70200;
export const 통장내역은행코드 = 70700;
