import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  InputCheckBox,
  InputDate,
  InputRadioBox,
  InputSelect,
  InputText,
} from "@/components/atoms/Inputs";
import { InputMultiple } from "@/components/atoms/InputMultiple";
import { Buttons } from "@/components/atoms/Buttons";
import { FormContent } from "@/components/organisms/Contents/FormContent";
import { ReactComponent as IconCreate } from "@/styles/assets/svg/icon_button_create.svg";
import { ReactComponent as IconSave } from "@/styles/assets/svg/icon_button_save.svg";
import { ReactComponent as IconDelete } from "@/styles/assets/svg/icon_button_delete.svg";

interface OrderInputProps {
  bottomHeight?: number;
  openinputbar: boolean;

  company: any[] | undefined;
  billing: any[] | undefined;
  cargoOwner: any[] | undefined;
  orderCo: any[] | undefined;
  forwarder: any[] | undefined;
  isDispatch: any[] | undefined;
  color: any[] | undefined;

  inputForm: UseFormReturn<any>;
  toggleOpen: () => void;
  onClickUnipass: () => Promise<void>;
  onClickNewData: () => void;
  onClickConfirmSaveData: () => Promise<void>;
  onClickDeleteData: () => Promise<void>;
}

const OrderInput = ({
  bottomHeight,
  openinputbar,

  company,
  billing,
  cargoOwner,
  orderCo,
  forwarder,
  isDispatch,
  color,

  inputForm,
  toggleOpen,
  onClickUnipass,
  onClickNewData,
  onClickConfirmSaveData,
  onClickDeleteData,
}: OrderInputProps) => {
  const { t } = useTranslation("main");
  const {
    control,
    formState: { errors },
  } = inputForm;

  return (
    <FormContent
      bottomHeight={bottomHeight && bottomHeight}
      title={t("오더접수")}
      titleButtons={
        <Buttons
          permission="save"
          type="button"
          size="sm"
          layout="text"
          color="primary100"
          icon={<IconCreate />}
          label="신규입력"
          onClick={onClickNewData}
        />
      }
      inputForms={
        <>
          <InputText
            label="접수번호"
            name="code"
            direction="column"
            control={control}
            errors={errors}
            disabled={true}
          />
          <InputSelect
            label="수입/수출"
            name="importExport"
            direction="column"
            displayExpr="name"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={[
              { id: 1, name: t("수입") },
              { id: 2, name: t("수출") },
            ]}
          />
          <InputSelect
            label="접수법인"
            name="receiveCo"
            direction="column"
            displayExpr="companyName"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={company}
          />
          <InputDate
            label="접수일자"
            name="receiveDate"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputSelect
            label="배차팀"
            name="dispatchTeam"
            direction="column"
            displayExpr="deptName"
            valueExpr="code"
            control={control}
            errors={errors}
            dataSource={isDispatch}
          />
          <InputSelect
            label="청구처"
            name="billingPlace"
            direction="column"
            displayExpr="company"
            valueExpr="oid"
            control={control}
            errors={errors}
            dataSource={billing}
            required={true}
          />
          <InputSelect
            label="발주처"
            name="orderCo"
            direction="column"
            displayExpr="company"
            valueExpr="oid"
            control={control}
            errors={errors}
            dataSource={orderCo}
          />
          <InputSelect
            label="선사"
            name="forwarder"
            direction="column"
            displayExpr="forwarder_Name"
            valueExpr="code"
            control={control}
            errors={errors}
            dataSource={forwarder}
          />
          <InputText
            label="선명"
            name="shipName"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="모선/항차"
            name="motherShip"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputDate
            label="선사반출기한"
            name="dem"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="청구처담당자"
            name="manager"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputDate
            label="입항일자"
            name="enterDay"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputDate
            label="출항일자"
            name="sailDay"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="B/K No"
            name="bkNo"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="DO No"
            name="doNo"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="B/L No"
            name="blNo"
            direction="column"
            control={control}
            errors={errors}
          />

          <InputRadioBox
            label="B/L 구분"
            name="blType"
            direction="column"
            displayExpr="name"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={[
              { id: 1, name: "M" },
              { id: 2, name: "H" },
            ]}
          />
          <Buttons
            permission="save"
            type="button"
            size="md"
            layout="solid"
            color="find"
            label="UNI-PASS 연동"
            onClick={onClickUnipass}
          />
          <InputSelect
            label="화주"
            name="cargoOwner"
            direction="column"
            displayExpr="company"
            valueExpr="oid"
            control={control}
            errors={errors}
            dataSource={cargoOwner}
          />
          <InputText
            label="실화주"
            name="realCargoOwner"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputSelect
            label="색상"
            name="color"
            direction="column"
            displayExpr="subName"
            valueExpr="subCode"
            control={control}
            errors={errors}
            dataSource={color}
          />
          <InputMultiple label="특이사항" direction="column">
            <>
              <InputCheckBox
                text="냉동"
                name="fridge"
                control={control}
                errors={errors}
              />
              <InputCheckBox
                text="위험물"
                name="danger"
                control={control}
                errors={errors}
              />
              <InputCheckBox
                text="탱크"
                name="tank"
                control={control}
                errors={errors}
              />
              <InputCheckBox
                text="직반입"
                name="direct"
                control={control}
                errors={errors}
              />
              <InputCheckBox
                text="유해화학물질"
                name="toxic"
                control={control}
                errors={errors}
              />
              <InputCheckBox
                text="보관"
                name="keep"
                control={control}
                errors={errors}
              />
            </>
          </InputMultiple>
          <InputText
            label="비고"
            name="remark"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="내부메모"
            name="innerMemo"
            direction="column"
            control={control}
            errors={errors}
          />
        </>
      }
      formButtons={
        <>
          <Buttons
            permission="delete"
            type="button"
            size="sm"
            layout="solid"
            color="destructive"
            icon={<IconDelete />}
            label="삭제"
            onClick={onClickDeleteData}
          />
          <Buttons
            permission="save"
            type="button"
            size="sm"
            layout="solid"
            color="save"
            icon={<IconSave />}
            label="저장"
            onClick={onClickConfirmSaveData}
          />
        </>
      }
    >
      {/* <Buttons
          type="button"
          size="xxsm"
          layout="solid"
          color="primary100"
          icon={<MovePageIcon width={26} height={26} viewBox="0 0 24 24" />}
          label={t("뒤로")}
          onClick={toggleOpen}
        /> */}
    </FormContent>
  );
};

export default OrderInput;
