import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { readAccessToken } from "./localStorage";

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  async (config) => {
    // localStorage에서 accessToken 호출
    const accessToken = readAccessToken();
    const ip = localStorage.getItem("userIP");

    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
    if (ip) config.headers.ip = ip;

    return config;
  },
  (error) => Promise.reject(error)
);

// const setupAxios = (userData: userType) => {
// api.interceptors.request.use(
//   async (config) => {
//     // localStorage에서 accessToken 호출
//     const accessToken = readAccessToken();
//     const ip = localStorage.getItem("userIP");

//     if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
//     if (ip) config.headers.ip = ip;
//     if (userData) config.headers.userid = userData.userId;

//     return config;
//   },
//   (error) => Promise.reject(error)
// );
// };

// api.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   async (error) => {
//     const originalRequest = error.config;

//     // 401에러 일 시
//     if (error.response?.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       // localStorage에서 accessToken, refresh 호출
//       const jwtToken = readAccessToken();
//       const refreshToken = readRefreshToken();
//       if (!refreshToken) {
//         alert("로그인해주세요");
//         return logout();
//       }
//       try {
//         // 토큰 재요청
//         const { data } = await axios.post(
//           `${process.env.REACT_APP_API_URL}/user/refresh-token`,
//           {
//             refreshToken,
//           },
//           {
//             headers: {
//               Authorization: `Bearer ${jwtToken}`,
//             },
//           }
//         );

//         // localStorage에 jwtToken, refreshToken 저장
//         const accessTokenData = data?.authToken;
//         writeAccessToken(accessTokenData);

//         return api(originalRequest);
//       } catch (e) {
//         alert("토큰이 만료되었습니다. 다시 로그인해주세요");
//         return logout();
//       }
//     }

//     return Promise.reject(error);
//   }
// );

const request = async <T>(config: AxiosRequestConfig): Promise<T> => {
  try {
    const { data } = await api.request<T>({ ...config });
    return data;
  } catch (error) {
    const { response }: any = error as unknown as AxiosError;
    if (response) {
      throw response.data;
    }

    throw error;
  }
};

// const setToken = (token: string) => {
//   api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
// };

export { request };
