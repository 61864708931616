import * as yup from "yup";

export const ComCompanyInputSchema = yup.object({
  oid: yup.string().nullable(),
  company: yup.string().required("회사명을 입력해주세요"),
  manageAssign: yup.string().nullable(),
  custoDivision: yup.string().required("거래처 분류를 선택해주세요"),
  tel: yup.string().required("대표전화를 입력해주세요"),
  fax: yup.string().nullable(),
  route: yup.string().nullable(),
  creditClass: yup.string().nullable(),
  assignWorker: yup.string().nullable(),
  viewRank: yup.string().nullable(),
  zipCode: yup.string().nullable(),
  address: yup.string().nullable(),
  address1: yup.string().nullable(),
  conCompany: yup.string().nullable(),
  agency: yup.string().nullable(),
  agencyPrice: yup.number().nullable(),
  reportTel: yup.string().nullable(),
  finDay: yup.string().nullable(),
  actStatus: yup.string().nullable(),
  nCollectPrice: yup.string().nullable(),
  nGivenPrice: yup.string().nullable(),
  editDay: yup.string().nullable(),
  editWorker: yup.string().nullable(),
  bizName: yup.string().nullable(),
  bizNo: yup.string().nullable(),
  ceoName: yup.string().nullable(),
  bizCondition: yup.string().nullable(),
  item: yup.string().nullable(),
  bizPlaceNo: yup.string().nullable(),
  bizAddress: yup.string().nullable(),
  bizDetailAddress: yup.string().nullable(),
  bizType: yup.string().nullable(),
  coporNo: yup.string().nullable(),
  payCycle: yup.string().nullable(),
  kindBiz: yup.string().nullable(),
  bank: yup.string().nullable(),
  acountNo: yup.string().nullable(),
  depositOwner: yup.string().nullable(),
  billManage: yup.string().nullable(),
  companyTel: yup.string().nullable(),
  billEmail: yup.string().nullable(),
  moveTel: yup.string().nullable(),
  isShuttleDepot: yup.boolean().nullable(),
  isBilling: yup.boolean().nullable(),
  isOrderCo: yup.boolean().nullable(),
  isCargoOwner: yup.boolean().nullable(),
  isYongchasa: yup.boolean().nullable(),
  dupleCompany: yup.string().nullable(),
  remark: yup.string().nullable(),
});

export interface ComCompanyType
  extends yup.InferType<typeof ComCompanyInputSchema> {}

export const InitialComCompanyInput: ComCompanyType = {
  oid: undefined,
  company: "",
  manageAssign: undefined,
  custoDivision: "",
  tel: "",
  fax: undefined,
  route: undefined,
  creditClass: undefined,
  assignWorker: undefined,
  viewRank: undefined,
  zipCode: undefined,
  address: undefined,
  address1: undefined,
  conCompany: undefined,
  agency: undefined,
  agencyPrice: 0,
  reportTel: undefined,
  finDay: undefined,
  actStatus: undefined,
  nCollectPrice: undefined,
  nGivenPrice: undefined,
  editDay: undefined,
  editWorker: undefined,
  bizName: undefined,
  bizNo: undefined,
  ceoName: undefined,
  bizCondition: undefined,
  item: undefined,
  bizPlaceNo: undefined,
  bizAddress: undefined,
  bizDetailAddress: undefined,
  bizType: undefined,
  coporNo: undefined,
  payCycle: undefined,
  kindBiz: undefined,
  bank: undefined,
  acountNo: undefined,
  depositOwner: undefined,
  billManage: undefined,
  companyTel: undefined,
  billEmail: undefined,
  moveTel: undefined,
  isShuttleDepot: false,
  isBilling: false,
  isOrderCo: false,
  isCargoOwner: false,
  isYongchasa: false,
  dupleCompany: undefined,
  remark: undefined,
};
