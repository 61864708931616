import { request } from "@/util/api";
import { useQuery } from "@tanstack/react-query";

const useComCodeData = (code: number, search?: any, enabled?: boolean) => {
  return useQuery<any[]>({
    queryKey: [code, search],
    queryFn: () =>
      request({
        method: "GET",
        url: `/comcode/detail/${code}`,
        params: search,
      }),
    enabled: enabled,
  });
};

export default useComCodeData;
