import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import { userInfo } from "@/state/atoms/userInfo";
import {
  BankAccountManageSearchType,
  InitBankAccountSearch,
} from "@/types/bankAccount.type";
import EtcCostModal from "@/components/molecules/Modal/EtcCostModal";
import TaxListModal from "@/components/molecules/Modal/TaxListModal";
import { ContentTemplate } from "@/components/templates/ContentTemplate";
import BankAccountManageLowerGrid from "./BankAccountManageLowerGrid";
import BankAccountManageMainGrid from "./BankAccountManageMainGrid";

const BankAccountManage = () => {
  const userData = useRecoilValue(userInfo);
  const [selectedRowKey, setSelectedRowKey] = useState<string | null>(null);
  const [search, setSearch] = useState<BankAccountManageSearchType>(
    InitBankAccountSearch(userData)
  );
  const [taxModal, setTaxModal] = useState<boolean>(false);
  const [etcModal, setEtcModal] = useState<boolean>(false);

  const gridMain: any = useRef();
  const gridTax: any = useRef();
  const gridEtc: any = useRef();

  const { data: mainData, refetch: mainRefetch } = useCustomQuery<any[]>(
    `account-list`,
    search
  );

  const {
    // reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    defaultValues: InitBankAccountSearch(userData),
  });

  const initPage = () => {
    const mainGridInstance = gridMain.current.instance;
    mainGridInstance.option("focusedRowIndex", -1);
    setSelectedRowKey(null);
    mainRefetch();
    // reset(InitBankAccountSearch(userData));
  };

  const onSearch = (data: any) => {
    setSearch(data);

    initPage();
  };

  const onFocusedRowChanged = (e: any) => {
    if (!e.row?.data) return;
    setSelectedRowKey(e.row.key);
  };

  return (
    <>
      <ContentTemplate bottomHeight={380}>
        <>
          <BankAccountManageMainGrid
            bottomHeight={380}
            gridMain={gridMain}
            mainData={mainData}
            control={control}
            errors={errors}
            searchSubmit={handleSubmit(onSearch)}
            onFocusedRowChanged={onFocusedRowChanged}
          />
          <BankAccountManageLowerGrid
            gridMain={gridMain}
            gridTax={gridTax}
            gridEtc={gridEtc}
            selectedRowKey={selectedRowKey}
            setTaxModal={setTaxModal}
            setEtcModal={setEtcModal}
            initPage={initPage}
          />
        </>
      </ContentTemplate>
      {taxModal && (
        <TaxListModal
          onClose={() => setTaxModal(false)}
          selectedRowKey={selectedRowKey}
          initPage={initPage}
          gridMain={gridMain}
        />
      )}
      {etcModal && (
        <EtcCostModal
          onClose={() => setEtcModal(false)}
          selectedRowKey={selectedRowKey}
          initPage={initPage}
          gridMain={gridMain}
        />
      )}
    </>
  );
};

export default BankAccountManage;
