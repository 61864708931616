import * as yup from "yup";

export type UserListType = {
  id: string;
  userId: string;
  userName: string;
  englishName: string;
  mail: string;
  hp: string;
  tel: string;
  fax: string;
  companyName: string;
  companyCode: string;
  deptName: string;
  deptCode: string;
  userStatusName: string;
  userStatus: string;
  dutyCodeName: string;
  dutyCode: string;
  authGroupName: string;
  authGroupId: string;
  driverId: string;
  remark: string;
};

export type UserListInputType = {
  userId: string;
  userStatus: string;
  password?: string;
  passwordConfirm?: string;
  userName: string;
  englishName?: string;
  mail?: string;
  hp?: string;
  tel?: string;
  fax?: string;
  companyCode: string;
  dutyCode?: string;
  deptCode?: string;
  authGroupId?: string;
  driverId?: string;
  remark?: string;
};

export const UserListInputSchema = yup.object<UserListInputType>({
  userId: yup
    .string()
    .required("아이디를 입력하세요")
    .max(20, "최대 20자리까지 입력가능"),
  userStatus: yup.string().nullable().required("사용상태를 선택하세요"),
  password: yup
    .string()
    .nullable()
    .min(3, "3자 이상 입력하세요")
    .max(50, "50자 미만으로 입력하세요"),
  passwordConfirm: yup
    .string()
    .nullable()
    .when("password", {
      is: (val: any) => (val && val.length > 0 ? true : false),
      then: () =>
        yup
          .string()
          .oneOf([yup.ref("password")], "비밀번호가 일치하지 않습니다"),
    }),
  userName: yup
    .string()
    .max(20, "20자 미만으로 입력하세요")
    .required("사용자명을 입력하세요"),
  englishName: yup.string().nullable().max(20, "20자 미만으로 입력하세요"),
  mail: yup.string().nullable().max(50, "50자 미만으로 입력하세요"),
  hp: yup.string().nullable(),
  tel: yup.string().nullable(),
  fax: yup.string().nullable(),
  companyCode: yup.string().nullable().required("소속법인을 선택하세요"),
  deptCode: yup.string().nullable(),
  authGroupId: yup.string().nullable().required("권한을 선택하세요"),
  driverId: yup.string().nullable(),
  remark: yup.string().nullable().max(100, "최대 100자리까지 입력 가능"),
});

export const InitialUserListInput: UserListInputType = {
  userId: "",
  password: undefined,
  passwordConfirm: undefined,
  userStatus: "",
  userName: "",
  englishName: undefined,
  mail: undefined,
  hp: undefined,
  tel: undefined,
  fax: undefined,
  companyCode: "",
  dutyCode: "",
  deptCode: "",
  authGroupId: "",
  driverId: "",
  remark: undefined,
};
