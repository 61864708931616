import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  Button,
  Column,
  Editing,
  Format,
  Lookup,
  Paging,
  RequiredRule,
  Scrolling,
} from "devextreme-react/data-grid";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import Grid from "@/components/atoms/Grid";
import { GridContent } from "@/components/organisms/Contents/GridContent";
import { DetailContent } from "@/components/organisms/Contents/DetailContent";

interface OrderDetailGridProps {
  openinputbar: boolean;
  selectedRowKey: string | null;
  gridSub: any;

  conType: any[] | undefined;

  extraChargeCellButtonRender: (e: any) => JSX.Element;
  extraChargeCellRender: (e: any) => JSX.Element;
  etcChargeHabulCellRender: (e: any) => JSX.Element;
}

const OrderDetailGrid = ({
  openinputbar,
  selectedRowKey,
  gridSub,
  conType,
  extraChargeCellButtonRender,
  extraChargeCellRender,
  etcChargeHabulCellRender,
}: OrderDetailGridProps) => {
  const { t } = useTranslation("main");
  const fridge = true;
  const danger = true;

  const { data: subData } = useCustomQuery<any[]>(
    `order/modal/detail`,
    { orderCode: selectedRowKey },
    !!selectedRowKey
  );

  const onInitNewRow = (e: any) => {
    e.data.bobtail = false;
    e.data.deliverDate = dayjs().format("YYYY-MM-DD");
    e.data.freight = 0;
    e.data.extraChargeObj = [];
    e.data.etcChargeHabulObj = [];
  };

  const setCellDateValue = (newData: any, value: any, dataField: string) => {
    if (value) {
      newData[dataField] = dayjs(value).format("YYYY-MM-DD");
    }
  };

  const setCellTimeValue = (newData: any, value: any, dataField: string) => {
    if (value) {
      newData[dataField] = dayjs(value).format("hh:mm:ss");
    }
  };

  const onEditorPreparing = useCallback((e: any) => {
    if (e.parentType === "dataRow" && e.dataField === "sealNo") {
      e.editorOptions.maxLength = 20;
    }
  }, []);

  return (
    <DetailContent>
      <Grid
        detailGrid={true}
        refs={gridSub}
        dataSource={subData}
        keyExpr="id"
        onToolbarPreparing={(e: any) => {
          e.toolbarOptions.items.forEach((item: any) => {
            if (item.name === "saveButton") {
              item.visible = false;
            }
          });
        }}
        onInitNewRow={onInitNewRow}
        onEditorPreparing={onEditorPreparing}
        focusedRowEnabled={false}
      >
        <Scrolling showScrollbar="always" useNative="false" />
        <Paging enabled={true} pageSize={100000} />
        <Editing
          mode="batch"
          allowAdding={true}
          allowUpdating={true}
          allowDeleting={true}
        />
        <Column type="buttons" width={55}>
          <Button name="delete" text="삭제" />
        </Column>
        <Column
          width={70}
          caption="No"
          cellRender={(e) => e.row.loadIndex + 1}
        />
        <Column
          dataField="deliverDate"
          caption={t("운송(요청)일자")}
          dataType="date"
          format="yyyy-MM-dd"
          setCellValue={(x: any, y: any) =>
            setCellDateValue(x, y, "deliverDate")
          }
          width={100}
        >
          <RequiredRule />
        </Column>
        <Column dataField="containerNo" caption={t("컨테이너번호")} width={140}>
          {/* <CustomRule message="컨테이너번호가 올바르지 않습니다." validationCallback={containerValidationCallback} /> */}
        </Column>
        <Column dataField="containerType" caption={t("컨규격")} width={60}>
          <Lookup
            dataSource={conType}
            valueExpr="subCode"
            displayExpr="subName"
          />
        </Column>
        <Column dataField="sealNo" caption={t("Seal No")} width={140} />
        <Column
          dataField="workTime"
          caption={t("작업시간")}
          format="HH:mm:ss"
          dataType="datetime"
          editorOptions={{
            displayFormat: "HH:mm:ss",
            useMaskBehavior: true,
            type: "time",
          }}
          width="100"
          setCellValue={(x: any, y: any) => setCellTimeValue(x, y, "workTime")}
        />
        <Column
          dataField="manager"
          caption={t("도착지담당자")}
          width={100}
          alignment="center"
        />
        <Column
          dataField="pointManageContact"
          caption={t("도착지담당자연락처")}
          width={100}
          alignment="center"
        />
        <Column
          dataField="freight"
          caption={t("청구금액")}
          width={75}
          alignment="right"
          dataType="number"
        >
          <RequiredRule />
          <Format type="fixedPoint" />
        </Column>
        <Column
          dataField="extraCharge"
          caption={t("할증금액")}
          width={100}
          alignment="right"
          dataType="number"
          cellRender={extraChargeCellRender}
          allowEditing={false}
        >
          <Format type="fixedPoint" />
        </Column>
        <Column
          dataField="etcChargeHabul"
          caption={t("기타하불")}
          width={100}
          alignment="right"
          dataType="number"
          cellRender={etcChargeHabulCellRender}
          allowEditing={false}
        >
          <Format type="fixedPoint" />
        </Column>
        <Column
          caption={t("할증등록")}
          width={80}
          cellRender={extraChargeCellButtonRender}
          allowEditing={false}
          alignment="center"
        />
        <Column
          dataField="cargoWeight"
          caption={t("화물중량(ton)")}
          width={90}
        />
        <Column
          dataField="returnDate"
          caption={t("반납마감일자")}
          width={100}
          dataType="date"
          format="yyyy-MM-dd"
          setCellValue={(x: any, y: any) =>
            setCellDateValue(x, y, "returnDate")
          }
        />
        <Column
          dataField="freightDate"
          caption={t("청구일자")}
          width={100}
          dataType="date"
          format="yyyy-MM-dd"
          setCellValue={(x: any, y: any) =>
            setCellDateValue(x, y, "freightDate")
          }
        />
        <Column
          dataField="bobtail"
          caption={t("밥테일")}
          dataType="boolean"
          width={50}
        />
        <Column dataField="extraInfo" caption={t("특이사항")} width={100} />
        {(fridge || danger) && (
          <Column dataField="temperature" caption={t("온도")} width={75} />
        )}
        {(fridge || danger) && (
          <Column dataField="humidity" caption={t("습도")} width={75} />
        )}
        {(fridge || danger) && (
          <Column dataField="vent" caption={t("환풍구")} width={75} />
        )}
        {(fridge || danger) && (
          <Column dataField="class" caption={t("클래스")} width={75} />
        )}
        {(fridge || danger) && (
          <Column dataField="unNo" caption={t("UN번호")} width={75} />
        )}
      </Grid>
    </DetailContent>
  );
};

export default OrderDetailGrid;
