import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  Format,
  HeaderFilter,
  Scrolling,
} from "devextreme-react/data-grid";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import Grid from "@/components/atoms/Grid";
import { DetailTabItem } from "@/components/atoms/DetailTabItem";
import { DetailContent } from "@/components/organisms/Contents/DetailContent";

interface NonPaymentPageLowerGridProps {
  selectedRowKey: string | null;
}

const NonPaymentPageLowerGrid = ({
  selectedRowKey,
}: NonPaymentPageLowerGridProps) => {
  const { t } = useTranslation("main");
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const gridDetail: any = useRef();

  const { data } = useCustomQuery<{ taxList: any[]; accountList: any[] }>(
    `non-payment/sub`,
    { oid: selectedRowKey },
    !!selectedRowKey
  );

  const tabs = [
    {
      component: (
        <Grid
          detailGrid={true}
          refs={gridDetail}
          dataSource={data?.taxList}
          keyExpr="finishNo"
        >
          <HeaderFilter visible={true} />
          {/* <ColumnChooser enabled={true} /> */}
          <Scrolling mode="virtual" />
          <Column
            dataField="companyName"
            caption={t("청구처/용차사/차량번호")}
            width="134"
          />
          {/* 접수법인 */}
          <Column
            dataField="receiveCoName"
            caption={t("접수법인")}
            width="134"
          />
          {/* 정산예정일자 */}
          <Column dataField="taxSchedule" caption={t("정산일자")} width="100" />
          {/* 세금계산서번호 */}
          <Column
            dataField="finishNo"
            caption={t("세금계산서번호")}
            width="150"
          />
          {/* 계산서상태 */}
          <Column dataField="taxStatus" caption={t("계산서상태")} width="100" />
          {/* 금액 */}
          <Column
            dataField="price"
            caption={t("금액")}
            dataType="number"
            width="130"
          >
            <Format type="fixedPoint" />
          </Column>
          {/* 세액 */}
          <Column
            dataField="taxTotal"
            caption={t("세액")}
            dataType="number"
            width="130"
          >
            <Format type="fixedPoint" />
          </Column>
          {/* 합계금액 */}
          <Column
            dataField="priceTotal"
            caption={t("합계금액")}
            dataType="number"
            width="130"
          >
            <Format type="fixedPoint" />
          </Column>
        </Grid>
      ),
    },
    {
      component: (
        <Grid
          detailGrid={true}
          refs={gridDetail}
          dataSource={data?.accountList}
          keyExpr="oid"
        >
          <HeaderFilter visible={true} />
          {/* <ColumnChooser enabled={true} /> */}
          <Scrolling mode="virtual" />
          <Column dataField="accountNo" caption={t("계좌번호")} />
          <Column dataField="bankName" caption={t("은행명")} />
          <Column dataField="information" caption={t("기재내용")} />
          <Column dataField="deposit" caption={t("입금")}>
            <Format type="fixedPoint" />
          </Column>
          <Column dataField="withdraw" caption={t("출금")}>
            <Format type="fixedPoint" />
          </Column>
          <Column dataField="remark" caption={t("메모")} />
          <Column dataField="transDate" caption={t("거래일시")} />
        </Grid>
      ),
    },
  ];
  return (
    <DetailContent
      detailSectionTab={
        <>
          <DetailTabItem
            label="상세내용"
            active={selectedTab === 0 ? true : false}
            onClick={() => setSelectedTab(0)}
          />
          <DetailTabItem
            label="통장내역"
            active={selectedTab === 1 ? true : false}
            onClick={() => setSelectedTab(1)}
          />
        </>
      }
      detailSectionButtons={<></>}
    >
      {tabs[selectedTab].component}
    </DetailContent>
  );
};

export default NonPaymentPageLowerGrid;
