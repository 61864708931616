import { InputSelect, InputText } from "@/components/atoms/Inputs";
import { 은행 } from "@/data/constant/queryKeys";
import useButtonLoading from "@/hooks/useButtonLoading";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import {
  CompanyInputSchema,
  InitialCompanyUserListInput,
} from "@/types/company.type";
import { usePostMutation, usePutMutation } from "@/util/common.fn";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "..";
import { ModalForm } from "../modal.styles";
import { useComCodeData } from "@/hooks/queries";
import { Buttons } from "@/components/atoms/Buttons";

interface CompanyInputModalProps {
  onClose: () => void;
  selectedRowKey: number | null;
}

const CompanyInputModal = ({
  onClose,
  selectedRowKey,
}: CompanyInputModalProps) => {
  const { alertErrorMessage, alertSuccessMessage } = useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { data: mainData, isLoading: mainDataLoading } = useCustomQuery(
    `company/${selectedRowKey}`,
    {},
    !!selectedRowKey
  );

  const { mutateAsync: create } = usePostMutation(`company`);
  const { mutateAsync: update } = usePutMutation(`company`);

  const { data: bank, isLoading: bankLoading } = useComCodeData(은행);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<any>({
    defaultValues: InitialCompanyUserListInput,
    resolver: yupResolver(CompanyInputSchema),
  });

  useEffect(() => {
    mainData && reset(mainData);
    // eslint-disable-next-line
  }, [mainData]);

  const openPost = () => {
    window.daum.postcode.load(() => {
      const postcode = new window.daum.Postcode({
        oncomplete: function (data: any) {
          let addr = "";
          let extraAddr = "";

          if (data.userSelectedType === "R") {
            addr = data.address;
          } else {
            addr = data.jibunAddress;
          }

          if (data.userSelectedType === "R") {
            if (data.bname !== "" && /[동|로|가]$/g.test(data.bname)) {
              extraAddr += data.bname;
            }
            if (data.buildingName !== "" && data.apartment === "Y") {
              extraAddr +=
                extraAddr !== "" ? ", " + data.buildingName : data.buildingName;
            }
            if (extraAddr !== "") {
              extraAddr = " (" + extraAddr + ")";
            }
            addr += extraAddr;
          }

          setValue("zipCode", data.zonecode);
          setValue("address", addr);
        },
      });
      postcode.open();
    });
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading();
      let res: any;
      if (selectedRowKey) {
        res = await update({ key: selectedRowKey, data });
      } else {
        res = await create(data);
      }

      if (res.state === 1) {
        await alertSuccessMessage("저장완료");
        onClose();
      }
    } catch (error) {
      console.log(error);
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };
  const onError = () => {};

  return (
    <Modal width="1100px" close={onClose} title="회사정보 등록">
      <ModalForm onSubmit={handleSubmit(onSubmit, onError)}>
        <div className="grid-4">
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="companyName"
            label="회사명"
            required={true}
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="companyNameEng"
            label="회사명 영문"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            mask="999-99-99999"
            name="bizNo"
            label="사업자등록번호"
            required={true}
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="ceoName"
            label="대표자명"
            required={true}
          />

          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="bizType"
            label="업태"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="bizItem"
            label="종목"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="tel"
            label="전화번호"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="hp"
            label="휴대전화"
          />

          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="fax"
            label="팩스번호"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="email"
            label="이메일"
          />
          <InputSelect
            type="v-array"
            control={control}
            errors={errors}
            dataSource={bank}
            valueExpr="subCode"
            displayExpr="subName"
            name="bankCode"
            label="계좌종류"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="bankNumber"
            label="계좌번호"
          />

          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="contactId"
            label="바로빌아이디"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="contactPassword"
            label="바로빌패스워드"
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="contactName"
            label="담당자이름"
          />
        </div>

        <div className="grid-4">
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="zipCode"
            label="우편번호"
            disabled={true}
          />
          <Buttons
            type="button"
            size="sm"
            layout="solid"
            color="find"
            label="주소검색"
            onClick={openPost}
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="address"
            label="주소"
            disabled={true}
          />
          <InputText
            type="v-array"
            control={control}
            errors={errors}
            name="addressDetail"
            label="상세주소"
          />
        </div>

        {/* 우편번호 넣기 */}

        <InputText
          type="v-array"
          control={control}
          errors={errors}
          name="remark"
          label="비고"
        />

        {/* Modal 저장 버튼 */}
        {/*  */}
        <Buttons
          permission="save"
          type="submit"
          size="full"
          layout="solid"
          color="save"
          label="저장"
          disabled={mainDataLoading || bankLoading}
        />
      </ModalForm>
    </Modal>
  );
};

export default CompanyInputModal;
