import { TreeList } from "devextreme-react";
import * as S from "./Tree.style";

export default function Tree(props: any) {
  if (props.isError) {
    return <></>;
  }

  const onToolbarPreparing = (e: any) => {
    e.toolbarOptions.items.forEach((item: any) => {
      item = false;
    });
  };

  return (
    <S.TreeBox>
      {props.title && <S.TreeTitle>{props.title}</S.TreeTitle>}
      <TreeList
        className={props.subGrid ? "sub-grid" : "main-grid"}
        id={props.id}
        key={props.keys}
        keyExpr={props.keyExpr || "code"}
        parentIdExpr={props.parentIdExpr}
        dataSource={props.dataSource || []}
        ref={props.refs}
        showRowLines={props.showRowLines || true}
        showBorders={props.showBorders || true}
        hoverStateEnabled={props.hoverStateEnabled || true}
        allowColumnResizing={props.allowColumnResizing || true}
        allowColumnReordering={props.allowColumnReordering || true}
        columnResizingMode="nextColumn"
        columnAutoWidth={true}
        columnMinWidth={40}
        focusedRowEnabled={props.focusedRowEnabled || false}
        onFocusedRowChanged={props.onFocusedRowChanged}
        onContentReady={props.onContentReady}
        onRowInserted={props.onRowInserted}
        onRowInserting={props.onRowInserting}
        onInitNewRow={props.onInitNewRow}
        onOptionChanged={props.onOptionChanged}
        onEditorPreparing={props.onEditorPreparing}
        onToolbarPreparing={props.onToolbarPreparing || onToolbarPreparing}
      >
        {props.children}
      </TreeList>
    </S.TreeBox>
  );
}
