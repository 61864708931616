import dayjs from "dayjs";
import * as yup from "yup";
import { userType } from "./user.type";

export interface ShuttleDispatchSearchType {
  receiveCo: string;
  dispatchTeam: string;
  importExport: string;
  searchType: string;
  firstDay: string;
  lastDay: string;
  containerNo: string;
  blNo: string;
  doNo: string;
  bkNo: string;
}

export const InitShuttleDispatchSearch = (
  useData: userType
): ShuttleDispatchSearchType => {
  return {
    receiveCo: useData.companyCode,
    dispatchTeam: useData.deptCode,
    importExport: "",
    searchType: "2",
    firstDay: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
    lastDay: dayjs().format("YYYY-MM-DD"),
    containerNo: "",
    blNo: "",
    doNo: "",
    bkNo: "",
  };
};

export const ShuttleDispatchInputSchema = yup.object().shape({
  code: yup.string().nullable(),
  receiveDate: yup.string().nullable(),
  receiveCo: yup.string().nullable(),
  dispatchTeam: yup.string().nullable(),
  deliverDate: yup.string().nullable(),
  returnDate: yup.string().nullable(),
  cargoOwner: yup.string().nullable(),
  importExport: yup.number().nullable(),
  billingPlace: yup.string().required("청구처를 선택해주세요"),
  containerType: yup.string().nullable(),
  containerNo: yup.string().nullable(),
  sealNo: yup.string().nullable(),
  cargoWeight: yup.string().nullable(),
  blNo: yup.string().nullable(),
  doNo: yup.string().nullable(),
  bkNo: yup.string().nullable(),
  motherShip: yup.string().nullable(),
  forwarder: yup.string().nullable(),
  realCargoOwner: yup.string().nullable(),
  freight: yup.number(),
  manager: yup.string().nullable(),
  extraInfo: yup.string().nullable(),
  remark: yup.string().nullable(),

  dispatches: yup.array().of(
    yup.object().shape({
      startPoint: yup.string().nullable(),
      endPoint: yup.string().nullable(),
      dispatchStatus: yup.string().nullable(),
      deliverDate: yup.string().nullable(),
      habul: yup.number(),
      carTypeCode: yup.string().nullable(),
      carNo: yup.string().nullable(),
      yongchasa: yup.string().nullable(),
    })
  ),
});

export interface ShuttleDispatchInputType
  extends yup.InferType<typeof ShuttleDispatchInputSchema> {}

export const InitShuttleDispatchInput = (
  userData: userType
): ShuttleDispatchInputType => {
  return {
    code: "",
    receiveDate: dayjs().format("YYYY-MM-DD"),
    receiveCo: userData.companyCode,
    dispatchTeam: userData.deptCode,
    deliverDate: dayjs().format("YYYY-MM-DD"),
    returnDate: "",
    cargoOwner: "",
    importExport: 1,
    billingPlace: "",
    containerType: "",
    containerNo: "",
    sealNo: "",
    cargoWeight: "",
    blNo: "",
    doNo: "",
    bkNo: "",
    motherShip: "",
    forwarder: "",
    realCargoOwner: "",
    freight: 0,
    manager: "",
    extraInfo: "",
    remark: "",

    dispatches: [
      {
        startPoint: "",
        endPoint: "",
        dispatchStatus: "62",
        deliverDate: dayjs().format("YYYY-MM-DD"),
        habul: 0,
        carTypeCode: "carNo",
        carNo: "",
        yongchasa: "",
      },
      {
        startPoint: "",
        endPoint: "",
        dispatchStatus: "62",
        deliverDate: dayjs().format("YYYY-MM-DD"),
        habul: 0,
        carTypeCode: "carNo",
        carNo: "",
        yongchasa: "",
      },
    ],
  };
};

export interface ShuttleDispatchInputType {}
