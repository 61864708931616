import styled from "styled-components";

interface InputBoxProps {
  type?: string;
  direction?: "row" | "column";
}

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: auto;
`;

export const Input = styled.div<InputBoxProps>`
  display: flex;
  width: 100%;
  align-items: ${(props) =>
    props.direction === "row" ? "center" : "flex-start"};
  flex-direction: ${(props) => props.direction};
  gap: ${(props) => (props.direction === "row" ? "10px" : "3px")};

  & > div {
    width: 100%;

    &.dx-editor-outlined {
      border: none;
    }
  }

  .dx-placeholder {
    padding-left: 4px;
  }

  .dx-texteditor-container {
    height: ${(props) => (props.direction === "row" ? "30px" : "34px")};
    font-size: 1.4rem;
    border: ${(props) =>
      props.direction === "row"
        ? "1px solid " + props.theme.colors.inputBorder
        : "none"};
    border-radius: 4px;

    input {
      padding: 0 10px;
    }
  }

  textarea {
    padding: 6px 10px;
  }

  .dx-checkbox-container {
    margin: 4px 0;

    .dx-checkbox-text {
      margin-right: 10px;
    }
  }

  .dx-radiobutton {
    display: flex;
    margin: 4px 16px 4px 0;
    align-items: center;

    .dx-radio-value-container {
      padding-right: 5px;
    }
  }
`;

export const InputLabel = styled.label<InputBoxProps>`
  color: ${(props) => props.theme.colors.inputLabel};
  font-size: 1.3rem;
  line-height: 1.3rem;
  text-align: ${(props) => (props.direction === "row" ? "right" : "left")};
  white-space: nowrap;

  span {
    color: ${(props) => props.theme.colors.destructive};
    font-size: 1.4rem;
    font-weight: 600;
  }
`;

export const InputErroredTxt = styled.p`
  color: ${(props) => props.theme.colors.warning};
`;

export const InputHelpTxt = styled.p``;
