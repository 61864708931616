import * as S from "./ContentTemplate.style";

interface ContentProps {
  sideWidth?: number;
  bottomHeight?: number;
  children?: React.ReactElement;
}

export const ContentTemplate = ({
  sideWidth,
  bottomHeight,
  children,
}: ContentProps) => {
  return (
    <S.ContentTemplate sideWidth={sideWidth} bottomHeight={bottomHeight}>
      {children}
    </S.ContentTemplate>
  );
};
