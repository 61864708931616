import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useCompanyData, useDispatchData } from "@/hooks/queries";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import { ClosingSearchType, InitialClosingSearch } from "@/types/closing.type";
import { usePutMutation } from "@/util/common.fn";
import {
  InputCheckBox,
  InputDate,
  InputSelect,
  InputText,
} from "@/components/atoms/Inputs";
import { GridContent } from "@/components/organisms/Contents/GridContent";
import { ContentTemplate } from "@/components/templates/ContentTemplate";
import ClosingLeftGrid from "./ClosingLeftGrid";
import ClosintRightGrid from "./ClosintRightGrid";
import { InputMultiple } from "@/components/atoms/InputMultiple";

const ClosingPage = () => {
  const { t } = useTranslation("main");
  // 데이터
  const [search, setSearch] = useState<ClosingSearchType>(InitialClosingSearch);
  const [subUrl, setSubUrl] = useState<string | null>(null);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);

  const gridMain = useRef<any>();
  const gridSub = useRef<any>();

  const { data: mainData, refetch: mainRefetch } = useCustomQuery<any[]>(
    `finish-sell-manage`,
    search
  );

  const { data: subData } = useCustomQuery<any[]>(
    `finish-sell-manage/${subUrl}`,
    {
      search: search,
      key: {
        oid: selectedRowData?.oid,
        receiveCo: selectedRowData?.receiveCoName,
        companyId: selectedRowData?.companyId,
        comOid: selectedRowData?.comOid,
        company: selectedRowData?.companyName,
        division: selectedRowData?.division,
      },
    },
    !!subUrl
  );

  const { data: dispatch } = useDispatchData();
  const { data: company } = useCompanyData();

  const {
    watch,
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    defaultValues: InitialClosingSearch,
  });

  const initPage = () => {
    const mainGridInstance = gridMain.current.instance;
    const subGridInstance = gridSub.current.instance;
    mainGridInstance.option("focusedRowIndex", -1);
    subGridInstance.option("focusedRowIndex", -1);
    setSubUrl(null);
    setSelectedRowData(null);
    mainRefetch();
    // reset(InitialDispatchInput);
  };

  const onSearch = (data: any) => {
    initPage();
    setSearch(data);
  };

  const onFocusedRowChanged = (e: any) => {
    if (!e.row?.data) return;
    setSelectedRowData(e.row.data);
    const { division } = e.row.data;
    if (division === "청구처") {
      setSubUrl("billing");
    }
    if (division === "용차사") {
      setSubUrl("yongcha");
    }
    if (division === "기사") {
      setSubUrl("driver");
    }
  };

  const handleMonthChange = ({ value }: any) => {
    const currentYear = dayjs(watch("firstDay")).year();
    const startOfMonth = dayjs(`${currentYear}-${value}-01`)
      .startOf("month")
      .format("YYYY-MM-DD");
    const endOfMonth = dayjs(`${currentYear}-${value}-01`)
      .endOf("month")
      .format("YYYY-MM-DD");

    setValue("firstDay", startOfMonth);
    setValue("lastDay", endOfMonth);
  };

  return (
    <>
      <ContentTemplate>
        <GridContent
          grid="550px"
          searchSection={
            <>
              <InputMultiple direction="row" label="검색기간">
                <>
                  <InputSelect
                    name="searchType"
                    displayExpr="name"
                    width={120}
                    valueExpr="id"
                    control={control}
                    errors={errors}
                    dataSource={[
                      { id: "1", name: "접수일자" },
                      { id: "0", name: "운송일자" },
                      { id: "2", name: "운송마감일자" },
                    ]}
                  />
                  <InputDate
                    name="firstDay"
                    width={120}
                    control={control}
                    errors={errors}
                  />
                  ~
                  <InputDate
                    name="lastDay"
                    width={120}
                    control={control}
                    errors={errors}
                  />
                </>
              </InputMultiple>
              <InputSelect
                label="월선택"
                name="month"
                width={80}
                displayExpr="name"
                valueExpr="id"
                control={control}
                errors={errors}
                dataSource={[
                  { id: "01", name: "1월" },
                  { id: "02", name: "2월" },
                  { id: "03", name: "3월" },
                  { id: "04", name: "4월" },
                  { id: "05", name: "5월" },
                  { id: "06", name: "6월" },
                  { id: "07", name: "7월" },
                  { id: "08", name: "8월" },
                  { id: "09", name: "9월" },
                  { id: "10", name: "10월" },
                  { id: "11", name: "11월" },
                  { id: "12", name: "12월" },
                ]}
                onValueChanged={handleMonthChange}
              />

              <InputSelect
                label="구분"
                name="isDivision"
                width={100}
                displayExpr="name"
                valueExpr="id"
                control={control}
                errors={errors}
                dataSource={[
                  { id: "0", name: t("전체") },
                  { id: "청구처", name: "청구처" },
                  { id: "용차사", name: "용차사" },
                  { id: "기사", name: "기사" },
                ]}
              />
              <InputSelect
                label="마감구분"
                name="isFinish"
                width={90}
                displayExpr="name"
                valueExpr="id"
                control={control}
                errors={errors}
                dataSource={[
                  { id: "0", name: t("전체") },
                  { id: "1", name: "마감" },
                  { id: "2", name: "미마감" },
                ]}
              />
              <InputSelect
                label="접수법인"
                name="receiveCo"
                width={130}
                displayExpr="companyName"
                valueExpr="id"
                control={control}
                errors={errors}
                dataSource={[
                  { id: "0", companyName: t("전체") },
                  ...(company || []),
                ]}
              />
              <InputSelect
                label="배차팀"
                name="dispatchTeam"
                width={180}
                displayExpr="deptName"
                valueExpr="code"
                control={control}
                errors={errors}
                dataSource={[
                  { code: "0", deptName: t("전체") },
                  ...(dispatch || []),
                ]}
              />

              <InputText
                label="청구처/용차사/차량번호"
                name="companyName"
                control={control}
                errors={errors}
              />
              <InputCheckBox
                label="청구이월조회"
                name="carryoverInquiry"
                control={control}
                errors={errors}
              />
            </>
          }
          searchSubmit={handleSubmit(onSearch)}
        >
          <>
            <ClosingLeftGrid
              gridMain={gridMain}
              mainData={mainData}
              onFocusedRowChanged={onFocusedRowChanged}
            />
            <ClosintRightGrid
              gridMain={gridMain}
              gridSub={gridSub}
              subData={subData}
              search={search}
              selectedRowData={selectedRowData}
              initPage={initPage}
            />
          </>
        </GridContent>
      </ContentTemplate>
    </>
  );
};

export default ClosingPage;
