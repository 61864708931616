import { useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePutMutation } from "@/util/common.fn";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import useButtonLoading from "@/hooks/useButtonLoading";
import { Buttons } from "@/components/atoms/Buttons";
import { InputPassword } from "@/components/atoms/Inputs";
import { Modal } from "..";
import { InputMultiple } from "@/components/atoms/InputMultiple";

interface ChangePasswordProps {
  onClose: () => void;
  userOid: string | null;
}

const ChangePassword = ({ onClose, userOid }: ChangePasswordProps) => {
  // const { mutate: update } = useStmUserUpdate(() => {
  //   close();
  // });
  const { alertErrorMessage, alertSuccessMessage, confirmMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { mutateAsync: update } = usePutMutation("user-list/password");

  const initialState = { password: "", passwordconfirm: "" };

  const schema = yup.object({
    password: yup
      .string()
      .required("비밀번호를 입력해주세요")
      .nullable()
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*#?&]{8,}$/,
        "8자 이상, 하나의 문자, 하나의 숫자, 하나의 특수문자"
      ),
    passwordconfirm: yup
      .string()
      .nullable()
      .when("password", {
        is: (val: any) => (val && val.length > 0 ? true : false),
        then: () =>
          yup
            .string()
            .nullable()
            .oneOf([yup.ref("password")], "비밀번호가 다릅니다"),
      }),
  });

  const {
    control,
    trigger,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(initialState);
    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data: any) => {
    const trig = await trigger();
    if (!trig) return;

    if (!(await confirmMessage("비밀번호를 변경하시겠습니까?"))) return;

    try {
      setLoading();
      await update({
        key: userOid,
        password: data.password,
      });

      await alertSuccessMessage("변경완료");

      onClose();
    } catch (error: any) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onError = (err: any) => {
    console.log(err);
  };

  return (
    <Modal
      width="300px"
      close={onClose}
      title="비밀번호 변경"
      modalButton={
        <Buttons
          permission="save"
          type="submit"
          size="lg"
          width={80}
          layout="solid"
          color="save"
          label="저장"
        />
      }
      formSubmit={handleSubmit(onSubmit, onError)}
    >
      <InputMultiple direction="column">
        <>
          <InputPassword
            type="v-array"
            control={control}
            errors={errors}
            name="password"
            label="새 비밀번호"
          />
          <InputPassword
            type="v-array"
            control={control}
            errors={errors}
            name="passwordconfirm"
            label="비밀번호 확인"
          />
        </>
      </InputMultiple>
    </Modal>
  );
};

export default ChangePassword;
