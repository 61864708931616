import dayjs from "dayjs";
import { userType } from "./user.type";
import * as yup from "yup";

export interface DispatchSearchType {
  firstDay: string;
  lastDay: string;
  searchType: number;
  authGroupId: string;
  company: string;
  dispatchTeam: string;
  importExport: string;
  isBobtail: number;
  isLoss: boolean;
}

export const InitialDispatchSearch = (
  userInfo: userType
): DispatchSearchType => {
  return {
    firstDay: dayjs().format("YYYY-MM-DD"),
    lastDay: dayjs().format("YYYY-MM-DD"),
    searchType: 2,
    authGroupId: userInfo.authGroup,
    company: userInfo.companyCode,
    dispatchTeam: userInfo?.deptCode || "",
    importExport: "",
    isBobtail: 0,
    isLoss: false,
  };
};

export interface DispatchInputType {
  orderCode: string;
  receiveDate: string;
  receiveCo?: number;
  receiveCoName: string;
  dispatchTeam?: string;
  dispatchTeamName?: string;
  bobtail?: boolean;
  importExport?: number;
  billingPlace?: string;
  orderCoName?: string;
  billingPlaceName?: string;
  freight?: number;
  freightDate?: string;
  forwarder?: string;
  cargoOwner?: string;
  cargoOwnerName?: string;
  containerType?: string;
  containerNo?: string;
  sealNo?: string;
  workTime?: string;
  deliverDate?: string;
  returnDate?: string;
  blNo?: string;
  bkNo?: string;
  doNo?: string;
  realCargoOwner?: string;
  enterDay?: string;
  sailDay?: string;
  fridge?: boolean;
  danger?: boolean;
  tank?: boolean;
  direct?: boolean;
  toxic?: boolean;
  keep?: boolean;
  temperature?: null;
  humidity?: null;
  vent?: null;
  unNo?: null;
  cargoWeight?: string;
  class?: string;
  chassis?: string;
  manager?: string;
  extraInfo?: string;
  pointManageContact?: string;
  color?: string;
  remark?: string;
  innerMemo?: string;
  userName?: string;
  createDate?: string;
  createTime?: string;
  completeNo?: string;
  dispatches: DispatchListType[];
}

export const InitialDispatchInput: DispatchInputType = {
  orderCode: "",
  receiveDate: "",
  receiveCo: undefined,
  receiveCoName: "",
  dispatchTeam: undefined,
  dispatchTeamName: undefined,
  bobtail: undefined,
  importExport: undefined,
  billingPlace: undefined,
  orderCoName: undefined,
  billingPlaceName: undefined,
  freight: undefined,
  freightDate: undefined,
  forwarder: undefined,
  cargoOwner: undefined,
  cargoOwnerName: undefined,
  containerType: undefined,
  containerNo: undefined,
  sealNo: undefined,
  workTime: undefined,
  deliverDate: undefined,
  returnDate: undefined,
  blNo: undefined,
  bkNo: undefined,
  doNo: undefined,
  realCargoOwner: undefined,
  enterDay: undefined,
  sailDay: undefined,
  fridge: undefined,
  danger: undefined,
  tank: undefined,
  direct: undefined,
  toxic: undefined,
  keep: undefined,
  temperature: undefined,
  humidity: undefined,
  vent: undefined,
  unNo: undefined,
  cargoWeight: undefined,
  class: undefined,
  chassis: undefined,
  manager: undefined,
  extraInfo: undefined,
  pointManageContact: undefined,
  color: undefined,
  remark: undefined,
  innerMemo: undefined,
  userName: undefined,
  createDate: undefined,
  createTime: undefined,
  completeNo: undefined,
  dispatches: [],
};

export interface DispatchListType {
  startPoint: string;
  endPoint: string;
  status: string;
  deliverDate: string;
  habul: number;
}

export const DispatchInputSchema = yup.object<DispatchInputType>().shape({
  deliverDate: yup.string().nullable(),
  containerNo: yup.string().nullable(),
  containerType: yup.string().nullable(),
  sealNo: yup.string().nullable(),
  workTime: yup.string().nullable(),
  manager: yup.string().nullable(),
  pointManageContact: yup.string().nullable(),
  freight: yup.number().required("청구금액을 입력해주세요"),
  cargoWeight: yup.string().nullable(),
  returnDate: yup.string().nullable(),
  freightDate: yup.string().nullable(),
  bobtail: yup.string().nullable(),
  extraInfo: yup.string().nullable(),
  temperature: yup.string().nullable(),
  humidity: yup.string().nullable(),
  vent: yup.string().nullable(),
  class: yup.string().nullable(),
  unNo: yup.string().nullable(),
  dispatches: yup.array().of(
    yup.object().shape({
      startPoint: yup.string().nullable(),
      endPoint: yup.string().nullable(),
      status: yup.string().nullable(),
      deliverDate: yup.string().nullable(),
      habul: yup.string().nullable(),
      toggle: yup.number().nullable(),
    })
  ),
});
