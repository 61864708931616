import { useRef } from "react";
import { useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import dayjs from "dayjs";
import { HtmlEditor } from "devextreme-react";
import {
  Item,
  TableContextMenu,
  TableResizing,
  Toolbar,
} from "devextreme-react/html-editor";
import { useMutation } from "@tanstack/react-query";
import useButtonLoading from "@/hooks/useButtonLoading";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import { ClosingSearchType } from "@/types/closing.type";
import { request } from "@/util/api";
import { getDefaultMessage } from "@/util/emailFunction";
import { isLoading } from "@/state/atoms/isLoading";
import { userInfo } from "@/state/atoms/userInfo";
import { InputText } from "@/components/atoms/Inputs";
import Loading from "@/components/atoms/Loading";
import { Buttons } from "@/components/atoms/Buttons";
import { Modal } from "..";
import { ModalForm } from "../modal.styles";
import * as S from "./mailingModal.style";

interface MailingModalProps {
  onClose: () => void;
  selectedRowData: any;
  mailPdf: Blob;
  search: ClosingSearchType;
}

const MailingModal = ({
  onClose,
  selectedRowData,
  mailPdf,
  search,
}: MailingModalProps) => {
  const loading = useRecoilValue(isLoading);
  const userData = useRecoilValue(userInfo);
  const markup = getDefaultMessage(userData, search);
  const editorRef = useRef<any>();
  const pdfName = `${selectedRowData?.companyName || ""}_거래명세서_${dayjs(
    search?.firstDay
  ).format("YYYY년 MM월 DD일")}~${dayjs(search?.lastDay).format(
    "YYYY년 MM월 DD일"
  )}.pdf`;

  const { mutateAsync: sendMail } = useMutation({
    mutationFn: (formData: FormData) =>
      request({
        method: "POST",
        url: `/mail/closing/send`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      }),
  });

  const { alertErrorMessage, alertSuccessMessage } = useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      subject: `[${userData.companyName || ""}] ${
        selectedRowData?.companyName || ""
      } ${dayjs(search?.firstDay).format("M월")} 명세서 첨부`,
    },
  });

  const onSubmit = async (data: any) => {
    try {
      setLoading();
      const body = editorRef.current.instance
        .option("value")
        .replace(
          /<td style="/gi,
          `<td style="border: 1px solid #ddd; padding: 2px 5px; `
        );
      const to = data.to;
      if (!to) {
        alertErrorMessage("받는사람을 입력해주세요.");
        return;
      }

      if (!mailPdf) return;

      const formData = new FormData();
      formData.append("from", "dev01@insystem.kr");
      formData.append("to", data.to);
      formData.append("subject", data.subject);
      formData.append("html", body);
      formData.append("pdf", mailPdf);
      formData.append("filename", pdfName);

      const res: any = await sendMail(formData);

      if (res.state === 1) {
        alertSuccessMessage("전송완료");

        onClose();
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  return (
    <Modal
      width="75%"
      close={onClose}
      title="거래명세서 메일 전송"
      modalButton={
        <Buttons
          permission="export"
          type="submit"
          size="lg"
          width={100}
          layout="solid"
          color="primary100"
          label="메일발송"
        />
      }
      formSubmit={handleSubmit(onSubmit)}
    >
      <>
        {loading && <Loading type="page" text="메일 발송 중입니다..." />}
        <S.ModalMailingWrapper>
          <InputText
            control={control}
            errors={errors}
            name="subject"
            label="제목"
          />

          <InputText
            control={control}
            errors={errors}
            name="to"
            label="받는 사람"
          />

          <S.ModalMailingAttach>
            <label>첨부파일</label>
            <div>{pdfName}</div>
          </S.ModalMailingAttach>

          <label>내용</label>

          <HtmlEditor
            ref={editorRef}
            height={"50vh"}
            defaultValue={markup}
            valueType="html"
          >
            <TableContextMenu enabled={true} />
            <TableResizing enabled={true} />
            <Toolbar multiline={true}>
              <Item name="undo" />
              <Item name="redo" />
              <Item name="separator" />
              <Item
                name="size"
                acceptedValues={[
                  "8pt",
                  "10pt",
                  "12pt",
                  "14pt",
                  "18pt",
                  "24pt",
                  "36pt",
                ]}
              />
              <Item
                name="font"
                acceptedValues={[
                  "Arial",
                  "Courier New",
                  "Georgia",
                  "Impact",
                  "Lucida Console",
                  "Tahoma",
                  "Times New Roman",
                  "Verdana",
                ]}
              />
              <Item name="separator" />
              <Item name="bold" />
              <Item name="italic" />
              <Item name="strike" />
              <Item name="underline" />
              <Item name="separator" />
              <Item name="alignLeft" />
              <Item name="alignCenter" />
              <Item name="alignRight" />
              <Item name="alignJustify" />
              <Item name="separator" />
              <Item name="orderedList" />
              <Item name="bulletList" />
              <Item name="separator" />
              <Item name="header" acceptedValues={[false, 1, 2, 3, 4, 5]} />
              <Item name="separator" />
              <Item name="color" />
              <Item name="background" />
              <Item name="separator" />
              <Item name="clear" />
              <Item name="codeBlock" />
              <Item name="blockquote" />
              <Item name="separator" />
              <Item name="insertTable" />
              <Item name="insertRowAbove" />
              <Item name="insertRowBelow" />
              <Item name="insertColumnLeft" />
              <Item name="insertColumnRight" />
              <Item name="deleteTable" />
              <Item name="deleteRow" />
              <Item name="deleteColumn" />
            </Toolbar>
          </HtmlEditor>
        </S.ModalMailingWrapper>
      </>
    </Modal>
  );
};

export default MailingModal;
