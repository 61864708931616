import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import InputMonth from "@/components/atoms/Inputs/InputMonth";
import { Buttons } from "@/components/atoms/Buttons";
import { ContentTemplate } from "@/components/templates/ContentTemplate";
import { GridContent } from "@/components/organisms/Contents/GridContent";
import NonPaymentPageGrid from "./NonPaymentPageGrid";
import NonPaymentPageLowerGrid from "./NonPaymentPageLowerGrid";

const NonPaymentPage = () => {
  const initialSearch = {
    firstMonth: dayjs().format("YYYY-MM"),
    lastMonth: dayjs().format("YYYY-MM"),
  };
  const [search, setSearch] = useState(initialSearch);
  const [selectedRowKey, setSelectedRowKey] = useState<string | null>(null);

  const gridMain: any = useRef();

  const { data: mainData, refetch: mainRefetch } = useCustomQuery<any[]>(
    `non-payment`,
    search
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<any>({
    defaultValues: initialSearch,
  });

  const onSearch = (data: any) => {
    setSearch(data);

    mainRefetch();
  };

  const onFocusedRowChanged = (e: any) => {
    if (!e.row?.data) return;
    setSelectedRowKey(e.row.key);
  };

  return (
    <ContentTemplate bottomHeight={380}>
      <>
        <NonPaymentPageGrid
          bottomHeight={380}
          gridMain={gridMain}
          mainData={mainData}
          control={control}
          errors={errors}
          searchSubmit={handleSubmit(onSearch)}
          onFocusedRowChanged={onFocusedRowChanged}
        />
        <NonPaymentPageLowerGrid selectedRowKey={selectedRowKey} />
      </>
    </ContentTemplate>
  );
};

export default NonPaymentPage;
