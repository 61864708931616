import { Controller } from "react-hook-form";
import { DateBox } from "devextreme-react";
import dayjs from "dayjs";
import { Input } from "..";

const InputMonth = ({
  width,
  control,
  errors,
  name,
  label,
  required,
  disabled = false,
  onValueChange = () => {},
  tabIndex,
  direction,
  className,
}: any) => {
  return (
    <Input
      type="date"
      direction={direction ? direction : `row`}
      label={label && label}
      required={required}
      errors={errors && errors}
    >
      <Controller
        render={({ field: { onChange, value } }) => {
          return (
            <DateBox
              type="date"
              mask={"0000-00"}
              displayFormat="yyyy-MM"
              className={className}
              onValueChange={(value) => {
                // onChange(value);
                if (value) onChange(dayjs(value).format("YYYY-MM"));
              }}
              onValueChanged={(value) => {
                onValueChange(value);
              }}
              value={value}
              disabled={disabled}
              calendarOptions={{
                maxZoomLevel: "year",
                minZoomLevel: "century",
              }}
              tabIndex={tabIndex}
              width={width}
            />
          );
        }}
        control={control}
        name={name}
        defaultValue={null}
      />
    </Input>
  );
};

export default InputMonth;
