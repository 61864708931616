import React from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseBtn } from "@/assets/svg/Close.svg";
import * as S from "./modal.styles";

interface ModalProps {
  width?: string;
  close?: any;
  title: string;
  titleContent?: any;
  children?: React.ReactElement;
  modalButton?: any;
  formSubmit?: any;
}

function Modal({
  width = "400px",
  close,
  title,
  titleContent,
  children,
  modalButton,
  formSubmit,
}: ModalProps) {
  const { t } = useTranslation("main");

  return (
    // Modal 배경
    <S.ModalBackground>
      {/* Modal Wrap */}
      <S.ModalContainer width={width}>
        <S.ModalHeader>
          <div className="modal-header">
            <span className="modal-title">{t(title)}</span>
            {/* Modal 내용 */}
            {titleContent && titleContent}
          </div>
          <S.ModalCloseButton onClick={close}>
            <CloseBtn />
          </S.ModalCloseButton>
        </S.ModalHeader>

        <S.ModalFormBox>
          <S.ModalFormContent>
            {/* Modal 내용 */}
            {children}
          </S.ModalFormContent>

          {modalButton && <S.ModalButtonsBox>{modalButton}</S.ModalButtonsBox>}
        </S.ModalFormBox>
      </S.ModalContainer>
    </S.ModalBackground>
  );
}

export { Modal };
