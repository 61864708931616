import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import * as yup from "yup";
import {
  Column,
  Editing,
  Lookup,
  Paging,
  Scrolling,
} from "devextreme-react/data-grid";
import { useTranslation } from "react-i18next";
import { userInfo } from "@/state/atoms/userInfo";
import { TemplateType } from "@/types/closing.type";
import { request } from "@/util/api";
import {
  useDeleteMutation,
  usePostMutation,
  usePutMutation,
} from "@/util/common.fn";
import { useMutation } from "@tanstack/react-query";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import useButtonLoading from "@/hooks/useButtonLoading";
import { yupResolver } from "@hookform/resolvers/yup";
import Grid from "@/components/atoms/Grid";
import { Buttons } from "@/components/atoms/Buttons";
import { InputSelect, InputText } from "@/components/atoms/Inputs";
import { Modal } from "..";
import * as S from "./SpecificationTemplateModal.style";

interface SpecificationTemplateModalProps {
  onClose: () => void;
  onSelect: (data: any) => void;

  selectedRowData: any;
}

const SpecificationTemplateModal = ({
  onClose,
  onSelect,
  selectedRowData,
}: SpecificationTemplateModalProps) => {
  const userData = useRecoilValue(userInfo);
  const [templateType, setTemplateType] = useState<"new" | "edit">("new");
  const [editTemplateModal, setEditTemplateModal] = useState<boolean>(false);
  const { alertErrorMessage } = useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { data: templete, refetch } = useCustomQuery<any[]>(`trade-sheet`, {});
  const { mutateAsync: companyInfo } = useMutation({
    mutationFn: (params: any) => {
      return request<
        {
          tel: string;
          fax: string;
          billManage: string;
          acountNo: string;
          depositOwner: string;
          bank: string;
        }[]
      >({ method: "GET", url: `/finish-sell-manage/trade-modal`, params });
    },
  });

  const schema = yup.object({
    templete: yup.string().required("거래명세서 양식을 선택해주세요"),
  });
  const {
    handleSubmit,
    getValues,
    setValue,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getCompanyInfo();
    // eslint-disable-next-line
  }, []);

  const getCompanyInfo = async () => {
    setLoading();
    try {
      const data = await companyInfo(selectedRowData);

      setValue("bank", data[0].bank);
      setValue("accountNo", data[0].acountNo);
      setValue("owner", data[0].depositOwner);
      setValue("manager", userData.userName);
      setValue("tel", data[0].tel);
      setValue("fax", data[0].fax);
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onSubmit = (data: TemplateType) => {
    onSelect(data);
  };

  return (
    <>
      <Modal
        width="600px"
        close={onClose}
        title="템플릿 선택"
        modalButton={
          <Buttons
            permission="save"
            type="submit"
            size="lg"
            width={120}
            layout="solid"
            color="save"
            label="명세서 출력"
          />
        }
        formSubmit={handleSubmit(onSubmit)}
      >
        <S.STModalContent>
          <S.STModalTopSection>
            <InputSelect
              control={control}
              errors={errors}
              label="날짜구분"
              dataSource={[
                { id: 1, name: "접수일자" },
                { id: 0, name: "운송일자" },
              ]}
              valueExpr="id"
              displayExpr="name"
              name="searchType"
            />
            <InputSelect
              control={control}
              errors={errors}
              label="거래명세서양식"
              dataSource={templete}
              valueExpr="oid"
              displayExpr="title"
              name="templete"
            />
            <div>
              <Buttons
                type="button"
                size="sm"
                layout="outline"
                color="primary100"
                label="신규양식"
                onClick={() => {
                  setEditTemplateModal(true);
                  setTemplateType("new");
                }}
                // disabled={issueLoading || minusIssueLoading}
              />
              <Buttons
                type="button"
                size="sm"
                layout="outline"
                color="find"
                label="양식수정"
                onClick={() => {
                  const template = getValues("templete");

                  if (!template) {
                    alertErrorMessage("양식을 선택해주세요");
                    return;
                  }
                  setEditTemplateModal(true);
                  setTemplateType("edit");
                }}
                // disabled={issueLoading || minusIssueLoading}
              />
            </div>
          </S.STModalTopSection>

          <S.STModalBottomSection>
            <InputText
              control={control}
              errors={errors}
              label="은행명"
              name="contactName"
              className="form-s"
              maxLength={100}
            />
            <InputText
              control={control}
              errors={errors}
              label="계좌번호"
              name="accountNo"
              className="form-s"
              maxLength={100}
            />
            <InputText
              control={control}
              errors={errors}
              label="예금주"
              name="owner"
              className="form-s"
              maxLength={100}
            />
            <div />
            <InputText
              control={control}
              errors={errors}
              label="담당자"
              name="manager"
              className="form-s"
              maxLength={100}
            />
            <InputText
              control={control}
              errors={errors}
              label="전화번호"
              name="tel"
              className="form-s"
              maxLength={100}
            />
            <InputText
              control={control}
              errors={errors}
              label="팩스번호"
              name="fax"
              className="form-s"
              maxLength={100}
            />
          </S.STModalBottomSection>
        </S.STModalContent>
      </Modal>
      {editTemplateModal && (
        <EditTemplateModal
          templete={templete}
          templateOid={getValues("templete")}
          templateType={templateType}
          onClose={() => {
            setValue("templete", undefined);
            refetch();
            setEditTemplateModal(false);
          }}
        />
      )}
    </>
  );
};

interface EditTemplateModalProps {
  onClose: () => void;
  templete: any[] | undefined;
  templateOid: any;
  templateType: "new" | "edit";
}

const EditTemplateModal = ({
  onClose,
  templete,
  templateOid,
  templateType,
}: EditTemplateModalProps) => {
  const { t } = useTranslation("main");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<
    {
      oid: string;
      useYn: boolean;
      columnName: string;
      printName: string;
      width: number;
      sortText: string;
      sortNo?: number;
    }[]
  >([
    {
      oid: "34",
      useYn: false,
      columnName: "",
      printName: "No",
      width: 30,
      sortText: "가운데",
    },
    {
      oid: "1",
      useYn: false,
      columnName: "deliverDate",
      printName: "운송일자",
      width: 70,
      sortText: "가운데",
    },
    {
      oid: "2",
      useYn: false,
      columnName: "price",
      printName: "공급가액",
      width: 80,
      sortText: "우측",
    },
    {
      oid: "3",
      useYn: false,
      columnName: "containerNo",
      printName: "컨테이너번호",
      width: 80,
      sortText: "가운데",
    },
    {
      oid: "4",
      useYn: false,
      columnName: "containerType",
      printName: "컨규격",
      width: 80,
      sortText: "가운데",
    },
    {
      oid: "420",
      useYn: false,
      columnName: "sealNo",
      printName: "Seal No",
      width: 80,
      sortText: "가운데",
    },
    {
      oid: "5",
      useYn: false,
      columnName: "remark",
      printName: "비고",
      width: 100,
      sortText: "좌측",
    },
    {
      oid: "6",
      useYn: false,
      columnName: "dispatchTeamName",
      printName: "배차팀",
      width: 100,
      sortText: "좌측",
    },
    {
      oid: "7",
      useYn: false,
      columnName: "billingPlaceName",
      printName: "청구처",
      width: 100,
      sortText: "좌측",
    },
    {
      oid: "8",
      useYn: false,
      columnName: "orderCompany",
      printName: "발주처",
      width: 100,
      sortText: "좌측",
    },
    {
      oid: "9",
      useYn: false,
      columnName: "forwarder",
      printName: "선사",
      width: 100,
      sortText: "좌측",
    },
    {
      oid: "10",
      useYn: false,
      columnName: "shipName",
      printName: "선명",
      width: 100,
      sortText: "좌측",
    },
    {
      oid: "11",
      useYn: false,
      columnName: "motherShip",
      printName: "모선/항차",
      width: 100,
      sortText: "좌측",
    },
    {
      oid: "12",
      useYn: false,
      columnName: "cargoOwnerName",
      printName: "화주",
      width: 100,
      sortText: "좌측",
    },
    {
      oid: "13",
      useYn: false,
      columnName: "dem",
      printName: "선사반출기한",
      width: 70,
      sortText: "가운데",
    },
    {
      oid: "14",
      useYn: false,
      columnName: "enterDay",
      printName: "입항일",
      width: 70,
      sortText: "가운데",
    },
    {
      oid: "15",
      useYn: false,
      columnName: "sailDay",
      printName: "출항일",
      width: 70,
      sortText: "가운데",
    },
    {
      oid: "19",
      useYn: false,
      columnName: "blNo",
      printName: "B/L No",
      width: 70,
      sortText: "가운데",
    },
    {
      oid: "20",
      useYn: false,
      columnName: "bkNo",
      printName: "B/K No",
      width: 70,
      sortText: "가운데",
    },
    {
      oid: "21",
      useYn: false,
      columnName: "doNo",
      printName: "DO No",
      width: 70,
      sortText: "가운데",
    },
    {
      oid: "22",
      useYn: false,
      columnName: "blNoM",
      printName: "마스터 B/L",
      width: 100,
      sortText: "가운데",
    },
    {
      oid: "23",
      useYn: false,
      columnName: "blNoH",
      printName: "하우스 B/L",
      width: 100,
      sortText: "가운데",
    },
    {
      oid: "24",
      useYn: false,
      columnName: "carNo",
      printName: "차량번호",
      width: 100,
      sortText: "가운데",
    },
    {
      oid: "25",
      useYn: false,
      columnName: "tax",
      printName: "부가세",
      width: 80,
      sortText: "우측",
    },
    {
      oid: "26",
      useYn: false,
      columnName: "totalPrice",
      printName: "합계금액",
      width: 80,
      sortText: "우측",
    },
    {
      oid: "27",
      useYn: false,
      columnName: "amount",
      printName: "수량",
      width: 50,
      sortText: "가운데",
    },
    {
      oid: "28",
      useYn: false,
      columnName: "importExportName",
      printName: "수입/수출",
      width: 50,
      sortText: "가운데",
    },
    {
      oid: "29",
      useYn: false,
      columnName: "extraRemark",
      printName: "청구비고",
      width: 100,
      sortText: "가운데",
    },
    {
      oid: "30",
      useYn: false,
      columnName: "etcHabulRemark",
      printName: "하불비고",
      width: 100,
      sortText: "가운데",
    },
    {
      oid: "31",
      useYn: false,
      columnName: "manager",
      printName: "청구담당자",
      width: 100,
      sortText: "가운데",
    },
    {
      oid: "32",
      useYn: false,
      columnName: "extraInfo",
      printName: "특이사항",
      width: 100,
      sortText: "가운데",
    },
    {
      oid: "33",
      useYn: false,
      columnName: "cargoWeight",
      printName: "중량",
      width: 100,
      sortText: "우측",
    },
    {
      oid: "350",
      useYn: false,
      columnName: "realCargoOwner",
      printName: "실화주",
      width: 100,
      sortText: "우측",
    },
    {
      oid: "380",
      useYn: false,
      columnName: "extraChargeDivision",
      printName: "청구구분",
      width: 100,
      sortText: "우측",
    },
    {
      oid: "390",
      useYn: false,
      columnName: "deliverCompleteDate",
      printName: "운송마감일자",
      width: 100,
      sortText: "우측",
    },
  ]);

  const gridRef: any = useRef();

  const { confirmMessage, alertSuccessMessage, alertErrorMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { mutateAsync: companyInfo } = useMutation({
    mutationFn: (oid: any) => {
      return request<
        {
          oid: string;
          tradeSheetMainOid: string;
          useYn: string;
          sortNo: number;
          columnName: string;
          printName: string;
          width: number;
          sortText: string;
          createId: string;
          createIp: string;
          updateId: string;
          updateIp: string;
          createDate: string;
          updateDate: string;
        }[]
      >({ method: "GET", url: `/trade-sheet/${oid}` });
    },
  });

  const { mutateAsync: create, status: createL } =
    usePostMutation(`trade-sheet`);
  const { mutateAsync: update, status: updateL } =
    usePutMutation(`trade-sheet`);
  const { mutateAsync: remove, status: deleteL } =
    useDeleteMutation(`trade-sheet`);

  useEffect(() => {
    if (
      createL === "pending" ||
      updateL === "pending" ||
      deleteL === "pending"
    ) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [createL, updateL, deleteL]);

  useEffect(() => {
    if (templateType === "edit" && templateOid && templete) {
      const filteredTempleteData = templete.filter(
        (cur) => cur.oid === templateOid
      );

      setValue("oid", templateOid);
      setValue("title", filteredTempleteData[0].title);
      getTempleteSubData();
    }
    // eslint-disable-next-line
  }, []);

  const schema = yup.object({
    title: yup.string().required("거래명세서 양식명을 입력해주세요"),
  });
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: any) => {
    setLoading();
    const gridInstance = gridRef.current.instance;
    const rows = gridInstance.getVisibleRows().map((cur: any) => cur.data);
    let res: any;
    try {
      if (templateType === "edit") {
        res = await update({
          data: data,
          rows,
        });
      } else if (templateType === "new") {
        res = await create({
          data: data,
          rows,
        });
      }

      if (res.state === 1) {
        await alertSuccessMessage("저장 완료");
      }

      onClose();
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onClickDeleteTemplete = async () => {
    setLoading();
    const result = await confirmMessage("삭제하시겠습니까?");
    if (!result) return;

    try {
      const res: any = await remove({ oid: templateOid });

      // delete되면 이전 모달에서 거래명세서양식 refetch
      if (res.state === 1) {
        await alertSuccessMessage("삭제 완료");
      }

      onClose();
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const getTempleteSubData = async () => {
    setLoading();
    try {
      const data = await companyInfo(templateOid);

      const result = dataSource.map((item) => {
        const matchingItem = data.find(
          (templateItem) => templateItem.columnName === item.columnName
        );

        item.sortNo = matchingItem?.sortNo;
        item.width = matchingItem?.width || item.width;
        item.sortText = matchingItem?.sortText || item.sortText;
        item.oid = matchingItem?.oid || item.oid;
        item.useYn = matchingItem?.useYn === "1" ? true : false;
        return item;
      });

      setDataSource(result);
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  return (
    <Modal
      width="600px"
      close={onClose}
      title="신규 양식"
      formSubmit={handleSubmit(onSubmit)}
    >
      <S.STModalContent>
        <S.STModalTopSection>
          <InputText
            control={control}
            errors={errors}
            name="title"
            label="양식명"
          />
          <div>
            <Buttons
              type="submit"
              size="sm"
              layout="solid"
              width={50}
              color="save"
              label="저장"
              disabled={isLoading}
            />
            {templateType === "edit" && (
              <Buttons
                type="button"
                size="sm"
                layout="solid"
                width={50}
                color="destructive"
                label="삭제"
                onClick={onClickDeleteTemplete}
                disabled={isLoading}
              />
            )}
          </div>
        </S.STModalTopSection>

        <S.STModalGrid>
          <Grid
            subGrid={true}
            dataSource={dataSource}
            refs={gridRef}
            onToolbarPreparing={(e: any) => {
              e.toolbarOptions.items.forEach((item: any) => {
                item.visible = false;
              });
            }}
            keyExpr="oid"
          >
            <Scrolling showScrollbar="always" useNative="false" />
            <Paging enabled={true} pageSize={1000} />
            <Editing mode="batch" allowUpdating={true} />
            <Column
              dataField="useYn"
              caption={t("사용체크")}
              dataType="boolean"
            />
            <Column dataField="sortNo" caption={t("순서")} />
            <Column
              dataField="columnName"
              caption={t("컬럼명")}
              visible={false}
            />
            <Column dataField="printName" caption={t("컬럼명")} />
            <Column dataField="printName" caption={t("출력명")} />
            <Column dataField="width" caption={t("넓이")} />
            <Column dataField="sortText" caption={t("정렬")}>
              <Lookup
                dataSource={[
                  { name: "좌측" },
                  { name: "가운데" },
                  { name: "우측" },
                ]}
                displayExpr="name"
                valueExpr="name"
              />
            </Column>
          </Grid>
        </S.STModalGrid>
      </S.STModalContent>
    </Modal>
  );
};

export default SpecificationTemplateModal;
