export const inputType = {
  STRING: "string",
  NUMBER: "number",
  DATE: "date",
} as const;

export interface AccountExcelFileOptionsType {
  column: string;
  title: string;
  type: (typeof inputType)[keyof typeof inputType];
  required: boolean;
}

export interface ExcelType {
  거래일시: string;
  계좌번호: string;
  기재내용: string;
  메모: string;
  은행명: string;
  입금: string;
  잔고: string;
  출금: string;
  [key: string]: string;
}

export const AccountExcelFileOptions: AccountExcelFileOptionsType[] = [
  {
    column: "accountNo",
    title: "계좌번호",
    type: inputType.STRING,
    required: true,
  },
  {
    column: "bankName",
    title: "은행명",
    type: inputType.STRING,
    required: true,
  },
  {
    column: "information",
    title: "기재내용",
    type: inputType.STRING,
    required: false,
  },
  {
    column: "deposit",
    title: "입금",
    type: inputType.NUMBER,
    required: true,
  },
  {
    column: "withdraw",
    title: "출금",
    type: inputType.NUMBER,
    required: true,
  },
  {
    column: "balance",
    title: "잔고",
    type: inputType.NUMBER,
    required: true,
  },
  {
    column: "remark",
    title: "메모",
    type: inputType.STRING,
    required: false,
  },
  {
    column: "transDate",
    title: "거래일시",
    type: inputType.DATE,
    required: true,
  },
];
