import styled from "styled-components";

export const ModalTaxIssueWrapper = styled.div<any>`
  position: relative;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  word-break: break-all;
  border-collapse: separate;
  border-spacing: 0;
  flex-direction: column;

  .readonly {
    background-color: #eaeaea !important;
    textarea {
      background-color: #eaeaea !important;
    }
  }
`;

export const ModalTaxIssueHeader = styled.div<any>`
  display: flex;
  width: 100%;
  padding: 10px;
  background: ${(props) => props.theme.colors.invoiceCell};
  border-top: 2px solid ${(props) => props.theme.colors.invoiceBorder};
  justify-content: space-between;
`;

export const ModalTaxIssueTitle = styled.span<any>`
  display: flex;
  width: ${(props) => (props.tradeset ? "auto" : "100%")};
  height: 100%;
  font-size: ${(props) => (props.tradeset ? "1.3rem" : "2.6rem")};
  font-weight: ${(props) => (props.tradeset ? "400" : "600")};
  align-items: center;
  justify-content: ${(props) => (props.tradeset ? "flex-start" : "center")};

  span {
    font-size: 2rem;
    font-weight: 500;
  }
`;

export const ModalTaxIssueBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 6px;
`;

export const InvoiceWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const Invoice = styled.div`
  display: grid;
  width: 50%;
  grid-template-columns: 30px 70px 1fr 70px 1fr;
  grid-template-rows: repeat(6, 1fr);

  &.invoicer {
    border-top: 2px solid ${(props) => props.theme.colors.invoicerHeader};
    border-bottom: 2px solid ${(props) => props.theme.colors.invoicerHeader};

    .invoicer-tit {
      background-color: ${(props) => props.theme.colors.invoicerHeader};
      color: ${(props) => props.theme.colors.invoicerCellTit};
      font-weight: bold;
      line-height: 2rem;
    }

    .item-tit {
      border-bottom-color: ${(props) => props.theme.colors.black12};
      border-right-color: ${(props) => props.theme.colors.black12};
      background-color: ${(props) => props.theme.colors.white100};
      color: ${(props) => props.theme.colors.invoicerCellTit};
    }
  }

  &.invoicee {
    border-top: 2px solid ${(props) => props.theme.colors.invoiceeHeader};
    border-bottom: 2px solid ${(props) => props.theme.colors.invoiceeHeader};

    .invoicee-tit {
      background-color: ${(props) => props.theme.colors.invoiceeHeader};
      color: ${(props) => props.theme.colors.invoiceeCellTit};
      font-weight: bold;
      line-height: 2rem;
    }

    .item-tit {
      border-bottom-color: ${(props) => props.theme.colors.black12};
      border-right-color: ${(props) => props.theme.colors.black12};
      background-color: ${(props) => props.theme.colors.white100};
      color: ${(props) => props.theme.colors.invoiceeCellTit};
    }
  }
`;

export const InvoiceItems = styled.div<any>`
  -webkit-print-color-adjust: exact;
  display: flex;
  height: ${(props) => (props.$gridRow ? "100%" : "36px")};
  padding: 3px;
  border-top-width: 0;
  border-left-width: 0;
  border: 0.5px solid ${(props) => props.theme.colors.invoiceInnerBorder};
  text-align: center;
  word-break: break-all;
  align-items: center;
  justify-content: center;
  grid-row: ${(props) =>
    props.$gridTop ? props.$gridTop + " / " + props.$gridBottom : "initial"};
  grid-column: ${(props) =>
    props.$gridLeft ? props.$gridLeft + " / " + props.$gridRight : "initial"};

  &.invoicer-tit {
    color: ${(props) => props.theme.colors.invoicerTit};
    border: none;
    background: ${(props) => props.theme.colors.invoicerHeader};
  }

  &.invoicee-tit {
    color: ${(props) => props.theme.colors.invoiceeTit};
    border: none;
    background: ${(props) => props.theme.colors.invoiceeHeader};
  }

  &.invoicer-item-tit {
    color: ${(props) => props.theme.colors.invoicerCellTit};
    background: ${(props) => props.theme.colors.invoicerCell};
  }

  &.invoicee-item-tit {
    color: ${(props) => props.theme.colors.invoiceeCellTit};
    background: ${(props) => props.theme.colors.invoiceeCell};
  }

  & > div {
    width: 100%;
    grid-template-columns: 100% !important;

    div {
      input,
      textarea {
        height: 24px;
        line-height: 20px;
        border: none;
        padding: 2px 5px;
      }
    }
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  width: 100%;
  border-top: 2px solid ${(props) => props.theme.colors.invoiceBorder};
  border-bottom: 2px solid ${(props) => props.theme.colors.invoiceBorder};
  grid-template-columns: 120px 1fr 1fr 1fr;
  grid-template-rows: repeat(2, 1fr);
`;

export const InfoItems = styled.div`
  display: flex;
  padding: 3px;
  align-items: center;
  justify-content: center;

  &.item-tit {
    color: ${(props) => props.theme.colors.invoiceCellTit};
    background: ${(props) => props.theme.colors.invoiceCell};
    border-bottom: 1px solid ${(props) => props.theme.colors.invoiceInnerBorder};
  }

  & + div {
    border-left: 1px solid ${(props) => props.theme.colors.invoiceInnerBorder};

    &.no-border {
      border-left: none;
    }
  }

  & > div {
    width: 100%;
    grid-template-columns: 100% !important;

    div {
      input,
      textarea {
        height: 24px;
        line-height: 20px;
        border: none;
        padding: 2px 5px;
      }
    }
  }
`;

export const AdditionalWrapper = styled.div<any>`
  display: grid;
  width: 100%;
  margin: ${(props: any) => (props.tradeset ? "0 0 10px" : "0")};
  border-top: 2px solid ${(props) => props.theme.colors.invoiceBorder};
  border-bottom: 2px solid ${(props) => props.theme.colors.invoiceBorder};
  grid-template-columns: ${(props) => (props.tradeset ? "100%" : "120px 1fr")};
  grid-template-rows: 1fr;
`;

export const AdditionalItems = styled.div`
  display: flex;
  padding: 3px;
  border-bottom: 1px solid ${(props) => props.theme.colors.invoiceInnerBorder};
  align-items: center;
  justify-content: center;
  gap: 4px;

  &.item-tit {
    color: ${(props) => props.theme.colors.invoiceCellTit};
    background: ${(props) => props.theme.colors.invoiceCell};
    border-right: 1px solid ${(props) => props.theme.colors.invoiceInnerBorder};
  }

  & > div {
    width: 100%;
    grid-template-columns: 100% !important;

    div {
      input,
      textarea {
        height: 24px;
        line-height: 20px;
        border: none;
        padding: 2px 5px;
      }
    }
  }
`;

export const DetailWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 6px;
  flex-direction: column;

  & > button {
    margin-bottom: 6px;
  }
`;

export const DetailItemsList = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 40px 40px 1fr 60px 60px 90px 90px 90px 1fr 100px;
`;

export const DetailItems = styled.div`
  display: flex;
  height: 36px;
  padding: 3px;
  border-bottom: 1px solid ${(props) => props.theme.colors.invoiceInnerBorder};
  align-items: center;
  justify-content: center;

  &.grid-full {
    padding: 30px 0;
    grid-column: 1 / 11;
  }

  &.item-tit {
    color: ${(props) => props.theme.colors.invoiceCellTit};
    background: ${(props) => props.theme.colors.invoiceCell};
    border-top: 2px solid ${(props) => props.theme.colors.invoiceBorder};
  }

  & + div {
    border-left: 1px solid ${(props) => props.theme.colors.invoiceInnerBorder};
  }

  & > div {
    width: 100%;
    grid-template-columns: 100% !important;

    div {
      input,
      textarea {
        height: 24px;
        line-height: 20px;
        border: none;
        padding: 2px 5px;
      }
    }
  }
`;

export const CloseWrapper = styled.div<any>`
  display: grid;
  width: 100%;
  border-top: 2px solid ${(props) => props.theme.colors.invoiceBorder};
  border-bottom: 2px solid ${(props) => props.theme.colors.invoiceBorder};
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, 1fr);
`;

export const CloseItems = styled.div<any>`
  display: flex;
  height: 32px;
  padding: 3px;
  align-items: center;
  justify-content: center;
  grid-row: ${(props: any) =>
    props.$gridTop ? props.$gridTop + " / " + props.$gridBottom : "initial"};
  grid-column: ${(props: any) =>
    props.$gridLeft ? props.$gridLeft + " / " + props.$gridRight : "initial"};

  &.item-tit {
    color: ${(props) => props.theme.colors.invoiceCellTit};
    background: ${(props) => props.theme.colors.invoiceCell};
    border-bottom: 1px solid ${(props) => props.theme.colors.invoiceInnerBorder};
  }

  &.item-finish {
    height: 64px;
    flex-direction: column;
    gap: 5px;

    div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      label {
        display: flex;
        font-size: 1.3rem;
        align-items: center;
        gap: 5px;

        input[type="radio"] {
          display: inline-block;
          width: 13px;
          height: 13px;
          line-height: 1;
          vertical-align: baseline;
          cursor: pointer;
        }
      }
    }
  }

  & + div {
    border-left: 1px solid ${(props) => props.theme.colors.invoiceInnerBorder};

    &.no-border {
      border-left: none;
    }
  }

  & > div {
    width: 100%;
    grid-template-columns: 100% !important;

    div {
      input,
      textarea {
        height: 24px;
        line-height: 20px;
        border: none;
        padding: 2px 5px;
      }
    }
  }
`;

export const TaxButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 6px;
`;
