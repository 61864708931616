import AsideMenuItem from "@/components/atoms/AsideMenuItem";
import * as S from "./AsideMenuList.style";

export interface AsideMenuProps {
  menuId?: string;
  menuDepth?: number;
  menuOpen?: boolean;
  asideOpen?: boolean;
  depthOpen?: boolean;
}

export const AsideMenuList = ({
  menuId,
  menuDepth,
  asideOpen,
  depthOpen,
}: AsideMenuProps) => {
  return (
    <S.AsideMenuList
      menuDepth={menuDepth}
      depthOpen={menuDepth ? depthOpen : true}
    >
      <AsideMenuItem
        asideOpen={asideOpen}
        menuDepth={menuDepth ? menuDepth : 0}
        menuId={menuId && menuId}
      />
    </S.AsideMenuList>
  );
};
