import { useTranslation } from "react-i18next";
import * as S from "./Input.style";

export interface InputProps {
  type?: string;
  value?: any;
  control?: any;
  errors?: any;
  name?: any;
  label?: string;
  text?: string;
  mask?: any;
  required?: boolean;
  disabled?: any;
  onValueChanged?: any;
  width?: any;
  height?: any;
  maxLength?: number;
  tabIndex?: number;
  placeHolder?: string;
  displayFormat?: any;
  direction?: "row" | "column";
  children?: React.ReactElement;
  className?: string;
  dataSource?: any;
  displayExpr?: any;
  valueExpr?: any;
  index?: any;
  arrayList?: any;
  fieldName?: any;
}

export const Input = ({
  type,
  errors,
  name,
  label,
  required,
  direction,
  children,
  arrayList,
  index,
  fieldName,
}: InputProps) => {
  const { t } = useTranslation("main");

  return (
    <S.InputBox>
      <S.Input type={type} direction={direction}>
        {label && (
          <S.InputLabel direction={direction}>
            {t(label)} {required && <span>*</span>}
          </S.InputLabel>
        )}

        {children}
      </S.Input>

      {errors[name]?.message && (
        <S.InputErroredTxt>{errors[name]?.message}</S.InputErroredTxt>
      )}
      {arrayList && arrayList[index] && (
        <S.InputHelpTxt>{arrayList[index][fieldName]?.message}</S.InputHelpTxt>
      )}
    </S.InputBox>
  );
};

export { default as InputAddress } from "./InputAddress";
export { default as InputCheckBox } from "./InputCheckBox";
export { default as InputColorBox } from "./InputColorBox";
export { default as InputDate } from "./InputDate";
export { default as InputLookup } from "./InputLookup";
export { default as InputMonth } from "./InputMonth";
export { default as InputNumber } from "./InputNumber";
export { default as InputPassword } from "./InputPassword";
export { default as InputRadioBox } from "./InputRadioBox";
export { default as InputSelect } from "./InputSelect";
export { default as InputText } from "./InputText";
export { default as InputTextArea } from "./InputTextArea";
