import { request } from "@/util/api";
import { userType } from "./../../types/user.type";
import { useQuery } from "@tanstack/react-query";
import { readAccessToken, readRefreshToken } from "@/util/localStorage";
import { useSetRecoilState } from "recoil";
import { userInfo } from "@/state/atoms/userInfo";
import { isAuthorized } from "@/state/atoms/isAuthorized";
import { useEffect } from "react";

function useGetUserInfo() {
  const setUserInfo = useSetRecoilState(userInfo);
  const setAuthorized = useSetRecoilState(isAuthorized);
  const accessToken = readAccessToken();
  const refreshToken = readRefreshToken();

  const rq = useQuery<{ user: userType }>({
    queryKey: ["userInfo"],
    queryFn: () => request({ method: "GET", url: "/user/user-info" }),
    enabled: !!accessToken,
  });
  useEffect(() => {
    if (rq.data) {
      setUserInfo(rq.data.user);
      // header에 userId넣기
    }
    if (!refreshToken) setAuthorized(false);
    // eslint-disable-next-line
  }, [rq, refreshToken]);

  return rq;
}

export { useGetUserInfo };
