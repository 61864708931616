import { GridButtonSet } from "@/components/molecules/GridButtonSet";
import * as S from "./DetailContent.style";

export interface DetailContentProps {
  position?: string;
  gridRef?: React.MutableRefObject<any>;
  storageKey?: string;
  detailSectionTab?: React.ReactElement;
  detailSectionButtons?: React.ReactElement;
  children?: React.ReactElement;
  totalCount?: number;
}

export const DetailContent = ({
  position,
  gridRef,
  storageKey,
  detailSectionTab,
  detailSectionButtons,
  children,
  totalCount,
}: DetailContentProps) => {
  const number = (e: number) => {
    return e?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <S.DetailContent position={position}>
      <>
        {detailSectionButtons && (
          <S.DetailSectionTop>
            <S.DetailSectionTab>
              {detailSectionTab && detailSectionTab}
            </S.DetailSectionTab>
            <S.DetailSectionButtons>
              {detailSectionButtons}
            </S.DetailSectionButtons>
          </S.DetailSectionTop>
        )}
        {storageKey && (
          <GridButtonSet dataGridRef={gridRef} storageKey={storageKey} />
        )}
        {children}
        {totalCount
          ? totalCount > 0 && (
              <S.DetailTotal>{`총 ${number(totalCount)}건`}</S.DetailTotal>
            )
          : ""}
      </>
    </S.DetailContent>
  );
};
