import { AsideMenuProps } from "@/components/molecules/AsideMenuList";
import styled, { css } from "styled-components";

export const AsideMenuItemBox = styled.li<AsideMenuProps>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`;

export const AsideMenuTitleBox = styled.div<AsideMenuProps>`
  display: flex;
  font-size: 1.4rem;
  align-items: center;
  gap: 12px;
  transition: all 0.2s ease;

  // 메뉴 Depth-0 Style
  ${(props) =>
    props.menuDepth === 0 &&
    css`
      color: ${(props) => props.theme.colors.white100};
    `}

  // 메뉴 Depth-1 Style
  ${(props) =>
    props.menuDepth === 1 &&
    css`
      color: ${props.menuOpen
        ? props.theme.colors.primary100
        : props.theme.colors.menuTxt};
      font-weight: ${props.menuOpen ? "600" : "400"};
    `}

  // 사이드바 닫기 Style
  ${(props) =>
    props.asideOpen === false &&
    css`
      span {
        display: none;
      }
    `}
`;

export const AsideMenuItem = styled.div<AsideMenuProps>`
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease;

  // 메뉴 Depth-0 Style
  ${(props) =>
    props.menuDepth === 0 &&
    css`
      padding: 0 20px;
      background: ${props.menuOpen
        ? props.theme.colors.primary100Hover
        : "none"};

      svg {
        path {
          fill: ${(props) => props.theme.colors.white100};
        }
      }

      &:hover {
        background: ${(props) => props.theme.colors.primary100Hover};
      }
    `}

  // 메뉴 Depth-1 Style
  ${(props) =>
    props.menuDepth === 1 &&
    css`
      padding: 0 30px;
      background: ${props.menuOpen ? props.theme.colors.primary12 : "none"};

      svg {
        path {
          fill: ${props.menuOpen
            ? props.theme.colors.primary100
            : props.theme.colors.black20};
        }
      }

      &:hover {
        background: ${(props) => props.theme.colors.primary12};

        ${AsideMenuTitleBox} {
          color: ${(props) => props.theme.colors.primary100};
          font-weight: 600;

          svg {
            path {
              fill: ${(props) => props.theme.colors.primary100};
            }
          }
        }
      }
    `}


  // 사이드바 닫기 Style
  ${(props) =>
    props.asideOpen === false &&
    css`
      padding: 0;
      justify-content: center;

      & > svg {
        display: none;
      }
    `}
`;
