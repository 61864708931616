import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  Format,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import Grid from "@/components/atoms/Grid";
import InputMonth from "@/components/atoms/Inputs/InputMonth";
import { GridContent } from "@/components/organisms/Contents/GridContent";

interface BankAccountManageMainGridProps {
  bottomHeight: number;
  gridMain: React.MutableRefObject<any>;
  mainData: any[] | undefined;

  onFocusedRowChanged: (e: any) => void;

  control: any;
  errors: any;
  searchSubmit: any;
}

const BankAccountManageMainGrid = ({
  bottomHeight,
  gridMain,
  mainData,
  onFocusedRowChanged,
  control,
  errors,
  searchSubmit,
}: BankAccountManageMainGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "BankAccountManageMainGrid";
  const [totalCount, setTotalCount] = useState<number>(0);
  return (
    <GridContent
      bottomHeight={bottomHeight && bottomHeight}
      searchSection={
        <InputMonth
          label="월선택"
          name="month"
          width={100}
          control={control}
          errors={errors}
        />
      }
      searchSubmit={searchSubmit}
      totalCount={totalCount}
      gridRef={gridMain}
      storageKey={storageKey}
    >
      <Grid
        refs={gridMain}
        dataSource={mainData}
        onFocusedRowChanged={onFocusedRowChanged}
        focusedRowEnabled={true}
        onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
        keyExpr="oid"
      >
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} />
        <StateStoring
          enabled={true}
          type="custom"
          customLoad={useCallback(
            () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
            []
          )}
        />
        <Scrolling mode="virtual" />

        <Column dataField="accountNo" caption={t("계좌번호")} />
        <Column dataField="bankName" caption={t("은행명")} />
        <Column dataField="information" caption={t("기재내용")} />
        <Column dataField="deposit" caption={t("입금")}>
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="withdraw" caption={t("출금")}>
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="balance" caption={t("잔고")}>
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="remark" caption={t("메모")} />
        <Column dataField="transDate" caption={t("거래일시")} />
      </Grid>
    </GridContent>
  );
};

export default BankAccountManageMainGrid;
