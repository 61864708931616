import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import dayjs from "dayjs";
import {
  Column,
  ColumnChooser,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import { useCompanyData, useDispatchData } from "@/hooks/queries";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import { userInfo } from "@/state/atoms/userInfo";
import {
  InputCheckBox,
  InputDate,
  InputSelect,
} from "@/components/atoms/Inputs";
import { InputMultiple } from "@/components/atoms/InputMultiple";
import Grid from "@/components/atoms/Grid";
import { GridContent } from "@/components/organisms/Contents/GridContent";
import { ContentTemplate } from "@/components/templates/ContentTemplate";

const DangerManagePage = () => {
  const { t } = useTranslation("main");
  const userData = useRecoilValue(userInfo);
  const initialState = {
    receiveCo: userData.companyCode,
    dispatchTeam: userData?.deptCode || "",
    firstDay: dayjs().format("YYYY-MM-DD"),
    lastDay: dayjs().format("YYYY-MM-DD"),
    complete: false,
  };
  const storageKey = "DangerManagePage";
  const [search, setSearch] = useState(initialState);
  const [totalCount, setTotalCount] = useState<number>(0);
  const gridMain = useRef<any>();

  const { data: mainData, refetch: mainRefetch } = useCustomQuery(
    `dangerManage`,
    search
  );

  const { data: dispatch } = useDispatchData();
  const { data: company } = useCompanyData();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: initialState,
  });

  const onSearch = (data: any) => {
    setSearch(data);
    mainRefetch();
  };

  return (
    <ContentTemplate>
      <GridContent
        searchSection={
          <>
            <InputMultiple direction="row" label="기준일자">
              <>
                <InputDate
                  name="firstDay"
                  width={130}
                  control={control}
                  errors={errors}
                />
                ~
                <InputDate
                  name="lastDay"
                  width={130}
                  control={control}
                  errors={errors}
                />
              </>
            </InputMultiple>
            <InputSelect
              label="접수법인"
              name="receiveCo"
              width={140}
              displayExpr="companyName"
              valueExpr="id"
              control={control}
              errors={errors}
              dataSource={[
                { id: "", companyName: t("전체") },
                ...(company || []),
              ]}
            />
            <InputSelect
              label="배차팀"
              name="dispatchTeam"
              width={140}
              displayExpr="deptName"
              valueExpr="code"
              control={control}
              errors={errors}
              dataSource={[
                { code: "", deptName: t("전체") },
                ...(dispatch || []),
              ]}
            />

            <InputCheckBox
              label="하불손실조회"
              name="complete"
              control={control}
              errors={errors}
            />
          </>
        }
        searchSubmit={handleSubmit(onSearch)}
        gridRef={gridMain}
        storageKey={storageKey}
        totalCount={totalCount}
      >
        <Grid
          key="id"
          keyExpr="id"
          dataSource={mainData}
          refs={gridMain}
          focusedRowEnabled={true}
          onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
        >
          <HeaderFilter visible={true} />
          <ColumnChooser enabled={true} />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(
              () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
              []
            )}
          />
          <Scrolling mode="virtual" />

          <Column
            dataField="code"
            caption={t("접수번호")}
            alignment="center"
            width={140}
          />
          <Column
            dataField="receiveCoName"
            caption={t("접수법인")}
            width={120}
          />
          <Column dataField="cargoOwnerName" caption={t("화주")} width={120} />
          <Column
            dataField="billingPlaceName"
            caption={t("청구처")}
            width={120}
          />
          <Column dataField="orderCoName" caption={t("발주처")} width={120} />
          <Column
            dataField="deliverDate"
            caption={t("창고입고날짜")}
            alignment="center"
            width={110}
          />
          <Column dataField="bookingNo" caption={t("B/K No")} width={100} />
          <Column
            dataField="containerNo"
            caption={t("컨테이너번호")}
            width={100}
          />
          <Column dataField="sealNo" caption={t("Seal No")} width={100} />
          <Column
            dataField="containerType"
            caption={t("컨규격")}
            alignment="center"
            width={80}
          />
          <Column
            dataField="forwarder"
            caption={t("선사")}
            alignment="center"
            width={80}
          />
          <Column
            dataField="shipName"
            caption={t("선명")}
            alignment="center"
            width={80}
          />
          <Column
            dataField="loadPlace"
            caption={t("반입지")}
            alignment="center"
            width={100}
          />
          <Column
            dataField="enterDay"
            caption={t("입항")}
            dataType="date"
            format="yyyy-MM-dd"
            alignment="center"
            width={100}
          />
          <Column
            dataField="sailDay"
            caption={t("출항")}
            dataType="date"
            format="yyyy-MM-dd"
            alignment="center"
            width={100}
          />
          <Column
            dataField="temperature"
            caption={t("온도")}
            alignment="center"
            width={80}
          />
          <Column
            dataField="humidity"
            caption={t("습도")}
            alignment="center"
            width={80}
          />
          <Column
            dataField="vent"
            caption={t("환풍구")}
            alignment="center"
            width={80}
          />
          <Column
            dataField="unNo"
            caption={t("UN번호")}
            alignment="center"
            width={100}
          />
          <Column
            dataField="class"
            caption={t("클래스")}
            alignment="center"
            width={100}
          />
          <Column
            dataField="unloadPlace"
            caption={t("하차지")}
            alignment="center"
            width={100}
          />
          <Column
            dataField="cargoWeight"
            caption={t("총중량")}
            alignment="center"
            width={80}
          />
          <Column
            dataField="carNo"
            caption={t("차량번호")}
            alignment="center"
            width={100}
          />
          <Column
            dataField="motherShip"
            caption={t("모선/항차")}
            alignment="center"
            width={90}
          />
        </Grid>
      </GridContent>
    </ContentTemplate>
  );
};

export default DangerManagePage;
