import { useMutation } from "@tanstack/react-query";
import { request } from "./api";

export const groupBy = function (data: any, key: any) {
  if (!Array.isArray(data) || data.length === 0) {
    return { 0: [] };
  }

  return data.reduce(function (carry: any, el: any) {
    const group = el[key];
    if (carry[group] === undefined) {
      carry[group] = [];
    }

    carry[group].push(el);
    return carry;
  }, {});
};

export function useGetMutation(
  code: string,
  onSuccess: (result?: any) => void = () => {},
  beforeSend = (data: any) => data
) {
  return useMutation({
    mutationFn: (data: any) => {
      const sendData = beforeSend(data);

      return request({ method: "GET", url: `/${code}`, params: sendData });
    },
    onSuccess: (result: any) => {
      onSuccess(result);
    },
    onError: mutationOnErrorFunction,
  });
}

export function usePostMutation<T>(
  code: string,
  onSuccess: (result?: any) => void = () => {},
  beforeSend = (data: any) => data
) {
  return useMutation({
    mutationFn: (data: any) => {
      const sendData = beforeSend(data);

      return request<T>({ method: "POST", url: `/${code}`, data: sendData });
    },
    onSuccess: (result: any) => {
      onSuccess(result);
    },
    onError: mutationOnErrorFunction,
  });
}

export function usePutMutation<T>(
  code: string,
  onSuccess: (result?: any) => void = () => {},
  beforeSend = (data: any) => data
) {
  return useMutation({
    mutationFn: (data: any) => {
      const sendData = beforeSend(data);

      return request<T>({ method: "PUT", url: `/${code}`, data: sendData });
    },
    onSuccess: (result: any) => {
      onSuccess(result);
    },
    onError: mutationOnErrorFunction,
  });
}

export function useDeleteMutation(
  code: string,
  onSuccess: (result?: any) => void = () => {}
) {
  return useMutation({
    mutationFn: (data: any) =>
      request({ method: "DELETE", url: `/${code}`, params: data }),
    onSuccess: (result) => {
      onSuccess(result);
    },
    onError: mutationOnErrorFunction,
  });
}

export function mutationOnErrorFunction(err: any) {
  // console.log(err);
  // alert(err);
  // toast.error(err?.message || "ERROR");
  // console.log("[err]", err);
}

export const toAbsoluteUrl = (pathname: string) =>
  process.env.PUBLIC_URL + pathname;

// 컨테이너 CHECK-DIGIT 계산
// http://www.gvct.co.uk/2011/09/how-is-the-check-digit-of-a-container-calculated/ 참고
export const containerValidationCallback = (e: any) => {
  if (!e.value) return true;
  if (e.value.length !== 11) return false; // 11자리가 아니면 return
  const conRegex = /^[A-Z]{4}\d{7}$/;
  const result = conRegex.test(e.value); // 형식에 안맞으면 return;
  if (!result) return false;

  let sum = calculateCheckDigit(e.value);

  let mod = sum % 11;
  if (mod === 10) mod = 0;

  if (parseInt(e.value[e.value.length - 1]) !== mod) return false; // CheckDigit이 다르면 return

  return true;
};

function calculateCheckDigit(value: string) {
  let sum = 0;

  for (let i = 0; i < 10; i++) {
    if (value[i].charCodeAt(0) === 65) {
      // A일때
      let num: number = value[i].charCodeAt(0) - 55;

      sum += num * Math.pow(2, i);
    } else if (value[i].charCodeAt(0) > 65 && value[i].charCodeAt(0) <= 75) {
      // B-K일때
      let num = value[i].charCodeAt(0) - 54;

      sum += num * Math.pow(2, i);
    } else if (value[i].charCodeAt(0) > 75 && value[i].charCodeAt(0) <= 85) {
      // L-U일떄
      let num = value[i].charCodeAt(0) - 53;

      sum += num * Math.pow(2, i);
    } else if (value[i].charCodeAt(0) > 85 && value[i].charCodeAt(0) <= 90) {
      // V-Z일때
      let num = value[i].charCodeAt(0) - 52;

      sum += num * Math.pow(2, i);
    } else {
      // 숫자일때
      sum += parseInt(value[i]) * Math.pow(2, i);
    }
  }

  return sum;
}

export const fileExcelDownload = (fileName: string) => {
  const atag = document.createElement("a");
  atag.href = `${process.env.REACT_APP_API_URL}/excel-export/excel/${fileName}`;

  setTimeout(() => {
    atag.click();
    atag.remove();
  }, 800);
};

export const sleep = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));
