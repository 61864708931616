import { NumberBox } from "devextreme-react";
import { Controller } from "react-hook-form";
import { Input, InputProps } from "..";

const InputNumber = ({
  control,
  errors,
  name,
  label,
  height,
  width,
  required,
  disabled = false,
  onValueChanged = () => {},
  tabIndex,
  direction,
  placeHolder,
}: InputProps) => {
  return (
    <Input
      type="number"
      direction={direction ? direction : `row`}
      name={name}
      label={label && label}
      required={required}
      errors={errors && errors}
    >
      <Controller
        render={({ field: { onChange, value } }) => {
          return (
            <NumberBox
              format="#,##0"
              // mask={mask}
              // useMaskedValue={true}
              onValueChanged={(value) => {
                onChange(value.value);
                onValueChanged(value);
              }}
              value={parseInt(value)}
              disabled={disabled}
              height={height}
              width={width}
              tabIndex={tabIndex}
              defaultValue={null}
              placeholder={placeHolder}
            />
          );
        }}
        control={control}
        name={name}
        defaultValue={null}
      />
    </Input>
  );
};

export default InputNumber;
