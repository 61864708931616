import {
  Column,
  ColumnChooser,
  Format,
  HeaderFilter,
  Scrolling,
} from "devextreme-react/data-grid";
import { useTranslation } from "react-i18next";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import { FinishSearchType } from "@/types/tax.type";
import Grid from "@/components/atoms/Grid";
import { GridContent } from "@/components/organisms/Contents/GridContent";
import { DetailContent } from "@/components/organisms/Contents/DetailContent";

interface FinishManageLowerGridProps {
  gridSub: React.MutableRefObject<any>;
  search: FinishSearchType;
  selectedRowData: any;
  subUrl: string | null;
}

const FinishManageLowerGrid = ({
  gridSub,
  search,
  selectedRowData,
  subUrl,
}: FinishManageLowerGridProps) => {
  const { t } = useTranslation("main");
  const { data: subData } = useCustomQuery<any[]>(
    `manage-all-finish/${subUrl}`,
    {
      ...search,
      id: selectedRowData?.id,
      receiveCo: selectedRowData?.receiveCoName,
      completeNo: selectedRowData?.completeNo,
      companyId: selectedRowData?.companyId,
      comOid: selectedRowData?.comOid,
      company: selectedRowData?.companyName,
      extraDivision: selectedRowData?.extraDivision,
      division: selectedRowData?.division,
    },
    !!subUrl
  );

  return (
    <DetailContent>
      <Grid
        detailGrid={true}
        refs={gridSub}
        dataSource={subData}
        keyExpr="id"
        // focusedRowEnabled={true}
        // onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
      >
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} />
        {/* <StateStoring enabled={true} type="custom" customLoad={useCallback(() => JSON.parse(localStorage.getItem(storageKey) || "{}"), [])} /> */}
        <Scrolling mode="virtual" />
        <Column
          dataField="extraDivision"
          caption={t("구분")}
          cellRender={(e) => {
            const { value } = e;
            if (value === "detail") return "오더";
            if (value === "dis") return "배차";
            if (value === "extra") return "할증";
            if (value === "etc") return "기타하불";
            return "";
          }}
        />
        <Column dataField="companyName" caption={t("접수법인")} width="110" />
        {/* 사업자 번호 */}
        <Column dataField="bizNo" caption={t("사업자번호")} width="134" />
        {/* 사업자 상호 */}
        <Column dataField="bizName" caption={t("사업자상호")} width="134" />
        <Column dataField="code" caption={t("접수번호")} width="110" />
        <Column dataField="receiveDate" caption={t("접수일자")} width="110" />
        <Column dataField="deliverDate" caption={t("운송일자")} width="110" />
        <Column dataField="completeNo" caption={t("마감번호")} width="110" />
        <Column dataField="completeDate" caption={t("마감일자")} width="110" />
        <Column dataField="closeStatus" caption={t("정산상태")} width="110" />
        <Column
          dataField="price"
          caption={t("금액")}
          dataType="number"
          width="110"
        >
          <Format type="fixedPoint" />
        </Column>
        <Column
          dataField="containerNo"
          caption={t("컨테이너번호")}
          width="110"
        />
        <Column dataField="containerType" caption={t("컨규격")} width="110" />
        <Column dataField="remark" caption={t("비고")} width="110" />
        <Column
          dataField="dispatchTeamName"
          caption={t("배차팀")}
          width="110"
        />
        <Column
          dataField="billingPlaceName"
          caption={t("청구처")}
          width="110"
        />
        <Column dataField="orderCompany" caption={t("발주처")} width="110" />
        <Column dataField="forwarder" caption={t("선사")} width="110" />
        <Column dataField="shipName" caption={t("선명")} width="110" />
        <Column dataField="motherShip" caption={t("모선/항차")} width="110" />
        <Column dataField="cargoOwnerName" caption={t("화주")} width="110" />
        <Column dataField="dem" caption={t("선사반출기한")} width="110" />
        <Column dataField="enterDay" caption={t("입항일")} width="110" />
        <Column dataField="sailDay" caption={t("출항일")} width="110" />
        <Column dataField="putDirect" caption={t("접안일")} width="110" />
        <Column dataField="loadPlace" caption={t("상차지")} width="110" />
        <Column dataField="unloadPlace" caption={t("하차지")} width="110" />
        <Column dataField="blNo" caption={t("B/L No")} width="110" />
        <Column dataField="bkNo" caption={t("B/K No")} width="110" />
        <Column dataField="doNo" caption={t("DO No")} width="110" />
      </Grid>
    </DetailContent>
  );
};

export default FinishManageLowerGrid;
