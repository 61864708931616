import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Column,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import { useCompanyData } from "@/hooks/queries";
import useButtonLoading from "@/hooks/useButtonLoading";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import { useDeleteMutation } from "@/util/common.fn";
import Grid from "@/components/atoms/Grid";
import { InputText } from "@/components/atoms/Inputs";
import CompanyInputModal from "@/components/molecules/Modal/CompanyInput";
import { ContentTemplate } from "@/components/templates/ContentTemplate";
import { GridContent } from "@/components/organisms/Contents/GridContent";
import { Buttons } from "@/components/atoms/Buttons";
import { ReactComponent as IconCreate } from "@/styles/assets/svg/icon_button_create.svg";
import { ReactComponent as IconDelete } from "@/styles/assets/svg/icon_button_delete.svg";

const CompanyPage = () => {
  const { t } = useTranslation("main");
  const storageKey = "CompanyPage";
  const [search, setSearch] = useState({});
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedRowKey, setSelectedRowKey] = useState<number | null>(null);
  const [companyModal, setCompanyModal] = useState<boolean>(false);
  const gridMain = useRef<any>();

  const { alertErrorMessage, alertSuccessMessage, confirmMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { data: mainData, refetch: mainRefetch } = useCompanyData(search);
  const { mutateAsync: remove } = useDeleteMutation("company");

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const initPage = () => {
    const instance = gridMain.current.instance;

    instance.option("focusedRowIndex", -1);
    setSelectedRowKey(null);
    mainRefetch();
  };

  const onSearch = (data: any) => {
    setSearch(data);
  };

  const onRowDblClick = () => {
    // if (!selectedRowKey) return;
    setCompanyModal(true);
  };
  const onFocusedRowChanged = (e: any) => {
    if (!e.row?.key) return;
    setSelectedRowKey(e.row.key);
  };

  const deleteData = async () => {
    if (!selectedRowKey) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }

    if (!(await confirmMessage("삭제하시겠습니까?"))) return;

    try {
      setLoading();

      await remove({ key: selectedRowKey });

      await alertSuccessMessage("삭제완료");
      initPage();
    } catch (error) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  return (
    <>
      <ContentTemplate>
        <GridContent
          searchSection={
            <>
              <InputText
                label="회사명"
                name="companyName"
                control={control}
                errors={errors}
              />
              <InputText
                label="대표자명"
                name="ceoName"
                width={100}
                control={control}
                errors={errors}
              />
            </>
          }
          searchSubmit={handleSubmit(onSearch)}
          gridRef={gridMain}
          storageKey={storageKey}
          totalCount={totalCount}
          gridSectionButtons={
            <>
              <Buttons
                type="button"
                size="sm"
                layout="outline"
                color="primary100"
                icon={<IconCreate />}
                label="신규입력"
                onClick={() => {
                  setSelectedRowKey(null);
                  setCompanyModal(true);
                }}
              />
              <Buttons
                type="button"
                size="sm"
                layout="outline"
                color="destructive"
                icon={<IconDelete />}
                label="삭제"
                onClick={deleteData}
              />
            </>
          }
        >
          {/* 메인 그리드 라인 */}
          <Grid
            key="id"
            keyExpr="id"
            dataSource={mainData}
            refs={gridMain}
            focusedRowEnabled={true}
            onRowDblClick={onRowDblClick}
            onFocusedRowChanged={onFocusedRowChanged}
            onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
          >
            <HeaderFilter visible={true} />
            {/* <ColumnChooser enabled={true} /> */}
            <StateStoring
              enabled={true}
              type="custom"
              customLoad={useCallback(
                () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
                []
              )}
            />
            <Scrolling mode="virtual" />

            <Column
              caption="No"
              cellRender={(e) => e.row.loadIndex + 1}
              alignment="center"
              width={35}
            />
            <Column dataField="companyName" caption={t("회사명")} width={120} />
            <Column
              dataField="bizNo"
              caption={t("사업자등록번호")}
              width={110}
            />
            <Column dataField="ceoName" caption={t("대표자명")} width={80} />
            <Column dataField="address" caption={t("주소")} width={260} />
            <Column
              dataField="addressDetail"
              caption={t("상세주소")}
              width={200}
            />
            <Column
              dataField="bizType"
              caption={t("업태")}
              alignment="center"
              width={100}
            />
            <Column
              dataField="bizItem"
              caption={t("종목")}
              alignment="center"
              width={120}
            />
            <Column dataField="tel" caption={t("전화번호")} width={100} />
            <Column dataField="fax" caption={t("팩스번호")} width={100} />
            <Column dataField="email" caption={t("이메일")} width={160} />
            <Column dataField="remark" caption={t("비고")} alignment="center" />
          </Grid>
        </GridContent>
      </ContentTemplate>
      {/* 신규등록 Modal */}
      {companyModal && (
        <CompanyInputModal
          onClose={() => {
            setCompanyModal(false);
            mainRefetch();
          }}
          selectedRowKey={selectedRowKey}
        />
      )}
    </>
  );
};

export default CompanyPage;
