import styled from "styled-components";

interface AddressProps {
  direction?: "row" | "column";
}

export const AddressBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const AddressLabel = styled.div<AddressProps>`
  display: flex;
  color: ${(props) => props.theme.colors.inputLabel};
  font-size: 1.3rem;
  line-height: 1.3rem;
  text-align: ${(props) => (props.direction === "row" ? "right" : "left")};
  white-space: nowrap;
  align-items: ${(props) =>
    props.direction === "row" ? "center" : "flex-start"};
  flex-direction: ${(props) => props.direction};
  gap: ${(props) => (props.direction === "row" ? "10px" : "3px")};
`;

export const AddressPostalCode = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
