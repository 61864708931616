import { UseFormReturn } from "react-hook-form";
import { useCompanyData } from "@/hooks/queries";
import { InputDate, InputSelect, InputText } from "@/components/atoms/Inputs";
import InputNumber from "@/components/atoms/Inputs/InputNumber";
import { Buttons } from "@/components/atoms/Buttons";
import { FormContent } from "@/components/organisms/Contents/FormContent";
import { ReactComponent as IconCreate } from "@/styles/assets/svg/icon_button_create.svg";
import { ReactComponent as IconSave } from "@/styles/assets/svg/icon_button_save.svg";
import { ReactComponent as IconDelete } from "@/styles/assets/svg/icon_button_delete.svg";

interface EtcCostInputProps {
  selectedRowKey: string | null;
  inputForm: UseFormReturn<any>;
  toggleOpen: () => void;
  onClickNewData: () => void;
  onClickSaveData: () => Promise<void>;
  onClickDeleteData: () => Promise<void>;
}

const EtcCostInput = ({
  selectedRowKey,
  inputForm,
  toggleOpen,
  onClickNewData,
  onClickSaveData,
  onClickDeleteData,
}: EtcCostInputProps) => {
  const { data: company } = useCompanyData();

  const {
    control,
    formState: { errors },
  } = inputForm;

  return (
    <FormContent
      title="기타비용 등록"
      titleButtons={
        <Buttons
          permission="save"
          type="button"
          size="sm"
          layout="text"
          color="primary100"
          icon={<IconCreate />}
          label="신규입력"
          onClick={onClickNewData}
        />
      }
      inputForms={
        <>
          <InputSelect
            label="업체"
            name="companyOid"
            direction="column"
            displayExpr="companyName"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={company}
          />
          {/* 등록일 */}
          <InputDate
            label="등록일"
            name="regDate"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* 업체명 */}
          <InputText
            label="업체명"
            name="companyName"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* 매출 */}
          <InputNumber
            label="매출"
            name="sales"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* 매입 */}
          <InputNumber
            label="매입"
            name="purchase"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* 비고1 */}
          <InputText
            label="비고1"
            name="remark01"
            direction="column"
            control={control}
            errors={errors}
          />
          {/* 비고2 */}
          <InputText
            label="비고2"
            name="remark02"
            direction="column"
            control={control}
            errors={errors}
          />
        </>
      }
      formButtons={
        <>
          {/* <Button
          className="input-back"
          variety="tran"
          size="xxsm"
          shape="solid"
          type="button"
          onClick={toggleOpen}
        >
          <MovePageIcon width={26} height={26} viewBox="0 0 24 24" />
          <span>뒤로</span>
        </Button> */}
          <Buttons
            permission="save"
            type="button"
            size="sm"
            layout="solid"
            color="save"
            icon={<IconSave />}
            label="저장"
            onClick={onClickSaveData}
          />
          <Buttons
            permission="delete"
            type="button"
            size="sm"
            layout="solid"
            color="destructive"
            icon={<IconDelete />}
            label="삭제"
            onClick={onClickDeleteData}
          />
        </>
      }
    />
  );
};

export default EtcCostInput;
