import * as S from "./InputMultiple.style";

export interface InputMultipleProps {
  span?: string;
  label?: string;
  direction?: "row" | "column";
  children?: React.ReactElement;
}

export const InputMultiple = ({
  span,
  label,
  direction,
  children,
}: InputMultipleProps) => {
  return (
    <S.InputMultipleBox direction={direction} span={span && span}>
      <>
        {label && (
          <S.InputMultipleLabel direction={direction}>
            <>{label}</>
          </S.InputMultipleLabel>
        )}

        <S.InputSetBox>{children}</S.InputSetBox>
      </>
    </S.InputMultipleBox>
  );
};
