// 정산관리
import ComCarPage from "@/pages/management/comCar";
import ComCompanyPage from "@/pages/management/comCompany";
import ForwarderPage from "@/pages/management/forwarder";
import ShuttleBasicPage from "@/pages/management/shuttleBasic";
import TerminalPage from "@/pages/management/terminal";
import BerthStatus from "@/pages/order/berthStatus";
import DangerManagePage from "@/pages/order/dangerManage";
import DispatchPage from "@/pages/order/dispatch";
import MakeOrderPage from "@/pages/order/makeOrder";
import InvoiceEmailHistory from "@/pages/settlement/InvoiceEmailHistory";
import AccountExcel from "@/pages/settlement/accountExcel";
import BankAccountManage from "@/pages/settlement/bankAccountManage";
import CalculateProfit from "@/pages/settlement/calculateProfit";
import ClosingPage from "@/pages/settlement/closing";
import EtcCost from "@/pages/settlement/etcCost";
import FinishManagePage from "@/pages/settlement/finishManage";
import NonPaymentPage from "@/pages/settlement/nonPaymentPage";
import ShuttleDispatchPage from "@/pages/shuttle/shuttleDispatch";
import ComCodePage from "@/pages/system/comCode";
import CompanyPage from "@/pages/system/company";
import DepartmentPage from "@/pages/system/department";
import DeptPermission from "@/pages/system/deptPermission";
import MenuPage from "@/pages/system/menu";
import UserPage from "@/pages/system/user";
import UserPermission from "@/pages/system/userPermission";

export interface MenuComponent {
  id: string;
  component: JSX.Element;
}
export const menuComponent: MenuComponent[] = [
  // 시스템관리
  {
    id: "B01",
    component: <ComCodePage />,
  },
  {
    id: "B06",
    component: <CompanyPage />,
  },
  {
    id: "B04",
    component: <DeptPermission />,
  },
  {
    id: "ak62kZpBUeemKcWMvddeMH",
    component: <UserPermission />,
  },
  {
    id: "hVeMtAcZUPNv2FszkQuP2b",
    component: <DepartmentPage />,
  },
  {
    id: "B03",
    component: <UserPage />,
  },
  {
    id: "B05",
    component: <MenuPage />,
  },
  // 관리
  {
    id: "bbmzGRg2w8oXpJcUfGDfoK",
    component: <ComCompanyPage />,
  },
  {
    id: "uFs4wPcTAK8jXJbP8vkZaS",
    component: <ComCarPage />,
  },
  {
    id: "3ngg5pWGris22VBBj6B7Tn",
    component: <TerminalPage />,
  },
  {
    id: "w6KNkDmaAAhRuabVRLmg3h",
    component: <ForwarderPage />,
  },
  {
    id: "rqHwNVFqv6mQ7aHJjmukJp",
    component: <ShuttleBasicPage />,
  },
  // 오더
  {
    id: "7JtVmFjdota2P4zCHzPgpU",
    component: <MakeOrderPage />,
  },
  {
    id: "mkU1Gvb7GqgxGJ35rd2pGC",
    component: <DispatchPage />,
  },
  {
    id: "phe2v8TgYJc1bVdJ1sxb6d",
    component: <DangerManagePage />,
  },
  {
    id: "xtYPqs7gnvDU7xkLYLdrND",
    component: <BerthStatus />,
  },

  // 셔틀
  {
    id: "ekntbWJBWhxHaT61AFJjri",
    component: <ShuttleDispatchPage />,
  },

  // 정산

  {
    id: "8Ug3y21CRfY7ZjsoooMa2w",
    component: <ClosingPage />,
  },
  {
    id: "gQGVxuMBFE5nYsnXBKucKK",
    component: <FinishManagePage />,
  },
  {
    id: "6ohCqLDX8AHA4K2m2uiGE3",
    component: <BankAccountManage />,
  },
  {
    id: "fKqXhuUWSxWxf1pLekPyMt",
    component: <InvoiceEmailHistory />,
  },
  {
    id: "oyrZnLVMbUwee6FbghYU8q",
    component: <EtcCost />,
  },
  {
    id: "fAm4MzaKDn4yHfC8evHrtt",
    component: <CalculateProfit />,
  },
  {
    id: "dRy3oya1QZT1wxj1uX4yDa",
    component: <AccountExcel />,
  },
  {
    id: "3xGjqo7G7jfxnkyUqiuMBA",
    component: <NonPaymentPage />,
  },

  // {
  //   id: "1r1DuEUHonVDmtakqw9N3c",
  //   component: (props) => <MotherShipPage />,
  // },
  // {
  //   id: "2hYYe1mrUg57jppJ9bntTW",
  //   component: (props) => <YongchasaPage />,
  // },

  // {
  //   id: "mVwkWGAsLNKJjKLmqsWFPU",
  //   component: (props) => <CargoOwnerPage />,
  // },
  // {
  //   id: "uCPX3FbEdxsE4uvfTyvuuc",
  //   component: (props) => <BillingPage />,
  // },
  // {
  //   id: "w6KNkDmaAAhRuabVRLmg3h",
  //   component: (props) => <ForwarderPage />,
  // },
  // {
  //   id: "ceCM7DHAKaKCnPLehjaqwo",
  //   component: (props) => <YongchaDispatchPage />,
  // },
  // {
  //   id: "2NJ4gThi91b9j21eCGhCMn",
  //   component: (props) => <SimpleDispatchPage />,
  // },
  // {
  //   id: "fR39bTp3MbgePwuCk6Qah2",
  //   component: (props) => <SuttleDispatchReturnPage />,
  // },

  // {
  //   id: "3c4XSGYuhLzSSjKDH6uVgZ",
  //   component: (props) => <Dispatch />,
  // },

  // {
  //   id: "wWYwqfNvKFbQp8oVtFDhVK",
  //   component: (props) => <CompanyTaxInfoPage />,
  // },

  // {
  //   id: "cG6R6e3ZxJECaXaFBCdnmv",
  //   component: (props) => <OrderSummary />,
  // },
  // {
  //   id: "q4HuL11hh8PzZexetpvhd8",
  //   component: (props) => <OrderRefPage />,
  // },
  // {
  //   id: "81jYGSgnB3Cre8vPHz7XH4",
  //   component: (props) => <WorkPlacePage />,
  // },

  // {
  //   id: "6oVyd6jfAscLFN87sLqR9g",
  //   component: (props) => <ReturnListManagePage />,
  // },
  // {
  //   id: "mMqp1H5aXqL9GxTwKckRMn",
  //   component: (props) => <ChassisRefPage />,
  // },
  // {
  //   id: "fA1Jv3xzkfwSHHGR8ZQ4PV",
  //   component: (props) => <DispatchStatus />,
  // },
  // {
  //   id: "7ij6p5TaEd61587oEWhZ8y",
  //   component: (props) => <ContainerCostPage />,
  // },
  // {
  //   id: "marz716K4gmQW8pcrKMcit",
  //   component: (props) => <LCLOrderPage />,
  // },
  // {
  //   id: "rqHwNVFqv6mQ7aHJjmukJp",
  //   component: (props) => <ShuttleBasicCodePage />,
  // },
  // {
  //   id: "oAo6nvE6n4EikmPvVXkFyD",
  //   component: (props) => <ShuttleSimpleDispatchPage />,
  // },

  // {
  //   id: "3Qa2qerrPkyobxUKxeFCV8",
  //   component: (props) => <ShuttleExcelPage />,
  // },
  // {
  //   id: "nGJs1UEbjYiap5bYXYH3e8",
  //   component: (props) => <ShuttleExcelUploadPage />,
  // },
  // {
  //   id: "jWk392Phw3TDJaAyyNwWV3",
  //   component: (props) => <ImportPickupPage />,
  // },

  // {
  //   id: "4Dqx2zBFo9KVqc8f86cczV",
  //   component: (props) => <ImportReturnPage />,
  // },
  // {
  //   id: "kGvLDNNnLbSgdbCHnafvZx",
  //   component: (props) => <ExportPickupPage />,
  // },
  // {
  //   id: "qna6o4rYZyfvvVfkgtwFHK",
  //   component: (props) => <ExportReturnPage />,
  // },

  // {
  //   id: "mLE9pBtHjCqLJKgrTcZwq7",
  //   component: (props) => <PushAppPage />,
  // },
  // {
  //   id: "uDFC1L2oWHJm6iwuQQgrh1",
  //   component: (props) => <AppFileManagePage />,
  // },
  // {
  //   id: "kWvMhxPPadHVqEuuQbZoHH",
  //   component: (props) => <TelephonePage />,
  // },
  // {
  //   id: "7RofBh3CdDpEtRHhXwidLK",
  //   component: (props) => <AppSheetPage />,
  // },
  // {
  //   id: "dEt67qojYE27L1B3bxjJXo",
  //   component: (props) => <AppContainerPage />,
  // },
  // {
  //   id: "3xGjqo7G7jfxnkyUqiuMBA",
  //   component: (props) => <NonPaymentPage />,
  // },

  // {
  //   id: "iaihDwNbJiNdPfkkVrDUqr",
  //   component: (props) => <SimpleSellFinishPage />,
  // },
];
