import styled, { css } from "styled-components";

interface GridProps {
  grid?: string;
  bottomHeight?: number;
  subGrid?: boolean;
  detailGrid?: boolean;
}

export const GridContentBox = styled.div<GridProps>`
  overflow: hidden;
  display: flex;
  width: 100%;
  height: ${(props) =>
    props.subGrid
      ? "100%"
      : props.bottomHeight
      ? "calc(100vh - 122px - " + props.bottomHeight + "px)"
      : "calc(100vh - 122px)"};
  padding: ${(props) => (props.subGrid ? "0px" : "10px")};
  background: ${(props) =>
    props.subGrid ? "none" : props.theme.colors.white100};
  flex-direction: column;
  gap: 10px;

  ${(props) =>
    props.subGrid &&
    css`
      flex-shrink: 100;
    `}
`;

export const SearchSection = styled.form`
  display: grid;
  width: 100%;
  grid-template-columns: auto 56px;

  button {
    border-radius: 0 4px 4px 0;
  }
`;

export const SearchBox = styled.div`
  display: flex;
  padding: 10px 16px;
  background: ${(props) => props.theme.colors.black4};
  border-left: 1px solid ${(props) => props.theme.colors.gray100};
  border-top: 1px solid ${(props) => props.theme.colors.gray100};
  border-bottom: 1px solid ${(props) => props.theme.colors.gray100};
  border-radius: 4px 0px 0px 4px;
  flex-wrap: wrap;
  gap: 8px 24px;
`;

export const GridSection = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  height: 100%;
`;

export const GridBox = styled.div<GridProps>`
  position: relative;
  overflow: hidden;
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 10px;

  ${(props) =>
    props.grid &&
    css`
      display: grid;
      grid-template-columns: ${props.grid} auto;
    `}
`;

export const GridSectionButtons = styled.div<GridProps>`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 5px;
`;

export const GridTotal = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.legendTxt};
  font-size: 1.2rem;
  font-weight: 500;
  align-items: center;
  justify-content: flex-end;
`;
