import styled, { css } from "styled-components";

interface FormProps {
  bottomHeight?: number;
  inputFormGrid?: number;
  scroll?: boolean;
}

export const FormContentBox = styled.div<FormProps>`
  overflow: hidden;
  display: flex;
  height: ${(props) =>
    props.bottomHeight
      ? "calc(100vh - 122px - " + props.bottomHeight + "px)"
      : "calc(100vh - 122px)"};
  padding: 20px;
  background: ${(props) => props.theme.colors.white50};
  flex-direction: column;
  gap: 20px;
`;

export const FormTitleBox = styled.div`
  display: flex;
  height: 32px;
  font-size: 1.8rem;
  font-weight: 600;
  align-items: center;
  justify-content: space-between;
`;

export const InputFormBox = styled.div<FormProps>`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const InputForm = styled.form<FormProps>`
  overflow: auto;
  display: flex;
  min-height: 32px;
  flex-direction: column;
  gap: 14px;

  ${(props) =>
    props.inputFormGrid &&
    css`
      display: grid;
      height: 100%;
      grid-template-columns: ${"repeat(" + props.inputFormGrid + ", 1fr)"};
    `}

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${(props) => props.theme.colors.scrollBar};
    border: 2px solid transparent;
    border-radius: 10px;

    &:hover {
      background-color: ${(props) => props.theme.colors.black50};
      transition: all 0.2s ease;
    }
  }

  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.black4};
  }
`;

export const FormButtonBox = styled.div`
  display: flex;
  grid-column: span 2;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;
  gap: 5px;
`;
