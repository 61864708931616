import * as S from "./StatusText.style";

interface StatusProps {
  label?: string;
  statusValue?: string;
}

export const StatusText = ({ label, statusValue }: StatusProps) => {
  return (
    <S.StatusText>
      <S.StatusLabel>{label}</S.StatusLabel>
      {statusValue}
    </S.StatusText>
  );
};
