import * as S from "./TopbarPopupItem.style";

interface TopbarPopupItemProps {
  title: string;
  icon?: React.ReactElement;
  onClick?: any;
}

export const TopbarPopupItem = ({
  title,
  icon,
  onClick,
}: TopbarPopupItemProps) => {
  return (
    <S.TopbarPopupItem onClick={onClick}>
      {icon}
      <span>{title}</span>
    </S.TopbarPopupItem>
  );
};
