import { useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { Button } from "devextreme-react";
import { selectedMenuSelector } from "@/state/menu";
import * as S from "./GridButtonSet.style";

export const GridButtonSet = ({ dataGridRef, storageKey }: any) => {
  const { t } = useTranslation("main");
  const currentMenu = useRecoilValue(selectedMenuSelector);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const saveState = useCallback(() => {
    setIsLoading(true);
    const state = dataGridRef.current.instance.state();
    if (state) {
      for (let i = 0; i < state.columns.length; i++) {
        state.columns[i].filterValue = null;
      }
    }

    // state를 localStorage에 저장
    localStorage.setItem(storageKey, JSON.stringify(state));

    setTimeout(() => setIsLoading(false), 100);
  }, [dataGridRef, storageKey]);

  const xlsState = useCallback(() => {
    setIsLoading(true);
    dataGridRef.current.instance.exportToExcel();
    setTimeout(() => setIsLoading(false), 100);
  }, [dataGridRef]);

  const deleteState = useCallback(() => {
    setIsLoading(true);
    localStorage.removeItem(storageKey);
    setTimeout(() => setIsLoading(false), 100);
  }, [storageKey]);

  return (
    <S.GridButtonSet>
      {currentMenu.isExport && (
        <Button
          icon="xlsfile"
          onClick={xlsState}
          disabled={isLoading}
          hint={t("엑셀 다운로드")}
        />
      )}
      <Button
        icon="save"
        onClick={saveState}
        disabled={isLoading}
        hint={t("그리드 저장")}
      />
      <Button
        icon="undo"
        onClick={deleteState}
        disabled={isLoading}
        hint={t("그리드 초기화")}
      />
    </S.GridButtonSet>
  );
};
