import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnFixing,
  Format,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import { ShuttleBasicSearch } from "@/types/shuttleBasic.type";
import Grid from "@/components/atoms/Grid";
import { Buttons } from "@/components/atoms/Buttons";
import { Modal } from "..";

interface ShuttleBasicModalProps {
  onClose: () => void;
  onSelect: (rowData: any) => void;
}

const ShuttleBasicModal = ({ onClose, onSelect }: ShuttleBasicModalProps) => {
  const { t } = useTranslation("main");
  const storageKey = "ShuttleBasicGrid";
  const [rowData, setRowData] = useState<any>();
  const [search] = useState(ShuttleBasicSearch);

  const gridRef = useRef<any>();

  const { alertErrorMessage } = useAlertMessage();

  const { data: mainData } = useCustomQuery<any[]>(`shuttlebasic`, search);

  const onFocusedRowChanged = (e: any) => {
    if (!e.row?.key) return;
    setRowData(e.row.data);
  };

  const importExportCellRender = useCallback((e: any) => {
    if (e.value === 1) return t("수입");
    if (e.value === 2) return t("수출");
    return;
    // eslint-disable-next-line
  }, []);

  const onClickSelect = () => {
    if (!rowData) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }

    onSelect(rowData);
    onClose();
  };

  return (
    <Modal
      width="1100px"
      close={onClose}
      title="셔틀기본정보"
      modalButton={
        <Buttons
          permission="save"
          type="button"
          size="lg"
          width={80}
          layout="solid"
          color="save"
          label="적용"
          onClick={onClickSelect}
        />
      }
    >
      {/* <SearchGroup onSubmit={handleSubmit(onSearch)}>
        <InputSelect
          type="h-array"
          control={control}
          errors={errors}
          label="접수법인"
          name="receiveCo"
          dataSource={[{ id: "0", companyName: "전체" }, ...(company || [])]}
          valueExpr="id"
          displayExpr="companyName"
        />
        <InputDate
          type="h-array"
          control={control}
          errors={errors}
          label="등록일"
          name="firstDay"
        />
        ~
        <InputDate
          type="h-array"
          control={control}
          errors={errors}
          name="lastDay"
        />
      </SearchGroup> */}
      <Grid
        subGrid={true}
        dataSource={mainData}
        refs={gridRef}
        onFocusedRowChanged={onFocusedRowChanged}
        onRowDblClick={onClickSelect}
        focusedRowEnabled={true}
        keyExpr="oid"
      >
        <ColumnFixing enabled={true} />
        <StateStoring
          enabled={true}
          type="custom"
          customLoad={useCallback(
            () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
            []
          )}
        />
        <Scrolling mode="virtual" />
        <Column
          caption="No"
          cellRender={(e) => e.row.loadIndex + 1}
          alignment="center"
        />
        <Column
          dataField="importExport"
          caption={t("수입/수출")}
          cellRender={importExportCellRender}
          alignment="center"
        />
        <Column
          dataField="cargoOwnerName"
          caption={t("화주")}
          alignment="center"
        />
        <Column
          dataField="billingPlaceName"
          caption={t("청구처")}
          alignment="center"
        />
        <Column
          dataField="containerType"
          caption={t("컨규격")}
          alignment="center"
        />
        <Column dataField="containerNo" caption={t("컨테이너번호")} />
        <Column
          dataField="loadPlaceName"
          caption={t("상차지")}
          alignment="center"
        />
        <Column
          dataField="workPlaceName"
          caption={t("작업지")}
          alignment="center"
        />
        <Column
          dataField="unloadPlaceName"
          caption={t("하차지")}
          alignment="center"
        />
        <Column dataField="freight" caption={t("청구운임")} dataType="number">
          <Format type="fixedPoint" />
        </Column>
        <Column
          dataField="habul01"
          caption={t("상차지 하불금액")}
          dataType="number"
        >
          <Format type="fixedPoint" />
        </Column>
        <Column
          dataField="habul02"
          caption={t("하차지 하불금액")}
          dataType="number"
        >
          <Format type="fixedPoint" />
        </Column>
      </Grid>
    </Modal>
  );
};

export default ShuttleBasicModal;
