import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useRecoilValue } from "recoil";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import useButtonLoading from "@/hooks/useButtonLoading";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import { yupResolver } from "@hookform/resolvers/yup";
import { userInfo } from "@/state/atoms/userInfo";
import {
  InitShuttleDispatchInput,
  InitShuttleDispatchSearch,
  ShuttleDispatchInputSchema,
  ShuttleDispatchInputType,
  ShuttleDispatchSearchType,
} from "@/types/shuttleDispatch.type";
import {
  useDeleteMutation,
  useGetMutation,
  usePostMutation,
  usePutMutation,
} from "@/util/common.fn";
import ShuttleBasicModal from "@/components/molecules/Modal/ShuttleBasicModal";
import { ContentTemplate } from "@/components/templates/ContentTemplate";
import ShuttleDispatchGrid from "./ShuttleDispatchGrid";
import ShuttleDispatchInput from "./ShuttleDispatchInput";

const ShuttleDispatchPage = () => {
  const userData = useRecoilValue(userInfo);
  const [openinputbar, setopeninputbar] = useState<boolean>(true);
  const [shuttleBasicModal, setShuttleBasicModal] = useState<boolean>(false);

  const [search, setSearch] = useState<ShuttleDispatchSearchType>(
    InitShuttleDispatchSearch(userData)
  );
  const [selectedRowKey, setSelectedRowKey] = useState<string | null>(null);
  const gridMain = useRef<any>();

  const { alertErrorMessage, alertSuccessMessage, confirmMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  // 데이터 FETCH
  const { data: mainData, refetch: mainRefetch } = useCustomQuery<any[]>(
    `shuttlesimple`,
    search
  );

  const { mutateAsync: orderCheck } = useGetMutation("order/check");
  const { mutateAsync: create } = usePostMutation(`shuttlesimple`);
  const { mutateAsync: update } = usePutMutation(`shuttlesimple`);
  const { mutateAsync: remove } = useDeleteMutation(`shuttlesimple`);

  const searchForm = useForm({
    defaultValues: InitShuttleDispatchSearch(userData),
  });

  const inputForm = useForm<ShuttleDispatchInputType>({
    resolver: yupResolver(ShuttleDispatchInputSchema),
    defaultValues: InitShuttleDispatchInput(userData),
  });

  const { reset, trigger, watch, getValues, setValue } = inputForm;

  const initPage = () => {
    const mainGridInstance = gridMain.current.instance;
    mainGridInstance.option("focusedRowIndex", -1);
    setSelectedRowKey(null);
    mainRefetch();
    reset(InitShuttleDispatchInput(userData));
  };

  const toggleOpen = () => setopeninputbar(!openinputbar);

  const onSearch = (data: any) => {
    initPage();
    setSearch(data);
    mainRefetch();
  };

  const onClickNewData = () => {
    initPage();
  };

  const onClickShuttleData = () => {
    setShuttleBasicModal(true);
  };

  const onSelect = (basicData: any) => {
    const dispatches = getValues("dispatches");
    setValue("cargoOwner", basicData.cargoOwner);
    setValue("importExport", basicData.importExport);
    setValue("billingPlace", basicData.billingPlace);
    setValue("containerType", basicData.containerType);
    setValue("freight", basicData.freight);

    if (dispatches) {
      dispatches[0].startPoint = basicData.loadPlace;
      dispatches[0].endPoint = basicData.workPlace;
      dispatches[0].habul = basicData.habul01;

      dispatches[1].startPoint = basicData.workPlace;
      dispatches[1].endPoint = basicData.unloadPlace;
      dispatches[1].habul = basicData.habul02;
    }
    setValue("dispatches", dispatches);
  };

  const onClickSaveData = async () => {
    const mainGridInstance = gridMain.current.instance;
    if (!(await trigger())) return;
    const data = watch();
    let res: any;

    const originalKey = selectedRowKey;

    try {
      setLoading();
      if (selectedRowKey) {
        res = await update({ key: selectedRowKey, data });
      } else {
        res = await create({ data });
      }

      if (res?.state === 1) {
        initPage();

        await alertSuccessMessage("저장완료");
      }

      mainGridInstance.option("focusedRowKey", originalKey);
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onClickDeleteData = async () => {
    if (!selectedRowKey) {
      await alertErrorMessage("데이터를 선택해주세요");
      return;
    }

    if (
      !(await confirmMessage(
        "정말 삭제하시겠습니까? 삭제된 데이터는 복구되지 않습니다."
      ))
    )
      return;
    // 마감여부 확인

    try {
      setLoading();
      await orderCheck({ code: selectedRowKey });

      const res: any = await remove({ code: selectedRowKey });
      if (res?.state === 1) {
        initPage();

        await alertSuccessMessage("삭제완료");
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onFocusedRowChanged = (e: any) => {
    if (!e.row?.data) return;
    setSelectedRowKey(e.row.key);
  };

  return (
    <>
      <ContentTemplate sideWidth={440}>
        <>
          <ShuttleDispatchGrid
            toggleOpen={toggleOpen}
            gridMain={gridMain}
            mainData={mainData}
            searchForm={searchForm}
            onSearch={onSearch}
            onFocusedRowChanged={onFocusedRowChanged}
          />
          <ShuttleDispatchInput
            selectedRowKey={selectedRowKey}
            openinputbar={openinputbar}
            inputForm={inputForm}
            toggleOpen={toggleOpen}
            onClickShuttleData={onClickShuttleData}
            onClickNewData={onClickNewData}
            onClickSaveData={onClickSaveData}
            onClickDeleteData={onClickDeleteData}
          />
        </>
      </ContentTemplate>
      {shuttleBasicModal && (
        <ShuttleBasicModal
          onClose={() => setShuttleBasicModal(false)}
          onSelect={onSelect}
        />
      )}
    </>
  );
};

export default ShuttleDispatchPage;
