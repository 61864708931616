import { userInfo } from "@/state/atoms/userInfo";
import { selecteMenuState } from "@/state/menu";
import { api } from "@/util/api";
import {
  logout,
  readAccessToken,
  readRefreshToken,
  writeAccessToken,
} from "@/util/localStorage";
import axios, { InternalAxiosRequestConfig } from "axios";
import { useEffect } from "react";
import { useRecoilValue } from "recoil";

export const useAxiosInterceptor = () => {
  const userData = useRecoilValue(userInfo);
  const currentMenu = useRecoilValue(selecteMenuState);

  useEffect(() => {
    const requestHandler = (config: InternalAxiosRequestConfig) => {
      // localStorage에서 accessToken 호출
      const accessToken = readAccessToken();
      const ip = localStorage.getItem("userIP");

      if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
      if (ip) config.headers.ip = ip;
      // if (userData) config.headers.userid = userData.userId;
      // if (currentMenu) config.headers.currentmenu = currentMenu;

      return config;
    };

    const errorHandler = async (error: any) => {
      const originalRequest = error.config;

      // 401에러 일 시
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        // localStorage에서 accessToken, refresh 호출
        const jwtToken = readAccessToken();
        const refreshToken = readRefreshToken();
        if (!refreshToken) {
          alert("로그인해주세요");
          return logout();
        }
        try {
          // 토큰 재요청
          const { data } = await axios.post(
            `${process.env.REACT_APP_API_URL}/user/refresh-token`,
            {
              refreshToken,
            },
            {
              headers: {
                Authorization: `Bearer ${jwtToken}`,
              },
            }
          );

          // localStorage에 jwtToken, refreshToken 저장
          const accessTokenData = data?.authToken;
          writeAccessToken(accessTokenData);

          return api(originalRequest);
        } catch (e) {
          alert("토큰이 만료되었습니다. 다시 로그인해주세요");
          return logout();
        }
      }

      return Promise.reject(error);
    };

    const requestInterceptor = api.interceptors.request.use(
      requestHandler,
      (error) => Promise.reject(error)
    );

    const responseInterceptor = api.interceptors.response.use((response) => {
      return response;
    }, errorHandler);

    return () => {
      api.interceptors.request.eject(requestInterceptor);
      api.interceptors.response.eject(responseInterceptor);
    };
  }, [userData, currentMenu]);
};
