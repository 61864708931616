import { request } from "@/util/api";
import { useQueries, useQuery } from "@tanstack/react-query";

export function useCustomQuery<T>(url: string, search: any, enabled?: boolean) {
  return useQuery<T>({
    queryKey: [url, search],
    queryFn: () => request({ method: "GET", url: `/${url}`, params: search }),
    enabled: enabled,
  });
}

export function useCustomQueries(urlArr: string[]) {
  const queryArr = urlArr.map((url) => {
    return {
      queryKey: [url],
      queryFn: () => request({ method: "GET", url: `/${url}` }),
    };
  });
  return useQueries({ queries: queryArr });
}
