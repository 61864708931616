import React from "react";
import { locale, loadMessages } from "devextreme/localization";
// import "@formatjs/intl-relativetimeformat/dist/locale-data/en";
import enMessages from "./messages/en.json";
import koMessages from "./messages/ko.json";
import { IntlProvider } from "react-intl";

const allMessages = {
  en: enMessages,
  ko: koMessages,
};

export function I18nProvider({ children }) {
  // const lang = useLang();
  const lang = localStorage.getItem("language") || "ko";
  const messages = allMessages[lang];
  locale(lang);

  loadMessages(allMessages);

  return (
    <IntlProvider locale={lang} defaultLocale="ko" messages={messages}>
      {children}
    </IntlProvider>
  );
}
