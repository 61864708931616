import { useState } from "react";
import { useSetRecoilState } from "recoil";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import { isAuthorized } from "@/state/atoms/isAuthorized";
import { request } from "@/util/api";
import { writeAccessToken, writeRefreshToken } from "@/util/localStorage";
import { Buttons } from "@/components/atoms/Buttons";
import * as S from "./AuthForm.style";

export const AuthForm = () => {
  const [errorMessage] = useState("");
  const setAuthorized = useSetRecoilState(isAuthorized);
  const { alertErrorMessage } = useAlertMessage();

  const login = async (e: any) => {
    e.preventDefault();
    const userId = e.target.userId.value;
    const password = e.target.password.value;

    try {
      const res = await request<{ authToken: string; refreshToken: string }>({
        url: "/user/login",
        method: "POST",
        data: {
          userId,
          password,
          companySelect: 1,
        },
      });

      writeAccessToken(res.authToken);
      writeRefreshToken(res.refreshToken);

      setAuthorized(true);
    } catch (error: any) {
      alertErrorMessage(error);
    }
  };
  return (
    <S.AuthFormBox>
      <S.AuthTitle>Login</S.AuthTitle>
      <S.AuthSubhead>아이디와 비밀번호를 입력해주세요.</S.AuthSubhead>
      <S.AuthForm onSubmit={login}>
        <S.AuthFormInput>
          {errorMessage && (
            <span className="error-msg-mobile">
              <span>!</span>
              {errorMessage}
            </span>
          )}
          <label>
            <span>아이디</span>
            <input type="text" name="userId" placeholder="계정 아이디 입력" />
          </label>
          <label>
            <span>비밀번호</span>
            <input
              type="password"
              name="password"
              placeholder="비밀번호 입력"
            />
          </label>
          {errorMessage && (
            <span className="error-msg">
              <span>!</span>
              {errorMessage}
            </span>
          )}
        </S.AuthFormInput>
        <Buttons
          type="submit"
          size="xlg"
          layout="solid"
          color="primary100"
          label="로그인"
        />
      </S.AuthForm>
    </S.AuthFormBox>
  );
};
