import { useRef } from "react";
import { useSetRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { logout } from "@/util/localStorage";
import { isAuthorized } from "@/state/atoms/isAuthorized";
import { TopbarPopupItem } from "@/components/atoms/TopbarPopupItem";
import * as S from "./TopbarPopup.style";
import { ReactComponent as IconLogout } from "@/styles/assets/svg/icon_logout.svg";

interface TopbarPopupProps {
  setPopupShow?: any;
}

export const TopbarPopup = ({ setPopupShow }: TopbarPopupProps) => {
  const popupRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation("main");
  const setAuthorized = useSetRecoilState(isAuthorized);

  const popupOutsideClick = (e: any) => {
    if (popupRef.current === e.target) {
      setPopupShow(false);
    }
  };

  return (
    <S.TopbarPopupBg ref={popupRef} onClick={(e: any) => popupOutsideClick(e)}>
      <S.TopbarPopup>
        {/* 비밀번호 변경 */}
        {/* <PopupBtn type="button" onClick={passwordModalContext?.setIsOpen}>
            <span>비밀번호 변경</span>
          </PopupBtn> */}

        <TopbarPopupItem
          title={`${t("로그아웃")}`}
          icon={<IconLogout />}
          onClick={() => {
            setAuthorized(false);
            logout();
          }}
        />
      </S.TopbarPopup>
    </S.TopbarPopupBg>
  );
};
