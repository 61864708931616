import * as yup from "yup";
export type DepartmentType = {
  oid?: string;
  code: string;
  deptName: string;
  sortRef?: number | null;
  remark?: string;
  createUserId?: string;
  createIp?: string;
  updateUserId?: string;
  updateIp?: string;
  createdat?: string;
  updatedat?: string;
  isDispatch?: boolean;
};

export const DepartmentInputSchema = yup.object<DepartmentType>({
  code: yup.string().required("부서코드를 입력해주세요"),
  deptName: yup.string().required("부서명을 입력해주세요"),
  sortRef: yup.number().nullable(),
  remark: yup.string().nullable(),
  isDispatch: yup.boolean(),
});

export const InitialDepartInput: DepartmentType = {
  code: "",
  deptName: "",
  sortRef: null,
  remark: "",
  isDispatch: false,
};
