import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Column,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import useButtonLoading from "@/hooks/useButtonLoading";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import { useDeleteMutation } from "@/util/common.fn";
import Grid from "@/components/atoms/Grid";
import { Buttons } from "@/components/atoms/Buttons";
import { InputText } from "@/components/atoms/Inputs";
import ComCarModal from "@/components/molecules/Modal/ComCarModal";
import { GridContent } from "@/components/organisms/Contents/GridContent";
import { ContentTemplate } from "@/components/templates/ContentTemplate";
import { ReactComponent as IconCreate } from "@/styles/assets/svg/icon_button_create.svg";
import { ReactComponent as IconDelete } from "@/styles/assets/svg/icon_button_delete.svg";

const ComCarPage = () => {
  const { t } = useTranslation("main");
  const storageKey = "ComCarPage";
  const [search, setSearch] = useState({});
  const [totalCount, setTotalCount] = useState<number>(0);
  const [selectedRowKey, setSelectedRowKey] = useState<number | null>(null);
  const [carModal, setCarModal] = useState<boolean>(false);
  const gridMain = useRef<any>();

  const { alertErrorMessage, alertSuccessMessage, confirmMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { data: mainData, refetch: mainRefetch } = useCustomQuery(
    `carManage`,
    search
  );
  const { mutateAsync: remove } = useDeleteMutation("carManage");

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const initPage = () => {
    const instance = gridMain.current.instance;

    instance.option("focusedRowIndex", -1);
    setSelectedRowKey(null);
    mainRefetch();
  };

  const onSearch = (data: any) => {
    setSearch(data);
  };

  const onRowDblClick = () => {
    setCarModal(true);
  };
  const onFocusedRowChanged = (e: any) => {
    if (!e.row?.key) return;
    setSelectedRowKey(e.row.key);
  };

  const deleteData = async () => {
    if (!selectedRowKey) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }

    if (!(await confirmMessage("삭제하시겠습니까?"))) return;

    try {
      setLoading();
      await remove({ key: selectedRowKey });

      await alertSuccessMessage("삭제완료");
      initPage();
    } catch (error) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  return (
    <>
      <ContentTemplate>
        <GridContent
          searchSection={
            <InputText
              label="차량번호"
              name="carNo"
              width={120}
              control={control}
              errors={errors}
            />
          }
          searchSubmit={handleSubmit(onSearch)}
          gridRef={gridMain}
          storageKey={storageKey}
          totalCount={totalCount}
          gridSectionButtons={
            <>
              <Buttons
                type="button"
                size="sm"
                layout="outline"
                color="primary100"
                icon={<IconCreate />}
                label="신규입력"
                onClick={() => {
                  setSelectedRowKey(null);
                  setCarModal(true);
                }}
              />
              <Buttons
                type="button"
                size="sm"
                layout="outline"
                color="destructive"
                icon={<IconDelete />}
                label="삭제"
                onClick={deleteData}
              />
            </>
          }
        >
          <Grid
            key="oid"
            keyExpr="oid"
            dataSource={mainData}
            refs={gridMain}
            focusedRowEnabled={true}
            onRowDblClick={onRowDblClick}
            onFocusedRowChanged={onFocusedRowChanged}
            onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
          >
            <HeaderFilter visible={true} />
            {/* <ColumnChooser enabled={true} /> */}
            <StateStoring
              enabled={true}
              type="custom"
              customLoad={useCallback(
                () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
                []
              )}
            />
            <Scrolling mode="virtual" />
            <Column
              caption={t("순번")}
              cellRender={(e) => e.row.loadIndex + 1}
              alignment="center"
              width={35}
            />
            <Column
              dataField="carNo"
              caption={t("차량번호")}
              alignment="center"
              width={100}
            />
            <Column
              dataField="departName"
              caption={t("관리소속")}
              width={120}
            />
            <Column
              dataField="carDivision"
              caption={t("차량구분")}
              width={80}
            />
            <Column dataField="kindCar" caption={t("차량종류")} width={90} />
            <Column dataField="carTon" caption={t("차량톤수")} width={90} />
            <Column dataField="assignName" caption={t("소속운송사")} />
            <Column
              dataField="carIdCard"
              caption={t("차량ID카드")}
              width={90}
            />
            <Column
              dataField="driverName"
              caption={t("이름")}
              alignment="center"
              width={70}
            />
            <Column dataField="tel" caption={t("휴대전화")} width={120} />
            <Column dataField="bizNo" caption={t("사업자번호")} width={110} />
            <Column dataField="bizName" caption={t("상호")} width={140} />
            <Column dataField="viewRank" caption={t("조회순위")} />
            <Column dataField="creditClass" caption={t("신용등급")} />
            <Column dataField="route" caption={t("전문노선")} />
          </Grid>
        </GridContent>
        {/* 페이지 타이틀 라인 */}
      </ContentTemplate>

      {/* 신규등록 Modal */}
      {carModal && (
        <ComCarModal
          onClose={() => {
            setCarModal(false);
            mainRefetch();
          }}
          selectedRowKey={selectedRowKey}
        />
      )}
    </>
  );
};

export default ComCarPage;
