import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import {
  useComCodeData,
  useCompanyData,
  useDriverData,
  useUserListData,
} from "@/hooks/queries";
import useButtonLoading from "@/hooks/useButtonLoading";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import { DepartmentType } from "@/types/department.type";
import { DeptAuthType } from "@/types/deptAuth.type";
import {
  InitialUserListInput,
  UserListInputSchema,
} from "@/types/userList.type";
import {
  useDeleteMutation,
  usePostMutation,
  usePutMutation,
} from "@/util/common.fn";
import { yupResolver } from "@hookform/resolvers/yup";
import { 사용자_활동상태_구분, 직책 } from "@/data/constant/queryKeys";
import { Buttons } from "@/components/atoms/Buttons";
import ChangePassword from "@/components/molecules/Modal/ChangePassword";
import { ContentTemplate } from "@/components/templates/ContentTemplate";
import UserGrid from "./UserGrid";
import UserInput from "./UserInput";

const UserPage = () => {
  const [selectedRowKey, setSelectedRowKey] = useState<string | null>(null);
  const [searchInput, setSearchInput] = useState({});
  const [isOpen, setOpen] = useState<boolean>(false);
  const [changePasswordModalVisible, setChangePasswordModalVisible] =
    useState(false);
  const gridMain = useRef<any>();

  const { alertErrorMessage, alertSuccessMessage, confirmMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { data: mainData, refetch: mainRefetch } = useUserListData(searchInput);

  const { mutateAsync: create } = usePostMutation("user-list/master");
  const { mutateAsync: update } = usePutMutation("user-list/master");
  const { mutateAsync: remove } = useDeleteMutation("user-list/master");

  const { data: company } = useCompanyData();
  const { data: department } = useCustomQuery<DepartmentType[]>(
    `department`,
    {}
  );
  const { data: duty } = useComCodeData(직책);
  const { data: status } = useComCodeData(사용자_활동상태_구분);
  const { data: driver } = useDriverData();
  const { data: auth } = useCustomQuery<DeptAuthType[]>(`authgroup/master`, {});

  const searchForm = useForm();
  const inputForm = useForm({
    values: InitialUserListInput,
    resolver: yupResolver(UserListInputSchema),
  });

  const { reset, trigger, watch: inputWatch } = inputForm;

  const toggleOpen = () => setOpen(!isOpen);

  const initPage = () => {
    const mainGridInstance = gridMain.current.instance;
    mainGridInstance.option("focusedRowIndex", -1);
    resetGridChanges();
    setSelectedRowKey(null);
    mainRefetch();
    reset(InitialUserListInput);
  };

  const resetGridChanges = () => {
    const mainGridInstance = gridMain.current.instance;
    mainGridInstance.option("editing.changes", []);
  };

  const onSearch = (data: any) => {
    setSearchInput(data);
    mainRefetch();
  };

  const onClickNewData = () => {
    initPage();
  };

  const onClickSaveData = async () => {
    const data = inputWatch();
    if (!(await trigger())) return;

    const mainGridInstance = gridMain.current.instance;

    const originalKey = selectedRowKey;
    let res: any;

    try {
      setLoading();
      if (selectedRowKey) {
        res = await update(data);
      } else {
        res = await create(data);
      }

      if (res.state === 1) {
        initPage();
        await alertSuccessMessage("저장완료");
      }

      mainGridInstance.option("focusedRowKey", originalKey);
    } catch (error: any) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onClickDeleteData = async () => {
    if (!selectedRowKey) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }
    try {
      setLoading();
      if (!(await confirmMessage("삭제하시겠습니까?"))) return;

      await remove({ key: selectedRowKey });

      await alertSuccessMessage("삭제완료");

      initPage();
    } catch (error: any) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onFocusedRowChanged = (e: any) => {
    if (!e.row?.data) return;
    const data = e.row.data;
    setSelectedRowKey(data.id);
    reset(data);
  };

  const changePasswordBtnRender = useCallback((e: any) => {
    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <Buttons
          type="button"
          size="xsm"
          layout="outline"
          color="grid"
          label="변경"
          onClick={() => {
            setChangePasswordModalVisible(true);
          }}
        />
      </div>
    );
  }, []);

  return (
    <>
      <ContentTemplate sideWidth={280}>
        <>
          <UserGrid
            gridMain={gridMain}
            mainData={mainData}
            searchForm={searchForm}
            toggleOpen={toggleOpen}
            onSearch={onSearch}
            onFocusedRowChanged={onFocusedRowChanged}
            changePasswordBtnRender={changePasswordBtnRender}
          />

          <UserInput
            selectedRowKey={selectedRowKey}
            inputForm={inputForm}
            status={status}
            company={company}
            duty={duty}
            department={department}
            auth={auth}
            driver={driver}
            toggleOpen={toggleOpen}
            onClickNewData={onClickNewData}
            onClickSaveData={onClickSaveData}
            onClickDeleteData={onClickDeleteData}
          />
        </>
      </ContentTemplate>
      {changePasswordModalVisible && (
        <ChangePassword
          onClose={() => setChangePasswordModalVisible(false)}
          userOid={selectedRowKey}
        />
      )}
    </>
  );
};

export default UserPage;
