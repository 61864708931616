import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnFixing,
  Format,
  Selection,
} from "devextreme-react/data-grid";
import { useCompanyData } from "@/hooks/queries";
import useButtonLoading from "@/hooks/useButtonLoading";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import { EtcCodeSearchType, InitEtcCostSearch } from "@/types/etcCost.type";
import { usePostMutation } from "@/util/common.fn";
import Grid from "@/components/atoms/Grid";
import { InputMultiple } from "@/components/atoms/InputMultiple";
import { InputDate, InputSelect } from "@/components/atoms/Inputs";
import { Buttons } from "@/components/atoms/Buttons";
import { Modal } from "..";
import { ReactComponent as IconSearch } from "@/styles/assets/svg/icon_search.svg";
import * as S from "./EtcCostModal.style";

interface EtcCostModalProps {
  onClose: () => void;
  selectedRowKey: string | null;
  initPage: () => void;
  gridMain: any;
}

const EtcCostModal = ({
  onClose,
  selectedRowKey,
  initPage,
  gridMain,
}: EtcCostModalProps) => {
  const { t } = useTranslation("main");
  const [search, setSearch] = useState<EtcCodeSearchType>(InitEtcCostSearch);
  const gridRef: any = useRef();
  const { alertErrorMessage, alertSuccessMessage } = useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { data: mainData, refetch: mainRefetch } = useCustomQuery<any[]>(
    `account-list/etc-cost`,
    search
  );
  const { data: company } = useCompanyData();
  const { mutateAsync: connect } = usePostMutation(`account-list/etc-cost`);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: InitEtcCostSearch,
  });

  const onSearch = (data: any) => {
    setSearch(data);

    mainRefetch();
  };

  const onClickConnect = async () => {
    try {
      setLoading();
      const originalKey = selectedRowKey;
      const res: any = await connect({
        key: selectedRowKey,
        data: gridRef.current.instance.getSelectedRowsData(),
      });

      if (res.state === 1) {
        initPage();

        await alertSuccessMessage("저장완료");
      }

      gridMain.current.instance.option("focusedRowKey", originalKey);
      onClose();
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  return (
    <Modal
      width="1100px"
      close={onClose}
      title="연결하기"
      modalButton={
        <Buttons
          permission="save"
          type="button"
          size="lg"
          width={80}
          layout="solid"
          color="save"
          label="저장"
          onClick={onClickConnect}
        />
      }
    >
      <S.ECModalContent>
        <S.ECModalTopSection onSubmit={handleSubmit(onSearch)}>
          <InputSelect
            type="h-array"
            control={control}
            errors={errors}
            label="접수법인"
            name="receiveCo"
            dataSource={[
              { id: "0", companyName: t("전체") },
              ...(company || []),
            ]}
            valueExpr="id"
            displayExpr="companyName"
          />
          <InputMultiple direction="row" label="등록일">
            <>
              <InputDate
                type="h-array"
                control={control}
                errors={errors}
                name="firstDay"
              />
              ~
              <InputDate
                type="h-array"
                control={control}
                errors={errors}
                name="lastDay"
              />
            </>
          </InputMultiple>
          <Buttons
            type="submit"
            size="sm"
            layout="solid"
            color="find"
            icon={<IconSearch width={18} />}
          />
        </S.ECModalTopSection>
        <Grid subGrid={true} dataSource={mainData} refs={gridRef} keyExpr="Oid">
          <ColumnFixing enabled={true} />
          <Selection
            mode="multiple"
            allowSelectAll={false}
            showCheckBoxesMode="always"
          />
          <Column dataField="receivecoName" caption={t("업체구분")} />
          <Column
            dataField="regDate"
            caption={t("등록일")}
            alignment="center"
            dataType="date"
            format="yyyy-MM-dd"
          />
          <Column
            dataField="companyName"
            caption={t("업체명")}
            alignment="center"
          />
          <Column dataField="sales" caption={t("매출")} alignment="center">
            <Format type="fixedPoint" />
          </Column>
          <Column dataField="purchase" caption={t("매입")} alignment="center">
            <Format type="fixedPoint" />
          </Column>
          <Column
            dataField="remark01"
            caption={t("비고1")}
            alignment="center"
          />
          <Column
            dataField="remark02"
            caption={t("비고2")}
            alignment="center"
          />
        </Grid>
      </S.ECModalContent>
    </Modal>
  );
};

export default EtcCostModal;
