import { useState } from "react";
import { Controller } from "react-hook-form";
import { TextBoxType } from "devextreme/ui/text_box";
import { TextBox, Button } from "devextreme-react/text-box";
import { Input } from "..";

const InputPassword = ({
  control,
  errors,
  name,
  label,
  mask,
  height,
  width,
  required,
  disabled = false,
  onValueChange = () => {},
  maxLength,
  tabIndex,
  direction,
  placeHolder,
}: any) => {
  const [passwordMode, setPasswordMode] = useState<TextBoxType>("password");

  return (
    <Input
      type="password"
      direction={direction ? direction : `row`}
      label={label && label}
      required={required}
      errors={errors && errors}
    >
      <Controller
        render={({ field: { onChange, value } }) => {
          if (value === "") value = null;

          return (
            <TextBox
              mode={passwordMode}
              mask={mask}
              useMaskedValue={true}
              onValueChanged={(value) => {
                onChange(value.value);
                onValueChange(value);
              }}
              value={value}
              disabled={disabled}
              height={height}
              width={width}
              maxLength={maxLength}
              tabIndex={tabIndex}
              defaultValue={null}
              placeholder={placeHolder}
            >
              <Button
                name="password"
                location="after"
                options={{
                  // icon: toAbsoluteUrl("/media/svg/icons/General/Binocular.svg"),
                  stylingMode: "text",
                  type: "default",
                  onClick: () => {
                    passwordMode === "password"
                      ? setPasswordMode("text")
                      : setPasswordMode("password");
                  },
                }}
              />
            </TextBox>
          );
        }}
        control={control}
        name={name}
        defaultValue={null}
      />
    </Input>
  );
};

export default InputPassword;
