import React, { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import useButtonLoading from "@/hooks/useButtonLoading";
import { yupResolver } from "@hookform/resolvers/yup";
import { TaxInputSchema, TaxInputType } from "@/types/tax.type";
import { usePostMutation, usePutMutation } from "@/util/common.fn";
import { InputDate, InputText } from "../Inputs";
import InputTextArea from "../Inputs/InputTextArea";
import InputNumber from "../Inputs/InputNumber";
import { Buttons } from "../Buttons";
import * as S from "./taxTable.styles";

interface TaxTableProps {
  onClose?: () => void;
  taxStatus:
    | "발행"
    | "예약발행"
    | "수정발행"
    | "수정 예약발행"
    | null
    | undefined;
  completeNo: string | undefined; // 마감번호
  finishNo: string | undefined; // 세금계산서 번호
  division: string | undefined;
  isIssued: boolean;
  setIsIssued: React.Dispatch<React.SetStateAction<boolean>>;
  taxData: TaxInputType;
}

const TaxTable = ({
  onClose,
  taxStatus,
  completeNo,
  finishNo,
  division,
  isIssued,
  setIsIssued,
  taxData,
}: TaxTableProps) => {
  const { t } = useTranslation("main");

  const [isMinusIssue, setIsMinusIssue] = useState<boolean>(false);
  const { alertErrorMessage, alertSuccessMessage, confirmMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { mutateAsync: issue } = usePostMutation(`manage-all-finish`);
  const { mutateAsync: cancelDefer } = usePutMutation(
    `manage-all-finish/cancel`
  );
  const { mutateAsync: minusIssue } = usePostMutation(`manage-all-finish/edit`);

  const {
    control,
    watch,
    register,
    reset,
    getValues,
    setValue,
    formState: { errors },
  } = useForm<TaxInputType>({
    resolver: yupResolver(TaxInputSchema),
  });

  const {
    fields: remarkFields,
    append: remarkAppend,
    remove: remarkRemove,
  } = useFieldArray({
    control,
    name: "remark",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "TaxInvoiceTradeLineItem",
  });

  useEffect(() => {
    reset(taxData);
    // eslint-disable-next-line
  }, [taxData]);

  const onUnitPriceChanged = () => {
    const data = watch();
    const TaxInvoiceTradeLineItem = data.TaxInvoiceTradeLineItem || [];

    let amountTotal = 0;
    let taxTotal = 0;

    const newTaxInvoiceTradeLineItem = [];

    for (let i = 0; i < TaxInvoiceTradeLineItem.length; i++) {
      const item = TaxInvoiceTradeLineItem[i];
      const chargeableUnit = item.chargeableUnit || 0;
      const unitPrice = item.unitPrice || 0;

      const amount = chargeableUnit * unitPrice;

      amountTotal += amount;
      taxTotal += Math.round(amount * 0.1);

      newTaxInvoiceTradeLineItem.push({
        ...item,
        amount: amount,
        tax: Math.round(amount * 0.1),
      });
    }

    setValue(`TaxInvoiceTradeLineItem`, newTaxInvoiceTradeLineItem);

    setValue("amountTotal", amountTotal);
    setValue("taxTotal", taxTotal);
    setValue("totalAmount", amountTotal + taxTotal);
  };

  const onTaxChanged = () => {
    const data = watch();
    const TaxInvoiceTradeLineItem = data.TaxInvoiceTradeLineItem || [];

    let taxTotal = 0;

    for (const item of TaxInvoiceTradeLineItem) {
      taxTotal += item.tax || 0;
    }

    setValue("taxTotal", taxTotal);
    setValue("totalAmount", data.amountTotal + taxTotal);
  };

  const onClicktoggleMinusIssue = () => {
    setIsIssued(false);
    setIsMinusIssue(true);

    const data = watch();

    setValue("amountTotal", -getValues("amountTotal"));
    setValue("taxTotal", -getValues("taxTotal"));
    setValue("totalAmount", -getValues("totalAmount"));

    const { TaxInvoiceTradeLineItem } = data;

    const newItem = [];
    for (const obj of TaxInvoiceTradeLineItem || []) {
      newItem.push({
        ...obj,
        amount: -(obj?.amount || 0),
        tax: -(obj?.tax || 0),
        unitPrice: -(obj?.unitPrice || 0),
      });
    }
    setValue(`TaxInvoiceTradeLineItem`, newItem);
  };

  /* 세금계산서 발행 */

  const onClickTaxIssue = async () => {
    if (!(await confirmMessage("세금계산서를 즉시발행하시겠습니까?"))) return;
    const data = watch();

    try {
      setLoading();
      const res: any = await issue({
        completeNo,
        main: { ...data, sendType: 1, division },
        sub: data.TaxInvoiceTradeLineItem,
      });

      if (res.state === 1) {
        await alertSuccessMessage("즉시발행 완료");
      }

      onClose && onClose();
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onClickTaxDeferIssue = async () => {
    if (!(await confirmMessage("세금계산서를 예약발행하시겠습니까?"))) return;
    const data = watch();

    try {
      setLoading();
      const res: any = await issue({
        completeNo,
        main: { ...data, sendType: 2, division },
        sub: data.TaxInvoiceTradeLineItem,
      });

      if (res.state === 1) {
        await alertSuccessMessage("예약발행 완료");
      }

      onClose && onClose();
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onClickTaxDeferCancel = async () => {
    if (!(await confirmMessage("세금계산서를 예약취소하시겠습니까?"))) return;

    try {
      setLoading();
      const res: any = await cancelDefer({ finishNo, division });

      if (res.state === 1) {
        await alertSuccessMessage("예약취소 완료");
      }
      onClose && onClose();
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onClickTaxMinusIssue = async () => {
    if (!(await confirmMessage("세금계산서를 즉시발행하시겠습니까?"))) return;
    const data = watch();

    try {
      setLoading();
      const res: any = await minusIssue({
        completeNo,
        main: { ...data, finishNo, sendType: 1, division },
        sub: data.TaxInvoiceTradeLineItem,
      });

      if (res.state === 1) {
        await alertSuccessMessage("즉시발행 완료");
      }

      onClose && onClose();
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onClickTaxMinusDeferIssue = async () => {
    if (!(await confirmMessage("세금계산서를 예약발행하시겠습니까?"))) return;
    const data = watch();

    try {
      setLoading();
      const res: any = await minusIssue({
        completeNo,
        main: { ...data, finishNo, sendType: 2, division },
        sub: data.TaxInvoiceTradeLineItem,
      });

      if (res.state === 1) {
        await alertSuccessMessage("예약발행 완료");
      }

      onClose && onClose();
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  return (
    <>
      <S.ModalTaxIssueWrapper>
        <S.ModalTaxIssueHeader className="taxissue-header-print">
          <S.ModalTaxIssueTitle>
            {!isMinusIssue && (
              <>
                {t("전 자 세 금 계 산 서")}
                <span>({t("공급자 보관용")})</span>
              </>
            )}
            {isMinusIssue && (
              <>
                {t("수 정 전 자 세 금 계 산 서")}
                <span>({t("공급자 보관용")})</span>
              </>
            )}
          </S.ModalTaxIssueTitle>
        </S.ModalTaxIssueHeader>
        <S.ModalTaxIssueBody>
          <S.InvoiceWrapper>
            <S.Invoice className="invoicer">
              <S.InvoiceItems
                $gridRow
                $gridTop={1}
                $gridBottom={7}
                className="invoicer-tit"
              >
                공<br />급<br />자
              </S.InvoiceItems>
              <S.InvoiceItems className="invoicer-item-tit">
                {t("등록번호")}
              </S.InvoiceItems>
              <S.InvoiceItems>{getValues("corpNum")}</S.InvoiceItems>
              <S.InvoiceItems className="invoicer-item-tit">
                {t("종사업장")}
              </S.InvoiceItems>
              <S.InvoiceItems>
                <InputText
                  control={control}
                  errors={errors}
                  className="form-s"
                  name="taxRegID"
                  maxLength={4}
                  tabIndex={5}
                  disabled={isIssued}
                />
              </S.InvoiceItems>
              <S.InvoiceItems className="invoicer-item-tit">
                {t("상호")}
              </S.InvoiceItems>
              <S.InvoiceItems>{getValues("corpName")}</S.InvoiceItems>
              <S.InvoiceItems className="invoicer-item-tit">
                {t("성명")}
              </S.InvoiceItems>
              <S.InvoiceItems>
                <InputTextArea
                  control={control}
                  errors={errors}
                  name="ceoName"
                  className="scroll"
                  maxLength={100}
                  tabIndex={7}
                  disabled={isIssued}
                />
              </S.InvoiceItems>
              <S.InvoiceItems className="invoicer-item-tit">
                {t("사업장")}
                <br />
                {t("주소")}
              </S.InvoiceItems>
              <S.InvoiceItems $gridLeft={3} $gridRight={6}>
                <InputTextArea
                  control={control}
                  errors={errors}
                  name="addr"
                  className="scroll"
                  maxLength={300}
                  tabIndex={8}
                  disabled={isIssued}
                />
              </S.InvoiceItems>
              <S.InvoiceItems className="invoicer-item-tit">
                {t("업태")}
              </S.InvoiceItems>
              <S.InvoiceItems>
                <InputTextArea
                  control={control}
                  errors={errors}
                  name="bizClass"
                  className="scroll"
                  maxLength={100}
                  tabIndex={9}
                  disabled={isIssued}
                />
              </S.InvoiceItems>
              <S.InvoiceItems className="invoicer-item-tit">
                {t("종목")}
              </S.InvoiceItems>
              <S.InvoiceItems>
                <InputTextArea
                  control={control}
                  errors={errors}
                  name="bizType"
                  className="scroll"
                  maxLength={300}
                  tabIndex={10}
                  disabled={isIssued}
                />
              </S.InvoiceItems>
              <S.InvoiceItems className="invoicer-item-tit">
                {t("담당자")}
              </S.InvoiceItems>
              <S.InvoiceItems>
                <InputText
                  control={control}
                  errors={errors}
                  name="contactName"
                  className="form-s"
                  maxLength={100}
                  tabIndex={11}
                  disabled={isIssued}
                />
              </S.InvoiceItems>
              <S.InvoiceItems className="invoicer-item-tit">
                {t("연락처")}
              </S.InvoiceItems>
              <S.InvoiceItems>
                <InputText
                  control={control}
                  errors={errors}
                  name="tel"
                  className="form-s"
                  maxLength={20}
                  tabIndex={12}
                  disabled={isIssued}
                />
              </S.InvoiceItems>
              <S.InvoiceItems className="invoicer-item-tit">
                {t("이메일")}
              </S.InvoiceItems>
              <S.InvoiceItems $gridLeft={3} $gridRight={6}>
                <InputTextArea
                  control={control}
                  errors={errors}
                  name="email"
                  className="form-s"
                  maxLength={100}
                  tabIndex={13}
                  disabled={isIssued}
                />
              </S.InvoiceItems>
            </S.Invoice>
            <S.Invoice className="invoicee">
              <S.InvoiceItems
                $gridRow
                $gridTop={1}
                $gridBottom={7}
                className="invoicee-tit"
              >
                공<br />급<br />받<br />는<br />자
              </S.InvoiceItems>
              <S.InvoiceItems className="invoicee-item-tit">
                {t("등록번호")}
              </S.InvoiceItems>
              <S.InvoiceItems>{getValues("corpNum_R")}</S.InvoiceItems>
              <S.InvoiceItems className="invoicee-item-tit">
                {t("종사업장")}
              </S.InvoiceItems>
              <S.InvoiceItems>
                <InputText
                  control={control}
                  errors={errors}
                  className="form-s"
                  name="taxRegID_R"
                  maxLength={4}
                  tabIndex={15}
                  disabled={isIssued}
                />
              </S.InvoiceItems>
              <S.InvoiceItems className="invoicee-item-tit">
                {t("상호")}
              </S.InvoiceItems>
              <S.InvoiceItems>{getValues("corpName_R")}</S.InvoiceItems>
              <S.InvoiceItems className="invoicee-item-tit">
                {t("성명")}
              </S.InvoiceItems>
              <S.InvoiceItems>
                <InputTextArea
                  control={control}
                  errors={errors}
                  name="ceoName_R"
                  className="scroll"
                  maxLength={100}
                  tabIndex={17}
                  disabled={isIssued}
                />
              </S.InvoiceItems>
              <S.InvoiceItems className="invoicee-item-tit">
                {t("사업장")}
                <br />
                {t("주소")}
              </S.InvoiceItems>
              <S.InvoiceItems $gridLeft={3} $gridRight={6}>
                <InputTextArea
                  control={control}
                  errors={errors}
                  name="addr_R"
                  className="scroll"
                  maxLength={300}
                  tabIndex={18}
                  disabled={isIssued}
                />
              </S.InvoiceItems>
              <S.InvoiceItems className="invoicee-item-tit">
                {t("업태")}
              </S.InvoiceItems>
              <S.InvoiceItems>
                <InputTextArea
                  control={control}
                  errors={errors}
                  name="bizClass_R"
                  className="scroll"
                  maxLength={100}
                  tabIndex={19}
                  disabled={isIssued}
                />
              </S.InvoiceItems>
              <S.InvoiceItems className="invoicee-item-tit">
                {t("종목")}
              </S.InvoiceItems>
              <S.InvoiceItems>
                <InputTextArea
                  control={control}
                  errors={errors}
                  name="bizType_R"
                  className="scroll"
                  maxLength={300}
                  tabIndex={20}
                  disabled={isIssued}
                />
              </S.InvoiceItems>
              <S.InvoiceItems className="invoicee-item-tit">
                {t("담당자")}
              </S.InvoiceItems>
              <S.InvoiceItems>
                <InputText
                  control={control}
                  errors={errors}
                  name="contactName_R"
                  className="form-s"
                  maxLength={100}
                  tabIndex={21}
                  disabled={isIssued}
                />
              </S.InvoiceItems>
              <S.InvoiceItems className="invoicee-item-tit">
                {t("연락처")}
              </S.InvoiceItems>
              <S.InvoiceItems>
                <InputText
                  control={control}
                  errors={errors}
                  name="tel_R"
                  className="form-s"
                  maxLength={20}
                  tabIndex={22}
                  disabled={isIssued}
                />
              </S.InvoiceItems>
              <S.InvoiceItems className="invoicee-item-tit">
                {t("이메일")}
              </S.InvoiceItems>
              <S.InvoiceItems $gridLeft={3} $gridRight={6}>
                <InputTextArea
                  control={control}
                  errors={errors}
                  name="email_R"
                  className="form-s"
                  maxLength={100}
                  tabIndex={23}
                  disabled={isIssued}
                />
              </S.InvoiceItems>
            </S.Invoice>
          </S.InvoiceWrapper>

          <S.InfoWrapper>
            <S.InfoItems className="item-tit">{t("작성일자")}</S.InfoItems>
            <S.InfoItems className="item-tit">{t("공급가액")}</S.InfoItems>
            <S.InfoItems className="item-tit">{t("세액")}</S.InfoItems>
            <S.InfoItems className="item-tit">{t("합계금액")}</S.InfoItems>
            <S.InfoItems className="no-border">
              <InputDate
                control={control}
                errors={errors}
                name="writeDate"
                disabled={isIssued}
                // onValueChanged={onWriteDateChanged}
              />
            </S.InfoItems>
            <S.InfoItems>
              <InputNumber
                control={control}
                errors={errors}
                className="readonly a_r form-s"
                name="amountTotal"
                disabled={true}
              />
            </S.InfoItems>
            <S.InfoItems>
              <InputNumber
                control={control}
                errors={errors}
                className="readonly a_r form-s"
                name="taxTotal"
                disabled={true}
              />
            </S.InfoItems>
            <S.InfoItems>
              <InputNumber
                control={control}
                errors={errors}
                className="readonly a_r form-s"
                name="totalAmount"
                disabled={true}
              />
            </S.InfoItems>
          </S.InfoWrapper>
          {!isIssued && (
            <Buttons
              type="button"
              size="sm"
              width={60}
              layout="outline"
              color="grid"
              label="비고추가"
              onClick={() => {
                const remark = watch("remark");
                if (Array.isArray(remark) && remark.length === 3) return;
                remarkAppend({});
              }}
            />
          )}

          {remarkFields.map((item, index) => (
            <S.AdditionalWrapper key={item.id}>
              <S.AdditionalItems className="item-tit">
                {t("비고")}
                {index + 1}
              </S.AdditionalItems>
              <S.AdditionalItems>
                <InputText
                  control={control}
                  errors={errors}
                  name={`remark[${index}].remark`}
                  className="form-s"
                  maxLength={150}
                  tabIndex={26}
                  disabled={isIssued}
                />
                <Buttons
                  type="button"
                  size="sm"
                  layout="outline"
                  color="destructive"
                  label="삭제"
                  onClick={() => {
                    remarkRemove(index);
                  }}
                  disabled={isIssued}
                />
              </S.AdditionalItems>
            </S.AdditionalWrapper>
          ))}
        </S.ModalTaxIssueBody>

        <S.DetailWrapper>
          {!isIssued && (
            <Buttons
              type="button"
              size="sm"
              width={60}
              layout="outline"
              color="grid"
              label="품목추가"
              onClick={() =>
                append({
                  purchaseExpiryYear: dayjs(
                    watch("writeDate") || undefined
                  ).format("YYYY"),
                  purchaseExpiryMonth: dayjs(
                    watch("writeDate") || undefined
                  ).format("MM"),
                  purchaseExpiryDay: dayjs(
                    watch("writeDate") || undefined
                  ).format("DD"),
                  chargeableUnit: 1,
                })
              }
            />
          )}
          <S.DetailItemsList>
            <S.DetailItems className="item-tit">{t("월")}</S.DetailItems>
            <S.DetailItems className="item-tit">{t("일")}</S.DetailItems>
            <S.DetailItems className="item-tit">{t("품목")}</S.DetailItems>
            <S.DetailItems className="item-tit">{t("규격")}</S.DetailItems>
            <S.DetailItems className="item-tit">{t("수량")}</S.DetailItems>
            <S.DetailItems className="item-tit">{t("단가")}</S.DetailItems>
            <S.DetailItems className="item-tit">{t("공급가액")}</S.DetailItems>
            <S.DetailItems className="item-tit">{t("세액")}</S.DetailItems>
            <S.DetailItems className="item-tit">{t("비고")}</S.DetailItems>
            <S.DetailItems className="item-tit">{t("삭제")}</S.DetailItems>
          </S.DetailItemsList>

          {fields.length === 0 ? (
            <S.DetailItemsList>
              <S.DetailItems className="grid-full">
                {t("상품를 등록해 주십시오.")}
              </S.DetailItems>
            </S.DetailItemsList>
          ) : (
            <>
              {fields.map((item, index) => {
                return (
                  <S.DetailItemsList key={item.id}>
                    <S.DetailItems>
                      <InputText
                        control={control}
                        errors={errors}
                        name={`TaxInvoiceTradeLineItem[${index}].purchaseExpiryMonth`}
                        className="form-s a_c readonly"
                        maxLength={2}
                        tabIndex={50}
                        disabled={isIssued}
                      />
                    </S.DetailItems>
                    <S.DetailItems>
                      <InputText
                        control={control}
                        errors={errors}
                        name={`TaxInvoiceTradeLineItem[${index}].purchaseExpiryDay`}
                        className="form-s a_c"
                        maxLength={2}
                        tabIndex={50}
                        disabled={isIssued}
                      />
                    </S.DetailItems>
                    <S.DetailItems>
                      <InputTextArea
                        control={control}
                        errors={errors}
                        name={`TaxInvoiceTradeLineItem[${index}].name`}
                        className="form-s a_c"
                        maxLength={100}
                        tabIndex={50}
                        disabled={isIssued}
                      />
                    </S.DetailItems>
                    <S.DetailItems>
                      <InputTextArea
                        control={control}
                        errors={errors}
                        name={`TaxInvoiceTradeLineItem[${index}].information`}
                        className="scroll a_l kor valignm"
                        maxLength={60}
                        tabIndex={50}
                        disabled={isIssued}
                      />
                    </S.DetailItems>
                    <S.DetailItems>
                      <InputNumber
                        control={control}
                        errors={errors}
                        name={`TaxInvoiceTradeLineItem[${index}].chargeableUnit`}
                        className="form-s a_r"
                        maxLength={12}
                        tabIndex={50}
                        disabled={isIssued}
                        onValueChanged={onUnitPriceChanged}
                      />
                    </S.DetailItems>
                    <S.DetailItems>
                      <InputNumber
                        control={control}
                        errors={errors}
                        name={`TaxInvoiceTradeLineItem[${index}].unitPrice`}
                        className="form-s a_r"
                        maxLength={18}
                        tabIndex={50}
                        disabled={isIssued}
                        onValueChanged={onUnitPriceChanged}
                      />
                    </S.DetailItems>
                    <S.DetailItems>
                      <InputNumber
                        control={control}
                        errors={errors}
                        name={`TaxInvoiceTradeLineItem[${index}].amount`}
                        className="form-s a_r"
                        maxLength={18}
                        tabIndex={50}
                        disabled={true}
                      />
                    </S.DetailItems>
                    <S.DetailItems>
                      <InputNumber
                        control={control}
                        errors={errors}
                        name={`TaxInvoiceTradeLineItem[${index}].tax`}
                        className="form-s a_r"
                        maxLength={18}
                        tabIndex={50}
                        disabled={isIssued}
                        onValueChanged={onTaxChanged}
                      />
                    </S.DetailItems>
                    <S.DetailItems>
                      <InputTextArea
                        control={control}
                        errors={errors}
                        name={`TaxInvoiceTradeLineItem[${index}].description`}
                        className="scroll a_l kor"
                        maxLength={100}
                        tabIndex={50}
                        disabled={isIssued}
                      />
                    </S.DetailItems>
                    <S.DetailItems>
                      <Buttons
                        type="button"
                        size="sm"
                        layout="outline"
                        color="destructive"
                        label="삭제"
                        onClick={() => {
                          remove(index);
                          onUnitPriceChanged();
                        }}
                        disabled={isIssued || index === 0}
                      />
                    </S.DetailItems>
                  </S.DetailItemsList>
                );
              })}
            </>
          )}
        </S.DetailWrapper>

        <S.CloseWrapper>
          <S.CloseItems className="item-tit">{t("현금")}</S.CloseItems>
          <S.CloseItems className="item-tit">{t("수표")}</S.CloseItems>
          <S.CloseItems className="item-tit">{t("어음")}</S.CloseItems>
          <S.CloseItems className="item-tit">{t("외상미수금")}</S.CloseItems>
          <S.CloseItems
            $gridLeft={5}
            $gridRight={6}
            $gridTop={1}
            $gridBottom={3}
            className="item-finish"
          >
            <span>{t("이 금액을")}</span>
            <div>
              <label>
                <input
                  type="radio"
                  className="rb"
                  value={1}
                  {...register("purposeType")}
                  disabled={isIssued}
                />
                {t("영수")}
              </label>
              <label>
                <input
                  type="radio"
                  className="rb"
                  value={2}
                  {...register("purposeType")}
                  disabled={isIssued}
                />
                {t("청구")}
              </label>
              {t("함.")}
            </div>
          </S.CloseItems>
          <S.CloseItems className="no-border">
            <InputNumber
              control={control}
              errors={errors}
              name="cash"
              className=" a_r form-s"
              tabIndex={51}
              disabled={isIssued}
            />
          </S.CloseItems>
          <S.CloseItems>
            <InputNumber
              control={control}
              errors={errors}
              name="chkBill"
              className=" a_r form-s"
              tabIndex={52}
              disabled={isIssued}
            />
          </S.CloseItems>
          <S.CloseItems>
            <InputNumber
              control={control}
              errors={errors}
              name="note"
              className=" a_r form-s"
              tabIndex={53}
              disabled={isIssued}
            />
          </S.CloseItems>
          <S.CloseItems>
            <InputNumber
              control={control}
              errors={errors}
              name="credit"
              className=" a_r form-s"
              tabIndex={54}
              disabled={isIssued}
            />
          </S.CloseItems>
        </S.CloseWrapper>
      </S.ModalTaxIssueWrapper>
      <S.TaxButtonGroup>
        {!isIssued && !isMinusIssue && (
          <>
            <Buttons
              type="button"
              size="lg"
              width={180}
              layout="solid"
              color="primary100"
              label="세금계산서 즉시발행"
              onClick={onClickTaxIssue}
              // disabled={issueLoading || minusIssueLoading}
            />
            <Buttons
              type="button"
              size="lg"
              width={180}
              layout="solid"
              color="primary100"
              label="세금계산서 예약발행"
              onClick={onClickTaxDeferIssue}
              // disabled={issueLoading || minusIssueLoading}
            />
          </>
        )}
        {taxStatus === "발행" && !isMinusIssue && (
          <Buttons
            type="button"
            size="lg"
            width={90}
            layout="solid"
            color="primary100"
            label="수정발행"
            onClick={onClicktoggleMinusIssue}
            // disabled={issueLoading || minusIssueLoading}
          />
        )}
        {taxStatus === "발행" && isMinusIssue && (
          <>
            <Buttons
              type="button"
              size="lg"
              width={90}
              layout="solid"
              color="primary100"
              label="즉시발행"
              onClick={onClickTaxMinusIssue}
              // disabled={issueLoading || minusIssueLoading}
            />
            <Buttons
              type="button"
              size="lg"
              width={90}
              layout="solid"
              color="primary100"
              label="예약발행"
              onClick={onClickTaxMinusDeferIssue}
              // disabled={issueLoading || minusIssueLoading}
            />
            <Buttons
              type="button"
              size="lg"
              width={80}
              layout="solid"
              color="destructive"
              label="취소"
              onClick={() => {
                setIsIssued(true);
                setIsMinusIssue(false);
                reset(taxData);
              }}
              // disabled={issueLoading || minusIssueLoading}
            />
          </>
        )}
        {taxStatus === "예약발행" && (
          <Buttons
            type="button"
            size="lg"
            width={90}
            layout="solid"
            color="destructive"
            label="예약취소"
            onClick={onClickTaxDeferCancel}
            // disabled={issueLoading || minusIssueLoading}
          />
        )}
        <Buttons
          type="button"
          size="lg"
          width={80}
          layout="solid"
          color="grid"
          label="출력"
          onClick={() => window.print()}
          // disabled={issueLoading || minusIssueLoading}
        />
      </S.TaxButtonGroup>
    </>
  );
};

export default TaxTable;
