import { useForm } from "react-hook-form";
import useButtonLoading from "@/hooks/useButtonLoading";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import { usePutMutation } from "@/util/common.fn";
import { Buttons } from "@/components/atoms/Buttons";
import InputNumber from "@/components/atoms/Inputs/InputNumber";
import { Modal } from "..";

interface TaxAmendProps {
  onClose: () => void;
  initPage: () => void;

  gridMain: any;
  gridSub: any;
}

const TaxAmend = ({ onClose, initPage, gridMain, gridSub }: TaxAmendProps) => {
  const { alertErrorMessage, alertSuccessMessage, confirmMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { mutateAsync: amendTax } = usePutMutation("finish-sell-manage/tax");

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      tax: 0,
    },
  });

  const onSubmit = async ({ tax }: any) => {
    if (!(await confirmMessage("부가세를 수정하시겠습니까?"))) return;
    const mainGridInstance = gridMain.current.instance;
    const subGridInstance = gridSub.current.instance;

    const originalKey = mainGridInstance.option("focusedRowKey");

    const selected = subGridInstance.getSelectedRowsData();

    try {
      setLoading();
      const res: any = await amendTax({ tax, selected });

      if (res.state === 1) {
        initPage();
        await alertSuccessMessage("저장완료");

        mainGridInstance.option("focusedRowKey", originalKey);

        onClose();
      }
    } catch (error) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  return (
    <Modal
      width="320px"
      close={onClose}
      title="부가세 수정"
      modalButton={
        <Buttons
          permission="save"
          type="submit"
          size="lg"
          width={80}
          layout="solid"
          color="save"
          label="저장"
        />
      }
      formSubmit={handleSubmit(onSubmit)}
    >
      <InputNumber
        type="v-array"
        control={control}
        errors={errors}
        name="tax"
        label="세액"
      />
    </Modal>
  );
};

export default TaxAmend;
