import styled from "styled-components";

export const DispatchItemBox = styled.div`
  grid-column: 1/3;
  display: grid;
  margin-top: 6px;
  padding-top: 20px;
  grid-template-columns: repeat(2, 1fr);
  border-top: 1px solid ${(props) => props.theme.colors.black4};
  gap: 14px;
`;

export const DispatchItemSelect = styled.div`
  display: flex;
  width: 100%;
  height: 34px;
  margin-top: 16px;
  background: ${(props) => props.theme.colors.white70};
  border: 1px solid ${(props) => props.theme.colors.black12};
  border-radius: 4px;
  align-items: center;
  justify-content: center;
`;

export const DispatchButtonSet = styled.div`
  display: flex;
  width: 100%;
  padding-bottom: 1px;
  align-items: flex-end;
  gap: 4px;
`;
