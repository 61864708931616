import styled from "styled-components";

export const TopbarPopupItem = styled.li`
  display: flex;
  height: 38px;
  padding: 0 16px;
  font-size: 1.4rem;
  align-items: center;
  gap: 4px;
  cursor: pointer;
  transition: all 0.2s ease;

  span {
    padding-top: 1px;
  }

  svg {
    path {
      fill: ${(props) => props.theme.colors.gray100};
      transition: all 0.2s ease;
    }
  }

  &:hover {
    color: ${(props) => props.theme.colors.primary100};
    background: ${(props) => props.theme.colors.primary12};

    svg {
      path {
        fill: ${(props) => props.theme.colors.primary100};
      }
    }
  }
`;
