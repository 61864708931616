import { Buttons } from "@/components/atoms/Buttons/index.tsx";
import { AsideMenuList } from "@/components/molecules/AsideMenuList/index.tsx";
import * as S from "./Aside.style.ts";
import { ReactComponent as IconArrow } from "@/styles/assets/svg/icon_menu_arrow.svg";
import { ReactComponent as LogoAside } from "@/styles/assets/svg/logo_aside.svg";

interface AsideProps {
  asideOpen: boolean;
  handleAsideOpen: any;
}

export default function Aside({ asideOpen, handleAsideOpen }: AsideProps) {
  return (
    <S.AsideBox asideOpen={asideOpen}>
      <S.Header asideOpen={asideOpen}>
        <Buttons
          type="button"
          size="sm"
          layout="text"
          color="white100"
          onClick={handleAsideOpen}
        >
          <IconArrow transform={asideOpen ? "" : "rotate(180)"} />
        </Buttons>
        <S.TitleHeader asideOpen={asideOpen}>
          Transportation
          <br />
          Management System
          <LogoAside />
        </S.TitleHeader>
      </S.Header>

      <AsideMenuList asideOpen={asideOpen} />
    </S.AsideBox>
  );
}
