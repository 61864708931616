import styled, { css } from "styled-components";
import { DetailContentProps } from ".";

export const DetailContent = styled.div<DetailContentProps>`
  grid-column: 1/3;
  overflow: hidden;
  display: flex;
  width: 100%;
  padding: 20px;
  background: ${(props) => props.theme.colors.secondary100};
  box-shadow: ${(props) => props.theme.shadows.popup};
  flex-direction: column;
  gap: 10px;

  ${(props) =>
    props.position === "right" &&
    css`
      grid-column: 2/3;
    `};
`;

export const DetailSectionTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DetailSectionTab = styled.ul`
  display: flex;
  padding: 2px;
  background: ${(props) => props.theme.colors.white100};
  border-radius: 22px;
`;

export const DetailSectionButtons = styled.div`
  display: flex;
  gap: 4px;
`;

export const DetailTotal = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.primary100};
  font-size: 1.2rem;
  font-weight: 500;
  align-items: center;
  justify-content: flex-end;
`;
