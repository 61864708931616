import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import dayjs from "dayjs";
import useButtonLoading from "@/hooks/useButtonLoading";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import { ClosingRowType } from "@/types/closing.type";
import { InitialTaxData, TaxInputType } from "@/types/tax.type";
import { request } from "@/util/api";
import TaxTable from "@/components/atoms/TaxTable";
import { Modal } from "..";

interface TaxIssueModalProps {
  onClose: () => void;
  selectedRowData: ClosingRowType | null;
}

const TaxIssueModal = ({ onClose, selectedRowData }: TaxIssueModalProps) => {
  const [isIssued, setIsIssued] = useState<boolean>(false);
  const [taxStatus, setTaxStatus] = useState<
    "발행" | "예약발행" | "수정발행" | "수정 예약발행" | undefined | null
  >(null);
  const [taxData, setTaxData] = useState<TaxInputType>(InitialTaxData);
  const { alertErrorMessage } = useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { mutateAsync: notIssueData } = useMutation({
    mutationFn: ({ completeNo, params }: any) => {
      return request<{
        main: any;
        sub: {
          deliverDate: string;
          price: string;
          tax: string;
          division: string;
          extraDivision: string;
        }[];
      }>({
        method: "GET",
        url: `/manage-all-finish/is-not-issue/${completeNo}`,
        params,
      });
    },
  });

  const { mutateAsync: issuedData } = useMutation({
    mutationFn: (finishNo: any) => {
      return request<{
        main: any;
        sub: {
          barobill_tax_main_oid: string;
          chargeable_unit: number;
          purchase_expiry: string;
          name: string;
          information: string;
          amount: number;
          tax: number;
          unit_price: number;
          description: string;
        }[];
      }>({ method: "GET", url: `/manage-all-finish/is-issue/${finishNo}` });
    },
  });

  useEffect(() => {
    setTaxStatus(selectedRowData?.taxStatus);

    if (selectedRowData?.finishNo && selectedRowData.closeStatus === "발행") {
      setIsIssued(true);
      getIssueDataFunction();
    } else {
      setIsIssued(false);
      getNotIssueDataFunction();
    }
    // eslint-disable-next-line
  }, [selectedRowData]);

  const getIssueDataFunction = async () => {
    try {
      setLoading();
      const data = await issuedData(selectedRowData?.finishNo);

      const { main, sub } = data;
      const mainObj = main[0];

      const remarkArr = [];
      remarkArr.push({ remark: mainObj.remark1 });
      mainObj.remark2 && remarkArr.push({ remark: mainObj.remark2 });
      mainObj.remark3 && remarkArr.push({ remark: mainObj.remark3 });

      const TaxInvoiceTradeLineItem = [];
      for (const obj of sub) {
        TaxInvoiceTradeLineItem.push({
          barobill_tax_main_oid: obj.barobill_tax_main_oid,
          purchaseExpiryYear: dayjs(mainObj.writeDate || undefined).format(
            "YYYY"
          ),
          purchaseExpiryMonth: obj.purchase_expiry.split("/")[0],
          purchaseExpiryDay: obj.purchase_expiry.split("/")[1],
          name: obj.name,
          information: obj.information,
          chargeableUnit: obj.chargeable_unit,
          unitPrice: obj.unit_price,
          amount: obj.amount,
          tax: obj.tax,
          description: obj.description,
        });
      }

      const TaxData: TaxInputType = {
        contactID: mainObj.contactID,

        corpNum: mainObj.corp_num,
        taxRegID: mainObj.tax_reg_id,
        corpName: mainObj.corp_name,
        ceoName: mainObj.ceo_name,
        addr: mainObj.addr,
        bizClass: mainObj.biz_class,
        bizType: mainObj.biz_type,
        contactName: mainObj.contact_name,
        tel: mainObj.tel,
        email: mainObj.email,

        corpNum_R: mainObj.corp_num_r,
        taxRegID_R: mainObj.tax_reg_id_r,
        corpName_R: mainObj.corp_name_r,
        ceoName_R: mainObj.ceo_name_r,
        addr_R: mainObj.addr_r,
        bizClass_R: mainObj.biz_class_r,
        bizType_R: mainObj.biz_type_r,
        contactName_R: mainObj.contact_name_r || "담당자",
        tel_R: mainObj.tel_R,
        email_R: mainObj.email_r,

        writeDate: mainObj.write_date,
        amountTotal: Number(mainObj.amount_total || 0),
        taxTotal: Number(mainObj.tax_total || 0),
        totalAmount: Number(mainObj.total_amount || 0),

        purposeType: `${mainObj.purpose_type}`,

        remark: remarkArr,

        TaxInvoiceTradeLineItem: TaxInvoiceTradeLineItem,
      };

      setTaxData(TaxData);
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const getNotIssueDataFunction = async () => {
    if (!selectedRowData) return;

    const {
      receiveCoName,
      companyId,
      comOid,
      companyName,
      division,
      completeNo,
    } = selectedRowData;

    try {
      setLoading();
      const res = await notIssueData({
        completeNo,
        params: {
          receiveCo: receiveCoName,
          completeNo: completeNo,
          companyId: companyId,
          comOid: comOid,
          company: companyName,
          division: division,
        },
      });

      const { main, sub } = res;

      const invoicer = main.invoicer[0];
      const invoicee = main.invoicee[0];
      const deliverDate = sub[0]?.deliverDate;

      const TaxInvoiceTradeLineItem = sub.map((cur) => {
        return {
          purchaseExpiryYear: dayjs().format("YYYY"),
          purchaseExpiryMonth: dayjs().format("MM"),
          purchaseExpiryDay: dayjs().format("DD"),
          name: "운송료",
          information: "",
          chargeableUnit: 1,
          unitPrice: Number(cur.price),
          amount: Number(cur.price),
          tax: Number(cur.tax),
          description: "",
        };
      });

      if (division === "청구처" || division === "용차사") {
        const TaxData: TaxInputType = {
          orderCode: invoicer.orderCode,
          contactID: invoicer.contactID,
          contactID_R: invoicee.contactID,
          corpNum: invoicer.Biz_No || "",
          // taxRegID: invoicer.bizPlaceNo,
          corpName: invoicer.companyName,
          ceoName: invoicer.ceoName,
          addr: `${invoicer.address || ""} ${invoicer.addressDetail || ""}`,
          /** 종목 */
          bizClass: invoicer.bizItem,
          /** 업태 */
          bizType: invoicer.bizType,
          contactName: invoicer.contactName || "",
          tel: invoicer.tel,
          email: invoicer.email || "",
          corpNum_R: invoicee.bizNo || "",
          taxRegID_R: invoicee.bizPlaceNo || "",
          corpName_R: invoicee.bizName,
          ceoName_R: invoicee.ceoName,
          addr_R: `${invoicee.address || ""} ${invoicee.address1 || ""}`,
          /** 종목 */
          bizClass_R: invoicee.item,
          /** 업태 */
          bizType_R: invoicee.bizCondition,
          contactName_R: invoicee.billManage || "담당자",
          tel_R: invoicee.tel,
          // email_R: invoicee.billEmail || "",
          email_R: "dev01@insystem.kr" || "",
          writeDate: deliverDate
            ? dayjs(deliverDate).format("YYYY-MM-DD")
            : dayjs().format("YYYY-MM-DD"),
          amountTotal: Number(sub[0]?.price || 0),
          taxTotal: Number(sub[0]?.tax || 0),
          totalAmount: Number(sub[0]?.price) + Number(sub[0]?.tax || 0),
          purposeType: "2",

          remark: [{ remark: "" }],

          TaxInvoiceTradeLineItem: TaxInvoiceTradeLineItem,
        };

        setTaxData(TaxData);
      } else if (division === "기사") {
        const TaxData: TaxInputType = {
          orderCode: invoicer.orderCode,
          contactID: invoicer.contactID,
          contactID_R: invoicee.contactID,
          corpNum: invoicer.bizNo || "",
          taxRegID: invoicer.bizPlaceNo,
          corpName: invoicer.bizName,
          ceoName: invoicer.ceoName,
          addr: `${invoicer.bizAddress || ""} ${
            invoicer.bizDetailAddress || ""
          }`,
          /** 종목 */
          bizClass: invoicer.item,
          /** 업태 */
          bizType: invoicer.bizCondition,
          contactName: invoicer.registerWorker,
          tel: invoicer.tel,
          email: invoicer.email,

          corpNum_R: invoicee.Biz_No || "",
          corpName_R: invoicee.companyName,
          ceoName_R: invoicee.ceoName,
          addr_R: `${invoicee.address || ""} ${invoicee.addressDetail || ""}`,
          bizClass_R: invoicee.bizItem,
          bizType_R: invoicee.bizType,
          contactName_R: invoicee.registerWorker || "담당자",
          tel_R: invoicee.tel,
          // email_R: invoicee.email,
          email_R: "dev01@insystem.kr" || "",

          writeDate: deliverDate
            ? dayjs(deliverDate).format("YYYY-MM-DD")
            : dayjs().format("YYYY-MM-DD"),
          amountTotal: Number(sub[0]?.price || 0),
          taxTotal: Number(sub[0]?.tax || 0),
          totalAmount: Number(sub[0]?.price) + Number(sub[0]?.tax || 0),
          purposeType: "2",

          remark: [{ remark: "" }],

          TaxInvoiceTradeLineItem: TaxInvoiceTradeLineItem,
        };

        setTaxData(TaxData);
      }
    } catch (error) {
      alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  return (
    <Modal width="75%" close={onClose} title="세금계산서">
      <TaxTable
        onClose={onClose}
        taxStatus={taxStatus}
        completeNo={selectedRowData?.completeNo}
        finishNo={selectedRowData?.finishNo}
        division={selectedRowData?.division}
        isIssued={isIssued}
        setIsIssued={setIsIssued}
        taxData={taxData}
      />
    </Modal>
  );
};

export default TaxIssueModal;
