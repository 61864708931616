import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  useBillingData,
  useCargoOwnerData,
  useComCodeData,
} from "@/hooks/queries";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import { 컨테이너_규격 } from "@/data/constant/queryKeys";
import { InputSelect, InputText } from "@/components/atoms/Inputs";
import InputNumber from "@/components/atoms/Inputs/InputNumber";
import { Buttons } from "@/components/atoms/Buttons";
import { FormContent } from "@/components/organisms/Contents/FormContent";
import { ReactComponent as IconCreate } from "@/styles/assets/svg/icon_button_create.svg";
import { ReactComponent as IconSave } from "@/styles/assets/svg/icon_button_save.svg";
import { ReactComponent as IconDelete } from "@/styles/assets/svg/icon_button_delete.svg";

interface ShuttleBasicInputProps {
  selectedRowKey: string | null;
  inputForm: UseFormReturn<any>;
  toggleOpen: () => void;
  onClickNewData: () => void;
  onClickSaveData: () => Promise<void>;
  onClickDeleteData: () => Promise<void>;
}

const ShuttleBasicInput = ({
  selectedRowKey,
  inputForm,
  toggleOpen,
  onClickNewData,
  onClickSaveData,
  onClickDeleteData,
}: ShuttleBasicInputProps) => {
  const { t } = useTranslation("main");
  const { data: billing } = useBillingData();
  const { data: cargoOwner } = useCargoOwnerData();
  const { data: conType } = useComCodeData(컨테이너_규격);

  const { data: terminal } = useCustomQuery<any[]>(`terminal/terminal`, {});
  const { data: workPlace } = useCustomQuery<any[]>(`terminal/workplace`, {});

  const {
    control,
    formState: { errors },
  } = inputForm;

  return (
    <FormContent
      title={t("셔틀 정보 등록")}
      titleButtons={
        <Buttons
          type="button"
          size="sm"
          layout="text"
          color="primary100"
          icon={<IconCreate />}
          label="신규입력"
          onClick={onClickNewData}
        />
      }
      inputForms={
        <>
          <InputText
            label="정렬순서"
            name="sortNo"
            direction="column"
            control={control}
            errors={errors}
            required={true}
          />
          <InputSelect
            label="수입/수출"
            name="importExport"
            direction="column"
            displayExpr="name"
            valueExpr="id"
            control={control}
            errors={errors}
            dataSource={[
              { id: 1, name: t("수입") },
              { id: 2, name: t("수출") },
            ]}
            required={true}
          />
          <InputSelect
            label="화주"
            name="cargoOwner"
            direction="column"
            displayExpr="company"
            valueExpr="oid"
            control={control}
            errors={errors}
            dataSource={cargoOwner}
            required={true}
          />
          <InputSelect
            label="청구처"
            name="billingPlace"
            direction="column"
            displayExpr="company"
            valueExpr="oid"
            control={control}
            errors={errors}
            dataSource={billing}
            required={true}
          />
          <InputSelect
            label="컨규격"
            name="containerType"
            direction="column"
            displayExpr="subName"
            valueExpr="subCode"
            control={control}
            errors={errors}
            dataSource={conType}
            required={true}
          />
          <InputText
            name="containerNo"
            label="컨테이너번호"
            direction="column"
            control={control}
            errors={errors}
            required={true}
          />
          <InputSelect
            label="상차지"
            name="loadPlace"
            direction="column"
            displayExpr="twName"
            valueExpr="code"
            control={control}
            errors={errors}
            dataSource={terminal}
            required={true}
          />
          <InputSelect
            label="작업지"
            name="workPlace"
            direction="column"
            displayExpr="twName"
            valueExpr="code"
            control={control}
            errors={errors}
            dataSource={workPlace}
          />
          <InputSelect
            label="하차지"
            name="unloadPlace"
            direction="column"
            displayExpr="twName"
            valueExpr="code"
            control={control}
            errors={errors}
            dataSource={terminal}
          />
          <InputNumber
            label="청구금액"
            name="freight"
            direction="column"
            control={control}
            errors={errors}
            required={true}
          />
          <InputNumber
            label="상차지 하불금액"
            name="habul01"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputNumber
            label="하차지 하불금액"
            name="habul02"
            direction="column"
            control={control}
            errors={errors}
          />
        </>
      }
      formButtons={
        <>
          <Buttons
            permission="save"
            type="button"
            size="sm"
            layout="solid"
            color="save"
            icon={<IconSave />}
            label="저장"
            onClick={onClickSaveData}
          />
          <Buttons
            permission="delete"
            type="button"
            size="sm"
            layout="solid"
            color="destructive"
            icon={<IconDelete />}
            label="삭제"
            onClick={onClickDeleteData}
          />
        </>
      }
    >
      {/* <Button
          className="input-back"
          variety="tran"
          size="xxsm"
          shape="solid"
          type="button"
          onClick={toggleOpen}
        >
          <MovePageIcon width={26} height={26} viewBox="0 0 24 24" />
          <span>{t("뒤로")}</span>
        </Button> */}
    </FormContent>
  );
};

export default ShuttleBasicInput;
