import dayjs from "dayjs";
import * as yup from "yup";

export interface EtcCodeSearchType {
  receiveCo: string;
  firstDay: string;
  lastDay: string;
}

export const InitEtcCostSearch = {
  receiveCo: "0",
  firstDay: dayjs().subtract(7, "days").format("YYYY-MM-DD"),
  lastDay: dayjs().format("YYYY-MM-DD"),
};

export const EtcCostInputSchema = yup.object().shape({
  companyOid: yup.string().required("업체를 선택해주세요"),
  regDate: yup.string().required("등록일을 선택해주세요"),
  companyName: yup.string().required("업체명을 입력해주세요"),
  sales: yup.number().nullable(),
  purchase: yup.number().nullable(),
  remark01: yup.string().nullable(),
  remark02: yup.string().nullable(),
});

export interface EtcCostInputType
  extends yup.InferType<typeof EtcCostInputSchema> {}

export const InitEtcCostInput: EtcCostInputType = {
  companyOid: "",
  regDate: dayjs().format("YYYY-MM-DD"),
  companyName: "",
  sales: 0,
  purchase: 0,
  remark01: "",
  remark02: "",
};
