import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Column,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import { AccountExcelFileOptions } from "@/types/accountExcel.type";
import Grid from "@/components/atoms/Grid";
import { Buttons } from "@/components/atoms/Buttons";
import { GridContent } from "@/components/organisms/Contents/GridContent";

interface AccountExcelUpperGridProps {
  bottomHeight: number;
  uploadClick: any;
  sampleClick: any;
  isLoading: any;
  gridMain: React.MutableRefObject<any>;
  mainGrid: any[] | undefined;
}

const AccountExcelUpperGrid = ({
  bottomHeight,
  uploadClick,
  sampleClick,
  isLoading,
  gridMain,
  mainGrid,
}: AccountExcelUpperGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "AccountExcelUpperGrid";
  const [totalCount, setTotalCount] = useState<number>(0);
  return (
    <GridContent
      bottomHeight={bottomHeight}
      gridSectionButtons={
        <>
          <Buttons
            permission="save"
            type="button"
            size="sm"
            layout="outline"
            color="grid"
            label="업로드(Excel)"
            onClick={uploadClick}
            isLoading={isLoading}
          />
          <Buttons
            permission="export"
            type="button"
            size="sm"
            layout="outline"
            color="grid"
            label="샘플 다운로드(Excel)"
            onClick={sampleClick}
            isLoading={isLoading}
          />
        </>
      }
      totalCount={totalCount}
      gridRef={gridMain}
      storageKey={storageKey}
    >
      <Grid
        refs={gridMain}
        dataSource={mainGrid}
        onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
      >
        <HeaderFilter visible={true} />
        {/* <ColumnChooser enabled={true} /> */}
        <StateStoring
          enabled={true}
          type="custom"
          customLoad={useCallback(
            () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
            []
          )}
        />
        <Scrolling mode="virtual" />
        {AccountExcelFileOptions.map((opt, index) => (
          <Column key={index} dataField={opt.column} caption={t(opt.title)} />
        ))}
      </Grid>
    </GridContent>
  );
};

export default AccountExcelUpperGrid;
