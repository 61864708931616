import React, { useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import { InputText } from "@/components/atoms/Inputs";
import Grid from "@/components/atoms/Grid";
import { GridContent } from "@/components/organisms/Contents/GridContent";

interface ComCodeGridProps {
  searchForm: UseFormReturn<any, any, undefined>;
  gridMain: React.MutableRefObject<any>;
  mainData: any[] | undefined;
  toggleOpen: () => void;
  onSearch: (data: any) => void;
  onFocusedRowChanged: (e: any) => void;
}

const ComCodeGrid = ({
  searchForm,
  gridMain,
  mainData,
  onSearch,
  onFocusedRowChanged,
}: ComCodeGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "CodeManage";
  const [mainTotalCount, setMainTotalCount] = useState<number>(0);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = searchForm;

  return (
    <GridContent
      searchSection={
        <>
          <InputText
            label="그룹코드"
            name="groupCode"
            width={80}
            control={control}
            errors={errors}
          />
          <InputText
            label="그룹코드명"
            width={160}
            name="groupName"
            control={control}
            errors={errors}
          />
        </>
      }
      searchSubmit={handleSubmit(onSearch)}
      gridRef={gridMain}
      storageKey={storageKey}
      totalCount={mainTotalCount}
    >
      <>
        {/* <Button
            variety="new"
            shape="solid"
            size="sm"
            type="button"
            onClick={toggleOpen}
          >
            입력
          </Button> */}

        <Grid
          id={storageKey}
          keyExpr="groupCode"
          refs={gridMain}
          dataSource={mainData}
          focusedRowEnabled={true}
          onFocusedRowChanged={onFocusedRowChanged}
          onContentReady={(e: any) =>
            setMainTotalCount(e.component.totalCount())
          }
        >
          <HeaderFilter visible={true} />
          <ColumnChooser enabled={true} />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(
              () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
              []
            )}
          />
          <Scrolling mode="infinite" />

          <Column
            dataField="groupCode"
            caption={t("그룹코드")}
            width={120}
            alignment="center"
          />
          <Column dataField="groupName" caption={t("그룹코드명")} />
        </Grid>
      </>
    </GridContent>
  );
};

export default ComCodeGrid;
