import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InputText } from "@/components/atoms/Inputs";
import { Buttons } from "@/components/atoms/Buttons";
import { FormContent } from "@/components/organisms/Contents/FormContent";
import { ReactComponent as IconCreate } from "@/styles/assets/svg/icon_button_create.svg";
import { ReactComponent as IconSave } from "@/styles/assets/svg/icon_button_save.svg";
import { ReactComponent as IconDelete } from "@/styles/assets/svg/icon_button_delete.svg";

interface ForwarderInputProps {
  selectedRowKey: string | null;
  inputForm: UseFormReturn<any>;
  toggleOpen: () => void;
  onClickNewData: () => void;
  onClickSaveData: () => Promise<void>;
  onClickDeleteData: () => Promise<void>;
}

const ForwarderInput = ({
  selectedRowKey,
  inputForm,
  toggleOpen,
  onClickNewData,
  onClickSaveData,
  onClickDeleteData,
}: ForwarderInputProps) => {
  const { t } = useTranslation("main");
  const {
    control,
    formState: { errors },
  } = inputForm;

  return (
    <FormContent
      title={t("선사 관리")}
      titleButtons={
        <Buttons
          type="button"
          size="sm"
          layout="text"
          color="primary100"
          icon={<IconCreate />}
          label="신규입력"
          onClick={onClickNewData}
        />
      }
      inputForms={
        <>
          <InputText
            label="코드"
            name="code"
            direction="column"
            control={control}
            errors={errors}
            required={true}
            disabled={selectedRowKey}
          />
          <InputText
            label="선사명(영어)"
            name="forwarderNameEn"
            direction="column"
            control={control}
            errors={errors}
            required={true}
          />
          <InputText
            label="선사명(한국어)"
            name="forwarderNameKo"
            direction="column"
            control={control}
            errors={errors}
          />
          <InputText
            label="비고"
            name="remark"
            direction="column"
            control={control}
            errors={errors}
          />
        </>
      }
      formButtons={
        <>
          <Buttons
            permission="save"
            type="button"
            size="sm"
            layout="solid"
            color="save"
            icon={<IconSave />}
            label="저장"
            onClick={onClickSaveData}
          />
          <Buttons
            permission="delete"
            type="button"
            size="sm"
            layout="solid"
            color="destructive"
            icon={<IconDelete />}
            label="삭제"
            onClick={onClickDeleteData}
          />
        </>
      }
    >
      {/* <Button
          className="input-back"
          variety="tran"
          size="xxsm"
          shape="solid"
          type="button"
          onClick={toggleOpen}
        >
          <MovePageIcon width={26} height={26} viewBox="0 0 24 24" />
          <span>{t("뒤로")}</span>
        </Button> */}
    </FormContent>
  );
};

export default ForwarderInput;
