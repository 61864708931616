import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  Format,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import Grid from "@/components/atoms/Grid";
import { GridContent } from "@/components/organisms/Contents/GridContent";
import { InputMonth } from "@/components/atoms/Inputs";
import { InputMultiple } from "@/components/atoms/InputMultiple";
import { Buttons } from "@/components/atoms/Buttons";

interface NonPaymentPageGridProps {
  bottomHeight: number;
  gridMain: React.MutableRefObject<any>;
  mainData: any[] | undefined;

  onFocusedRowChanged: (e: any) => void;

  control: any;
  errors: any;
  searchSubmit: any;
}

const NonPaymentPageGrid = ({
  bottomHeight,
  gridMain,
  mainData,
  onFocusedRowChanged,
  control,
  errors,
  searchSubmit,
}: NonPaymentPageGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "NonPaymentPageGrid";
  const [totalCount, setTotalCount] = useState<number>(0);

  return (
    <GridContent
      bottomHeight={bottomHeight}
      searchSection={
        <InputMultiple label="월선택" direction="row">
          <>
            <InputMonth
              name="firstMonth"
              width={100}
              control={control}
              errors={errors}
            />
            ~
            <InputMonth
              name="lastMonth"
              width={100}
              control={control}
              errors={errors}
            />
          </>
        </InputMultiple>
      }
      searchSubmit={searchSubmit}
      gridSectionButtons={
        <Buttons
          permission="export"
          type="button"
          size="sm"
          layout="outline"
          color="grid"
          label="다운로드(Excel)"
        />
      }
      totalCount={totalCount}
      gridRef={gridMain}
      storageKey={storageKey}
    >
      <Grid
        refs={gridMain}
        dataSource={mainData}
        onFocusedRowChanged={onFocusedRowChanged}
        focusedRowEnabled={true}
        onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
        keyExpr="oid"
      >
        <HeaderFilter visible={true} />
        {/* <ColumnChooser enabled={true} /> */}
        <StateStoring
          enabled={true}
          type="custom"
          customLoad={useCallback(
            () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
            []
          )}
        />
        <Scrolling mode="virtual" />

        <Column dataField="baseMonth" caption={t("작성일자")} />
        <Column dataField="receiveCo" caption={t("접수법인")} />
        <Column dataField="division" caption={t("구분")} />
        <Column dataField="company" caption={t("청구처/용차사/차량번호")} />
        <Column dataField="cycle" caption={t("결제일자")} />
        <Column dataField="total" caption={t("금액")} dataType="number">
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="taxTotal" caption={t("세액")} dataType="number">
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="priceTotal" caption={t("합계")} dataType="number">
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="totalBank" caption={t("통장합계")} dataType="number">
          <Format type="fixedPoint" />
        </Column>
        <Column dataField="nonPayment" caption={t("미수금")} dataType="number">
          <Format type="fixedPoint" />
        </Column>
      </Grid>
    </GridContent>
  );
};

export default NonPaymentPageGrid;
