import { HtmlEditor } from "devextreme-react";
import {
  Item,
  TableContextMenu,
  TableResizing,
  Toolbar,
} from "devextreme-react/html-editor";
import { useRef } from "react";
import { Modal } from "..";
import {
  ModalMailingAttach,
  ModalMailingWrapper,
} from "../MailingModal/mailingModal.style";
import { ModalForm } from "../modal.styles";

interface EmailPopupProps {
  onClose: () => void;
  selectedData: any;
}

const EmailPopup = ({ onClose, selectedData }: EmailPopupProps) => {
  const { subject, mailTo, contents, fileName } = selectedData;
  const editorRef = useRef<any>();

  return (
    <Modal width="75%" close={onClose} title="거래명세서 메일">
      <ModalForm>
        <ModalMailingWrapper>
          <ModalMailingAttach>
            <label>제목</label>
            <div>{subject}</div>
          </ModalMailingAttach>
          <ModalMailingAttach>
            <label>받는 사람</label>
            <div>{mailTo}</div>
          </ModalMailingAttach>
          <ModalMailingAttach>
            <label>첨부파일</label>
            <div>{fileName}</div>
          </ModalMailingAttach>

          <label>내용</label>

          <HtmlEditor
            ref={editorRef}
            height={"675px"}
            defaultValue={contents}
            valueType="html"
            disabled={true}
          >
            <TableContextMenu enabled={true} />
            <TableResizing enabled={true} />
            <Toolbar multiline={true}>
              <Item name="undo" />
              <Item name="redo" />
              <Item name="separator" />
              <Item
                name="size"
                acceptedValues={[
                  "8pt",
                  "10pt",
                  "12pt",
                  "14pt",
                  "18pt",
                  "24pt",
                  "36pt",
                ]}
              />
              <Item
                name="font"
                acceptedValues={[
                  "Arial",
                  "Courier New",
                  "Georgia",
                  "Impact",
                  "Lucida Console",
                  "Tahoma",
                  "Times New Roman",
                  "Verdana",
                ]}
              />
              <Item name="separator" />
              <Item name="bold" />
              <Item name="italic" />
              <Item name="strike" />
              <Item name="underline" />
              <Item name="separator" />
              <Item name="alignLeft" />
              <Item name="alignCenter" />
              <Item name="alignRight" />
              <Item name="alignJustify" />
              <Item name="separator" />
              <Item name="orderedList" />
              <Item name="bulletList" />
              <Item name="separator" />
              <Item name="header" acceptedValues={[false, 1, 2, 3, 4, 5]} />
              <Item name="separator" />
              <Item name="color" />
              <Item name="background" />
              <Item name="separator" />
              <Item name="clear" />
              <Item name="codeBlock" />
              <Item name="blockquote" />
              <Item name="separator" />
              <Item name="insertTable" />
              <Item name="insertRowAbove" />
              <Item name="insertRowBelow" />
              <Item name="insertColumnLeft" />
              <Item name="insertColumnRight" />
              <Item name="deleteTable" />
              <Item name="deleteRow" />
              <Item name="deleteColumn" />
            </Toolbar>
          </HtmlEditor>
        </ModalMailingWrapper>
      </ModalForm>
    </Modal>
  );
};

export default EmailPopup;
