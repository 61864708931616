import styled from "styled-components";

interface ModalStyleProps {
  width?: string;
}

export const ModalBackground = styled.div`
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: ${(props) => props.theme.colors.black}b2;
  align-items: center;
  justify-content: center;
  z-index: 11;

  // 모바일환경(600px 이하) 설정
  @media (max-width: 600px) {
    padding: 20px;
  }
`;

export const ModalContainer = styled.div<ModalStyleProps>`
  display: flex;
  width: ${(props) => props.width};
  /* max-height: calc(100vh - 60px); */
  padding: 30px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.15);
  flex-direction: column;
  gap: 20px;
  z-index: 12;

  // 모바일환경(600px 이하) 설정
  @media (max-width: 600px) {
    padding: 20px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .modal-header {
    display: flex;
    font-size: 1.8rem;
    font-weight: 500;
    flex-direction: row;
    gap: 14px;

    .modal-title {
      font-size: 1.8rem;
      font-weight: 500;
    }

    .h-array {
      .dx-selectbox {
        width: 120px;
      }
    }
  }
`;

export const ModalCloseButton = styled.button`
  display: flex;
  padding: 5px;
  border: none;
  background: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background 0.1s ease;

  &:hover {
    border-radius: 4px;
    background: rgba(0, 0, 0, 0.1);
    transition: background 0.1s ease;
  }
`;

export const ModalFormBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

export const ModalFormContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;

  .dx-datagrid-rowsview {
    border-left: 1px solid ${(props) => props.theme.colors.black12} !important;
    border-right: 1px solid ${(props) => props.theme.colors.black12} !important;
    border-bottom: 1px solid ${(props) => props.theme.colors.black12} !important;
    border-radius: 0 0 4px 4px;
  }
`;

export const ModalForm = styled.form<any>`
  overflow-y: auto;
  display: flex;
  padding: 10px;
  flex-direction: column;
  gap: 30px;

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: ${(props) => props.theme.colors.scrollBar};
    border: 2px solid transparent;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  .h-array {
    display: flex;
    flex-direction: row;
    gap: 10px;

    &.flex-end {
      align-items: flex-end;
    }

    & > div {
      width: 100%;
    }
  }

  .v-array {
    display: flex;
    gap: 10px;
    flex-direction: column;

    & + .modal-section-header {
      margin-top: 30px;
    }
  }

  &.grid-2 {
    display: grid;
    grid-template-columns: ${(props) =>
      props.gridColumn + " calc(100% - " + props.gridColumn + " - 10px)"};
    grid-column-gap: 10px;
    grid-row-gap: 30px;
  }

  &.grid-3 {
    display: grid;
    grid-template-areas:
      "leftGrid rightGrid"
      "centerGird centerGrid";
    grid-template-columns: ${(props) =>
      props.gridColumn + " calc(100% - " + props.gridColumn + " - 10px)"};
    grid-template-rows: 1fr 2fr;
    gap: 10px;
    overflow-x: auto;
  }

  .grid-4 {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px 20px;

    .grid-span {
      grid-column: 1 / span 4;
    }

    .cell-w-btn {
      display: grid;
      grid-template-columns: 1fr 26px;
      grid-column-gap: 5px;
      align-items: flex-end;

      .dx-button {
        height: 26px;
      }
    }

    & + .grid-4,
    + .grid-full {
      margin-top: 10px;
    }
  }

  .grid-5 {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 5px 10px;

    .grid-span {
      grid-column: 1 / span 5;
    }
  }

  .grid-7 {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;

    .grid-span {
      grid-column: 1 / span 7;
    }

    .remark {
      grid-column: 1 / span 7;
    }
  }

  .modal-input-tab {
    position: relative;
    margin-top: 20px;

    ul {
      position: relative;
      display: flex;
      bottom: -1px;
      align-items: flex-end;
      gap: 2px;

      li {
        padding: 5px 30px;
        font-size: 1.4rem;
        color: ${(props) => props.theme.colors.tabTxt};
        background: ${(props) => props.theme.colors.tab};
        border-radius: 4px 4px 0 0;
        cursor: pointer;

        &.active {
          padding: 8px 30px;
          color: ${(props) => props.theme.colors.primary100};
          font-weight: 500;
          background: ${(props) => props.theme.colors.white};
          border-top: 1px solid #ebebeb;
          border-left: 1px solid #ebebeb;
          border-right: 1px solid #ebebeb;
        }

        &:hover {
          color: ${(props) => props.theme.colors.tabTxtHover};
          background: ${(props) => props.theme.colors.tabTxt};
        }
      }
    }

    .tab-content {
      padding: 20px;
      border: 1px solid #ebebeb;
    }
  }

  // 모바일환경(600px 이하) 설정
  @media (max-width: 600px) {
    .grid-4,
    .grid-7 {
      grid-template-columns: repeat(2, 1fr) !important;

      .grid-span {
        grid-column: 1 / span 2;
      }

      .remark {
        grid-column: 1 / span 2;
      }
    }
  }
`;

export const ModalFormLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  &.left-grid {
    display: flex;
    flex-direction: column;
    grid-area: leftGrid;
  }
`;

export const ModalFormCenter = styled.div`
  display: flex;
  flex-direction: column;
  grid-area: centerGrid;
  gap: 30px;
  grid-column: auto / span 2;
`;

export const ModalFormRight = styled.div`
  overflow: auto;
  max-height: 820px;
  padding: 10px;
  background: ${(props) => props.theme.colors.searchBg};
  border: 1px solid ${(props) => props.theme.colors.innerSectionBorder};

  .dx-datagrid {
    overflow: auto;
    background: none;

    .dx-toolbar {
      background: none;
    }

    .dx-header-row {
      background: ${(props) => props.theme.colors.gridHeader};
      color: ${(props) => props.theme.colors.white};
    }

    tbody {
      tr {
        background: ${(props) => props.theme.colors.white};
      }
    }
  }

  &.right-grid {
    display: flex;
    flex-direction: column;
    grid-area: rightGrid;
  }
`;

export const ModalButtonsBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 6px;
`;

export const ModalFile = styled.div`
  display: grid;
  margin: 10px 0;
  grid-template-rows: repeat(1, 1fr);
  gap: 10px;

  .file {
    display: grid;
    width: 100%;
    grid-template-columns: auto 1fr;
    gap: 10px;

    .input-label {
      min-width: 80px;
      color: ${(props) => props.theme.colors.inputLabel};
      font-size: 1.2rem;
      line-height: 26px;
      white-space: nowrap;
    }

    label {
      display: inline-block;
      min-width: 60px;
      height: 26px;
      padding: 0 10px;
      color: ${(props) => props.theme.colors.white};
      font-size: 1.3rem;
      line-height: 24px;
      background: ${(props) => props.theme.colors.modify};
      border-radius: 2px;

      &:hover {
        background: ${(props) => props.theme.colors.modifyHover};
        cursor: pointer;
      }
    }

    .input-txt {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 30px;
      gap: 5px;

      &.btn-3 {
        grid-template-columns: 1fr 88px !important;
      }

      input[type="text"] {
        width: 100%;
        height: 26px;
        padding: 4px 7px;
      }
    }

    input[type="file"] {
      display: none;
    }

    .modal-file-info {
      padding: 4px 10px;
      margin-right: 5px;
      font-size: 1.2rem;
      border: 1px solid ${(props) => props.theme.colors.inputBorder};
      border-radius: 2px;
      background-color: ${(props) => props.theme.colors.sideBG};
      flex-grow: 1;

      &.warning {
        border: 1px solid ${(props) => props.theme.colors.warning};
        background: ${(props) => props.theme.colors.white};
      }

      .fv-plugins-message-container {
        color: ${(props) => props.theme.colors.warning};
      }
    }
  }
`;

export const ModalImageEx = styled.div`
  display: grid;
  margin-top: 20px;
  grid-template-columns: 2.3fr 1fr;
  align-items: start;
  gap: 20px;

  // 모바일환경(600px 이하) 설정
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const ModalImageEx2 = styled.div`
  display: grid;
  margin-top: 20px;
  grid-template-columns: 2fr 1fr 1fr;
  align-items: start;
  gap: 20px;

  // 모바일환경(600px 이하) 설정
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const ModalSection = styled.div`
  .modal-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .modal-section-title {
    font-size: 1.4rem;
    font-weight: 500;
  }

  .input-list {
    overflow-y: auto;
    max-height: 250px;
    min-height: 60px;
    padding: 10px;
    border: 1px solid ${(props) => props.theme.colors.inputBorder};
    border-radius: 2px;
    background-color: ${(props) => props.theme.colors.sideBG};

    .list-null {
      width: 100%;
      font-size: 1.4rem;
      text-align: center;
      line-height: 40px;
    }

    .list-file {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 26px;
      gap: 5px;

      &.btn-3 {
        grid-template-columns: 1fr 88px !important;
      }

      & + .list-file {
        margin-top: 10px;
        padding-top: 10px;
        border-top: 1px solid #ececec;
      }

      input[type="file"] {
        display: none;
        width: 100%;
      }

      input[type="text"] {
        height: 26px;
        padding: 4px 7px;
      }

      .modal-file-info {
        padding: 4px 10px;
        margin-right: 5px;
        font-size: 1.2rem;
        background-color: ${(props) => props.theme.colors.sideBG};
        flex-grow: 1;
      }
    }
  }

  .input-set {
    display: grid;
    grid-template-columns: auto 30px;
    align-items: end;
    gap: 5px;

    label {
      width: 120px;
    }
  }

  .pdf-control {
    display: flex;
    width: 100%;
    align-items: flex-end;
    gap: 5px;

    div:nth-child(1) {
      flex-grow: 1;
    }
  }

  .pdf-viewer {
    position: relative;
    margin-top: 10px;

    .pdf-file {
      width: 100%;
      height: 620px;
    }
  }

  .pdf-viewer-page {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    gap: 10px;

    input {
      width: 50px;
      height: 26px;
      padding: 4px;
      text-align: center;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  }

  .modal-input-grid {
    display: grid;
    margin-top: 30px;
    gap: 20px;

    &.span-2 {
      grid-template-columns: 1fr 1fr;
    }
  }

  // 모바일환경(600px 이하) 설정
  @media (max-width: 600px) {
    .pdf-viewer {
      .pdf-file {
        width: 100%;
        height: 450px;
      }
    }

    .modal-input-grid {
      &.span-2 {
        grid-template-columns: 1fr;
      }
    }

    .input-set {
      label {
        width: 160px;
      }
    }
  }
`;

export const FileLoading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 620px;
  background: ${(props) => props.theme.colors.black}cc;
`;

export const ModalPaymentTable = styled.table`
  border-top: 2px solid ${(props) => props.theme.colors.gridSubHeaderBorder};
  border-bottom: 2px solid ${(props) => props.theme.colors.gridSubHeaderBorder};
  border-collapse: collapse;

  thead + tbody {
    border-top: 1px solid ${(props) => props.theme.colors.gridSubHeaderBorder};
  }
`;

export const ModalPaymentTr = styled.tr`
  & + tr {
    border-top: 1px solid ${(props) => props.theme.colors.inputBorder};
  }

  &.bottom {
    th,
    td {
      border-bottom: 1px solid
        ${(props) => props.theme.colors.gridSubHeaderBorder};
    }
  }
`;

export const ModalPaymentTh = styled.th`
  width: 80px;
  text-align: center;
  color: ${(props) => props.theme.colors.LayoutRightTit};
  font-size: 1.3rem;
  font-weight: 500;
  background: ${(props) => props.theme.colors.LayoutRightTopBg};

  &.header {
    color: ${(props) => props.theme.colors.white};
    background: ${(props) => props.theme.colors.secondary};
  }

  & + td {
    border-left: 1px solid ${(props) => props.theme.colors.inputBorder};
  }
`;

export const ModalPaymentTd = styled.td`
  text-align: center;
  padding: 1px 4px 3px;
  font-size: 1.3rem;
`;

export const ModalCostTable = styled.table`
  border-top: 2px solid ${(props) => props.theme.colors.gridSubHeaderBorder};
  border-bottom: 2px solid ${(props) => props.theme.colors.gridSubHeaderBorder};
  border-collapse: collapse;
`;

export const ModalCostTr = styled.tr``;

export const ModalCostTh = styled.th`
  height: 32px;
  text-align: center;
  color: ${(props) => props.theme.colors.white};
  font-size: 1.3rem;
  background: ${(props) => props.theme.colors.secondary};

  & + th {
    border-left: 1px solid ${(props) => props.theme.colors.inputBorder};
  }
`;

export const ModalCostTd = styled.td`
  font-size: 1.3rem;
  padding: 1px 4px 3px;

  & + td {
    border-left: 1px solid ${(props) => props.theme.colors.inputBorder};
  }
`;

export const ModalTaxIssueFooter = styled.div<any>`
  display: grid;
  width: 100%;
  padding: ${(props) => (props.tradeset ? "10px" : "5px 10px")};
  color: ${(props) => props.theme.colors.tableHeaderTxt};
  background: ${(props) => props.theme.colors.white};
  grid-template-columns: ${(props) => (props.tradeset ? "7fr 1fr" : "100%")};
  grid-template-rows: 1fr;
`;

export const ModalTaxIssueSubtit = styled.span`
  display: flex;
`;
