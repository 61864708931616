import { createGlobalStyle } from "styled-components";

export const GlobalFonts = createGlobalStyle`
// Noto Sans KR
@font-face {
	font-family: 'Noto Sans KR';
	font-weight: 900;
	font-display: swap;
	src: local('NotoSansKR Black'), url(./ttf/NotoSansKR-Black.ttf) format('ttf');
}

@font-face {
	font-family: 'Noto Sans KR';
	font-weight: 800;
	font-display: swap;
	src: local('NotoSansKR ExtraBold'), url(./ttf/NotoSansKR-ExtraBold.ttf) format('ttf');
}

@font-face {
	font-family: 'Noto Sans KR';
	font-weight: 700;
	font-display: swap;
	src: local('NotoSansKR Bold'), url(./ttf/NotoSansKR-Bold.ttf) format('ttf');
}

@font-face {
	font-family: 'Noto Sans KR';
	font-weight: 600;
	font-display: swap;
	src: local('NotoSansKR SemiBold'), url(./ttf/NotoSansKR-SemiBold.ttf) format('ttf');
}

@font-face {
	font-family: 'Noto Sans KR';
	font-weight: 500;
	font-display: swap;
	src: local('NotoSansKR Medium'), url(./ttf/NotoSansKR-Medium.ttf) format('ttf');
}

@font-face {
	font-family: 'Noto Sans KR';
	font-weight: 400;
	font-display: swap;
	src: local('NotoSansKR Regular'), url(./ttf/NotoSansKR-Regular.ttf) format('ttf');
}

@font-face {
	font-family: 'Noto Sans KR';
	font-weight: 300;
	font-display: swap;
	src: local('NotoSansKR Light'), url(./ttf/NotoSansKR-Light.ttf) format('ttf');
}

@font-face {
	font-family: 'Noto Sans KR';
	font-weight: 200;
	font-display: swap;
	src: local('NotoSansKR ExtraLight'), url(./ttf/NotoSansKR-ExtraLight.ttf) format('ttf');
}

@font-face {
	font-family: 'Noto Sans KR';
	font-weight: 100;
	font-display: swap;
	src: local('NotoSansKR Thin'), url(./ttf/NotoSansKR-Thin.ttf) format('ttf');
}

// Roboto
@font-face {
	font-family: 'Roboto';
	font-weight: 900;
	font-display: swap;
	src: local('Roboto Black'), url(./ttf/NotoSansKR-Black.ttf) format('ttf');
}


@font-face {
	font-family: 'Roboto';
	font-weight: 700;
	font-display: swap;
	src: local('Roboto Bold'), url(./ttf/Roboto-Bold.ttf) format('ttf');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 500;
	font-display: swap;
	src: local('Roboto Medium'), url(./ttf/Roboto-Medium.ttf) format('ttf');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 400;
	font-display: swap;
	src: local('Roboto Regular'), url(./ttf/Roboto-Regular.ttf) format('ttf');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 300;
	font-display: swap;
	src: local('Roboto Light'), url(./ttf/Roboto-Light.ttf) format('ttf');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 100;
	font-display: swap;
	src: local('Roboto Thin'), url(./ttf/Roboto-Thin.ttf) format('ttf');
}
`;
