import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Column, Format } from "devextreme-react/data-grid";
import {
  ClosingSearchType,
  InvoiceeData,
  InvoicerData,
  PriceDataType,
  TemplateType,
} from "@/types/closing.type";
import Grid from "@/components/atoms/Grid";
import { InputText } from "../Inputs";
import * as S from "./tradeSheetTable.style";

interface TradeSheetTableProps {
  printRef: React.MutableRefObject<any>;
  TradeSheetInput: UseFormReturn<any, any, undefined>;
  gridColumn: any[];
  dataSource: any[];
  search: ClosingSearchType;
  invoicerData: InvoicerData | undefined;
  invoiceeData: InvoiceeData | undefined;
  priceData: PriceDataType;
  templateInfo: TemplateType | undefined;
}

const TradeSheetTable = ({
  printRef,
  TradeSheetInput,
  gridColumn,
  dataSource,
  search,
  invoicerData,
  invoiceeData,
  priceData,
  templateInfo,
}: TradeSheetTableProps) => {
  const { t } = useTranslation("main");
  const {
    control,
    formState: { errors },
  } = TradeSheetInput;
  return (
    <S.ModalTaxIssueWrapper ref={printRef} id="pdf-form">
      <S.ModalTaxIssueHeader modalHeader>
        {t("거래명세서")}
      </S.ModalTaxIssueHeader>
      <S.ModalTaxIssueHeader tradeset className="taxissue-header-print">
        <S.ModalTaxIssueTitle tradeset>
          {t("거래기간")}:{" "}
          {dayjs(search.firstDay).format(t("YYYY년 MM월 DD일"))} ~{" "}
          {dayjs(search.lastDay).format(t("YYYY년 MM월 DD일"))}
        </S.ModalTaxIssueTitle>
        <S.ModalTaxIssueSubtit>
          {t("발행일자")}: {dayjs().format("YYYY-MM-DD")}
        </S.ModalTaxIssueSubtit>
      </S.ModalTaxIssueHeader>
      <S.ModalTaxIssueBody>
        <S.TradesetWrapper>
          <S.Tradeset>
            <S.TradesetBody>
              <S.TradesetRows>
                <S.TradesetItems
                  width="30px"
                  rowSpan={5}
                  className="tradeset-tit"
                >
                  공<br />급<br />자
                </S.TradesetItems>
                <S.TradesetItems className="item-tit">
                  {t("등록번호")}
                </S.TradesetItems>
                <S.TradesetItems colSpan={3}>
                  <div className="item-input">{invoicerData?.Biz_No}</div>
                </S.TradesetItems>
              </S.TradesetRows>

              <S.TradesetRows>
                <S.TradesetItems className="item-tit">
                  {t("상호")}
                </S.TradesetItems>
                <S.TradesetItems>
                  <div className="item-input">
                    {invoicerData?.Invoicer_CorpName}
                  </div>
                </S.TradesetItems>
                <S.TradesetItems className="item-tit">
                  {t("성명")}
                </S.TradesetItems>
                <S.TradesetItems>
                  <div className="item-input">
                    {invoicerData?.Invoicer_CEOName}
                  </div>
                </S.TradesetItems>
              </S.TradesetRows>

              <S.TradesetRows>
                <S.TradesetItems className="item-tit">
                  {t("주소")}
                </S.TradesetItems>
                <S.TradesetItems colSpan={3}>
                  <div className="item-input">
                    {invoicerData?.Invoicer_Addr}
                  </div>
                </S.TradesetItems>
              </S.TradesetRows>

              <S.TradesetRows>
                <S.TradesetItems className="item-tit">
                  {t("업태")}
                </S.TradesetItems>
                <S.TradesetItems>
                  <div className="item-input">
                    {invoicerData?.Invoicer_BizClass}
                  </div>
                </S.TradesetItems>
                <S.TradesetItems className="item-tit">
                  {t("종목")}
                </S.TradesetItems>
                <S.TradesetItems>
                  <div className="item-input">
                    {invoicerData?.Invoicer_BizType}
                  </div>
                </S.TradesetItems>
              </S.TradesetRows>

              <S.TradesetRows>
                <S.TradesetItems className="item-tit">
                  {t("전화번호")}
                </S.TradesetItems>
                <S.TradesetItems>
                  <div className="item-input">{invoicerData?.Invoicer_Tel}</div>
                </S.TradesetItems>
                <S.TradesetItems className="item-tit">FAX</S.TradesetItems>
                <S.TradesetItems>
                  <InputText
                    type="v-array"
                    control={control}
                    errors={errors}
                    name="Invoicer_Fax"
                  />
                </S.TradesetItems>
              </S.TradesetRows>
            </S.TradesetBody>
          </S.Tradeset>

          <S.Tradeset>
            <S.TradesetBody>
              <S.TradesetRows>
                <S.TradesetItems
                  width="30px"
                  rowSpan={5}
                  className="tradeset-tit"
                >
                  공<br />급<br />받<br />는<br />자
                </S.TradesetItems>
                <S.TradesetItems className="item-tit">
                  {t("등록번호")}
                </S.TradesetItems>
                <S.TradesetItems colSpan={3}>
                  <div className="item-input">
                    {invoiceeData?.Biz_No_Return}
                  </div>
                </S.TradesetItems>
              </S.TradesetRows>

              <S.TradesetRows>
                <S.TradesetItems className="item-tit">
                  {t("상호")}
                </S.TradesetItems>
                <S.TradesetItems>
                  <div className="item-input">
                    {invoiceeData?.Invoicee_CorpName}
                  </div>
                </S.TradesetItems>
                <S.TradesetItems className="item-tit">
                  {t("성명")}
                </S.TradesetItems>
                <S.TradesetItems>
                  <div className="item-input">
                    {invoiceeData?.Invoicee_CEOName}
                  </div>
                </S.TradesetItems>
              </S.TradesetRows>

              <S.TradesetRows>
                <S.TradesetItems className="item-tit">
                  {t("주소")}
                </S.TradesetItems>
                <S.TradesetItems colSpan={3}>
                  <div className="item-input">
                    {invoiceeData?.Invoicee_Addr}
                  </div>
                </S.TradesetItems>
              </S.TradesetRows>

              <S.TradesetRows>
                <S.TradesetItems className="item-tit">
                  {t("업태")}
                </S.TradesetItems>
                <S.TradesetItems>
                  <div className="item-input">
                    {invoiceeData?.Invoicee_BizClass}
                  </div>
                </S.TradesetItems>
                <S.TradesetItems className="item-tit">
                  {t("종목")}
                </S.TradesetItems>
                <S.TradesetItems>
                  <div className="item-input">
                    {invoiceeData?.Invoicee_BizType}
                  </div>
                </S.TradesetItems>
              </S.TradesetRows>

              <S.TradesetRows>
                <S.TradesetItems className="item-tit">
                  {t("전화번호")}
                </S.TradesetItems>
                <S.TradesetItems>
                  <div className="item-input">{invoiceeData?.Invoicee_Tel}</div>
                </S.TradesetItems>
                <S.TradesetItems className="item-tit">FAX</S.TradesetItems>
                <S.TradesetItems>
                  <InputText
                    type="v-array"
                    control={control}
                    errors={errors}
                    name="Fax_No"
                  />
                </S.TradesetItems>
              </S.TradesetRows>
            </S.TradesetBody>
          </S.Tradeset>
        </S.TradesetWrapper>

        <S.AdditionalWrapper tradeset>
          <Grid subGrid={true} dataSource={dataSource} keyExpr="id">
            {gridColumn.map((column) => {
              let sort = "left";
              if (column.sortText === "가운데") {
                sort = "center";
              } else if (column.sortText === "우측") {
                sort = "right";
              }

              if (
                column.printName === "공급가액" ||
                column.printName === "금액" ||
                column.printName === "부가세" ||
                column.printName === "합계금액"
              ) {
                return (
                  <Column
                    dataField={column.columnName}
                    caption={t(column.printName)}
                    width={column.width}
                    alignment={sort}
                  >
                    <Format type="fixedPoint" />
                  </Column>
                );
              } else {
                return (
                  <Column
                    dataField={column.columnName}
                    caption={t(column.printName)}
                    width={column.width}
                    alignment={sort}
                  />
                );
              }
            })}
          </Grid>
        </S.AdditionalWrapper>
      </S.ModalTaxIssueBody>

      <S.TotalWrapper>
        <div className="total-tit">{t("합계금액")}</div>
        <div className="total-items">
          <div className="item-tit">{t("합계")}</div>
          <div style={{ textAlign: "right" }}>{priceData.Amount}</div>
        </div>
        <div className="total-items">
          <div className="item-tit">{t("부가가치세")}</div>
          <div style={{ textAlign: "right" }}>{priceData.Tax}</div>
        </div>
        <div className="total-items">
          <div className="item-tit">{t("총계")}</div>
          <div style={{ textAlign: "right" }}>{priceData.TotalAmount}</div>
        </div>
      </S.TotalWrapper>

      <S.ModalTaxIssueFooter tradeset className="taxissue-header-print">
        <S.ModalTaxIssueTitle tradeset>
          {t("은행명")}: {templateInfo?.bank}
        </S.ModalTaxIssueTitle>
        <S.ModalTaxIssueSubtit>
          {t("담당자")} : {templateInfo?.manager}
        </S.ModalTaxIssueSubtit>
      </S.ModalTaxIssueFooter>
      <S.ModalTaxIssueFooter tradeset className="taxissue-header-print">
        <S.ModalTaxIssueTitle tradeset>
          {t("계좌번호")}: {templateInfo?.accountNo}
        </S.ModalTaxIssueTitle>
        <S.ModalTaxIssueSubtit>Tel: {templateInfo?.tel}</S.ModalTaxIssueSubtit>
      </S.ModalTaxIssueFooter>
      <S.ModalTaxIssueFooter tradeset className="taxissue-header-print">
        <S.ModalTaxIssueTitle tradeset>
          {t("예금주")}:{templateInfo?.owner}
        </S.ModalTaxIssueTitle>
        <S.ModalTaxIssueSubtit>Fax: {templateInfo?.fax}</S.ModalTaxIssueSubtit>
      </S.ModalTaxIssueFooter>
    </S.ModalTaxIssueWrapper>
  );
};

export default TradeSheetTable;
