import { useRecoilValue } from "recoil";
import { openTabsState } from "@/state/menu";
import { Tab } from "@/types/menu.type";
import * as S from "./tabList.style";
import { TabItems } from "@/components/atoms/TabItems";

export default function TabList() {
  const openTabs = useRecoilValue(openTabsState);

  return (
    <S.TabListBox>
      <S.TabList>
        {openTabs.map((tab: Tab, idx: number) => (
          <TabItems item={tab} key={idx} />
        ))}
      </S.TabList>
    </S.TabListBox>
  );
}
