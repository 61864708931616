import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Column,
  ColumnChooser,
  Format,
  HeaderFilter,
  Scrolling,
  StateStoring,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import Grid from "@/components/atoms/Grid";
import { GridContent } from "@/components/organisms/Contents/GridContent";

interface ClosingLeftGridProps {
  gridMain: any;
  mainData: any[] | undefined;

  onFocusedRowChanged: (e: any) => void;
}

const ClosingLeftGrid = ({
  gridMain,
  mainData,
  onFocusedRowChanged,
}: ClosingLeftGridProps) => {
  const { t } = useTranslation("main");
  const storageKey = "ClosingLeftGrid";
  const [totalCount, setTotalCount] = useState<number>(0);

  return (
    <GridContent
      subGrid={true}
      totalCount={totalCount}
      gridRef={gridMain}
      storageKey={storageKey}
    >
      <Grid
        refs={gridMain}
        dataSource={mainData}
        keyExpr="oid"
        onFocusedRowChanged={onFocusedRowChanged}
        focusedRowEnabled={true}
        onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
      >
        <HeaderFilter visible={true} />
        <ColumnChooser enabled={true} />
        <StateStoring
          enabled={true}
          type="custom"
          customLoad={useCallback(
            () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
            []
          )}
        />
        <Scrolling mode="virtual" />
        <Column
          dataField="division"
          caption={t("구분")}
          alignment="center"
          width={70}
        />
        <Column
          dataField="companyName"
          caption={t("청구처/용차사/차량번호")}
          width={180}
        />
        {/* 접수법인 */}
        <Column
          dataField="total"
          caption={t("총건수")}
          dataType="number"
          width={60}
        />
        <Column
          dataField="isNotFinish"
          caption={t("미마감")}
          dataType="number"
          width={60}
        />
        <Column
          dataField="isFinish"
          caption={t("마감")}
          dataType="number"
          width={60}
        />
        {/* 숫자포맷 */}
        <Column
          dataField="priceTotal"
          caption={t("합계금액")}
          dataType="number"
          width={100}
        >
          <Format type="fixedPoint" />
        </Column>
        <Summary>
          <TotalItem
            column="priceTotal"
            summaryType="sum"
            valueFormat="fixedPoint"
            displayFormat="합계 : {0}"
          />
        </Summary>
      </Grid>
    </GridContent>
  );
};

export default ClosingLeftGrid;
