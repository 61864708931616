import { useRef, useState } from "react";
import { Column } from "devextreme-react/data-grid";
import { Editing } from "devextreme-react/tree-list";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import useButtonLoading from "@/hooks/useButtonLoading";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import useAlertMessage from "@/hooks/utils/useAlertMessage";
import {
  useDeleteMutation,
  usePostMutation,
  usePutMutation,
} from "@/util/common.fn";
import { yupResolver } from "@hookform/resolvers/yup";
import { Buttons } from "@/components/atoms/Buttons";
import { InputText } from "@/components/atoms/Inputs";
import { FormContent } from "@/components/organisms/Contents/FormContent";
import { ContentTemplate } from "@/components/templates/ContentTemplate";
import DeptPermissionGrid from "./DeptPermissionGrid";
import { ReactComponent as IconCreate } from "@/styles/assets/svg/icon_button_create.svg";
import { ReactComponent as IconSave } from "@/styles/assets/svg/icon_button_save.svg";
import { ReactComponent as IconDelete } from "@/styles/assets/svg/icon_button_delete.svg";
import { GridContent } from "@/components/organisms/Contents/GridContent";
import Tree from "@/components/atoms/Tree";

/**
 * 부서 권한 관리 페이지
 *
 */
const DeptPermission = () => {
  const { t } = useTranslation("main");
  /* #region  */

  const initialSearch = {
    authGroupName: "",
  };

  const [search, setSearch] = useState(initialSearch);
  const [subTotalCount, setSubTotalCount] = useState<number>(0);
  const [selectedRowKey, setSelectedRowKey] = useState<string | null>(null);
  const gridMain = useRef<any>();
  const gridSub = useRef<any>();
  const [isOpen, setOpen] = useState<boolean>(false);

  const { alertErrorMessage, alertSuccessMessage, confirmMessage } =
    useAlertMessage();
  const { setLoading, setUnloading } = useButtonLoading();

  const { data: mainData, refetch: mainRefetch } = useCustomQuery<any[]>(
    `authgroup/master`,
    search
  );
  const { data: subData, refetch: subRefetch } = useCustomQuery(
    `authgroup/detail`,
    {
      authGroupId: selectedRowKey,
    },
    !!selectedRowKey
  );
  const { mutateAsync: create } = usePostMutation("authgroup/master");
  const { mutateAsync: update } = usePutMutation("authgroup/master");
  const { mutateAsync: remove } = useDeleteMutation("authgroup/master");

  const schema = yup.object({
    authGroupName: yup.string().nullable().required("그룹명을 입력해주세요"),
    remark: yup.string().nullable(),
  });

  const {
    control,
    reset,
    trigger,
    watch,
    formState: { errors },
  } = useForm<{ authGroupName: string; remark?: string | null }>({
    defaultValues: {
      authGroupName: "",
      remark: "",
    },
    resolver: yupResolver(schema),
  });

  const searchForm = useForm({
    defaultValues: initialSearch,
  });

  /* #endregion */

  /* #region functions ------------------------------------ */

  const initPage = () => {
    const mainGridInstance = gridMain.current.instance;
    const subGridInstance = gridSub.current.instance;
    mainGridInstance.option("focusedRowIndex", -1);
    subGridInstance.option("focusedRowIndex", -1);
    resetGridChanges();
    setSelectedRowKey(null);
    mainRefetch();
    subRefetch();
    reset({
      authGroupName: "",
      remark: "",
    });
  };

  const resetGridChanges = () => {
    const mainGridInstance = gridMain.current.instance;
    const subGridInstance = gridSub.current.instance;
    mainGridInstance.option("editing.changes", []);
    subGridInstance.option("editing.changes", []);
  };

  const toggleOpen = () => setOpen(!isOpen);
  const onClickSave = async () => {
    if (!(await trigger())) return;
    try {
      setLoading();

      const mainGridInstance = gridMain.current.instance;
      const subGridInstance = gridSub.current.instance;

      const changes = subGridInstance.option("editing.changes");

      const originalRowKey = selectedRowKey;

      let res: any;

      // selectedRowKey가 없으면 신규
      if (selectedRowKey) {
        // update
        res = await update({
          authGroupId: selectedRowKey,
          inputData: watch(),
          data: changes,
        });
      } else {
        // create
        res = await create({
          inputData: watch(),
          data: changes,
        });
      }

      if (res.state === 1) {
        await alertSuccessMessage("저장완료");
      }

      initPage();
      mainGridInstance.option("focusedRowKey", originalRowKey);
    } catch (error: any) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onClickDelete = async () => {
    if (!selectedRowKey) {
      alertErrorMessage("데이터를 선택해주세요");
      return;
    }

    try {
      setLoading();
      if (!(await confirmMessage("삭제하시겠습니까?"))) return;

      await remove({ id: selectedRowKey });

      await alertSuccessMessage("삭제완료");

      initPage();
    } catch (error: any) {
      await alertErrorMessage(error);
    } finally {
      setUnloading();
    }
  };

  const onSearch = (data: any) => {
    setSearch(data);

    initPage();
  };

  const onFocusedRowChanged = (e: any) => {
    if (!e.row?.data) return;
    const data = e.row.data;
    resetGridChanges();
    setSelectedRowKey(data.id);
    reset({
      authGroupName: data.authGroupName,
      remark: data.remark,
    });
  };

  const onEditorPreparing = (e: any) => {
    if (e.parentType === "dataRow") {
      const standardHandler = e.editorOptions.onValueChanged;
      e.editorOptions.onValueChanged = function (x: any) {
        standardHandler(x);
        const instance = gridSub.current.instance;
        const node = instance.getNodeByKey(e.row.key);
        const children = node.children;

        instance.expandRow(e.row.key);

        if (Array.isArray(children)) {
          children.forEach((cur) => {
            const index = instance.getRowIndexByKey(cur.key);

            // 문제점, 보이는 row만 수정할 수 있다.
            instance.cellValue(index, e.dataField, x.value);
          });
        }
      };
    }
  };

  /* #endregion */

  return (
    <ContentTemplate sideWidth={440}>
      <>
        {/* 왼쪽 */}
        <DeptPermissionGrid
          searchForm={searchForm}
          gridMain={gridMain}
          mainData={mainData}
          toggleOpen={toggleOpen}
          onSearch={onSearch}
          onFocusedRowChanged={onFocusedRowChanged}
        />

        {/* 오른쪽 */}
        <FormContent
          title={t("권한그룹 등록")}
          titleButtons={
            <Buttons
              type="button"
              size="sm"
              layout="text"
              color="primary100"
              icon={<IconCreate />}
              label="신규입력"
              onClick={() => {
                initPage();
              }}
            />
          }
          inputForms={
            <>
              <InputText
                label="그룹명"
                name="authGroupName"
                direction="column"
                control={control}
                errors={errors}
              />
              <InputText
                label="비고"
                name="remark"
                direction="column"
                control={control}
                errors={errors}
              />
            </>
          }
          inputFormGrid={2}
          formButtons={
            <>
              <Buttons
                permission="save"
                type="button"
                size="sm"
                layout="solid"
                color="save"
                icon={<IconSave />}
                label="저장"
                onClick={onClickSave}
              />
              <Buttons
                permission="delete"
                type="button"
                size="sm"
                layout="solid"
                color="destructive"
                icon={<IconDelete />}
                label="삭제"
                onClick={onClickDelete}
              />
            </>
          }
        >
          {/* <Button
              className="input-back"
              variety="tran"
              size="xxsm"
              shape="solid"
              type="button"
              onClick={toggleOpen}
            >
              <MovePageIcon width={26} height={26} viewBox="0 0 24 24" />
              <span>{t("뒤로")}</span>
            </Button> */}
          <GridContent totalCount={subTotalCount} subGrid={true}>
            <Tree
              title={t("그룹 메뉴권한 설정")}
              subGrid={true}
              id="AuthDetail"
              refs={gridSub}
              keys="id"
              keyExpr="id"
              parentIdExpr="parentId"
              dataSource={subData}
              focusedRowEnabled={false}
              onEditorPreparing={onEditorPreparing}
              onContentReady={(e: any) =>
                setSubTotalCount(e.component.totalCount())
              }
            >
              <Editing mode="batch" allowUpdating={true} />

              <Column dataField="depth" caption="Depth" allowEditing={false} />
              <Column
                dataField="menuName"
                caption={t("메뉴명")}
                allowEditing={false}
              />
              <Column
                dataField="isAuth"
                caption={t("조회")}
                dataType="boolean"
                allowEditing={true}
              />
              <Column
                dataField="isSave"
                caption={t("저장")}
                dataType="boolean"
                allowEditing={true}
              />
              <Column
                dataField="isDelete"
                caption={t("삭제")}
                dataType="boolean"
                allowEditing={true}
              />
              <Column
                dataField="isExport"
                caption={t("출력")}
                dataType="boolean"
                allowEditing={true}
              />
            </Tree>
          </GridContent>
        </FormContent>
      </>
    </ContentTemplate>
  );
};

export default DeptPermission;
