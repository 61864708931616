import { Route, Routes } from "react-router-dom";
import { useRecoilValue } from "recoil";
import AuthInit from "./modules/AuthInit";
import Login from "./pages/auth/login";
import { isAuthorized } from "./state/atoms/isAuthorized";

const RoutesPage = () => {
  // 로그인 여부
  const authorized = useRecoilValue(isAuthorized);

  // 로그인 x
  if (!authorized) {
    return <Login />;
  }

  return (
    <Routes>
      {/* 로그인 o */}
      <Route path="/" element={<AuthInit />} />
    </Routes>
  );
};

export default RoutesPage;
