import * as queryKeys from "@/data/constant/queryKeys";
import { UserListType } from "@/types/userList.type";
import { request } from "@/util/api";
import { useQuery } from "@tanstack/react-query";

const useUserListData = (search?: any, enabled?: boolean) => {
  return useQuery<UserListType[]>({
    queryKey: [queryKeys.USERLIST_DATA, search],
    queryFn: () =>
      request({
        method: "GET",
        url: `/user-list/master`,
        params: search,
      }),
    enabled: enabled,
  });
};

export default useUserListData;
