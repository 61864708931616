import React, { useCallback, useEffect, useRef, useState } from "react";
import { alert } from "devextreme/ui/dialog";
import { request } from "@/util/api";
import { useMutation } from "@tanstack/react-query";
import { Buttons } from "@/components/atoms/Buttons";

const useExtraCharge = (gridSub: any) => {
  const [extraChargeModal, setExtraChargeModal] = useState<boolean>(false);
  const [extraChargeData, setExtraChargeData] = useState<any[]>([]);
  const [etcChargeHabulData, setEtcChargeHabulData] = useState<any[]>([]);
  const [detailRowData, setDetailRowData] = useState<any>();
  const [detailRowKey, setDetailRowKey] = useState<string>();

  // 할증금액, 기타하불 refs
  const extraChargeRef: any = useRef();
  const etcChargeHabulRef: any = useRef();

  const { mutateAsync: getData } = useMutation({
    mutationFn: (id: string) =>
      request<{ extraCharge: any[]; etcChargeHabul: any[] }>({
        method: "GET",
        url: `/extraCharge`,
        params: { orderDetailCode: id || "999999" },
      }),
  });

  useEffect(() => {
    if (extraChargeModal) {
      setTimeout(() => _setExtraCharge(), 200);
    }
    // eslint-disable-next-line
  }, [extraChargeModal]);

  /** ExtraCharge 모달 open시 데이터 binding */
  const _setExtraCharge = () => {
    const detailGridData = gridSub.current.instance.option("editing.changes");

    // instance
    const extraChargeInstance = extraChargeRef.current.instance;
    const etcChargeHabulInstance = etcChargeHabulRef.current.instance;

    for (const obj of detailGridData) {
      if (obj.key !== detailRowKey) continue;

      const extraChargeData = obj.data.extraChargeObj || [];
      const etcChargeHabulData = obj.data.etcChargeHabulObj || [];

      _bindingDatas(extraChargeData, extraChargeInstance);
      _bindingDatas(etcChargeHabulData, etcChargeHabulInstance);
    }
  };

  const _bindingDatas = (itemList: any[], instance: any) => {
    for (const item of itemList) {
      const { type, data, key } = item;
      const rowIndex = instance.getRowIndexByKey(key);

      if (type === "insert") {
        instance.addRow();
        for (const tag in data) {
          instance.cellValue(0, `${tag}`, data[tag]);
        }
      } else if (type === "update") {
        for (const tag in data) {
          instance.cellValue(rowIndex, `${tag}`, data[tag]);
        }
      } else {
        instance.deleteRow(rowIndex);
      }
    }
  };

  /**
   * 할증청구, 기타하불 데이터 불러오는 함수
   * @param {*} id
   */
  const _getExtraChargeData = async (id: string) => {
    try {
      const data = await getData(id);

      setExtraChargeData(data.extraCharge);
      setEtcChargeHabulData(data.etcChargeHabul);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * 할증등록 total계산 함수
   * @param {any[]} charges DB데이터
   * @param {any[]} chargeChanges grid에서 변경된 데이터
   * @param {string} amountName 할증등록은 "chargedAmount" 기타하불은 "habul"
   */
  const _calculateTotalCharge = (
    charges: any[],
    chargeChanges: any[],
    amountName: string
  ) => {
    let total = 0;

    // grid에서 변경된 데이터 insert, update, remove 분리
    const insertChanges =
      chargeChanges?.filter((cur: any) => cur.type === "insert") ?? [];
    const updateChanges =
      chargeChanges?.filter((cur: any) => cur.type === "update") ?? [];
    const removeChanges =
      chargeChanges?.filter((cur: any) => cur.type === "remove") ?? [];

    // DB데이터가 있는경우
    if (Array.isArray(charges) && charges.length > 0) {
      total = charges.reduce((acc, cur) => {
        const chargedAmount =
          updateChanges?.filter((ex) => ex.key === cur.id)[0]?.data?.[
            amountName
          ] ?? cur[amountName];

        return acc + chargedAmount;
      }, total);

      total = insertChanges.reduce((acc, cur) => {
        return acc + cur.data[amountName];
      }, total);

      total = removeChanges.reduce((acc, cur) => {
        const removedCharge = charges.filter(
          (extra) => extra.id === cur.key
        )[0];
        return acc - removedCharge[amountName];
      }, total);
    }
    // grid에서 변경된 데이터만 있는 경우
    else if (Array.isArray(chargeChanges) && chargeChanges.length > 0) {
      total = chargeChanges.reduce((acc, cur) => {
        return acc + parseInt(cur.data[amountName]);
      }, total);
    }
    return total;
  };

  const onClose = () => {
    setExtraChargeModal(false);
  };

  // 할증금액 변경시 합계 계산
  const extraChargeCellRender = useCallback((e: any) => {
    // extraCharges = 할증등록 DB데이터
    const { extraCharges = [] } = e.data;

    /** grid에서 CRUD한 변경된 할증등록 데이터 */
    const extraChargeChanges = gridSub.current.instance
      ?.option("editing.changes")
      ?.filter((cur: any) => cur.key === e.key)[0]?.data?.extraChargeObj;

    // 할증등록 total
    const totalExtraCharge = _calculateTotalCharge(
      extraCharges,
      extraChargeChanges,
      "chargedAmount"
    );

    const formattedTotal = true
      ? totalExtraCharge.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : totalExtraCharge.toLocaleString("ko-KR");

    return <div>{formattedTotal}</div>;
    // eslint-disable-next-line
  }, []);

  // 기타하불 변경시 합계 계산
  const etcChargeHabulCellRender = useCallback((e: any) => {
    // etcChargeHabuls = 기타하불 DB데이터
    const { etcChargeHabules = [] } = e.data;

    /** grid에서 CRUD한 변경된 기타하불 데이터 */
    const etcChargeHabulChanges = gridSub.current.instance
      ?.option("editing.changes")
      ?.filter((cur: any) => cur.key === e.key)[0]?.data?.etcChargeHabulObj;

    // 기타하불 total
    const totalEtcChargeHabul = _calculateTotalCharge(
      etcChargeHabules,
      etcChargeHabulChanges,
      "habul"
    );

    const formattedTotal = true
      ? totalEtcChargeHabul.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : totalEtcChargeHabul.toLocaleString("ko-KR");

    return <div>{formattedTotal}</div>;
    // eslint-disable-next-line
  }, []);

  const extraChargeCellButtonRender = (e: any) => {
    return (
      <Buttons
        type="button"
        size="xsm"
        layout="solid"
        color="grid"
        label="할증등록"
        onClick={() => {
          _getExtraChargeData(e?.data?.id);
          setDetailRowData(e?.data);
          setDetailRowKey(e?.key);

          setTimeout(() => setExtraChargeModal(true), 0);
        }}
      />
    );
  };

  const onExtraChargeSubmit = async () => {
    const DRIVER = 4305; // habulDivision
    const COMPANY = 4304;

    // Get datagrid instances
    const extraChargeInstance = extraChargeRef.current.instance;
    const etcChargeHabulInstance = etcChargeHabulRef.current.instance;

    // Validate datagrids
    const [extraChargeValid, etcChargeHabulValid] = await Promise.all([
      extraChargeInstance.getController("validating").validate(true),
      etcChargeHabulInstance.getController("validating").validate(true),
    ]);

    // validation 실패시 return false;
    if (!extraChargeValid || !etcChargeHabulValid) return false;

    // Get datagrid changes
    const extraChargeData = extraChargeInstance.option("editing.changes");
    const etcChargeHabulData = etcChargeHabulInstance.option("editing.changes");

    // modal datagrid Row
    const etcChargeHabulRow = etcChargeHabulInstance.getVisibleRows();

    // rows를 돌면서 habulDivision이 업체일때 하불처가없으면 오류
    for (const obj of etcChargeHabulRow) {
      if (obj.removed) continue;

      if (
        (obj.data.habulDivision === DRIVER && !obj.data.fromDirverNo) ||
        (obj.data.habulDivision === COMPANY && !obj.data.fromCompany)
      ) {
        alert("하불처 혹은 기사명을 선택해주세요", "오류");
        return;
      }
    }

    // detailGrid Data
    const detailGridData = gridSub.current.instance.option("editing.changes");
    const index = detailGridData.findIndex(
      (obj: any) => obj.key === detailRowKey
    );

    if (index >= 0 && detailGridData[index].type !== "remove") {
      detailGridData[index].data.extraChargeObj = extraChargeData;
      detailGridData[index].data.etcChargeHabulObj = etcChargeHabulData;
      // remove?
    } else {
      detailGridData.push({
        data: {
          extraChargeObj: extraChargeData,
          etcChargeHabulObj: etcChargeHabulData,
        },
        key: detailRowKey,
        type: "update",
      });
    }

    onClose();
  };

  return {
    // data
    extraChargeModal,
    extraChargeData,
    etcChargeHabulData,
    detailRowData,
    detailRowKey,

    // ref
    extraChargeRef,
    etcChargeHabulRef,

    // function
    onClose,
    extraChargeCellButtonRender,
    extraChargeCellRender,
    etcChargeHabulCellRender,
    onExtraChargeSubmit,
  };
};

export default useExtraCharge;
