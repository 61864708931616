import styled, { css } from "styled-components";

interface TabItemProps {
  isSelected?: boolean;
}

export const TabItems = styled.li<TabItemProps>`
  display: flex;
  height: 30px;
  padding: 0 4px 0 8px;
  color: ${(props) => props.theme.colors.titleTxt};
  background: ${(props) => props.theme.colors.black12};
  border-radius: 6px 6px 0 0;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s ease;

  svg {
    path {
      fill: ${(props) => props.theme.colors.titleTxt};
    }
  }

  &:hover {
    color: ${(props) => props.theme.colors.black100};
    background: ${(props) => props.theme.colors.white100};

    svg {
      path {
        fill: ${(props) => props.theme.colors.black100};
      }
    }
  }

  ${(props) =>
    props.isSelected &&
    css`
      color: ${(props) => props.theme.colors.black100};
      background: ${(props) => props.theme.colors.white100};
      cursor: default;

      svg {
        path {
          fill: ${(props) => props.theme.colors.black100};
        }
      }
    `}
`;

export const TabItemTitle = styled.div`
  display: flex;
  width: 100%;
  height: 30px;
  padding-left: 2px;
  padding-right: 20px;
  font-size: 1.3rem;
  font-weight: 500;
  white-space: nowrap;
  align-items: center;
  gap: 8px;
`;

export const TabItemStatus = styled.span<TabItemProps>`
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 3px;
  background: ${(props) => props.theme.colors.primary100};
`;
