import dayjs from "dayjs";
import { userType } from "./user.type";

export interface BankAccountManageSearchType {
  // bizNo: string;
  // bankNumber: string;
  month: string;
}

export const InitBankAccountSearch = (
  userData: userType
): BankAccountManageSearchType => {
  return {
    // bizNo: userData.bizNo,
    // bankNumber: "전체",
    month: dayjs().format("YYYY-MM"),
  };
};
