import { useCallback, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  Column,
  ColumnChooser,
  HeaderFilter,
  Scrolling,
  StateStoring,
} from "devextreme-react/data-grid";
import { useCustomQuery } from "@/hooks/useCustomQuery";
import { InputDate, InputSelect } from "@/components/atoms/Inputs";
import { InputMultiple } from "../../../components/atoms/InputMultiple";
import Grid from "@/components/atoms/Grid";
import { GridContent } from "@/components/organisms/Contents/GridContent";
import { ContentTemplate } from "@/components/templates/ContentTemplate";

const BerthStatus = () => {
  const { t } = useTranslation("main");
  const initialState = {
    startDate: dayjs(new Date()).format("YYYY-MM-DD"),
    endDate: dayjs(new Date()).add(7, "days").format("YYYY-MM-DD"),
    trminlCode: "BPTS",
    searchType: 1,
  };
  const storageKey = "BerthStatus";
  const [search, setSearch] = useState(initialState);
  const [totalCount, setTotalCount] = useState<number>(0);
  const gridMain = useRef<any>();

  const { data: mainData, refetch: mainRefetch } = useCustomQuery(
    `berthStatPy`,
    search
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: initialState,
  });

  const onSearch = (data: any) => {
    setSearch(data);
    mainRefetch();
  };

  return (
    <ContentTemplate>
      <GridContent
        searchSection={
          <>
            <InputMultiple direction="row" label="검색기간">
              <>
                <InputSelect
                  name="searchType"
                  width={120}
                  displayExpr="name"
                  valueExpr="id"
                  control={control}
                  errors={errors}
                  dataSource={[
                    { id: 1, name: "접안예정일자" },
                    { id: 2, name: "출항예정일자" },
                  ]}
                />
                <InputDate
                  name="startDate"
                  width={130}
                  control={control}
                  errors={errors}
                />
                ~
                <InputDate
                  name="endDate"
                  width={130}
                  control={control}
                  errors={errors}
                />
              </>
            </InputMultiple>
            <InputSelect
              label="터미널 코드"
              name="trminlCode"
              width={100}
              displayExpr="name"
              valueExpr="id"
              control={control}
              errors={errors}
              dataSource={[
                { id: "HKT", name: "HKT" },
                { id: "DPCT", name: "DPCT" },
                { id: "BPTG", name: "BPTG" },
                { id: "BPTS", name: "BPTS" },
                { id: "BNMT", name: "BNMT" },
                { id: "BNCT", name: "BNCT" },
                { id: "HPNT", name: "HPNT" },
                { id: "HJNC", name: "HJNC" },
                { id: "PNC", name: "PNC" },
                { id: "PNIT", name: "PNIT" },
                { id: "SNCT", name: "SNCT" },
                { id: "HJIT", name: "HJIT" },
                { id: "E1CT", name: "E1CT" },
                { id: "ICT", name: "ICT" },
                { id: "UNCT", name: "UNCT" },
                { id: "KPOL", name: "KPOL" },
                { id: "PCCT", name: "PCCT" },
                { id: "GWCT", name: "GWCT" },
              ]}
            />
          </>
        }
        searchSubmit={handleSubmit(onSearch)}
        gridRef={gridMain}
        storageKey={storageKey}
        totalCount={totalCount}
      >
        <Grid
          key="oid"
          keyExpr="oid"
          dataSource={mainData}
          refs={gridMain}
          focusedRowEnabled={true}
          onContentReady={(e: any) => setTotalCount(e.component.totalCount())}
        >
          <HeaderFilter visible={true} />
          <ColumnChooser enabled={true} />
          <StateStoring
            enabled={true}
            type="custom"
            customLoad={useCallback(
              () => JSON.parse(localStorage.getItem(storageKey) || "{}"),
              []
            )}
          />
          <Scrolling mode="virtual" />

          <Column
            dataField="trminlCode"
            caption={t("터미널코드")}
            alignment="center"
            width={100}
          />
          <Column
            dataField="berthCode"
            caption={t("선석코드")}
            alignment="center"
            width={90}
          />
          <Column
            dataField="trminlVoyg"
            caption={t("모선/항차")}
            alignment="center"
            width={90}
          />
          <Column
            dataField="wtorcmpCode"
            caption={t("선사")}
            alignment="center"
            width={80}
          />
          <Column
            dataField="trminlShipnm"
            caption={t("모선명")}
            alignment="center"
            width={160}
          />
          <Column
            dataField="shipRute"
            caption={t("항로")}
            alignment="center"
            width={80}
          />
          <Column
            dataField="csdhpPrarnde"
            caption={t("입항예정일시")}
            alignment="center"
            width={140}
          />
          <Column
            dataField="tkoffPrarnde"
            caption={t("출항예정일시")}
            alignment="center"
            width={160}
          />
          <Column
            dataField="csdhpDrc"
            caption={t("접안방향")}
            alignment="center"
            width={90}
          />

          <Column
            dataField="workStarDay"
            caption={t("작업시작일시")}
            alignment="center"
            width={140}
          />
          <Column
            dataField="workFiniDay"
            caption={t("작업완료일시")}
            alignment="center"
            width={140}
          />
          <Column
            dataField="carryFiniDay"
            caption={t("반입마감일시")}
            alignment="center"
            width={140}
          />
          <Column
            dataField="landngQy"
            caption={t("양하수량")}
            alignment="center"
            width={90}
          />
          <Column
            dataField="shipngQy"
            caption={t("적하수량")}
            alignment="center"
            width={90}
          />
          <Column
            dataField="reshmtQy"
            caption={t("이적수량")}
            alignment="center"
            width={90}
          />
          <Column
            dataField="predBerth"
            caption={t("전배")}
            alignment="center"
            width={90}
          />
          <Column
            dataField="shipment"
            caption={t("선적")}
            alignment="center"
            width={100}
          />
          <Column
            dataField="shifting"
            caption={t("S/H")}
            alignment="center"
            width={100}
          />
        </Grid>
      </GridContent>
    </ContentTemplate>
  );
};

export default BerthStatus;
